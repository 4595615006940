import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Box, Button, CircularProgress, Alert } from "@mui/material";

import { TypographyNormal } from "../customComponent";
import useCountDown from "react-countdown-hook";
import axios from "../../config/axios";
import {
  SET_GLOBAL_ERROR,
  SET_GLOBAL_ERROR_MESSAGE,
} from "../../store/actionTypes/globalActionType";

const TimerCountdown = () => {
  const dispatch = useDispatch();

  const SIXTY_SECONDS = +process.env.REACT_APP_OTP_EXPIRY;

  const interval = 1000; // interval to change remaining time amount, defaults to 1000

  const [timeLeft, { start }] = useCountDown(SIXTY_SECONDS, interval);

  const [loadingResendCode, setLoadingResendCode] = useState(false);

  const [counter, setCounter] = useState(1);

  // start the timer during the first render
  useEffect(() => {
    start();
  }, [start]);

  const handleResendCode = async (e) => {
    e.preventDefault();

    setLoadingResendCode(true);
    try {
      const requestToken = sessionStorage.getItem("requestToken");

      const { data } = await axios({
        method: "POST",
        url: "/api/auth/login/request-otp",
        data: { method: "EMAIL" },
        headers: { authorization: `Bearer ${requestToken}` },
      });

      sessionStorage.setItem("otpKey", data.token);
    } catch (error) {
      const errorMessage = error.response.data.message;
      dispatch({ type: SET_GLOBAL_ERROR, payload: true });
      dispatch({ type: SET_GLOBAL_ERROR_MESSAGE, payload: errorMessage });
    } finally {
      start();
      setCounter(counter + 1);
      setLoadingResendCode(false);
    }
  };

  return (
    <Box display="flex" justifyContent="center" sx={{ mt: 1 }}>
      {counter === 3 ? (
        <Alert severity="warning">
          You have reached the maximum resend code.
        </Alert>
      ) : timeLeft / 1000 === 0 ? (
        loadingResendCode ? (
          <Button
            sx={{ fontSize: 14, textTransform: "capitalize", color: "#1C4ED8" }}
            disabled={counter === 3}
            onClick={handleResendCode}
          >
            <CircularProgress
              size={22}
              thickness={3}
              sx={{ color: "#1D4ED8" }}
            />
          </Button>
        ) : (
          <Button
            sx={{ fontSize: 14, textTransform: "capitalize", color: "#1C4ED8" }}
            disabled={counter === 3}
            onClick={handleResendCode}
          >
            Resend Code
          </Button>
        )
      ) : (
        <TypographyNormal sx={{ fontWeight: 300 }} gutterBottom>
          Don't get the code? Resend code in {timeLeft / 1000} seconds
        </TypographyNormal>
      )}
    </Box>
  );
};

export default TimerCountdown;
