import React from "react";
import { Button } from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";
import { useNavigate } from "react-router-dom";

const UserHistoryNavigateButton = () => {
  const navigate = useNavigate();
  return (
    <Button
      startIcon={<HistoryIcon />}
      sx={{ boxShadow: 3, mr: 2 }}
      variant="contained"
      onClick={() => {
        navigate("/users/history");
      }}
    >
      User Action History
    </Button>
  );
};

export default UserHistoryNavigateButton;
