import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Alert,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
} from "@mui/material";
import { CstTypo14W300 } from "../../customComponent";
import { initiateAXIOS } from "../../../config/axios";
import Schedule from "../../../models/ScheduleModel";
import Announcement from "../../../models/AnnouncementModel";
import RenderViewDetailSchedule from "./RenderViewDetailSchedule";
import { initiateAnnouncementAPI, initiateScheduleAPI } from "../../../api";

//TODO when working on Group Assign Schedule
const ViewDetailSchedule = ({ schedule, onClose, openDetail }) => {
  const _isMounted = useRef(true);

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const [scheduleDetail, setScheduleDetail] = useState({});

  const handleClickClose = () => {
    onClose(false);
    setScheduleDetail({});
  };

  const getSchedule = useCallback(async () => {
    const access_token = sessionStorage.getItem("access_token");
    setLoading(true);

    try {
      if (
        schedule.status === "ANNOUNCEMENT_ON" ||
        schedule.status === "ANNOUNCEMENT_OFF"
      ) {
        const { data } = await initiateAXIOS.get(
          initiateAnnouncementAPI + schedule.id,
          {
            headers: { authorization: `Bearer ${access_token}` },
          }
        );

        const OOP = new Announcement(data);
        setScheduleDetail(OOP);
      } else {
        const { data } = await initiateAXIOS.get(
          initiateScheduleAPI + schedule.id,
          {
            headers: { authorization: `Bearer ${access_token}` },
          }
        );

        const OOP = new Schedule(data);
        setScheduleDetail(OOP);
      }
    } catch (error) {
      const errorMessage = error.response.data.message;
      setError(true);
      setErrorMessage(errorMessage);
    } finally {
      setLoading(false);
    }
  }, [schedule.id, schedule.status]);

  useEffect(() => {
    if (openDetail) {
      getSchedule();
    }
    return () => {
      _isMounted.current = false;
    };
  }, [openDetail, schedule.id, getSchedule]);

  return (
    <>
      <Dialog
        maxWidth="lg"
        open={openDetail}
        fullWidth
        onClose={handleClickClose}
      >
        <DialogContent>
          {error && (
            <Alert severity="error" variant="outlined">
              {errorMessage}
            </Alert>
          )}

          {loading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <CircularProgress
                size={20}
                thickness={3}
                sx={{ color: "#1D4ED8" }}
              />
              <CstTypo14W300 sx={{ color: "#1D4ED8", marginTop: "15px" }}>
                Loading Data...
              </CstTypo14W300>
            </Box>
          ) : (
            <RenderViewDetailSchedule
              schedule={scheduleDetail}
              onClose={onClose}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ViewDetailSchedule;
