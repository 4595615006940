import { initiateAXIOS } from "../../config/axios";
import Viewer from "../../models/ViewerModel";
import Group from "../../models/GroupModel";
import Schedule from "../../models/ScheduleModel";
import { handleError } from "../handleError";
import {
  SET_HOME_ACTIVE_ANNOUNCEMENT,
  SET_HOME_ACTIVE_SCHEDULE,
  SET_HOME_ACTIVE_VIEWER,
  SET_HOME_GROUP,
  SET_HOME_LAST_VIEWER_ACTIVE,
  SET_HOME_LOADING_GROUP,
  SET_HOME_LOADING_LAST_VIEWER_ACTIVE,
  SET_HOME_LOADING_SCHEDULE,
  SET_HOME_LOADING_SCHEDULE_PENDING_ACTIVE_STATUS,
  SET_HOME_LOADING_VIEWER,
  SET_HOME_SCHEDULE_PENDING_ACTIVE_STATUS,
  SET_HOME_VIEWER,
  SET_HOME_LOADING_ACTIVE_ANNOUNCEMENT,
} from "../actionTypes/homeActionType";
import {
  SET_GLOBAL_ERROR,
  SET_GLOBAL_ERROR_MESSAGE,
} from "../actionTypes/globalActionType";
import {
  API_LIMIT,
  initiateViewerAPI,
  initiateGroupAPI,
  initiateScheduleAPI,
  announcementAPILimit,
} from "../../api";
import { getContentById } from "./announcementAction";

export const getAllViewers = () => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");
    dispatch({ type: SET_HOME_LOADING_VIEWER, payload: true });

    try {
      const { data } = await initiateAXIOS.get(
        initiateViewerAPI + `?limit=${API_LIMIT}`,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      let viewers = [];
      const listViewer = data.items;
      listViewer.map((item) => viewers.push(new Viewer(item)));

      const active = viewers.filter((viewer) => viewer.status === "ACTIVE");

      dispatch({ type: SET_HOME_VIEWER, payload: viewers });
      dispatch({ type: SET_HOME_ACTIVE_VIEWER, payload: active });
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_GLOBAL_ERROR, payload: true });
      dispatch({ type: SET_GLOBAL_ERROR_MESSAGE, payload: message });
    } finally {
      dispatch({ type: SET_HOME_LOADING_VIEWER, payload: false });
    }
  };
};

export const getGroups = () => {
  const access_token = sessionStorage.getItem("access_token");
  return async (dispatch) => {
    dispatch({ type: SET_HOME_LOADING_GROUP, payload: true });

    try {
      const { data } = await initiateAXIOS.get(
        initiateGroupAPI + `?limit=${API_LIMIT}`,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      const listGroup = data.items;
      let groups = [];
      listGroup.map((item) => groups.push(new Group(item)));
      dispatch({ type: SET_HOME_GROUP, payload: groups });
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_GLOBAL_ERROR, payload: true });
      dispatch({ type: SET_GLOBAL_ERROR_MESSAGE, payload: message });
    } finally {
      dispatch({ type: SET_HOME_LOADING_GROUP, payload: false });
    }
  };
};

export const getActiveSchedules = () => {
  const access_token = sessionStorage.getItem("access_token");
  return async (dispatch) => {
    dispatch({ type: SET_HOME_LOADING_SCHEDULE, payload: true });

    try {
      const { data } = await initiateAXIOS.get(
        initiateScheduleAPI + `?limit=${API_LIMIT}&isActive=true`,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      let schedules = [];
      const dataSchedule = data.items;
      dataSchedule.map((item) => schedules.push(new Schedule(item)));
      dispatch({ type: SET_HOME_ACTIVE_SCHEDULE, payload: schedules });
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_GLOBAL_ERROR, payload: true });
      dispatch({ type: SET_GLOBAL_ERROR_MESSAGE, payload: message });
    } finally {
      dispatch({ type: SET_HOME_LOADING_SCHEDULE, payload: false });
    }
  };
};

export const getLastActiveViewers = (limit) => {
  return async (dispatch) => {
    dispatch({
      type: SET_HOME_LAST_VIEWER_ACTIVE,
      payload: [],
    });
    const access_token = sessionStorage.getItem("access_token");
    dispatch({ type: SET_HOME_LOADING_LAST_VIEWER_ACTIVE, payload: true });

    try {
      const { data } = await initiateAXIOS.get(
        initiateViewerAPI + `?limit=${limit}&sortBy=updatedAt&sortOrder=desc`,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      let viewers = [];
      const listViewer = data.items;
      listViewer.map((item) => viewers.push(new Viewer(item)));

      dispatch({
        type: SET_HOME_LAST_VIEWER_ACTIVE,
        payload: viewers,
      });
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_GLOBAL_ERROR, payload: true });
      dispatch({ type: SET_GLOBAL_ERROR_MESSAGE, payload: message });
    } finally {
      dispatch({ type: SET_HOME_LOADING_LAST_VIEWER_ACTIVE, payload: false });
    }
  };
};

export const getPendingActiveSchedules = (limit) => {
  return async (dispatch) => {
    dispatch({ type: SET_HOME_SCHEDULE_PENDING_ACTIVE_STATUS, payload: [] });

    const access_token = sessionStorage.getItem("access_token");
    dispatch({
      type: SET_HOME_LOADING_SCHEDULE_PENDING_ACTIVE_STATUS,
      payload: true,
    });

    try {
      const { data } = await initiateAXIOS.get(
        initiateScheduleAPI + `?limit=${limit}`,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      let schedules = [];
      const dataSchedule = data.items;
      dataSchedule.map((item) => schedules.push(new Schedule(item)));
      dispatch({
        type: SET_HOME_SCHEDULE_PENDING_ACTIVE_STATUS,
        payload: schedules,
      });
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_GLOBAL_ERROR, payload: true });
      dispatch({ type: SET_GLOBAL_ERROR_MESSAGE, payload: message });
    } finally {
      dispatch({
        type: SET_HOME_LOADING_SCHEDULE_PENDING_ACTIVE_STATUS,
        payload: false,
      });
    }
  };
};

export const getActiveAnnouncements = () => {
  return async (dispatch) => {
    dispatch({
      type: SET_HOME_ACTIVE_ANNOUNCEMENT,
      payload: [],
    });
    const access_token = sessionStorage.getItem("access_token");
    dispatch({ type: SET_HOME_LOADING_ACTIVE_ANNOUNCEMENT, payload: true });

    try {
      const { data } = await initiateAXIOS.get(
        // TODO check add isActive=true param
        announcementAPILimit + "&isActive=true",
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      let announcements = [];
      await Promise.all(
        data.items.map(async (ann) => {
          const contentData = await getContentById(dispatch, ann.contentId);
          announcements.push(new Schedule({ ...ann, content: contentData }));
        })
      );

      dispatch({
        type: SET_HOME_ACTIVE_ANNOUNCEMENT,
        payload: announcements,
      });
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_GLOBAL_ERROR, payload: true });
      dispatch({ type: SET_GLOBAL_ERROR_MESSAGE, payload: message });
    } finally {
      dispatch({ type: SET_HOME_LOADING_ACTIVE_ANNOUNCEMENT, payload: false });
    }
  };
};
