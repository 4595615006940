import React, { useState } from "react";

import { IconButton, Menu, TableCell, TableRow } from "@mui/material";

import { styled } from "@mui/material/styles";

import { CustomMenuItem } from "../customComponent";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteContentUpload from "./DeleteContentUpload";
import EditContentUpload from "./EditContentUpload";
import DetailContentUpload from "./DetailContentUpload";
import InstallDesktopIcon from "@mui/icons-material/InstallDesktop";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
// import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import AssignContent from "./AssignContent";
// import AssignChildContent from "./AssignChildContent";

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f3f8fe",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ContentUploadItem = ({ contentUpload }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openAssign, setOpenAssign] = useState(false);
  // const [openChildAssign, setOpenChildAssign] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpenAssign = () => {
    setOpenAssign(true);
    handleClose();
  };
  const handleClickCloseAssign = () => {
    setOpenAssign(false);
  };

  // const handleClickOpenChildAssign = () => {
  //   setOpenChildAssign(true);
  //   handleClose();
  // };
  // const handleClickCloseChildAssign = () => {
  //   setOpenChildAssign(false);
  // };

  const handleClickOpenEdit = () => {
    setOpenEdit(true);
    handleClose();
  };
  const handleClickCloseEdit = () => {
    setOpenEdit(false);
  };

  return (
    <StyledTableRow>
      <TableCell>{contentUpload.name}</TableCell>
      <TableCell>{contentUpload.type}</TableCell>
      <TableCell align="right">
        <IconButton
          sx={{ color: "#111827" }}
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <MoreHorizIcon fontSize="small" />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <DetailContentUpload data={contentUpload} />
          {contentUpload.type === "CONTENT" && (
            <CustomMenuItem onClick={handleClickOpenAssign} dense>
              <InstallDesktopIcon fontSize="small" sx={{ mr: "5px" }} />
              Assign Content
            </CustomMenuItem>
          )}

          {/* <CustomMenuItem onClick={handleClickOpenChildAssign} dense>
            <LibraryAddIcon fontSize="small" sx={{ mr: "5px" }} />
            Create Child Content
          </CustomMenuItem> */}

          <CustomMenuItem onClick={handleClickOpenEdit} dense>
            <EditOutlinedIcon
              color="primary"
              fontSize="small"
              sx={{ mr: "5px" }}
            />
            Edit Content Upload
          </CustomMenuItem>
          <DeleteContentUpload id={contentUpload.id} />
        </Menu>

        <AssignContent
          id={contentUpload.id}
          open={openAssign}
          handleClickClose={handleClickCloseAssign}
        />

        {/* <AssignChildContent
          id={contentUpload.id}
          type={contentUpload.type}
          open={openChildAssign}
          handleClickClose={handleClickCloseChildAssign}
        /> */}

        <EditContentUpload
          data={contentUpload}
          open={openEdit}
          handleClickClose={handleClickCloseEdit}
        />
      </TableCell>
    </StyledTableRow>
  );
};

export default ContentUploadItem;
