import React from "react";

const DisplayIcon = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.6001 5.99998C3.6001 4.67449 4.67461 3.59998 6.0001 3.59998H18.0001C19.3256 3.59998 20.4001 4.67449 20.4001 5.99997V15.6C20.4001 16.9255 19.3256 18 18.0001 18H15.3371L15.4837 18.5865L16.4487 19.5514C16.7919 19.8946 16.8945 20.4108 16.7088 20.8592C16.5231 21.3076 16.0855 21.6 15.6001 21.6H8.40013C7.91478 21.6 7.47721 21.3076 7.29148 20.8592C7.10574 20.4108 7.20841 19.8946 7.5516 19.5514L8.51658 18.5865L8.6632 18H6.0001C4.67461 18 3.6001 16.9255 3.6001 15.6V5.99998ZM10.5257 14.4C10.508 14.3996 10.4904 14.3996 10.4728 14.4H6.0001V5.99997H18.0001V14.4H13.5275C13.5099 14.3996 13.4923 14.3996 13.4746 14.4H10.5257Z"
          fill="#9CA3AF"
        />
      </svg>
    </>
  );
};

export default DisplayIcon;
