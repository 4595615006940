import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  // Button,
  CircularProgress,
  IconButton,
  Paper,
  Tooltip,
} from "@mui/material";
import { TypographyLarge, TypographyNormal } from "../customComponent";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import CloseIcon from "@mui/icons-material/Close";
import { SET_OPEN_UPLOAD_WINDOW } from "../../store/actionTypes/contentUploadActionType";

const colorStyling = {
  inProgress: "#0288d1",
  completed: "#2e7d32",
  failed: "#d10220",
};

const UploadingWindow = () => {
  const dispatch = useDispatch();
  const { openUploadWindow, uploadProgress, uploadFilename, uploadStatus } =
    useSelector((state) => state.contentUpload);

  const handleCloseWindow = (e) => {
    dispatch({ type: SET_OPEN_UPLOAD_WINDOW, payload: false });
  };

  return (
    <>
      {openUploadWindow && (
        <Paper
          elevation={10}
          sx={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            zIndex: 1500,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              alignContent: "center",
              flexWrap: "nowrap",
              backgroundColor:
                uploadStatus === "completed"
                  ? colorStyling.completed
                  : uploadStatus === "failed"
                  ? colorStyling.failed
                  : colorStyling.inProgress,
              py: "4px",
              px: "8px",
            }}
          >
            <TypographyNormal sx={{ color: "#fff" }}>
              {uploadStatus === "completed"
                ? "Upload completed."
                : uploadStatus === "failed"
                ? "Upload failed."
                : uploadStatus === "canceled"
                ? "Upload canceled."
                : "Upload in progress..."}
            </TypographyNormal>
            {uploadStatus !== "progress" && (
              <IconButton onClick={handleCloseWindow}>
                <CloseIcon sx={{ color: "#fff" }} />
              </IconButton>
            )}
          </Box>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            {uploadStatus === "completed" ? (
              <CheckCircleIcon
                color="success"
                sx={{ m: "8px", fontSize: "32px" }}
              />
            ) : uploadStatus === "failed" || uploadStatus === "canceled" ? (
              <ErrorIcon color="error" sx={{ m: "8px", fontSize: "32px" }} />
            ) : (
              <CircularProgress size="32px" sx={{ m: "8px" }} />
            )}

            {uploadFilename.length > 26 ? (
              <Tooltip title={uploadFilename}>
                <TypographyLarge sx={{ mx: "8px" }}>
                  {uploadFilename.substr(0, 26) + "..."}
                </TypographyLarge>
              </Tooltip>
            ) : (
              <TypographyLarge sx={{ mx: "8px" }}>
                {uploadFilename}
              </TypographyLarge>
            )}

            <TypographyLarge sx={{ mx: "8px" }}>
              {uploadProgress + "%"}
            </TypographyLarge>
          </Box>

          {/* {uploadStatus === "progress" && (
            <Box display="flex" justifyContent="flex-end">
              <Button
                size="small"
                color="error"
                onClick={() => {
                  abortUpload();
                }}
              >
                Cancel
              </Button>
            </Box>
          )} */}
        </Paper>
      )}
    </>
  );
};

export default UploadingWindow;
