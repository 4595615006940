import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  IconButton,
  Menu,
  Tooltip,
  MenuItem,
  Radio,
  FormControlLabel,
  RadioGroup,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import SortIcon from "@mui/icons-material/Sort";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import SearchIcon from "@mui/icons-material/SearchOutlined";

import { getGroups } from "../../store/actions/groupAction";
import { API_LIMIT } from "../../api";
import { ascending, descending } from "../../helpers/sorting";
import { SET_GROUP } from "../../store/actionTypes/groupActionType";

const FilterGroup = () => {
  const { groups } = useSelector((state) => state.group);
  const dispatch = useDispatch();

  const [groupName, setGroupName] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);

  const openSortButton = Boolean(anchorEl);

  const [sortBy, setSortBy] = useState("Oldest");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeSortBy = (event) => {
    const value = event.target.value;
    setSortBy(value);

    let sorted;

    switch (value) {
      case "Oldest":
        sorted = ascending(groups, "id");
        break;

      case "Newest":
        sorted = descending(groups, "id");
        break;

      case "A-Z":
        sorted = ascending(groups, "name");
        break;

      case "Z-A":
        sorted = descending(groups, "name");
        break;

      default:
        break;
    }

    dispatch({ type: SET_GROUP, payload: sorted });
  };

  const handleRefresh = () => {
    const query = "?limit=" + API_LIMIT;
    dispatch(getGroups(query));
    setSortBy("");
    setGroupName("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const query = `&nameLike=${groupName}`;
    dispatch(getGroups(`?limit=${API_LIMIT}${query}`));
  };

  return (
    <>
      <Box
        component="form"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        onSubmit={handleSubmit}
      >
        <TextField
          fullWidth
          size="small"
          type="search"
          placeholder="Search group..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
        />

        <Button onClick={handleSubmit} variant="contained" sx={{ ml: 2 }}>
          Search
        </Button>

        <Box sx={{ display: "flex", ml: 2 }}>
          <IconButton
            sx={{ color: "#111827" }}
            onClick={handleClick}
            aria-controls={openSortButton ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openSortButton ? "true" : undefined}
          >
            <Tooltip title="Sort">
              <SortIcon fontSize="small" />
            </Tooltip>
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openSortButton}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <RadioGroup value={sortBy} onChange={handleChangeSortBy}>
              <MenuItem>
                <FormControlLabel
                  disableTypography
                  value="Oldest"
                  control={<Radio size="small" />}
                  label="Oldest"
                  sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
                />
              </MenuItem>
              <MenuItem>
                <FormControlLabel
                  disableTypography
                  value="Newest"
                  control={<Radio size="small" />}
                  label="Newest"
                  sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
                />
              </MenuItem>
              <MenuItem>
                <FormControlLabel
                  disableTypography
                  value="A-Z"
                  control={<Radio size="small" />}
                  label="A-Z"
                  sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
                />
              </MenuItem>
              <MenuItem>
                <FormControlLabel
                  disableTypography
                  value="Z-A"
                  control={<Radio size="small" />}
                  label="Z-A"
                  sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
                />
              </MenuItem>
            </RadioGroup>
          </Menu>

          <IconButton
            sx={{ color: "#111827", ml: 2 }}
            color="primary"
            onClick={handleRefresh}
          >
            <Tooltip title="Clear">
              <ClearOutlinedIcon fontSize="small" />
            </Tooltip>
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export default FilterGroup;
