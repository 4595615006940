import React, { useState } from "react";
import { useDispatch } from "react-redux";

import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import { editSchedule } from "../../../store/actions/scheduleAction";

const ApproveSchedule = ({ schedule }) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let status;

    schedule.isApproved ? (status = false) : (status = true);

    const payload = {
      id: schedule.id,
      schedule: { isApproved: status },
    };

    dispatch(editSchedule(payload));
    setOpen(false);
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        color={schedule.isApproved ? "warning" : "success"}
        variant="contained"
        startIcon={
          schedule.isApproved ? (
            <UnpublishedIcon fontSize="small" />
          ) : (
            <CheckCircleIcon fontSize="small" />
          )
        }
        sx={{ mr: 2, boxShadow: 3 }}
      >
        {schedule.isApproved ? "Unapprove Schedule" : "Approve Schedule"}
      </Button>

      <Dialog maxWidth="sm" open={open} onClose={handleClickClose}>
        <DialogContent>
          <DialogContentText
            sx={{
              textAlign: "center",
              mb: 2,
            }}
          >
            Are you sure want to{" "}
            <strong>{schedule.isApproved ? "Unapprove" : "Approve"}</strong> the
            schedule <strong>{schedule.name}?</strong>{" "}
          </DialogContentText>

          <Box>
            <Alert severity="warning" variant="outlined">
              {schedule.isApproved ? (
                <strong>
                  Unapproving will automatically deactivate the schedule
                </strong>
              ) : (
                <strong>
                  Approving will automatically activate the schedule
                </strong>
              )}
            </Alert>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            color="error"
            variant="outlined"
            fullWidth
            startIcon={<ClearIcon />}
            onClick={handleClickClose}
          >
            Cancel
          </Button>

          <Button
            color={schedule.isApproved ? "warning" : "primary"}
            variant="contained"
            fullWidth
            startIcon={<DoneIcon />}
            onClick={handleSubmit}
          >
            {schedule.isApproved ? "Unapprove Schedule" : "Approve Schedule"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ApproveSchedule;
