import React from "react";

const GroupBigBlueIcon = () => {
  return (
    <>
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="32" cy="32" r="32" fill="#EFF6FF" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.9129 28.739V31.3477H28.0868C27.1365 31.3477 26.2455 31.0936 25.4781 30.6497V37.8694C25.4781 39.3102 26.646 40.4781 28.0868 40.4781H35.9129V43.0868H28.0868C25.2053 43.0868 22.8694 40.7509 22.8694 37.8694V26.1303V25.4781V24.8259H25.4781V25.4781V26.1303C25.4781 27.571 26.646 28.739 28.0868 28.739H35.9129Z"
          fill="#FFBD80"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.9565 22.2174C18.9565 25.0989 21.2924 27.4348 24.1739 27.4348C27.0554 27.4348 29.3913 25.0989 29.3913 22.2174C29.3913 19.3359 27.0554 17 24.1739 17C21.2924 17 18.9565 19.3359 18.9565 22.2174ZM26.7827 22.2173C26.7827 23.658 25.6147 24.826 24.174 24.826C22.7332 24.826 21.5653 23.658 21.5653 22.2173C21.5653 20.7766 22.7332 19.6086 24.174 19.6086C25.6147 19.6086 26.7827 20.7766 26.7827 22.2173Z"
          fill="#F55858"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.6086 41.7826C34.6086 44.6641 36.9445 47 39.826 47C42.7075 47 45.0434 44.6641 45.0434 41.7826C45.0434 38.9011 42.7075 36.5652 39.826 36.5652C36.9445 36.5652 34.6086 38.9011 34.6086 41.7826ZM42.4348 41.7827C42.4348 43.2234 41.2668 44.3913 39.8261 44.3913C38.3853 44.3913 37.2174 43.2234 37.2174 41.7827C37.2174 40.3419 38.3853 39.174 39.8261 39.174C41.2668 39.174 42.4348 40.3419 42.4348 41.7827Z"
          fill="#FFBD80"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.6086 30.0433C34.6086 32.9248 36.9445 35.2607 39.826 35.2607C42.7075 35.2607 45.0434 32.9248 45.0434 30.0433C45.0434 27.1618 42.7075 24.8259 39.826 24.8259C36.9445 24.8259 34.6086 27.1618 34.6086 30.0433ZM42.4348 30.0432C42.4348 31.484 41.2668 32.6519 39.8261 32.6519C38.3853 32.6519 37.2174 31.484 37.2174 30.0432C37.2174 28.6025 38.3853 27.4345 39.8261 27.4345C41.2668 27.4345 42.4348 28.6025 42.4348 30.0432Z"
          fill="#00D4FF"
        />
      </svg>
    </>
  );
};

export default GroupBigBlueIcon;
