import React, { useEffect, useState } from "react";
import { Alert, Box, CircularProgress, Pagination } from "@mui/material";
import { TypographyNormal } from "../customComponent";
import { useSelector, useDispatch } from "react-redux";
import { getViewers } from "../../store/actions/viewerAction";
import ViewerItem from "./ViewerItem";
import { usePagination } from "../hooks";

const ViewerList = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getViewers());
  }, [dispatch]);

  const { viewers, loadingViewer } = useSelector((state) => state.viewer);

  const [page, setPage] = useState(1);
  const PER_PAGE = 5;

  const count = Math.ceil(viewers.length / PER_PAGE);
  const _DATA = usePagination(viewers, PER_PAGE);

  const handleChangePagination = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  useEffect(() => {
    setPage(1);
    _DATA.jump(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewers]);

  if (loadingViewer)
    return (
      <Box
        sx={{ height: "20vh" }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <CircularProgress size={22} thickness={3} sx={{ color: "#1D4ED8" }} />
        <TypographyNormal sx={{ color: "#1D4ED8", mt: 2, fontWeight: 300 }}>
          Loading Data...
        </TypographyNormal>
      </Box>
    );

  return (
    <>
      {!loadingViewer && !viewers.length ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Alert severity="info" variant="outlined">
            <strong>No results found!</strong> There are no available viewers at
            the moment or We couldn't find what you're looking for
          </Alert>
        </Box>
      ) : (
        _DATA
          .currentData()
          .map((viewer) => <ViewerItem key={viewer.id} viewer={viewer} />)
      )}

      {!loadingViewer && viewers.length ? (
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
          <Pagination
            count={count}
            page={page}
            shape="rounded"
            color="primary"
            onChange={handleChangePagination}
          />
        </Box>
      ) : null}
    </>
  );
};

export default ViewerList;
