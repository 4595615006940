import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Dialog, DialogContent } from "@mui/material";
import { CstTypo14W300 } from "../../customComponent";
import { initiateAXIOS } from "../../../config/axios";
import Viewer from "../../../models/ViewerModel";
import { useDispatch } from "react-redux";
import {
  SET_GLOBAL_ERROR,
  SET_GLOBAL_ERROR_MESSAGE,
} from "../../../store/actionTypes/globalActionType";
import RenderViewDetailViewer from "./RenderViewDetailViewer";
import { initiateViewerAPI } from "../../../api/";
import { colorStyling } from "../../../helpers/color";
import Schedule from "../../../models/ScheduleModel";

const ViewDetailViewer = ({ viewer, openDetail, onClose }) => {
  const dispatch = useDispatch();

  const handleClickClose = () => {
    onClose(false);
    setViewerDetail({});
  };

  const [loading, setLoading] = useState(false);

  const [viewerDetail, setViewerDetail] = useState({});

  const getViewer = async () => {
    const access_token = sessionStorage.getItem("access_token");
    setLoading(true);

    try {
      const { data } = await initiateAXIOS.get(initiateViewerAPI + viewer.id, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      let scheduleData = [];
      data.schedules.map((item) => scheduleData.push(new Schedule(item)));

      const OOP = new Viewer({ ...data, schedules: scheduleData });
      setViewerDetail(OOP);
    } catch (e) {
      const errorMessage = e.response.data.message;
      dispatch({ type: SET_GLOBAL_ERROR, payload: true });
      dispatch({
        type: SET_GLOBAL_ERROR_MESSAGE,
        payload: errorMessage,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (openDetail) {
      getViewer();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDetail]);

  return (
    <>
      <Dialog
        maxWidth="lg"
        fullWidth
        open={openDetail}
        onClose={handleClickClose}
      >
        <DialogContent>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CircularProgress
                size={20}
                thickness={3}
                sx={{ color: colorStyling.primary }}
              />
              <CstTypo14W300 sx={{ color: colorStyling.primary, mt: 2 }}>
                Loading Data...
              </CstTypo14W300>
            </Box>
          ) : (
            <RenderViewDetailViewer viewer={viewerDetail} onClose={onClose} />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ViewDetailViewer;
