import React, { useState, forwardRef } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Dialog,
  AppBar,
  Slide,
  IconButton,
  Toolbar,
  Typography,
  Paper,
  Box,
  Pagination,
} from "@mui/material";
import { CustomTableHead, TertiaryButton } from "../customComponent";
import {
  LoginImg,
  SidebarImg,
  DashboardImg,
  GroupImg,
  CreateAGroupImg,
  SubgroupsImg,
  InsideGroupsOneImg,
  InsideGroupsTwoImg,
  ViewerOneImg,
  ViewerTwoImg,
  ScheduleOneImg,
  ScheduleTwoImg,
  CreateAScheduleImg,
  AssigningAScheduleOneImg,
  AssigningAScheduleTwoImg,
  JobHistoryImg,
  AnnouncementImg,
  CreateAnAnnouncementImg,
} from "./images";
import { Close } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { usePagination } from "../hooks";

const data = [
  {
    title: "Logging in",
    description: "Enter your username and password given.",
    img: LoginImg,
  },
  {
    title: "Sidebar",
    description: "Use the sidebar to navigate between pages.",
    img: SidebarImg,
  },
  {
    title: "Dashboard",
    description:
      "This is the page where you can have an overview of all current active Viewers and Schedules. It can also give you a brief summary of you Viewers, Schedules, and Groups.",
    img: DashboardImg,
  },
  {
    title: "Group",
    description:
      "Here you will be able to access your current Group, and add new Groups.",
    img: GroupImg,
  },
  {
    title: "Creating a Group",
    description:
      "To create a group, click on the “+ Create New Group” button. A pop-up similar to the picture on the right will appear. Enter your group name in the row, and click  the “Create Group” button.",
    img: CreateAGroupImg,
  },
  {
    title: "Subgroups",
    description:
      "Click on the down arrow button to view all Subgroups on a Group. The down arrow will subsequently change to an up arrow, displaying the Subgroups. Use the search bar and filters to find specific Groups.",
    img: SubgroupsImg,
  },
  {
    title: "Inside Groups",
    description:
      "Selecting a group bring you to a page similar to this. Here you may view it’s Subgroups, Viewers, and Schedules. ",
    img: InsideGroupsOneImg,
  },
  {
    title: "",
    description:
      "Click the 3 dots beside the Group’s name to bring up a pop-up. Here you may change the Group’s name or delete it.",
    img: InsideGroupsTwoImg,
  },
  {
    title: "Viewer",
    description:
      "Here you can have an overview of all your current Viewers. You can also check on their statuses individually. Use the search bars and filters to find specific Viewers.",
    img: ViewerOneImg,
  },
  {
    title: "",
    description:
      "Clicking on a Viewer opens up a pop-up with the Viewer’s details.",
    img: ViewerTwoImg,
  },
  {
    title: "Schedule",
    description:
      "Here you can view all Schedules and view their details. Use the search bar and filters to find a specific Schedule.",
    img: ScheduleOneImg,
  },
  {
    title: "",
    description: "Click on a Schedule to view it’s details.",
    img: ScheduleTwoImg,
  },
  {
    title: "Creating a Schedule",
    description:
      "Click on the “+ Create New Schedule” button. A pop-up similar to the picture on the right will appear. Enter the Schedult name, it’s frequency, it’s type, it’s command, and it’s start and end date. Once done click the “Create Schedule” button. Frequency is how often the Schedule will be put out. Type is the type of device you would like to take action. Command is the action you would like the device to take.",
    img: CreateAScheduleImg,
  },
  {
    title: "Assigning a Schedule",
    description:
      "Click the “New Assign” button. A pop-up will appear below the button. The image of the pop-up has been enlarged. Click the “Assign Group” button to assign the Schedule to a Group. Click the “Assign Viewer” button to assign the Schedule to a Group. A pop-up similar to the images on the right will appear respectively. ",
    img: AssigningAScheduleOneImg,
  },
  {
    title: "",
    description:
      "Click on the check boxes on the right of the pop-up to choose which Viewers/Groups will have the Schedule assigned to it. Use the search bar on either pop-up to find a specific Viewer/Group. Click the “Assign” button on the bottom of the pop-up to assign the Schedule accordingly to your specifications.",
    img: AssigningAScheduleTwoImg,
  },
  {
    title: "Job History",
    description:
      "Here you can view the history of Schedules assigned. Use the search bar and filters to find a specific Schedule. Click on a previous job to view it’s details.",
    img: JobHistoryImg,
  },
  {
    title: "Announcements",
    description:
      "Here you can have an overview of all announcements. Use the search bar and filters to find a specific Announcement. Click on an announcement to view it’s details.",
    img: AnnouncementImg,
  },
  {
    title: "Creating an Announcement",
    description:
      "Click on the “+ Create New Announcement” button. A pop-up similar to the picture on the right should appear. Enter your Announcement name and a link (if any). Once done click the “Create Announcement” button.",
    img: CreateAnAnnouncementImg,
  },
];

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ShowImage = ({ open, onClose, item }) => {
  const handleClickClose = () => {
    onClose(false);
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={handleClickClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative", backgroundColor: "#1C4ED8" }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {item.title}
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClickClose}
            aria-label="close"
          >
            <Close />
          </IconButton>
        </Toolbar>
      </AppBar>
      <img src={item.img} alt="img content" />
    </Dialog>
  );
};

const RenderData = ({ index, item }) => {
  const [showImg, setShowImg] = useState(false);

  const handleOpenImg = () => {
    setShowImg(true);
  };

  const handleCloseSImg = () => {
    setShowImg(false);
  };

  const StyledTableRow = styled(TableRow)(() => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#f3f8fe",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <>
      <StyledTableRow key={index}>
        <TableCell>{item.title}</TableCell>
        <TableCell>{item.description}</TableCell>
        <TableCell>
          <TertiaryButton onClick={handleOpenImg}>View</TertiaryButton>
        </TableCell>
        <ShowImage open={showImg} onClose={handleCloseSImg} item={item} />
      </StyledTableRow>
    </>
  );
};

const TableComponent = () => {
  const [page, setPage] = useState(1);

  const DATA_PER_PAGE = 7;

  const count = Math.ceil(data.length / DATA_PER_PAGE);

  const _DATA = usePagination(data, DATA_PER_PAGE);

  const handleChangePagination = (e, page) => {
    setPage(page);
    _DATA.jump(page);
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ overflowY: "auto", maxHeight: 700 }}
      >
        <Table aria-label="global table">
          <TableHead>
            <TableRow>
              <CustomTableHead>Title</CustomTableHead>
              <CustomTableHead>Description</CustomTableHead>
              <CustomTableHead>Content</CustomTableHead>
            </TableRow>
          </TableHead>

          <TableBody>
            {_DATA.currentData().map((item, index) => (
              <RenderData key={index} item={item} index={index} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {data.length ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            pt: 4,
            pb: 2,
            pr: 2,
          }}
        >
          <Pagination
            count={count}
            page={page}
            shape="rounded"
            color="primary"
            onChange={handleChangePagination}
          />
        </Box>
      ) : null}
    </>
  );
};

export default TableComponent;
