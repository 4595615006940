import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import UploadIcon from "@mui/icons-material/Upload";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import LoopIcon from '@mui/icons-material/Loop';
import { debounce } from "lodash";
import { getAllAssets } from "../../../../store/actions/cmsAction"; // TODO: removeAsset
import UploadField from "../UploadField";
import MediaItem from "./MediaItem";
import UploadingMediaItem from "./UploadingMediaItem";
import { TypographySmall } from "../../../customComponent";

const MediaPicker = ({ open, handleClose, name, addContent }) => {
  const dispatch = useDispatch();
  const { media, uploadingMedia, loadingMedia, errorHandler } = useSelector((state) => state.cms);

  const [mediaLimit] = useState(10);
  const [mediaOffset, setMediaOffset] = useState(0);
  const [mediaType, setMediaType] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [selectedMedia, setSelectedMedia] = useState({});
  const [openUpload, setOpenUpload] = useState(false);
  const [combinedCount, setCombinedCount] = useState(0);

  const handleCloseUploadUI = () => {
    setOpenUpload(false);
  };

  useEffect(() => {
    const getPayload = {
      limit: mediaLimit,
      offset: mediaOffset,
      type: mediaType,
      nameLike: searchInput,
    };
    open ? dispatch(getAllAssets(getPayload)) : setOpenUpload(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, mediaLimit, mediaOffset, mediaType]);

  const navigateBack = useCallback(
    () => {
      const nextOffset = mediaOffset - mediaLimit;
      setMediaOffset(nextOffset);

      const getPayload = {
        limit: mediaLimit,
        offset: nextOffset,
        type: mediaType,
        nameLike: searchInput,
      };

      dispatch(getAllAssets(getPayload));
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mediaLimit, mediaOffset, mediaType, searchInput],
  );

  const navigateNext = useCallback(
    () => {
      const nextOffset = mediaOffset + mediaLimit;
      setMediaOffset(nextOffset);

      const getPayload = {
        limit: mediaLimit,
        offset: nextOffset,
        type: mediaType,
        nameLike: searchInput,
      };

      dispatch(getAllAssets(getPayload));
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mediaLimit, mediaOffset, mediaType, searchInput],
  );

  const searchByLikeName = useCallback(
    (input) => {
      const getPayload = {
        limit: mediaLimit,
        offset: 0,
        type: mediaType,
        nameLike: input,
      };

      setMediaOffset(0);
      dispatch(getAllAssets(getPayload));
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mediaLimit, mediaOffset, mediaType],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dbcSearchFn = useCallback(debounce(searchByLikeName, 1111), [mediaLimit, mediaOffset, mediaType]);

  const handleSearchInputChange = (event) => {
    const val = event.target.value;
    setSearchInput(val);
    dbcSearchFn(val);
  };

  const handleMediaTypeChange = useCallback(
    (event) => {
      const val = event.target.value;
      setMediaType(val);

      const getPayload = {
        limit: mediaLimit,
        offset: 0,
        type: val,
        nameLike: searchInput,
      };

      setMediaOffset(0);
      dispatch(getAllAssets(getPayload));
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mediaLimit, mediaOffset, mediaType, searchInput],
  );

  useEffect(() => {
    const counts = uploadingMedia?.length + media?.length;
    setCombinedCount(counts);
  }, [uploadingMedia, media]);

  return (
    <Dialog open={open} maxWidth="lg" fullWidth onClose={() => handleClose()}>
      <DialogTitle
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        Media Picker {name}
        <IconButton onClick={() => handleClose()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ minHeight: "60vh" }}>
        {!openUpload ? (
          <>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{ mt: 1 }}
            >
              <TextField
                onChange={handleSearchInputChange}
                value={searchInput}
                fullWidth
                type="Search"
                placeholder="Search media..."
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                size="small"
                select
                SelectProps={{ native: true }}
                value={mediaType}
                onChange={handleMediaTypeChange}
                sx={{ mx: 2, width: "150px" }}
              >
                <option defaultValue value="">
                  All Media
                </option>
                <option value="IMAGE">Images</option>
                <option value="VIDEO">Videos</option>
              </TextField>
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{ mt: 1 }}
            >
              <Button
                variant="outlined"
                sx={{ height: "320px", minWidth: "fit-content", padding: 0 }}
                disabled={mediaOffset === 0}
                onClick={() => navigateBack()}
              >
                <NavigateBeforeIcon
                  color="inherit"
                  sx={{ fontSize: "33px" }}
                />
              </Button>

              {loadingMedia && 
                <Box
                  alignContent={"center"}
                  sx={{ py: 2 }}
                  height={"33vw"}
                >
                  <LoopIcon
                    color="inherit"
                    sx={{
                      fontSize: "55px",
                      animation: "spin 2s linear infinite",
                      "@keyframes spin": {
                        "0%": {
                          transform: "rotate(360deg)",
                        },
                        "100%": {
                          transform: "rotate(0deg)",
                        },
                      },
                    }}
                  />
                </Box>
              }
              
              {!loadingMedia && 
                <Grid container
                  gap={"20px"}
                  columns={5}
                  paddingX={3}
                  sx={{ py: 2 }}
                  height={"33vw"}
                  justifyContent={(combinedCount % 5 === 0) ? "space-between" : "start"}
                >
                  {uploadingMedia?.map((media) => (
                    <UploadingMediaItem key={media.name} media={media} />
                  ))}

                  {media.slice(0, (10-uploadingMedia.length))?.map((m) => (
                    <MediaItem
                      key={m.id}
                      media={m}
                      selectedMedia={selectedMedia}
                      setSelectedMedia={setSelectedMedia}
                    />
                  ))}
                </Grid>
              }

              <Button
                variant="outlined"
                sx={{ height: "320px", minWidth: "fit-content", padding: 0 }}
                disabled={combinedCount < 10}
                onClick={() => navigateNext()}
              >
                <NavigateNextIcon
                  color="inherit"
                  sx={{ fontSize: "33px" }}
                />
              </Button>
            </Box>
            <Button
              sx={{
                position: "absolute",
                bottom: 90,
                right: 25,
                borderRadius: "16px",
                boxShadow: "0px 17px 10px -10px rgba(0,0,0,0.4)",
              }}
              variant="contained"
              startIcon={<UploadIcon fontSize="small" />}
              onClick={() => setOpenUpload(true)}
            >
              Upload Media
            </Button>
          </>
        ) : (
          <>
            <UploadField closeUploadUI={handleCloseUploadUI} />
            <Button
              sx={{
                position: "absolute",
                bottom: 90,
                right: 25,
                borderRadius: "16px",
                boxShadow: "0px 17px 10px -10px rgba(0,0,0,0.4)",
                backgroundColor: "white",
              }}
              variant="outlined"
              startIcon={<CloseIcon fontSize="small" />}
              onClick={() => setOpenUpload(false)}
            >
              Cancel Upload
            </Button>
          </>
        )}
      </DialogContent>

      {errorHandler.selectedMedia && (
        <TypographySmall
          sx={{
            mx: 2,
            mt: "2px",
            color: "red",
            fontWeight: 600,
            textAlign: "right",
          }}
        >
          You need to select one media before continuing.
        </TypographySmall>
      )}

      <DialogActions sx={{ mx: 2, mb: 1 }}>
        {/* // TODO (EIN-264)
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            const { id } = selectedMedia;
            dispatch(removeAsset({ id }));
            setSelectedMedia({});
          }}
        >
          Remove Media
        </Button> */}
        <Button variant="outlined" onClick={() => handleClose()}>Cancel</Button>
        <Button
          variant="contained"
          onClick={() => {
            addContent({ ...selectedMedia, interval: 10 });
            setSelectedMedia({});
          }}
        >
          Select Media
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MediaPicker;
