import * as yup from "yup";

export const createUserRoleSchema = yup.object().shape({
  name: yup.string().min(6).required(),
  email: yup.string().email().required(),
  password: yup.string().min(8).max(30).required(),
  confirmPassword: yup.string().oneOf([yup.ref("password"), null]),
  role: yup.string().required(),
});

export const createAdminRoleSchema = yup.object().shape({
  name: yup.string().min(6).required(),
  email: yup.string().email().required(),
  password: yup.string().min(8).max(30).required(),
  confirmPassword: yup.string().oneOf([yup.ref("password"), null]),
  role: yup.string().required(),
});
