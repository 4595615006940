import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";

import { deleteSchedule } from "../../../store/actions/scheduleAction";

const DeleteSchedule = ({ schedule }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleDelete = (id) => {
    dispatch(deleteSchedule(id));

    handleClickClose();
    navigate("/schedules");
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        color="error"
        variant="contained"
        startIcon={<DeleteIcon fontSize="small" />}
        sx={{ ml: 2, boxShadow: 3 }}
      >
        Delete Schedule
      </Button>

      <Dialog maxWidth="sm" open={open} onClose={handleClickClose}>
        <DialogContent>
          <DialogContentText
            sx={{
              textAlign: "center",
              mb: 2,
            }}
          >
            Are you sure want to delete the <strong>{schedule.name}?</strong>
          </DialogContentText>

          <Box>
            <Alert severity="warning" variant="outlined">
              <strong>You won't be able to revert this!</strong>
            </Alert>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            color="error"
            variant="outlined"
            fullWidth
            startIcon={<ClearIcon />}
            onClick={handleClickClose}
          >
            Cancel
          </Button>

          <Button
            color="error"
            variant="contained"
            fullWidth
            startIcon={<DoneIcon />}
            onClick={() => handleDelete(schedule.id)}
          >
            Yes, Delete It!
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteSchedule;
