import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/ModeEdit";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

import { editSchedule } from "../../../store/actions/scheduleAction";
import { initiateAXIOS } from "../../../config/axios";
import { contentCMSAPILimit } from "../../../api";
import { CustomTextField } from "../../customComponent";
// import { dateFormatISO } from "../../../helpers/dateFormat";

const StyledBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  ":last-child": {
    marginBottom: 0,
  },
}));

const EditSchedule = ({ schedule }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const isMounted = useRef(false);

  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [customContentList, setCustomContentList] = useState([]);
  const [selectCustomContent, setSelectCustomContent] = useState("");

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let payload;
    payload = {
      id: schedule.id,
      schedule: {},
    };
    // }

    if (name !== schedule.name) {
      payload["schedule"]["name"] = name;
    }

    if (!schedule.args[1] && url !== schedule.url) {
      payload["schedule"]["url"] = url;
    }

    if (schedule.args[1] && schedule.args[1] !== +selectCustomContent) {
      payload["schedule"]["contentId"] = +selectCustomContent;
    }

    dispatch(editSchedule(payload));
  };

  const getCMSContent = async () => {
    const access_token = sessionStorage.getItem("access_token");
    try {
      const { data } = await initiateAXIOS.get(contentCMSAPILimit, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      setCustomContentList(data.items);
    } catch (error) {
      // setIsError(true);
      // setErrorMessage(error.response.data.message);
      console.log(error);
    }
  };

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      setUrl(schedule.args?.[0]);
      setName(schedule.name);
      if (schedule.args?.[1]) {
        getCMSContent();
        setSelectCustomContent(schedule.args?.[1]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedule.args]);

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        startIcon={<EditIcon fontSize="small" />}
        variant="contained"
        sx={{ boxShadow: 3 }}
      >
        Edit Schedule
      </Button>

      <Dialog maxWidth="sm" open={open} onClose={handleClickClose} fullWidth>
        <Box component="form" onSubmit={handleSubmit}>
          <DialogTitle
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            Edit Schedule
            <IconButton onClick={handleClickClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            {/* <StyledBox>
              <InputLabel>Schedule Status</InputLabel>
              <TextField
                margin="dense"
                disabled
                value={schedule.status}
                fullWidth
              />
            </StyledBox> */}

            <StyledBox>
              <InputLabel>Schedule Name</InputLabel>
              <TextField
                autoFocus
                required
                fullWidth
                margin="dense"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </StyledBox>
            {schedule.args?.[1] ? (
              <Box>
                <InputLabel>Select Content</InputLabel>
                <CustomTextField
                  select
                  required
                  SelectProps={{ native: true }}
                  fullWidth
                  margin="dense"
                  name="contentSelect"
                  value={selectCustomContent}
                  onChange={(e) => {
                    setSelectCustomContent(e.target.value);
                  }}
                >
                  <option value="" disabled>
                    Choose Content
                  </option>
                  {customContentList.map((content) => (
                    <option value={content.id} key={content.id}>
                      {content.name}
                    </option>
                  ))}
                </CustomTextField>
              </Box>
            ) : (
              <Box>
                <InputLabel>URL</InputLabel>

                <TextField
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  required
                />
              </Box>
            )}
          </DialogContent>

          <DialogActions>
            <Button fullWidth variant="outlined" onClick={handleClickClose}>
              Cancel
            </Button>

            <Button fullWidth variant="contained" type="submit">
              Save
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default EditSchedule;
