import React, { useState, useEffect } from "react";

import { DialogContent } from "@material-ui/core";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  IconButton,
  InputLabel,
  Grid,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";

import { TypographyLarge } from "../../../customComponent";
import { CustomTextField } from "../../../customComponent";
import { styled } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { editSMPLinkAnnouncement } from "../../../../store/actions/smpAction";
import { initiateAXIOS } from "../../../../config/axios";
import { announcementAPILimit } from "../../../../api";
import Announcement from "../../../../models/AnnouncementModel";
import { ascending } from "../../../../helpers/sorting";

const StyledBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

const DetailLinkAnnouncement = ({ open, onClose, data, type }) => {
  const dispatch = useDispatch();

  const initialState = {
    delayState:
      data.delayAnnouncementId === null ? "" : data.delayAnnouncementId,
    disruptionState:
      data.disruptionAnnouncementId === null
        ? ""
        : data.disruptionAnnouncementId,
    freeBusState:
      data.freeBusAnnouncementId === null ? "" : data.freeBusAnnouncementId,
  };

  const { loadingLinkSMPDisruption, stationDisruptions } = useSelector(
    (state) => state.smp
  );
  const { equipmentBreakdown } = useSelector(
    (state) => state.equipmentBreakdown
  );

  const [announcements, setAnnouncements] = useState([]);

  const [delaySelect, setDelaySelect] = useState(initialState.delayState);
  const [disruptionSelect, setDisruptionSelect] = useState(
    initialState.disruptionState
  );
  const [busSelect, setBusSelect] = useState(initialState.freeBusState);

  const getEquipments = () => {
    let equipments = [];
    equipmentBreakdown.forEach((equip) => {
      equipments = equipments.concat(equip.equipments);
    });
    return equipments;
  };

  const filterAnnouncements = (announcements, id) => {
    const equipmentFilter = announcements.filter((item) => {
      return !getEquipments()?.some((fItem) => {
        return id !== "" && item.id === id
          ? false
          : item.id === fItem.announcementId;
      });
    });

    const filtered = equipmentFilter.filter((item) => {
      return !stationDisruptions.some((fItem) => {
        return id !== "" && item.id === id
          ? false
          : item.id === fItem.delayAnnouncementId ||
              item.id === fItem.disruptionAnnouncementId ||
              item.id === fItem.freeBusAnnouncementId;
      });
    });
    return ascending(filtered, "name");
  };

  const getAnnouncements = async () => {
    const access_token = sessionStorage.getItem("access_token");
    try {
      const { data } = await initiateAXIOS.get(announcementAPILimit, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      const announcementList = data.announcement;

      let announcements = [];
      announcementList.map((item) =>
        announcements.push(new Announcement(item))
      );
      setAnnouncements(announcements);
    } catch (error) {
      // setIsError(true);
      // setErrorMessage(error.response.data.message);
      console.log(error);
    }
  };

  useEffect(() => {
    if (open) getAnnouncements();
  }, [open]);

  const handleClose = () => {
    onClose(false);
  };

  const handleLinkDelaySelect = (event) => {
    setDelaySelect(event.target.value);
  };

  const handleLinkDisruptionSelect = (event) => {
    setDisruptionSelect(event.target.value);
  };

  const handleLinkBusSelect = (event) => {
    setBusSelect(event.target.value);
  };

  const handleSubmit = (event) => {
    const payload = { id: data.id, type: "submit", announcement: {} };
    let changeExist = false;
    if (type === "Delay") {
      if (busSelect !== "" && data.freeBusAnnouncementId !== busSelect) {
        payload.announcement["freeBusAnnouncementId"] = busSelect;
        changeExist = true;
      }

      if (delaySelect !== "" && data.delayAnnouncementId !== delaySelect) {
        payload.announcement["delayAnnouncementId"] = delaySelect;
        changeExist = true;
      }
    }

    if (type === "Disruption") {
      if (
        disruptionSelect !== "" &&
        data.disruptionAnnouncementId !== disruptionSelect
      ) {
        payload.announcement["disruptionAnnouncementId"] = disruptionSelect;
        changeExist = true;
      }
    }

    if (changeExist) {
      dispatch(editSMPLinkAnnouncement(payload));
      handleClose();
    }
  };

  const handleRemoveAnnouncement = (type) => {
    const payload = { id: data.id, type: "remove", announcement: {} };
    switch (type) {
      case "Delay":
        payload.announcement["delayAnnouncementId"] = 0;
        setDelaySelect("");
        break;
      case "FreeBus":
        payload.announcement["freeBusAnnouncementId"] = 0;
        setBusSelect("");
        break;
      case "Disruption":
        payload.announcement["disruptionAnnouncementId"] = 0;
        setDisruptionSelect("");
        break;
      default:
        return;
    }
    if (Object.keys(payload.announcement).length !== 0) {
      dispatch(editSMPLinkAnnouncement(payload));
    }
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={handleClose}>
      <Box>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {"Link " + type + " Announcement"}
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {loadingLinkSMPDisruption ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress size={24} sx={{ color: "#1C4ED8" }} />
          </Box>
        ) : (
          <DialogContent>
            <Box sx={{ mb: "20px" }}>
              <StyledBox>
                <InputLabel>Station :</InputLabel>
                <TypographyLarge>{data.code + " Announcement"}</TypographyLarge>
              </StyledBox>
            </Box>

            {type === "Delay" && (
              <>
                <Box sx={{ mb: "20px" }}>
                  <StyledBox>
                    <InputLabel>Delay A Announcement :</InputLabel>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item xs={11}>
                        <CustomTextField
                          select
                          required
                          SelectProps={{ native: true }}
                          fullWidth
                          margin="dense"
                          name="delayAnnouncementId"
                          value={delaySelect}
                          onChange={handleLinkDelaySelect}
                        >
                          <option value="" disabled>
                            Choose Announcement
                          </option>
                          {filterAnnouncements(announcements, delaySelect)?.map(
                            (announcement) => (
                              <option
                                value={announcement.id}
                                key={announcement.id}
                              >
                                {announcement.name}
                              </option>
                            )
                          )}
                        </CustomTextField>
                      </Grid>
                      <Grid item xs={1}>
                        <Tooltip
                          title="Remove Delay A Announcement"
                          placement="top"
                          disableInteractive
                        >
                          <Box>
                            <IconButton
                              sx={{ borderRadius: 2 }}
                              size="large"
                              color="error"
                              onClick={() => {
                                handleRemoveAnnouncement("Delay");
                              }}
                              disabled={
                                initialState.delayState === "" ? true : false
                              }
                            >
                              <DoDisturbIcon fontSize="inherit" />
                            </IconButton>
                          </Box>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </StyledBox>
                </Box>
                <Box sx={{ mb: "20px" }}>
                  <StyledBox>
                    <InputLabel>Delay B Announcement :</InputLabel>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item xs={11}>
                        <CustomTextField
                          select
                          required
                          SelectProps={{ native: true }}
                          fullWidth
                          margin="dense"
                          name="freeBusAnnouncementId"
                          value={busSelect}
                          onChange={handleLinkBusSelect}
                        >
                          <option value="" disabled>
                            Choose Announcement
                          </option>
                          {filterAnnouncements(announcements, busSelect)?.map(
                            (announcement) => (
                              <option
                                value={announcement.id}
                                key={announcement.id}
                              >
                                {announcement.name}
                              </option>
                            )
                          )}
                        </CustomTextField>
                      </Grid>
                      <Grid item xs={1}>
                        <Tooltip
                          title="Remove Delay B Announcement"
                          placement="top"
                          disableInteractive
                        >
                          <Box>
                            <IconButton
                              sx={{ borderRadius: 2 }}
                              size="large"
                              color="error"
                              onClick={() => {
                                handleRemoveAnnouncement("FreeBus");
                              }}
                              disabled={
                                initialState.freeBusState === "" ? true : false
                              }
                            >
                              <DoDisturbIcon fontSize="inherit" />
                            </IconButton>
                          </Box>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </StyledBox>
                </Box>
              </>
            )}

            {type === "Disruption" && (
              <>
                <Box sx={{ mb: "20px" }}>
                  <StyledBox>
                    <InputLabel>Disruption Announcement :</InputLabel>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item xs={11}>
                        <CustomTextField
                          select
                          required
                          SelectProps={{ native: true }}
                          fullWidth
                          margin="dense"
                          name="DisruptionAnnouncementId"
                          value={disruptionSelect}
                          onChange={handleLinkDisruptionSelect}
                        >
                          <option value="" disabled>
                            Choose Announcement
                          </option>
                          {filterAnnouncements(
                            announcements,
                            disruptionSelect
                          )?.map((announcement) => (
                            <option
                              value={announcement.id}
                              key={announcement.id}
                            >
                              {announcement.name}
                            </option>
                          ))}
                        </CustomTextField>
                      </Grid>
                      <Grid item xs={1}>
                        <Tooltip
                          title="Remove Announcement"
                          placement="top"
                          disableInteractive
                        >
                          <Box>
                            <IconButton
                              sx={{ borderRadius: 2 }}
                              size="large"
                              color="error"
                              onClick={() => {
                                handleRemoveAnnouncement("Disruption");
                              }}
                              disabled={
                                initialState.disruptionState === ""
                                  ? true
                                  : false
                              }
                            >
                              <DoDisturbIcon fontSize="inherit" />
                            </IconButton>
                          </Box>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </StyledBox>
                </Box>
              </>
            )}
          </DialogContent>
        )}
        <DialogActions>
          <Button fullWidth variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button fullWidth variant="contained" onClick={handleSubmit}>
            Link Announcement
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default DetailLinkAnnouncement;
