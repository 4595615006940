export const SET_ORGANIZATION = "SET_ORGANIZATION";
export const SET_LOADING_ORGANIZATION = "SET_LOADING_ORGANIZATION";
export const SET_NEW_ORGANIZATION = "SET_NEW_ORGANIZATION";
export const SET_EDIT_ORGANIZATION = "SET_EDIT_ORGANIZATION";
export const SET_DELETE_ORGANIZATION = "SET_DELETE_ORGANIZATION";
export const SET_SUCCESS_PROMPT_ORGANIZATION_PAGE =
  "SET_SUCCESS_PROMPT_ORGANIZATION_PAGE";
export const SET_ERROR_PROMPT_ORGANIZATION_PAGE =
  "SET_ERROR_PROMPT_ORGANIZATION_PAGE";
export const SET_SUCCESS_MESSAGE_ORGANIZATION_PAGE =
  "SET_SUCCESS_MESSAGE_ORGANIZATION_PAGE";
export const SET_ERROR_MESSAGE_ORGANIZATION_PAGE =
  "SET_ERROR_MESSAGE_ORGANIZATION_PAGE";
