import React from "react";
import { Box, ButtonBase, Grid, LinearProgress, Tooltip } from "@mui/material";
import { TypographyLarge } from "../../../customComponent";

const UploadingMediaItem = ({ media }) => {
  let showElement;
  showElement = <img
    style={{ maxWidth: "100%", maxHeight: "80%", m: 1 }}
    src={media.thumbnailUrl ?? media.src}
    alt={media.name}
  />;

  return (
    <Grid item sx={{ height: "fit-content" }}>
      <ButtonBase
        sx={{
          border: "2px solid rgba(132, 137, 151,0.5)",
          backgroundColor: "#f5f5f5",
          borderRadius: "8px",
          width: "150px",
          height: "200px",
          p: 2,
          cursor: "default",
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{ width: "100%", height: "100%" }}
        >
          <Box
            display={"flex"}
            alignContent={"center"}
            alignItems={"center"}
            sx={{ height: "80%" }}
          >
            {showElement}
          </Box>
          <Box sx={{ width: "100%" }}>
            {media.status === "uploading" ? (
              <LinearProgress
                sx={{
                  height: 6,
                  borderRadius: 4,
                }}
                variant="determinate"
                value={media.progress}
              />
            ) : (
              <LinearProgress
                sx={{
                  height: 6,
                  borderRadius: 4,
                }}
                variant="query"
              />
            )}
          </Box>
          <Tooltip title={media.name}>
            <TypographyLarge>
              {media.name.substring(0, 10) + "..."}
            </TypographyLarge>
          </Tooltip>
        </Box>
      </ButtonBase>
    </Grid>
  );
};

export default UploadingMediaItem;
