import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import {
  CstTypo14W300,
  CstTypo20W600,
  CustomTextField,
  PrimaryButton,
} from "../components/customComponent";
import { resetPasswordSchema } from "../schemas/resetPasswordSchema";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress, Link } from "@mui/material";
import axios from "../config/axios";
import { useNavigate } from "react-router";
import { CssBaseline } from "@material-ui/core";
import useCountDown from "react-countdown-hook";
import {
  SET_GLOBAL_ERROR,
  SET_GLOBAL_ERROR_MESSAGE,
  SET_GLOBAL_SUCCESS_MESSAGE,
  SET_GLOBAL_SUCCESS,
} from "../store/actionTypes/globalActionType";
import { useDispatch } from "react-redux";
import { GlobalErrorPrompt, GlobalSuccessPrompt } from "../components";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const resolver = resetPasswordSchema;

  const [isLoading, setIsLoading] = useState(false);

  const resetPasswordOTPKey = sessionStorage.getItem("resetPasswordOTPKey");

  const resetPasswordToken = sessionStorage.getItem("resetPasswordToken");

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(resolver) });

  const submitResetPassword = async (data) => {
    const abortController = new AbortController();

    const verificationCode = data["Verification Code"];

    const newPassword = data["New Password"];

    let payload = {
      otp: verificationCode,
      newPassword,
    };

    setIsLoading(true);

    try {
      await axios({
        url: "/api/auth/reset-password/otp",
        method: "POST",
        headers: { authorization: `Bearer ${resetPasswordOTPKey}` },
        data: payload,
        signal: abortController.signal,
      }).then(() => {
        const successMessage = "Password succesfully changed";
        dispatch({ type: SET_GLOBAL_SUCCESS, payload: true });
        dispatch({ type: SET_GLOBAL_SUCCESS_MESSAGE, payload: successMessage });

        setTimeout(() => {
          navigate("/login");
        }, 2000);

        sessionStorage.removeItem("resetPasswordOTPKey");
        sessionStorage.removeItem("resetPasswordToken");
      });
    } catch (error) {
      const errorMessage = error.response.data.message;
      dispatch({ type: SET_GLOBAL_ERROR, payload: true });
      dispatch({ type: SET_GLOBAL_ERROR_MESSAGE, payload: errorMessage });

      const errorKeyword = error.response.data.detail.keyword;
      if (errorKeyword === "resetPasswordAttemptsError") {
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      }
    } finally {
      reset({
        "Verification Code": "",
        "New Password": "",
        "Confirm Password": "",
      });
      setIsLoading(false);
      abortController.abort();
    }
  };

  return (
    <>
      <CssBaseline />
      <GlobalSuccessPrompt />
      <GlobalErrorPrompt />

      <Box
        component="main"
        sx={{
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            border: "1px solid lightgrey",
            width: "37.5rem",
            borderRadius: "6px",
            py: 2,
            px: 6,
          }}
        >
          <CstTypo20W600 sx={{ textAlign: "center" }}>
            Reset Password
          </CstTypo20W600>

          <Box component="form" onSubmit={handleSubmit(submitResetPassword)}>
            <CustomTextField
              {...register("Verification Code")}
              margin="normal"
              label="Enter your verification code"
              fullWidth
              error={errors["Verification Code"]}
              helperText={errors["Verification Code"]?.message}
            />

            <CustomTextField
              margin="normal"
              label="Enter your new password"
              fullWidth
              type="password"
              {...register("New Password")}
              error={errors["New Password"]}
              helperText={errors["New Password"]?.message}
            />

            <CustomTextField
              margin="normal"
              label="Enter your confirm password"
              fullWidth
              type="password"
              {...register("Confirm Password")}
              error={errors["Confirm Password"]}
              helperText={
                errors["Confirm Password"] && "Confirm Password does not match"
              }
            />

            <CountdownTimer resetPasswordToken={resetPasswordToken} />

            {isLoading ? (
              <PrimaryButton
                size="large"
                sx={{ mt: 1 }}
                disabled
                fullWidth
                variant="outlined"
              >
                <CircularProgress size={22} style={{ color: "grey" }} />
              </PrimaryButton>
            ) : (
              <PrimaryButton
                size="large"
                sx={{ mt: 1 }}
                fullWidth
                type="submit"
                variant="outlined"
              >
                Reset Password
              </PrimaryButton>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

const CountdownTimer = ({ resetPasswordToken }) => {
  const dispatch = useDispatch();

  const SIXTY_SECONDS = 60 * 1000;
  const interval = 1000;
  const [timeLeft, { start }] = useCountDown(SIXTY_SECONDS, interval);

  useEffect(() => {
    start();
  }, [start]);

  const [isLoaded, setIsLoaded] = useState(false);

  const handleResendVerificationCode = async () => {
    setIsLoaded(true);
    try {
      const { data } = await axios({
        url: "/api/auth/reset-password/request-otp",
        method: "POST",
        headers: { authorization: `Bearer ${resetPasswordToken}` },
        data: { method: "EMAIL" },
      });
      const resetPasswordOTPKey = data.otpKey;
      sessionStorage.setItem("resetPasswordOTPKey", resetPasswordOTPKey);
    } catch (error) {
      const errorMessage = error.response.data.message;
      dispatch({ type: SET_GLOBAL_ERROR, payload: true });
      dispatch({ type: SET_GLOBAL_SUCCESS_MESSAGE, payload: errorMessage });
    } finally {
      setIsLoaded(false);
    }
  };

  return (
    <Box component="div" sx={{ my: 1 }}>
      {isLoaded ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress size={24} sx={{ color: "#1C4ED8" }} />
        </Box>
      ) : (
        <CstTypo14W300>
          If you have reset your password, login{" "}
          <Link
            href="/login"
            sx={{ cursor: "pointer", color: "#1C4ED8", fontWeight: 500 }}
            underline="hover"
            onClick={() => sessionStorage.clear()}
          >
            here
          </Link>
          .{" "}
          {timeLeft / 1000 === 0 ? (
            <span>
              Resend verification code{" "}
              <Link
                onClick={handleResendVerificationCode}
                sx={{ cursor: "pointer", color: "#1C4ED8", fontWeight: 500 }}
                underline="hover"
              >
                here.
              </Link>
            </span>
          ) : (
            <span>
              Verification code will be expired in {timeLeft / 1000} seconds.
            </span>
          )}
        </CstTypo14W300>
      )}
    </Box>
  );
};

export default ResetPassword;
