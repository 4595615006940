import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { Clear, Done } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";

import { deleteGroup } from "../../../store/actions/groupAction";

const DeleteGroup = ({ group }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleDelete = (id) => {
    let payload;

    payload = { id: id };
    dispatch(deleteGroup(payload));
    navigate("/groups");

    handleClickClose();
  };

  return (
    <>
      <Button
        startIcon={<DeleteIcon fontSize="small" />}
        color="error"
        onClick={() => setOpen(true)}
        variant="contained"
        sx={{ boxShadow: 3 }}
      >
        Delete Group
      </Button>

      <Dialog maxWidth="sm" open={open} onClose={handleClickClose}>
        <DialogContent>
          <DialogContentText sx={{ textAlign: "center", mb: 2 }}>
            Are you sure want to delete the <strong>{group.name}?</strong>
          </DialogContentText>

          <Box sx={{ textAlign: "center" }}>
            <Alert severity="warning" variant="outlined">
              All assigned viewers and schedules will be unassigned accordingly
              once this group is deleted.{" "}
              <strong>You won't be able to revert this!</strong>
            </Alert>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            color="error"
            variant="outlined"
            fullWidth
            startIcon={<Clear />}
            onClick={handleClickClose}
          >
            Cancel
          </Button>

          <Button
            color="error"
            variant="contained"
            fullWidth
            startIcon={<Done />}
            onClick={() => handleDelete(group.id)}
          >
            Yes, Delete It!
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteGroup;
