import {
  SET_ANNOUNCEMENT,
  SET_ANNOUNCEMENT_DETAIL,
  SET_DELETE_ANNOUNCEMENT,
  SET_LOADING_ANNOUNCEMENT,
  SET_LOADING_ANNOUNCEMENT_DETAIL,
  SET_NEW_ANNOUNCEMENT,
  SET_SUCCESS_PROMPT_ANNOUNCEMENT_PAGE,
  SET_SUCCESS_MESSAGE_ANNOUNCEMENT_PAGE,
  SET_ERROR_MESSAGE_ANNOUNCEMENT_PAGE,
  SET_ERROR_PROMPT_ANNOUNCEMENT_PAGE,
} from "../actionTypes/announcementActionType";

const initialState = {
  announcements: [],
  loadingAnnouncement: false,
  announcement: {},
  loadingAnnouncementDetail: false,
  isSuccessPromptAnnouncementPage: false,
  isErrorPromptAnnouncementPage: false,
  successMessageAnnouncementPage: "",
  errorMessageAnnouncementPage: "",
};

export const announcementReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_ANNOUNCEMENT:
      return { ...state, announcements: payload };

    case SET_LOADING_ANNOUNCEMENT:
      return { ...state, loadingAnnouncement: payload };

    case SET_NEW_ANNOUNCEMENT:
      return { ...state, announcements: [payload, ...state.announcements] };

    case SET_ANNOUNCEMENT_DETAIL:
      return { ...state, announcement: payload };

    case SET_LOADING_ANNOUNCEMENT_DETAIL:
      return { ...state, loadingAnnouncementDetail: payload };

    // case SET_EDIT_ANNOUNCEMENT:
    //   const data = state.announcements;
    //   const objIndex = data.findIndex((obj) => obj.id === payload.id);
    //   data[objIndex] = payload;
    //   return { ...state, announcements: [...data] };

    case SET_DELETE_ANNOUNCEMENT:
      return {
        ...state,
        announcements: state.announcements.filter(
          (announcement) => announcement.id.toString() !== payload.toString()
        ),
      };

    case SET_SUCCESS_PROMPT_ANNOUNCEMENT_PAGE:
      return { ...state, isSuccessPromptAnnouncementPage: payload };

    case SET_SUCCESS_MESSAGE_ANNOUNCEMENT_PAGE:
      return { ...state, successMessageAnnouncementPage: payload };

    case SET_ERROR_PROMPT_ANNOUNCEMENT_PAGE:
      return { ...state, isErrorPromptAnnouncementPage: payload };

    case SET_ERROR_MESSAGE_ANNOUNCEMENT_PAGE:
      return { ...state, errorMessageAnnouncementPage: payload };

    default:
      return state;
  }
};
