import React, { forwardRef } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";
import { SET_GLOBAL_SUCCESS } from "../../store/actionTypes/globalActionType";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="standard" {...props} />;
});

const GlobalSuccessPrompt = () => {
  const { isSuccess, successMessage } = useSelector((state) => state.global);
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch({ type: SET_GLOBAL_SUCCESS, payload: false });
  };

  const vertical = "bottom";
  const horizontal = "center";

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={isSuccess}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {successMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default GlobalSuccessPrompt;
