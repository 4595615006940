import React, { useEffect, useCallback, useRef, Fragment } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import axios from "../config/axios";
import { useDispatch } from "react-redux";
import {
  SET_GLOBAL_ERROR,
  SET_GLOBAL_ERROR_MESSAGE,
  SET_GLOBAL_SUCCESS,
  SET_GLOBAL_SUCCESS_MESSAGE,
} from "../store/actionTypes/globalActionType";

var duration = require("dayjs/plugin/duration");
dayjs.extend(duration);

const SessionTimeout = () => {
  const dispatch = useDispatch();
  const isAuthenticated = sessionStorage.getItem("access_token");
  const navigate = useNavigate();
  const sessionInterval = +process.env.REACT_APP_SESSION_EXPIRY / 60000; // (minutes)

  let warningInactiveInterval = useRef();
  let startTimerInterval = useRef();

  // start inactive check
  let timeChecker = useCallback(() => {
    // warning timer
    let warningInactive = (timeString) => {
      clearTimeout(startTimerInterval.current);

      warningInactiveInterval.current = setInterval(() => {
        const maxTime = sessionInterval; // maximum ideal time given before logout

        const diff = dayjs.duration(dayjs().diff(dayjs(timeString)));
        const minPast = diff.minutes();

        // console.log(minPast, "-", maxTime);
        if (minPast === maxTime) {
          clearInterval(warningInactiveInterval.current);
          sessionStorage.removeItem("lastTimeStamp");
          //  logout function here
          logout();
        }
      }, 1000);
    };

    const logout = async () => {
      await axios({
        method: "POST",
        url: "/api/auth/logout",
        headers: { authorization: `Bearer ${isAuthenticated}` },
      })
        .then(() => {
          dispatch({ type: SET_GLOBAL_SUCCESS, payload: true });
          dispatch({
            type: SET_GLOBAL_SUCCESS_MESSAGE,
            payload: "Logout successful",
          });
        })
        .catch((e) => {
          dispatch({ type: SET_GLOBAL_ERROR, payload: true });
          dispatch({
            type: SET_GLOBAL_ERROR_MESSAGE,
            payload: e.response.data.message,
          });
        })
        .finally(() => {
          localStorage.clear();
          sessionStorage.clear();
          sessionStorage.removeItem("lastTimeStamp");
          navigate("/login", {
            state: {
              error: "Session expired. Please login again to continue.",
            },
          });
        });
    };

    startTimerInterval.current = setTimeout(() => {
      let storedTimeStamp = sessionStorage.getItem("lastTimeStamp");
      warningInactive(storedTimeStamp);
    }, 60000);
  }, [sessionInterval, navigate, dispatch, isAuthenticated]);

  // reset interval timer
  let resetTimer = useCallback(() => {
    clearTimeout(startTimerInterval.current);
    clearInterval(warningInactiveInterval.current);

    let timeStamp;

    if (isAuthenticated) {
      timeStamp = dayjs();
      sessionStorage.setItem("lastTimeStamp", timeStamp);
    } else {
      clearInterval(warningInactiveInterval.current);
      sessionStorage.removeItem("lastTimeStamp");
    }

    timeChecker();
  }, [isAuthenticated, timeChecker]);

  useEffect(() => {
    const events = ["click", "load", "scroll"];

    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    timeChecker();

    return () => {
      clearTimeout(startTimerInterval.current);
    };
  }, [resetTimer, timeChecker]);

  return <Fragment />;
};

export default SessionTimeout;
