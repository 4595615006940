import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  Box,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Button,
  CircularProgress,
  Alert,
  DialogContentText,
  DialogActions,
  Radio,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import { styled } from "@mui/material/styles";

import { initiateAXIOS } from "../../../../config/axios";
import {
  SET_ERROR_MESSAGE_VIEWER_PAGE,
  SET_ERROR_PROMPT_VIEWER_PAGE,
} from "../../../../store/actionTypes/viewerActionTypes";
import { TypographyNormal } from "../../../customComponent";
import { useDebounce } from "../../../hooks";
import { colorStyling } from "../../../../helpers/color";
import { groupAPILimit } from "../../../../api";
import { GroupBlueIcon } from "../../../../icons";
import { assignViewerToGroup } from "../../../../store/actions/viewerAction";

const InputSearch = styled("input")(() => ({
  outline: "none",
  border: "none",
  fontSize: "16px",
  lineHeight: "26px",
  fontWeight: 400,
  width: "100%",
  marginLeft: "10px",
  fontFamily: "Inter, sans-serif",
}));

const BoxInput = styled(Box)(({ theme }) => ({
  border: "1px solid #DCDEE3",
  display: "flex",
  alignItems: "center",
  paddingLeft: theme.spacing(1.875),
  paddingRight: theme.spacing(1.875),
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
  borderRadius: "6px",
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

const ViewerAssignGroup = ({ viewer }) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const [selectedItem, setSelectedItem] = useState({});

  const [loading, setLoading] = useState(false);

  const [listGroup, setListGroup] = useState([]);

  const [search, setSearch] = useState("");

  /**
   * 1 Second delay after input value change to do API fetch
   */
  const debouncedSearchTerm = useDebounce(search, 1000);

  const [isError, setIsError] = useState(false);

  const [openConfirmation, setOpenConfirmation] = useState(false);

  /**
   * Fetching schedule data
   * @param {string} name Search input value
   */
  const getData = async (name) => {
    const access_token = sessionStorage.getItem("access_token");
    setLoading(true);
    try {
      const { data } = await initiateAXIOS.get(
        groupAPILimit + `${name ? "&nameLike=" + name : ""}`,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      setListGroup(data?.items);
    } catch (e) {
      const errorMessage = e?.response?.data?.message;
      dispatch({ type: SET_ERROR_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({
        type: SET_ERROR_MESSAGE_VIEWER_PAGE,
        payload: errorMessage ?? "undefined error found.",
      });
    } finally {
      setLoading(false);
    }
  };

  /**
   * Inital API call when dialog open
   */
  useEffect(() => {
    if (open) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  /**
   * Run api call after set value (1 second) when search box value change
   */
  useEffect(
    () => {
      if (debouncedSearchTerm) {
        getData(debouncedSearchTerm);
      } else if (debouncedSearchTerm === "" && open) {
        getData();
      } else {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );

  const handleClickClose = () => {
    setOpen(false);
    setListGroup([]);
    setSearch("");
    setSelectedItem([]);
  };

  const handleCloseAlert = () => {
    setIsError(false);
  };

  /**
   * Handling radio
   * @param {*} event
   */
  const handleChangeRadio = (e, group) => {
    setSelectedItem({ id: e.target.value, name: group?.name });
  };

  const handleCancelSelection = () => {
    setSelectedItem({});
  };

  /**
   * Show error alert when there is no selected schedule
   * Otherwise open confirmation dialog
   */
  const handleConfirmation = () => {
    if (!selectedItem.id) setIsError(true);
    else {
      setIsError(false);
      setOpenConfirmation(true);
    }
  };

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
  };

  const handleSubmit = () => {
    const payload = {
      id: viewer.id,
      screenGroupId: +selectedItem.id,
    };

    dispatch(assignViewerToGroup(payload));
    handleCloseConfirmation(false);
    handleClickClose();
  };

  return (
    <>
      <Button
        sx={{ ml: 2, boxShadow: 3, textTransform: "capitalize" }}
        onClick={() => setOpen(true)}
        variant="contained"
        startIcon={<AddIcon />}
      >
        Assign New Group
      </Button>

      <Dialog maxWidth="sm" open={open} onClose={handleClickClose} fullWidth>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            textTransform: "capitalize",
          }}
        >
          Assign New Group
          <IconButton onClick={handleClickClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <Divider />

        <DialogContent>
          <BoxInput>
            <SearchIcon fontSize="medium" sx={{ color: "#9CA3AF" }} />

            <InputSearch
              type="search"
              placeholder={"Search group..."}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </BoxInput>

          {isError && (
            <Alert
              severity="error"
              variant="outlined"
              onClose={handleCloseAlert}
            >
              Please assign one group to selected viewer
            </Alert>
          )}

          {!selectedItem.id ? (
            <Alert severity="info" variant="outlined" sx={{ mt: 1 }}>
              <strong>No group selected!</strong>
            </Alert>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mb: 2,
                mt: 1,
                border: "1px solid #03a9f4",
                borderRadius: "4px",
                p: 1,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <GroupBlueIcon />
                <Box sx={{ ml: 2 }}>
                  <TypographyNormal sx={{ fontWeight: 600 }}>
                    {selectedItem.name}
                  </TypographyNormal>
                </Box>
              </Box>
              <Button
                variant="outlined"
                color="error"
                onClick={() => {
                  handleCancelSelection();
                }}
              >
                Remove Selection
              </Button>
            </Box>
          )}

          <Box
            sx={{
              maxHeight: 300,
              overflowY: "auto",
              p: 2,
              borderRadius: "6px",
              border: "1px solid #DCDEE3",
              mt: 1,
            }}
          >
            {!loading && !listGroup.length ? (
              <Alert severity="info" variant="outlined">
                <strong>No groups found!</strong> Currently there are no
                available groups or We couldn't find what you're looking for
              </Alert>
            ) : null}

            {loading && (
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
                sx={{ py: 1 }}
              >
                <CircularProgress
                  size={20}
                  thickness={3}
                  sx={{ color: colorStyling.primary }}
                />
                <TypographyNormal
                  sx={{
                    color: colorStyling.primary,
                    marginTop: "15px",
                    fontWeight: 300,
                  }}
                >
                  Loading Data...
                </TypographyNormal>
              </Box>
            )}

            {listGroup?.map((item) => (
              <Box
                key={item.id}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mb: 2,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <GroupBlueIcon />

                  <Box sx={{ ml: 2, display: "flex", alignItems: "center" }}>
                    <TypographyNormal sx={{ fontWeight: 600 }}>
                      {item.name}
                    </TypographyNormal>
                  </Box>
                </Box>

                <Radio
                  checked={`${item.id}` === selectedItem.id}
                  value={item.id}
                  onChange={(e) => handleChangeRadio(e, item)}
                />
              </Box>
            ))}
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" fullWidth onClick={handleClickClose}>
            Cancel
          </Button>

          <Button onClick={handleConfirmation} variant="contained" fullWidth>
            Confirm
          </Button>
          <Confirmation
            open={openConfirmation}
            onClose={() => handleCloseConfirmation()}
            selectedItem={selectedItem}
            handleSubmit={() => handleSubmit()}
            viewer={viewer}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ViewerAssignGroup;

const Confirmation = ({
  selectedItem,
  open,
  onClose,
  handleSubmit,
  viewer,
}) => {
  const handleCallbackSubmit = (event) => {
    event.preventDefault();

    handleSubmit();
  };
  return (
    <Dialog maxWidth="sm" open={open} onClose={onClose}>
      <DialogContent>
        <DialogContentText sx={{ textAlign: "center" }}>
          Are you sure want to assign group <strong>{selectedItem.name}</strong>{" "}
          to <strong>{viewer.name}?</strong>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          startIcon={<ClearIcon />}
          fullWidth
          variant="outlined"
          onClick={onClose}
        >
          No
        </Button>

        <Button
          onClick={handleCallbackSubmit}
          startIcon={<DoneIcon />}
          fullWidth
          variant="contained"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
