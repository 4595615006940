import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  CircularProgress,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { TypographyNormal } from "../../customComponent";
import { usePagination } from "../../hooks";
import ContentHistoryItem from "./ContentHistoryItem";
import { getContentHistory } from "../../../store/actions/contentUploadAction";

const ContentHistoryList = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getContentHistory());
  }, [dispatch]);

  const { contentHistory, loadingContentUploads: loading } = useSelector(
    (state) => state.contentUpload
  );

  const [page, setPage] = useState(1);

  const DATA_PER_PAGE = 5;

  const count = Math.ceil(contentHistory.length / DATA_PER_PAGE);

  const _DATA = usePagination(contentHistory, DATA_PER_PAGE);

  const handleChangePagination = (e, page) => {
    setPage(page);
    _DATA.jump(page);
  };

  if (loading)
    return (
      <Box
        sx={{ height: "20vh" }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <CircularProgress size={20} thickness={3} sx={{ color: "#1D4ED8" }} />
        <TypographyNormal sx={{ color: "#1D4ED8", mt: 2, fontWeight: 300 }}>
          Loading Data...
        </TypographyNormal>
      </Box>
    );

  return (
    <>
      {!contentHistory.length || contentHistory === undefined ? (
        <Alert severity="info" variant="filled">
          <strong>No Content History</strong>
        </Alert>
      ) : (
        <TableContainer
          component={Paper}
          sx={{ overflowY: "auto", maxHeight: 500, boxShadow: 3 }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Viewer Name</TableCell>
                <TableCell>Group Name</TableCell>
                <TableCell>Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contentHistory &&
                _DATA
                  .currentData()
                  .map((data) => (
                    <ContentHistoryItem key={data.id} historyData={data} />
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {!loading && contentHistory.length ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            pt: 4,
            pb: 2,
            pr: 2,
          }}
        >
          <Pagination
            count={count}
            page={page}
            shape="rounded"
            color="primary"
            onChange={handleChangePagination}
          />
        </Box>
      ) : null}
    </>
  );
};

export default ContentHistoryList;
