// Creating thumbnail from the first frame of the video
export const createVideoThumbnail = async (file) => {
  let thumbnail = null;
  var video = document.createElement("video");
  var timeupdate = function () {
    if (snapImage()) {
      video.removeEventListener("timeupdate", timeupdate);
      video.pause();
    }
  };
  video.addEventListener("loadeddata", function () {
    if (snapImage()) {
      video.removeEventListener("timeupdate", timeupdate);
    }
  });
  var snapImage = function () {
    try {
      var canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas
        .getContext("2d")
        .drawImage(video, 0, 0, canvas.width, canvas.height);
      thumbnail = canvas.toDataURL();
      return true;
    } catch (error) {
      console.log(`error! createThumbnailFromVideo:`, error);
      return false;
    }
  };

  video.addEventListener("timeupdate", timeupdate);
  video.preload = "metadata";
  video.crossOrigin = 'anonymous';
  video.src = file;
  // Load video in Safari / IE11
  video.muted = true;
  video.playsInline = true;
  await video.play();
  return thumbnail;
};

export const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]), 
      n = bstr.length, 
      u8arr = new Uint8Array(n);
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type:mime});
};

export const resizeImage = (data, type) => {
  const RESIZED_RES = 480;
  return new Promise(async (resolve, reject) => {
    let thumbnail = null;
    // Load the image
    var reader = new FileReader();
    reader.onload = function (readerEvent) {
        var image = new Image();
        image.onload = function (imageEvent) {
          // Resize the image
          var canvas = document.createElement('canvas'),
            max_size = RESIZED_RES, // max res for width/height
            width = image.width,
            height = image.height;
          if (width > height) {
            if (width > max_size) {
              height *= max_size / width;
              width = max_size;
            }
          } else {
            if (height > max_size) {
              width *= max_size / height;
              height = max_size;
            }
          }
          canvas.width = width;
          canvas.height = height;
          canvas.getContext('2d').drawImage(image, 0, 0, width, height);
          thumbnail = canvas.toDataURL(type); // ex. "image/jpeg"
          resolve(thumbnail);
        }
        image.src = readerEvent.target.result;
    }
    reader.readAsDataURL(data);
  });
}
