import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SortIcon from "@mui/icons-material/Sort";
import ClearIcon from "@mui/icons-material/ClearOutlined";

import { filterUser, getUsers } from "../../store/actions/userAction";
import {
  SET_ERROR_MESSAGE_USER_PAGE,
  SET_ERROR_PROMPT_USER_PAGE,
  SET_USER,
  SET_USER_PAGE,
} from "../../store/actionTypes/userActionType";
import { decrypt } from "../../helpers/crypto";
import Role from "../../helpers/roles";
import { ascending, descending } from "../../helpers/sorting";
import { API_LIMIT, initiateOrganizationAPI } from "../../api";
import { initiateAXIOS } from "../../config/axios";

const UserFilter = () => {
  const dispatch = useDispatch();
  const isMounted = useRef(false);

  const [name, setName] = useState("");

  const [role, setRole] = useState("");

  const [organizations, setOrganizations] = useState([]);

  const [organizationId, setOrganizationId] = useState("");

  const { loggedUser } = useSelector((state) => state.auth);

  const decryptedLoggedUser = decrypt(loggedUser);

  const userRole = decryptedLoggedUser.type;

  const { users } = useSelector((state) => state.user);

  const [anchorEl, setAnchorEl] = useState(null);

  const openSortButton = Boolean(anchorEl);

  const [sortBy, setSortBy] = useState("A-Z");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeSortBy = (event) => {
    const value = event.target.value;
    setSortBy(value);

    let sorted;

    switch (value) {
      case "Oldest":
        sorted = ascending(users, "id");
        break;

      case "Newest":
        sorted = descending(users, "id");
        break;

      case "A-Z":
        sorted = ascending(users, "name");
        break;

      case "Z-A":
        sorted = descending(users, "name");
        break;

      default:
        break;
    }

    dispatch({ type: SET_USER, payload: sorted });
  };

  const getOrganizations = async () => {
    const access_token = sessionStorage.getItem("access_token");
    try {
      const { data } = await initiateAXIOS.get(
        initiateOrganizationAPI + `?limit=${API_LIMIT}`,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );
      setOrganizations(data.items);
    } catch (error) {
      const errorMessage = error.response.data.message;
      dispatch({ type: SET_ERROR_PROMPT_USER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_USER_PAGE, payload: errorMessage });
    }
  };

  const handleResetState = () => {
    setName("");
    setRole("");
    setOrganizationId("");
    setOrganizations([]);
    setSortBy("A-Z");
  };

  const handleRefresh = () => {
    dispatch(getUsers());
    dispatch({ type: SET_USER_PAGE, payload: 0 });
    handleResetState();
    if (userRole === Role.Eins) {
      getOrganizations();
    }
  };

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current && userRole === Role.Eins) {
      getOrganizations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRole]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const query = [];

    if (name) {
      query.push(`nameLike=${name}`);
    }

    if (role) {
      query.push(`type=${role}`);
    }

    if (organizationId) {
      query.push(`organizationId=${organizationId}`);
    }

    const payload = `&${query.join("&")}`;

    dispatch(filterUser(payload));
    dispatch({ type: SET_USER_PAGE, payload: 0 });
  };

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
      onSubmit={handleSubmit}
    >
      <TextField
        fullWidth
        size="small"
        sx={{ mr: 2 }}
        placeholder="Search user..."
        type="search"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        value={name}
        onChange={(e) => setName(e.target.value)}
      />

      {(userRole === Role.Superadmin || userRole === Role.Eins) && (
        <TextField
          size="small"
          SelectProps={{ native: true }}
          select
          fullWidth
          value={role}
          onChange={(e) => setRole(e.target.value)}
          sx={{ mr: 2 }}
        >
          <option disabled defaultValue value="">
            Any Role
          </option>
          <option value="ADMIN">Admin</option>
          <option value="USER">User</option>
        </TextField>
      )}

      {userRole === Role.Eins && (
        <TextField
          size="small"
          select
          fullWidth
          SelectProps={{ native: true }}
          value={organizationId}
          onChange={(e) => setOrganizationId(e.target.value)}
          sx={{ mr: 2 }}
        >
          <option value="" disabled>
            Any Organization
          </option>
          {organizations.map((organization) => (
            <option key={organization.id} value={organization.id}>
              {organization.name}
            </option>
          ))}
        </TextField>
      )}

      <Button type="submit" variant="contained">
        Search
      </Button>

      <IconButton sx={{ color: "#111827", mx: 2 }} onClick={handleClick}>
        <Tooltip title="Sort">
          <SortIcon fontSize="small" />
        </Tooltip>
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openSortButton}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <RadioGroup value={sortBy} onChange={handleChangeSortBy}>
          <MenuItem>
            <FormControlLabel
              disableTypography
              value="Oldest"
              control={<Radio size="small" />}
              label="Oldest"
              sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
            />
          </MenuItem>
          <MenuItem>
            <FormControlLabel
              disableTypography
              value="Newest"
              control={<Radio size="small" />}
              label="Newest"
              sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
            />
          </MenuItem>
          <MenuItem>
            <FormControlLabel
              disableTypography
              value="A-Z"
              control={<Radio size="small" />}
              label="A-Z"
              sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
            />
          </MenuItem>
          <MenuItem>
            <FormControlLabel
              disableTypography
              value="Z-A"
              control={<Radio size="small" />}
              label="Z-A"
              sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
            />
          </MenuItem>
        </RadioGroup>
      </Menu>

      <IconButton onClick={handleRefresh} sx={{ color: "#111827" }}>
        <Tooltip title="Clear">
          <ClearIcon fontSize="small" />
        </Tooltip>
      </IconButton>
    </Box>
  );
};

export default UserFilter;
