import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, AlertTitle } from "@mui/material";

import { SET_SUCCESS_PROMPT_ANNOUNCEMENT_PAGE } from "../../store/actionTypes/announcementActionType";

const AnnouncementSuccessPrompt = () => {
  const dispatch = useDispatch();

  const {
    isSuccessPromptAnnouncementPage: isSuccess,
    successMessageAnnouncementPage: successMessage,
  } = useSelector((state) => state.announcement);

  const handleClose = (event, reason) => {
    dispatch({ type: SET_SUCCESS_PROMPT_ANNOUNCEMENT_PAGE, payload: false });
  };

  return (
    isSuccess && (
      <Alert sx={{ my: 2 }} severity="success" variant="filled" onClose={handleClose}>
        <AlertTitle>Success!</AlertTitle>
        {successMessage}
      </Alert>
    )
  );
};

export default AnnouncementSuccessPrompt;
