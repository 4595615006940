import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { initiateAXIOS } from "../../../../config/axios";
import { initiateViewerAPI } from "../../../../api";
import { TypographySmall, TypographyNormal, TypographyLarge } from "../../../customComponent";
import { ViewerBlueIcon } from "../../../../icons";

const tableStyling = {
  padding: "12px 12px"
};

const ContentViewer = ({ open, handleClose, data }) => {
  const [loading, setLoading] = useState(false);

  const DATA_PER_PAGE = 10;
  const [fetchOffset] = useState(0); // TODO: Infinite scrolling
  const [connectedViewers, setConnectedViewers] = useState([]);

  const getViewerByContent = useCallback(
    async (contentID) => {
      const access_token = sessionStorage.getItem("access_token");
      let getURL = `${initiateViewerAPI}?limit=${DATA_PER_PAGE}`;
      getURL += `&offset=${fetchOffset}`;
      getURL += `&contentId=${contentID}`;
  
      try {
        const { data } = await initiateAXIOS.get(getURL, {
          headers: { authorization: `Bearer ${access_token}` },
        });
  
        const viewers = []
        data.items?.map((item) => viewers.push(item));
        setConnectedViewers(viewers);
        setLoading(false);
      } catch (error) {
        console.error(`Error found while [getViewerByContent]:`, error);
      }
    },

    [fetchOffset],
  );

  useEffect(() => {
    setLoading(true);
    if (open) {
      getViewerByContent(data.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={() => handleClose()}>
      <DialogTitle
        display={"flex"}
        justifyContent={"space-between"}
      >
        Content Usage [{data.name}]
        <IconButton onClick={() => handleClose()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {loading && (
          <Box
            sx={{ height: "20vh" }}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <CircularProgress size={20} thickness={3} sx={{ color: "#1D4ED8" }} />
            <TypographyNormal sx={{ color: "#1D4ED8", mt: 2, fontWeight: 400 }}>
              Loading Data...
            </TypographyNormal>
          </Box>
        )}

        {!loading && (
          <Box sx={{ border: "1px solid #d6d6d6", borderRadius: "4px" }}>
            <TableContainer
              component={Paper}
              sx={{ overflowY: "auto", maxHeight: 444, boxShadow: 3 }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ ...tableStyling, paddingLeft: 2 }}>
                      Viewer Name
                    </TableCell>
                    <TableCell align="center" sx={{ ...tableStyling, width: 111 }}>
                      Viewer ID
                    </TableCell>
                    <TableCell align="center" sx={{ ...tableStyling, width: 222 }}>
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {connectedViewers.length !== 0 && connectedViewers.map((viewer) => (
                    <TableRow key={viewer.id}>
                      <TableCell>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <ViewerBlueIcon />
                          <TypographyNormal sx={{ ml: 1 }}>
                            {viewer.name}
                          </TypographyNormal>
                        </Box>
                      </TableCell>
                      <TableCell align="center">{viewer.id}</TableCell>
                      <TableCell align="center">
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <TypographySmall
                            sx={{
                              alignItems: "center",
                              borderRadius: "15px",
                              color:
                                viewer.connectionStatus === "ONLINE"
                                  ? "#065F46"
                                  : "#111827",
                              backgroundColor:
                                viewer.connectionStatus === "ONLINE"
                                  ? "#D1FAE5"
                                  : "#DFDFDF",
                              p: 1,
                              mr: 1,
                              fontWeight: 600,
                            }}
                          >
                            <FiberManualRecordIcon
                              sx={{
                                color:
                                  viewer.connectionStatus === "ONLINE"
                                    ? "#065F46"
                                    : "#9CA3AF",
                                fontSize: 8,
                                mr: 1,
                              }}
                            />
                            {viewer.connectionStatus === "ONLINE" ? "Online" : "Offline"}
                          </TypographySmall>

                          <TypographySmall
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              borderRadius: "15px",
                              color: viewer.displayStatus === "ON" ? "#065F46" : "#111827",
                              backgroundColor:
                                viewer.displayStatus === "ON" ? "#D1FAE5" : "#DFDFDF",
                              p: 1,
                            }}
                          >
                            <FiberManualRecordIcon
                              sx={{
                                color:
                                  viewer.displayStatus === "ON" ? "#065F46" : "#9CA3AF",
                                fontSize: 8,
                                mr: 1,
                              }}
                            />
                            Display:
                            <TypographySmall sx={{ mx: "2px", fontWeight: 600 }}>
                              {viewer.displayStatus === "ON" ? "On" : "Off"}
                            </TypographySmall>
                          </TypographySmall>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {connectedViewers.length === 0 && (
              <Paper
                alignItems={"center"}
                bgcolor={"#f9f9f9"}
                sx={{ p: 5, boxShadow: 3, backgroundColor: "#e7e7e7" }}
              >
                <TypographyLarge textAlign={"center"}>
                  No viewer has use this specific content.
                </TypographyLarge>
              </Paper>
            )}
          </Box>
        )}
      </DialogContent>

      <DialogActions sx={{ mx: 2, pb: 2 }}>
        <Button variant="outlined" onClick={() => handleClose()}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContentViewer;
