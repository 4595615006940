import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  FormControlLabel,
  DialogTitle,
  IconButton,
  Divider,
  TextField,
  Button,
  InputLabel,
  Checkbox,
} from "@mui/material";
import { blue, green, grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import { Close } from "@mui/icons-material";

import { TypographySmall } from "../customComponent";
import { editUser } from "../../store/actions/userAction";
import {
  editPasswordAdminSchema,
  editPasswordUserSchema,
} from "../../schemas/editPasswordSchema";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Role from "../../helpers/roles";
import { API_LIMIT, initiateUserGroupAPI } from "../../api";
import {
  SET_ERROR_MESSAGE_USER_PAGE,
  SET_ERROR_PROMPT_USER_PAGE,
} from "../../store/actionTypes/userActionType";
import { initiateAXIOS } from "../../config/axios";

const StyledBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  ":last-child": {
    marginBottom: theme.spacing(0),
  },
}));

const AdminTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: green[700],
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: green[700],
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: green[700],
    },
    "&:hover fieldset": {
      borderColor: green[700],
    },
    "&.Mui-focused fieldset": {
      borderColor: green[700],
    },
  },
});

const UserTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: blue[900],
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: blue[900],
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: blue[900],
    },
    "&:hover fieldset": {
      borderColor: blue[900],
    },
    "&.Mui-focused fieldset": {
      borderColor: blue[900],
    },
  },
});

const EditUser = ({ open, onClose, user }) => {
  const dispatch = useDispatch();

  const handleClickClose = () => {
    onClose(false);
    setChangePassword(false);
    reset({
      "Admin Password": "",
      "New Password": "",
      "Confirm Password": "",
    });
    unregister(["Admin Password", "New Password", "Confirm Password"]);
  };

  let resolver;

  if (user.type === Role.Admin || user.type === Role.Superadmin) {
    resolver = editPasswordAdminSchema;
  } else resolver = editPasswordUserSchema;

  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(resolver),
  });

  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [selectGroup, setSelectGroup] = useState(user.userGroupId);
  const [userGroups, setUserGroups] = useState([]);

  const [changePassword, setChangePassword] = useState(false);

  const submitEditForm = (data) => {
    let payload = {
      id: user.id,
      user: {},
    };

    if (name !== user.name) {
      payload["user"]["name"] = name;
    }

    if (email !== user.email) {
      payload["user"]["email"] = email;
    }

    if (changePassword) {
      payload["user"]["currentPassword"] = data["Admin Password"];
      payload["user"]["password"] = data["New Password"];
    }

    if (selectGroup !== user.userGroupId)
      payload["user"]["userGroupId"] = +selectGroup;

    dispatch(editUser(payload));

    reset({
      "Admin Password": "",
      "New Password": "",
      "Confirm Password": "",
    });

    setChangePassword(false);

    unregister(["Admin Password", "New Password", "Confirm Password"]);
  };

  const stopPropagationForTab = (event) => {
    if (event.key === "Tab") event.stopPropagation();

    if (event.key === "e") event.stopPropagation();
  };

  const getUserGroups = async (query) => {
    const access_token = sessionStorage.getItem("access_token");
    try {
      const { data } = await initiateAXIOS.get(
        initiateUserGroupAPI + `?limit=${API_LIMIT}` + (query ?? ""),
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      setUserGroups(data.items);
    } catch (error) {
      const errorMessage = error.response.data.message;
      dispatch({ type: SET_ERROR_PROMPT_USER_PAGE, payload: true });
      dispatch({
        type: SET_ERROR_MESSAGE_USER_PAGE,
        payload: errorMessage,
      });
    }
  };

  useEffect(() => {
    if (open) {
      getUserGroups(`&organizationId=${user.organizationId}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <Dialog
        maxWidth="sm"
        open={open}
        fullWidth
        onKeyDown={stopPropagationForTab}
        onClose={handleClickClose}
      >
        <Box component="form" onSubmit={handleSubmit(submitEditForm)}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <DialogTitle>Edit User</DialogTitle>
            <IconButton
              onClick={handleClickClose}
              sx={{ mr: ".75rem", color: grey[400] }}
            >
              <Close />
            </IconButton>
          </Box>

          <Divider />

          <DialogContent>
            <StyledBox>
              <InputLabel>Username</InputLabel>
              <TextField
                value={name}
                onChange={(e) => setName(e.target.value)}
                autoFocus
                margin="dense"
                fullWidth
                required
                name="name"
              />
            </StyledBox>

            <StyledBox>
              <InputLabel>Email</InputLabel>
              <TextField
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoFocus
                margin="dense"
                fullWidth
                required
                name="email"
              />
            </StyledBox>

            <StyledBox>
              <InputLabel>User Group</InputLabel>

              <TextField
                select
                margin="dense"
                fullWidth
                required
                SelectProps={{
                  native: true,
                }}
                onChange={(e) => setSelectGroup(e.target.value)}
                value={selectGroup}
              >
                <option value="">Select Group</option>
                {userGroups?.map((group) => (
                  <option key={group.id} value={group.id}>
                    {group.name}
                  </option>
                ))}
              </TextField>
            </StyledBox>

            <StyledBox>
              <FormControlLabel
                control={<Checkbox size="small" />}
                value={changePassword}
                onChange={(e) => {
                  if (e.target.checked === true) {
                    setChangePassword(true);
                  } else {
                    setChangePassword(false);
                    unregister([
                      "Admin Password",
                      "New Password",
                      "Confirm Password",
                    ]);
                  }
                }}
                label={
                  <TypographySmall sx={{ fontWeight: 300 }}>
                    Enable to change password
                  </TypographySmall>
                }
              />
            </StyledBox>

            {changePassword ? (
              <Box>
                <StyledBox sx={{ width: "100%" }}>
                  <InputLabel>Admin Password</InputLabel>
                  <AdminTextField
                    {...register("Admin Password")}
                    helperText={errors["Admin Password"]?.message}
                    error={errors["Admin Password"] ? true : false}
                    margin="dense"
                    fullWidth
                    type="password"
                    variant="outlined"
                    required
                    placeholder="Enter your admin password"
                    sx={{ backgroundColor: "white" }}
                  />
                </StyledBox>

                <StyledBox>
                  <InputLabel>New Password</InputLabel>
                  <UserTextField
                    {...register("New Password")}
                    helperText={errors["New Password"]?.message}
                    error={errors["New Password"] ? true : false}
                    margin="dense"
                    fullWidth
                    type="password"
                    variant="outlined"
                    required
                    placeholder="Enter new password for user"
                  />
                </StyledBox>

                <StyledBox>
                  <InputLabel>Confirm Password</InputLabel>
                  <UserTextField
                    {...register("Confirm Password")}
                    helperText={
                      errors["Confirm Password"] &&
                      "Confirm password must be equal to new password"
                    }
                    error={errors["Confirm Password"] ? true : false}
                    margin="dense"
                    fullWidth
                    type="password"
                    variant="outlined"
                    required
                    placeholder="Enter confirm password for user"
                  />
                </StyledBox>
              </Box>
            ) : null}
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" fullWidth onClick={handleClickClose}>
              Cancel
            </Button>

            <Button variant="contained" fullWidth type="submit">
              Save
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default EditUser;
