import * as yup from "yup";

export const editPasswordAdminSchema = yup.object().shape({
  "Admin Password": yup.string().notRequired(),
  "New Password": yup.string().min(16).max(30).nullable().notRequired(),
  "Confirm Password": yup.string().oneOf([yup.ref("New Password"), null]),
});

export const editPasswordUserSchema = yup.object().shape({
  "Admin Password": yup.string().notRequired(),
  "New Password": yup.string().min(8).max(30).nullable().notRequired(),
  "Confirm Password": yup.string().oneOf([yup.ref("New Password"), null]),
});
