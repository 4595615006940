import React, { useState, useEffect } from "react";
import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import { useDebounce } from "../../../hooks";

import DeleteIcon from "@mui/icons-material/Delete";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const ContentItem = ({ content, index, removeContent, handleIntervalChange }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: content.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const [interval, setInterval] = useState("");
  const [resetFlag, setResetFlag] = useState(false);
  const debouncedInterval = useDebounce(interval, 500);

  useEffect(
    () => {
      if (debouncedInterval === "" || +debouncedInterval <= 0) {
        setInterval(content.interval);
        setResetFlag(true);
        return;
      }

      if (resetFlag) {
        setResetFlag(false);
        return;
      }

      if (debouncedInterval) {
        handleIntervalChange(index, debouncedInterval);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedInterval] // Only call effect if debounced interval changes
  );

  return (
    <Box
      ref={setNodeRef}
      style={style}
      key={"content" + index}
      sx={{
        mx: 1,
        mt: 1,
        p: 1,
        borderRadius: "4px",
        ":hover": {
          backgroundColor: "rgba(28, 78, 216, 0.1)",
        },
      }}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Box display={"flex"} alignItems={"center"}>
        <Box sx={{ mx: 1 }}>
          <UnfoldMoreIcon
            sx={{
              ":hover": { border: "2px solid #1E4ED8", borderRadius: "4px" },
            }}
            {...listeners}
            {...attributes}
          />
        </Box>
        <Box sx={{ mx: 1 }}>{index + 1}</Box>
        <Box>{content.name}</Box>
      </Box>

      <Box display={"flex"}>
        <Box>
          <TextField
            sx={{ width: "100px" }}
            size="small"
            placeholder="0"
            value={interval}
            onChange={(e) => setInterval(e.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="end">Sec</InputAdornment>,
            }}
          />
        </Box>
        <Box sx={{ mx: "2px" }}>
          <IconButton color="error" onClick={() => removeContent(content.id, index)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default ContentItem;
