import React from "react";

import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  IconButton,
  Divider,
  Button,
  InputLabel,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import { Close } from "@mui/icons-material";

import { TypographyXLarge } from "../customComponent";

const StyledBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  ":last-child": {
    marginBottom: theme.spacing(0),
  },
}));

const ViewUser = ({ open, onClose, user }) => {
  const handleClickClose = () => {
    onClose(false);
  };

  return (
    <>
      <Dialog maxWidth="sm" open={open} fullWidth onClose={handleClickClose}>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <DialogTitle>View User</DialogTitle>
            <IconButton
              onClick={handleClickClose}
              sx={{ mr: ".75rem", color: grey[400] }}
            >
              <Close />
            </IconButton>
          </Box>

          <Divider />

          <DialogContent>
            <StyledBox>
              <InputLabel sx={{ mb: "4px" }}>Username :</InputLabel>
              <TypographyXLarge>{user.name}</TypographyXLarge>
            </StyledBox>

            <StyledBox>
              <InputLabel sx={{ mt: "20px", mb: "4px" }}>Role :</InputLabel>
              <TypographyXLarge>{user.type}</TypographyXLarge>
            </StyledBox>

            <StyledBox>
              <InputLabel sx={{ mt: "20px", mb: "4px" }}>
                Organization :
              </InputLabel>
              <TypographyXLarge>{user.organization.name}</TypographyXLarge>
            </StyledBox>

            <StyledBox>
              <InputLabel sx={{ mt: "20px", mb: "4px" }}>Email :</InputLabel>
              <TypographyXLarge>{user.email}</TypographyXLarge>
            </StyledBox>

            <StyledBox>
              <InputLabel sx={{ mt: "20px", mb: "4px" }}>
                Phone Number :
              </InputLabel>
              <TypographyXLarge>{user.phone}</TypographyXLarge>
            </StyledBox>
          </DialogContent>

          <Divider></Divider>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button variant="outlined" onClick={handleClickClose}>
              Close
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default ViewUser;
