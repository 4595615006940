import {
  SET_ERROR_MESSAGE_JOB_HISTORY_PAGE,
  SET_ERROR_PROMPT_JOB_HISTORY_PAGE,
  SET_JOB_HISTORY,
  SET_JOB_HISTORY_PAGE,
  SET_LOADING_JOB_HISTORY,
} from "../actionTypes/jobHistoryActionType";

const initialState = {
  loadingJobHistory: false,
  jobHistories: [],
  isJobHistoryHasError: false,
  errorMessageJobHistory: "",
  page: 0,
};

export const jobHistoryReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_JOB_HISTORY:
      return { ...state, jobHistories: payload };

    case SET_LOADING_JOB_HISTORY:
      return { ...state, loadingJobHistory: payload };

    case SET_ERROR_PROMPT_JOB_HISTORY_PAGE:
      return { ...state, isJobHistoryHasError: payload };

    case SET_ERROR_MESSAGE_JOB_HISTORY_PAGE:
      return { ...state, errorMessageJobHistory: payload };

    case SET_JOB_HISTORY_PAGE:
      return { ...state, page: payload };

    default:
      return state;
  }
};
