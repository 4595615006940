export const SET_DISRUPTION = "SET_DISRUPTION";
export const SET_LOADING_DISRUPTION = "SET_LOADING_DISRUPTION";
export const SET_NEW_DISRUPTION = "SET_NEW_DISRUPTION";
export const SET_DELETE_DISRUPTION = "SET_DELETE_DISRUPTION";
export const SET_DELETE_ALL_DISRUPTION = "SET_DELETE_ALL_DISRUPTION";
export const SET_ERROR_PROMPT_DISRUPTION = "SET_ERROR_PROMPT_DISRUPTION";
export const SET_ERROR_MESSAGE_DISRUPTION = "SET_ERROR_MESSAGE_DISRUPTION";
export const SET_SUCCESS_PROMPT_DISRUPTION = "SET_SUCCESS_PROMPT_DISRUPTION";
export const SET_SUCCESS_MESSAGE_DISRUPTION = "SET_SUCCESS_MESSAGE_DISRUPTION";
export const SET_DISRUPTION_ANNOUNCEMENT = "SET_DISRUPTION_ANNOUNCEMENT";
