import { Box, Container, CssBaseline, Toolbar, Paper } from "@mui/material";
import { TypographyNormal, TypographyXLarge } from "../customComponent";
import Sidebar from "../Sidebar";
import ViewerSuccessPrompt from "./ViewerSuccessPrompt";
import ViewerErrorPrompt from "./ViewerErrorPrompt";
import FilterViewer from "./FilterViewer";
import ViewerList from "./ViewerList";
import CreateViewer from "./CreateViewer";
import { useSelector } from "react-redux";
import { decrypt } from "../../helpers/crypto";
import Role from "../../helpers/roles";

const Viewer = () => {
  const { loggedUser } = useSelector((state) => state.auth);
  const decryptedLoggedUser = decrypt(loggedUser);
  const userRole = decryptedLoggedUser.type;
  return (
    <div style={{ display: "flex" }}>
      <Sidebar />

      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Toolbar />
        <CssBaseline />

        <Container maxWidth="xl">
          <Box sx={{ mb: 1 }}>
            <TypographyXLarge sx={{ fontWeight: 700 }}>Viewer</TypographyXLarge>
            <TypographyNormal sx={{ fontWeight: 400 }}>
              Manage your digital signage viewers
            </TypographyNormal>
          </Box>

          <ViewerSuccessPrompt />
          <ViewerErrorPrompt />

          {userRole === Role.Eins && (
            <Box display={"flex"} justifyContent={"flex-end"}>
              <CreateViewer />
            </Box>
          )}

          <Paper
            component="section"
            elevation={2}
            sx={{ p: 2, my: 2, borderRadius: "6px" }}
          >
            <FilterViewer />
          </Paper>

          <Paper
            component="section"
            elevation={2}
            sx={{ p: 2, my: 2, borderRadius: "6px" }}
          >
            <ViewerList />
          </Paper>
        </Container>
      </Box>
    </div>
  );
};

export default Viewer;
