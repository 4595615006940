import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { TypographyNormal } from "../customComponent";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  Radio,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDebounce } from "../hooks";
import { initiateAXIOS } from "../../config/axios";
import { GroupBlueIcon, ViewerBlueIcon } from "../../icons";
import {
  instanceCMSContentAPI,
  instanceCMSViewerAPI,
  instanceCMSViewerGroupAPI,
} from "../../api";
import { assignContentUpload } from "../../store/actions/contentUploadAction";
import { filterBasedOnId } from "../../helpers/filterByReference";

const StyledBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  ":last-child": {
    marginBottom: 0,
  },
}));

const AssignContent = ({ id, open, handleClickClose }) => {
  const dispatch = useDispatch();
  const [selectViewerType, setSelectViewerType] = useState("");
  const [selectViewerId, setSelectViewerId] = useState("");
  const [contentData, setContentData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChangeViewerType = (e) => {
    setSelectViewerType(e.target.value);
  };

  const handleViewerValue = (val) => {
    setSelectViewerId(val);
  };

  const handleSubmit = () => {
    let payload = { add: [{ id: +selectViewerId }] };

    dispatch(
      assignContentUpload({
        id,
        payload,
        type: selectViewerType === "viewerGroup" ? "viewer-group" : "viewer",
      })
    );
  };

  // Effect for API call
  useEffect(
    () => {
      const getContent = async () => {
        const access_token = sessionStorage.getItem("access_token");
        setLoading(true);
        try {
          const { data } = await initiateAXIOS.get(instanceCMSContentAPI + id, {
            headers: { authorization: `Bearer ${access_token}` },
          });

          setContentData(data);
        } catch (error) {
          // setIsError(true);
          // setErrorMessage(error.response.data.message);
          console.log(error);
        } finally {
          setLoading(false);
        }
      };

      if (open) {
        getContent();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open] // Only call effect if open
  );
  return (
    <>
      <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClickClose}>
        <Box>
          <DialogTitle>Assign Offline Content</DialogTitle>

          <DialogContent>
            <StyledBox>
              <InputLabel>Viewer Type</InputLabel>

              <TextField
                margin="dense"
                select
                SelectProps={{
                  native: true,
                }}
                required
                fullWidth
                size="small"
                onChange={handleChangeViewerType}
                value={selectViewerType}
              >
                <option value="" disabled>
                  Select Viewer Type
                </option>
                <option value="viewerGroup">Group Viewer</option>
                <option value="viewer">Single Viewer</option>
              </TextField>
            </StyledBox>

            {selectViewerType && !loading && (
              <ViewerInputDebounce
                content={contentData}
                type={selectViewerType}
                viewerValue={selectViewerId}
                setViewerValue={handleViewerValue}
              />
            )}
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" onClick={handleClickClose} fullWidth>
              Cancel
            </Button>

            <Button
              variant="contained"
              onClick={() => {
                handleSubmit();
              }}
              fullWidth
            >
              Assign
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

const ViewerInputDebounce = ({
  content,
  type,
  viewerValue,
  setViewerValue,
}) => {
  const [inputText, setInputText] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const debouncedSearchTerm = useDebounce(inputText, 1000);
  const [viewerList, setViewerList] = useState([]);
  const [selectedViewer, setSelectedViewer] = useState({});

  // Effect for API call
  useEffect(
    () => {
      const getViewers = async (value) => {
        const access_token = sessionStorage.getItem("access_token");
        setIsSearching(true);
        try {
          const { data } = await initiateAXIOS.get(
            (type === "viewerGroup"
              ? instanceCMSViewerGroupAPI
              : instanceCMSViewerAPI) + `&nameLike=${value}`,
            {
              headers: { authorization: `Bearer ${access_token}` },
            }
          );

          const filteredData = filterBasedOnId(data, content[type + "s"]);
          setViewerList(filteredData);
        } catch (error) {
          // setIsError(true);
          // setErrorMessage(error.response.data.message);
          console.log(error);
        } finally {
          setIsSearching(false);
        }
      };

      if (debouncedSearchTerm) {
        getViewers(debouncedSearchTerm);
      } else {
        setIsSearching(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );

  useEffect(() => {
    setInputText("");
    setViewerList([]);
    setSelectedViewer({});
    setViewerValue("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const handleCancelSelection = () => {
    setInputText("");
    setViewerList([]);
    setSelectedViewer({});
    setViewerValue("");
  };

  return (
    <Box>
      {!selectedViewer.id && (
        <>
          <InputLabel>
            Search {type === "viewer" ? "Viewer" : "Group"} Name :
          </InputLabel>
          <TextField
            margin="dense"
            required
            fullWidth
            size="small"
            onChange={(e) => setInputText(e.target.value)}
            value={inputText}
          />
        </>
      )}

      {!selectedViewer.id ? (
        <Alert severity="info" variant="outlined" sx={{ mt: 1 }}>
          <strong>No {type === "viewer" ? "viewer" : "group"} selected!</strong>
        </Alert>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 2,
            mt: 1,
            border: "1px solid #03a9f4",
            borderRadius: "4px",
            p: 1,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {type === "viewer" ? <ViewerBlueIcon /> : <GroupBlueIcon />}
            <Box sx={{ ml: 2 }}>
              <TypographyNormal sx={{ fontWeight: 600 }}>
                {selectedViewer.name}
              </TypographyNormal>
            </Box>
          </Box>
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              handleCancelSelection();
            }}
          >
            Remove Selection
          </Button>
        </Box>
      )}

      {isSearching && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress size={24} sx={{ color: "#1C4ED8" }} />
        </Box>
      )}

      {!selectedViewer.id && (
        <Box
          sx={{
            maxHeight: 300,
            overflowY: "auto",
            p: 2,
            borderRadius: "6px",
            border: "1px solid #DCDEE3",
            mt: 1,
            mb: 2,
          }}
        >
          {!isSearching && !viewerList.length && (
            <Alert severity="info" variant="outlined">
              <strong>
                No {type === "viewer" ? "viewers" : "groups"} found!
              </strong>{" "}
              There are no available {type === "viewer" ? "viewers" : "groups"}{" "}
              at the moment or We couldn't find what you're looking for
            </Alert>
          )}

          {!isSearching &&
            viewerList?.map((viewer) => (
              <Box
                key={viewer.id}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mb: 2,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {type === "viewer" ? <ViewerBlueIcon /> : <GroupBlueIcon />}

                  <Box sx={{ ml: 2 }}>
                    <TypographyNormal sx={{ fontWeight: 600 }}>
                      {viewer.name}
                    </TypographyNormal>
                  </Box>
                </Box>

                <Radio
                  checked={viewerValue === viewer.id.toString()}
                  value={viewer.id}
                  name="radio-buttons"
                  onChange={(e) => {
                    setViewerValue(e.target.value);
                    setSelectedViewer({
                      id: e.target.value,
                      name: viewer.name,
                    });
                  }}
                />
              </Box>
            ))}
        </Box>
      )}
    </Box>
  );
};

export default AssignContent;
