import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Select,
  FormControl,
  CircularProgress,
  MenuItem,
  OutlinedInput,
  IconButton,
  Button,
  Paper,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FiberManualRecord, NavigateNext } from "@mui/icons-material";

import { ViewDetailViewer } from "..";

import {
  TypographyNormal,
  TypographyLarge,
  TypographySmall,
} from "../../components/customComponent";
import { getLastActiveViewers } from "../../store/actions/homeAction";
import { useNavigate } from "react-router-dom";
import { ViewerBlueIcon } from "../../icons";

const useStyles = makeStyles(() => ({
  hover: { "&:hover": { backgroundColor: "#DBE9FE", cursor: "pointer" } },
}));

const HomeLastActiveViewerList = () => {
  const dispatch = useDispatch();

  const { lastActiveViewers: viewers, loadingLastActiveViewers: loading } =
    useSelector((state) => state.home);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getLastActiveViewers(5));
  }, [dispatch]);

  const [integer, setInteger] = useState(5);

  const handleChange = (e) => {
    dispatch({ type: "SET_HOME_LAST_ACTIVE_VIEWER_DATA", payload: [] });
    setInteger(e.target.value);
    dispatch(getLastActiveViewers(e.target.value));
  };

  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        height={250}
      >
        <CircularProgress size={22} thickness={3} sx={{ color: "#1D4ED8" }} />
        <TypographyNormal
          sx={{ color: "#1D4ED8", marginTop: "15px", fontWeight: 300 }}
        >
          Loading Data...
        </TypographyNormal>
      </Box>
    );

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          <TypographyLarge sx={{ fontWeight: 600 }}>Recent</TypographyLarge>

          <Box display="flex" alignItems="center">
            <FormControl>
              <Select
                input={<OutlinedInput size="small" />}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={handleChange}
                value={integer}
              >
                <MenuItem dense value={5}>
                  5
                </MenuItem>
                <MenuItem dense value={10}>
                  10
                </MenuItem>
              </Select>
            </FormControl>
            <TypographyLarge sx={{ ml: 2, fontWeight: 600 }}>
              Active Viewers
            </TypographyLarge>
          </Box>
        </Box>

        {Array.isArray(viewers) && !viewers.length ? (
          <TypographyNormal sx={{ fontWeight: 300 }} align="center">
            No viewer
          </TypographyNormal>
        ) : (
          <Paper
            elevation={2}
            sx={{
              maxHeight: 425,
              overflowY: "auto",
            }}
          >
            {viewers.map((viewer) => (
              <HomeLastActiveViewerItem key={viewer.id} viewer={viewer} />
            ))}
          </Paper>
        )}
      </Box>

      <Button
        variant="contained"
        onClick={() => navigate("/viewers")}
        fullWidth
      >
        View All Viewers
      </Button>
    </>
  );
};

const HomeLastActiveViewerItem = ({ viewer }) => {
  const classes = useStyles();

  const [openDetail, setOpenDetail] = useState(false);

  const handleOpenDetail = () => {
    setOpenDetail(true);
  };

  const handleCloseDetail = () => {
    setOpenDetail(false);
  };

  return (
    <>
      <Box
        onClick={handleOpenDetail}
        sx={{ borderRadius: "6px", mb: 1 }}
        boxShadow="0px 4px 20px rgba(158, 181, 195, 0.18)"
        className={classes.hover}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={1}
      >
        <Box display="flex">
          <ViewerBlueIcon />

          <Box ml={2}>
            <TypographyNormal sx={{ fontWeight: 600, mb: 0.5 }}>
              {viewer.name}
            </TypographyNormal>

            <TypographySmall sx={{ fontWeight: 300 }}>
              {viewer.viewerGroup !== null
                ? viewer.viewerGroup?.pathName[0]
                : null}
            </TypographySmall>
          </Box>
        </Box>

        <Box display="flex" alignItems="center">
          <TypographySmall
            sx={{
              display: "flex",
              alignItems: "center",
              borderRadius: "15px",
              color: viewer.status === "ACTIVE" ? "#065F46" : "#111827",
              backgroundColor:
                viewer.status === "ACTIVE" ? "#D1FAE5" : "#DFDFDF",
              padding: "8px",
              marginRight: "15px",
            }}
          >
            <FiberManualRecord
              sx={{
                color: viewer.status === "ACTIVE" ? "#065F46" : "#9CA3AF",
                fontSize: 8,
                marginRight: "5px",
              }}
            />
            {viewer.status === "ACTIVE" ? "Active" : "Inactive"}
          </TypographySmall>

          <IconButton color="inherit">
            <NavigateNext />
          </IconButton>
        </Box>
      </Box>

      <ViewDetailViewer
        openDetail={openDetail}
        onClose={handleCloseDetail}
        viewer={viewer}
      />
    </>
  );
};

export default HomeLastActiveViewerList;
