export const SET_ANNOUNCEMENT = "SET_ANNOUNCEMENT";
export const SET_LOADING_ANNOUNCEMENT = "SET_LOADING_ANNOUNCEMENT";
export const SET_NEW_ANNOUNCEMENT = "SET_NEW_ANNOUNCEMENT";
export const SET_ANNOUNCEMENT_DETAIL = "SET_ANNOUNCEMENT_DETAIL";
export const SET_LOADING_ANNOUNCEMENT_DETAIL = "SET_LOADING_ANNOUNCEMENT_DETAIL";
export const SET_EDIT_ANNOUNCEMENT = "SET_EDIT_ANNOUNCEMENT";
export const SET_DELETE_ANNOUNCEMENT = "SET_DELETE_ANNOUNCEMENT";
export const SET_SUCCESS_PROMPT_ANNOUNCEMENT_PAGE =
  "SET_SUCCESS_PROMPT_ANNOUNCEMENT_PAGE";
export const SET_ERROR_PROMPT_ANNOUNCEMENT_PAGE = "SET_ERROR_PROMPT_ANNOUNCEMENT_PAGE";
export const SET_SUCCESS_MESSAGE_ANNOUNCEMENT_PAGE =
  "SET_SUCCESS_MESSAGE_ANNOUNCEMENT_PAGE";
export const SET_ERROR_MESSAGE_ANNOUNCEMENT_PAGE = "SET_ERROR_MESSAGE_ANNOUNCEMENT_PAGE";
