import React from "react";
import { useDispatch } from "react-redux";

import {
  Dialog,
  Box,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import { forceLogoutUser } from "../../store/actions/userAction";

const ForceLogoutUser = ({ user, open, onClose }) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch(forceLogoutUser(+user.id));

    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <Box component="form" onSubmit={handleSubmit}>
        <DialogContent>
          <DialogContentText sx={{ mb: 2, textAlign: "center" }}>
            Are you sure want to <strong>force logout {user.name}?</strong>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            fullWidth
            startIcon={<ClearIcon />}
            onClick={handleClose}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            fullWidth
            startIcon={<DoneIcon />}
            type="submit"
          >
            Yes, Logout User!
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ForceLogoutUser;
