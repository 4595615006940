import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ascending, descending } from "../../../helpers/sorting";
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SortIcon from "@mui/icons-material/Sort";
import ClearIcon from "@mui/icons-material/ClearOutlined";
import { SET_USER_HISTORY } from "../../../store/actionTypes/userActionType";
import { getUserHistory } from "../../../store/actions/userAction";
import Role from "../../../helpers/roles";

const UserHistoryFilter = ({ role }) => {
  const dispatch = useDispatch();

  const { userHistory } = useSelector((state) => state.user);

  const [name, setName] = useState("");
  const [roleSelect, setRoleSelect] = useState("");
  const [recordedAtStart, setRecordedAtStart] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);

  const openSortButton = Boolean(anchorEl);

  const [sortBy, setSortBy] = useState("Newest");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeSortBy = (event) => {
    const value = event.target.value;
    setSortBy(value);

    let sorted;

    switch (value) {
      case "Oldest":
        sorted = ascending(userHistory, "id");
        break;

      case "Newest":
        sorted = descending(userHistory, "id");
        break;

      default:
        break;
    }

    dispatch({ type: SET_USER_HISTORY, payload: sorted });
  };

  const handleResetState = () => {
    setName("");
    setRoleSelect("");
    setSortBy("Newest");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const query = [];

    if (name) {
      query.push(`userNameLike=${name}`);
    }

    if (roleSelect) {
      query.push(`userRole=${roleSelect}`);
    }

    if (recordedAtStart) {
      const startDate = recordedAtStart + "T00:00:00";
      const endDate = recordedAtStart + "T23:59:59";
      query.push(`recordedAtGte=${startDate}`);
      query.push(`recordedAtLte=${endDate}`);
    }

    const payload = `&${query.join("&")}`;

    dispatch(getUserHistory(payload));
    setSortBy("Newest");
  };

  const handleRefresh = () => {
    dispatch(getUserHistory(""));
    handleResetState();
  };

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
      onSubmit={handleSubmit}
    >
      <TextField
        fullWidth
        size="small"
        sx={{ mr: 2 }}
        placeholder="Search user ..."
        type="search"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        value={name}
        onChange={(e) => setName(e.target.value)}
      />

      <TextField
        size="small"
        SelectProps={{ native: true }}
        select
        fullWidth
        value={roleSelect}
        onChange={(e) => setRoleSelect(e.target.value)}
        sx={{ mr: 2, maxWidth: 150 }}
      >
        <option defaultValue value="">
          Any Role
        </option>
        {role !== Role.Admin && <option value="SUPERADMIN">Superadmin</option>}
        <option value="ADMIN">Admin</option>
        <option value="USER">User</option>
      </TextField>

      <TextField
        sx={{ mr: 2, maxWidth: 185 }}
        size="small"
        fullWidth
        value={recordedAtStart}
        onChange={(e) => {
          setRecordedAtStart(e.target.value);
        }}
        type="date"
      />

      <Button type="submit" variant="contained">
        Search
      </Button>

      <IconButton sx={{ color: "#111827", mx: 2 }} onClick={handleClick}>
        <Tooltip title="Sort">
          <SortIcon fontSize="small" />
        </Tooltip>
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openSortButton}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <RadioGroup value={sortBy} onChange={handleChangeSortBy}>
          <MenuItem>
            <FormControlLabel
              disableTypography
              value="Oldest"
              control={<Radio size="small" />}
              label="Oldest"
              sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
            />
          </MenuItem>
          <MenuItem>
            <FormControlLabel
              disableTypography
              value="Newest"
              control={<Radio size="small" />}
              label="Newest"
              sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
            />
          </MenuItem>
        </RadioGroup>
      </Menu>

      <IconButton onClick={handleRefresh} sx={{ color: "#111827" }}>
        <Tooltip title="Clear">
          <ClearIcon fontSize="small" />
        </Tooltip>
      </IconButton>
    </Box>
  );
};

export default UserHistoryFilter;
