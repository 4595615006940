import React from "react";

const AdminIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0001 10.8C13.9883 10.8 15.6001 9.1882 15.6001 7.19998C15.6001 5.21175 13.9883 3.59998 12.0001 3.59998C10.0119 3.59998 8.4001 5.21175 8.4001 7.19998C8.4001 9.1882 10.0119 10.8 12.0001 10.8Z"
        fill="#F55858"
      />
      <path
        d="M3.6001 21.6C3.6001 16.9608 7.3609 13.2 12.0001 13.2C16.6393 13.2 20.4001 16.9608 20.4001 21.6H3.6001Z"
        fill="#F55858"
      />
      <path
        d="M12.0001 10.8C13.9883 10.8 15.6001 9.1882 15.6001 7.19998C15.6001 5.21175 13.9883 3.59998 12.0001 3.59998C10.0119 3.59998 8.4001 5.21175 8.4001 7.19998C8.4001 9.1882 10.0119 10.8 12.0001 10.8Z"
        fill="#F55858"
      />
      <path
        d="M11.9997 10.8C13.9879 10.8 15.5997 9.1882 15.5997 7.19998C15.5997 5.21175 13.9879 3.59998 11.9997 3.59998C10.0114 3.59998 8.39966 5.21175 8.39966 7.19998C8.39966 9.1882 10.0114 10.8 11.9997 10.8Z"
        fill="#FBE4D7"
      />
      <path
        d="M11.9997 10.8C13.9879 10.8 15.5997 9.1882 15.5997 7.19998C15.5997 5.21175 13.9879 3.59998 11.9997 3.59998C10.0114 3.59998 8.39966 5.21175 8.39966 7.19998C8.39966 9.1882 10.0114 10.8 11.9997 10.8Z"
        fill="#FBE4D7"
      />
    </svg>
  );
};

export default AdminIcon;
