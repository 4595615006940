import React from "react";

const PCOrangeIcon = () => {
  return (
    <>
      <svg
        width="33"
        height="32"
        viewBox="0 0 33 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="16.3335" cy="16" r="16" fill="#F1B68B" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.7336 12C10.7336 11.1164 11.45 10.4 12.3336 10.4H20.3336C21.2173 10.4 21.9336 11.1164 21.9336 12V18.4C21.9336 19.2837 21.2173 20 20.3336 20H18.5583L18.656 20.391L19.2994 21.0343C19.5282 21.2631 19.5966 21.6072 19.4728 21.9062C19.3489 22.2051 19.0572 22.4 18.7337 22.4H13.9337C13.6101 22.4 13.3184 22.2051 13.1946 21.9062C13.0707 21.6072 13.1392 21.2631 13.368 21.0343L14.0113 20.391L14.109 20H12.3336C11.45 20 10.7336 19.2837 10.7336 18.4V12ZM15.3507 17.6C15.3389 17.5998 15.3272 17.5998 15.3154 17.6H12.3336V12H20.3336V17.6H17.3519C17.3402 17.5998 17.3284 17.5998 17.3167 17.6H15.3507Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export default PCOrangeIcon;
