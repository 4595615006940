import React from "react";
import { Link } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import { Box, Button } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Typography2XLarge, TypographyXLarge } from "./customComponent";
import { DisconnectedIcon } from "../icons";

const NotFound = () => {
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const access_token = sessionStorage.getItem("access_token");

  return (
    <Box
      sx={{
        backgroundColor: "#f3f8ff",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "100vh",
        width: "100vw",
      }}
    >
      <Typography2XLarge
        sx={{
          fontSize: lg ? "4rem" : "2rem",
          mb: 4,
          fontWeight: 700,
          textAlign: "center",
          p: 1,
        }}
      >
        404: Page Not Found
      </Typography2XLarge>
      <TypographyXLarge
        sx={{
          fontWeight: 600,
          mb: 4,
          textAlign: "center",
          fontSize: lg ? "1.5rem" : "1rem",
          p: 1,
        }}
      >
        We’re sorry, the page you requested could not be found.
      </TypographyXLarge>

      {access_token ? (
        <Link to="/" style={{ textDecoration: "none" }}>
          <Button variant="contained" sx={{ mb: 8 }}>
            Back to Home
          </Button>
        </Link>
      ) : (
        <Link to="/login" style={{ textDecoration: "none" }}>
          <Button variant="contained" sx={{ mb: 8 }}>
            Back to Login
          </Button>
        </Link>
      )}

      {lg && <DisconnectedIcon />}
    </Box>
  );
};

export default NotFound;
