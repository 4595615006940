import { AccountCircle, Logout } from "@mui/icons-material";
import {
  Toolbar,
  Box,
  Menu,
  Backdrop,
  CircularProgress,
  Button,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  CustomAppBar,
  CustomMenuItem,
  TypographyLarge,
  TypographyNormal,
} from "./customComponent";
import axios from "../config/axios";
import {
  SET_GLOBAL_ERROR,
  SET_GLOBAL_ERROR_MESSAGE,
  SET_GLOBAL_SUCCESS,
  SET_GLOBAL_SUCCESS_MESSAGE,
} from "../store/actionTypes/globalActionType";
import {
  SET_ERROR_MESSAGE_VIEWER_PAGE,
  SET_ERROR_PROMPT_VIEWER_PAGE,
} from "../store/actionTypes/viewerActionTypes";
import {
  SET_ERROR_MESSAGE_GROUP_PAGE,
  SET_ERROR_PROMPT_GROUP_PAGE,
} from "../store/actionTypes/groupActionType";
import {
  SET_ERROR_MESSAGE_SCHEDULE_PAGE,
  SET_ERROR_PROMPT_SCHEDULE_PAGE,
} from "../store/actionTypes/scheduleActionType";
import {
  SET_AUTH_USER,
  SET_ERROR_MESSAGE_USER_PAGE,
  SET_ERROR_PROMPT_USER_PAGE,
} from "../store/actionTypes/userActionType";
import { decrypt } from "../helpers/crypto";

const drawerWidth = 240;

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [isLoading, setIsLoading] = useState(false);

  const { loggedUser } = useSelector((state) => state.auth);
  const decryptedLoggedUser = decrypt(loggedUser);

  const resetAllPromptState = () => {
    dispatch({ type: SET_GLOBAL_ERROR_MESSAGE, payload: "" });
    dispatch({ type: SET_GLOBAL_ERROR, payload: false });
    dispatch({ type: SET_ERROR_MESSAGE_VIEWER_PAGE, payload: "" });
    dispatch({ type: SET_ERROR_PROMPT_VIEWER_PAGE, payload: false });
    dispatch({ type: SET_ERROR_MESSAGE_GROUP_PAGE, payload: "" });
    dispatch({ type: SET_ERROR_PROMPT_GROUP_PAGE, payload: false });
    dispatch({ type: SET_ERROR_MESSAGE_SCHEDULE_PAGE, payload: "" });
    dispatch({ type: SET_ERROR_PROMPT_SCHEDULE_PAGE, payload: false });
    dispatch({ type: SET_ERROR_MESSAGE_USER_PAGE, payload: "" });
    dispatch({ type: SET_ERROR_PROMPT_USER_PAGE, payload: false });
    dispatch({ type: SET_AUTH_USER, payload: {} });
  };

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleLogout = async (event) => {
    event.preventDefault();

    const access_token = sessionStorage.getItem("access_token");

    setIsLoading(true);

    try {
      await axios({
        method: "POST",
        url: "/api/auth/logout",
        headers: { authorization: `Bearer ${access_token}` },
      });

      dispatch({ type: SET_GLOBAL_SUCCESS, payload: true });
      dispatch({ type: SET_GLOBAL_SUCCESS_MESSAGE, payload: "Logout success" });
      navigate("/login");
      resetAllPromptState();
    } catch (e) {
      dispatch({ type: SET_GLOBAL_ERROR, payload: true });
      dispatch({
        type: SET_GLOBAL_ERROR_MESSAGE,
        payload: e.response.data.message,
      });
      setIsLoading(false);
    } finally {
      localStorage.clear();
      sessionStorage.clear();
      sessionStorage.removeItem("lastTimeStamp");
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAccount = () => {
    navigate("/myAccount");
    setAnchorEl(null);
  };

  return (
    <>
      <CustomAppBar
        position="fixed"
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          paddingLeft: "0.5rem",
        }}
      >
        <Toolbar>
          <Box display="flex" flexDirection="column" sx={{ flexGrow: 1 }}></Box>

          <Button
            onClick={handleClick}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Box>
              <TypographyLarge
                sx={{
                  fontWeight: 600,
                  color: "#111827",
                }}
              >
                {decryptedLoggedUser.name}
              </TypographyLarge>
              <TypographyNormal
                sx={{
                  fontWeight: 400,
                  color: "#5F6B7C",
                }}
              >
                {/* 
                  TODO: Check new API
                  {decryptedLoggedUser.organization.name} |{" "} 
                */}
                {decryptedLoggedUser.type}
              </TypographyNormal>
            </Box>
          </Button>

          <Menu
            id="simple-menu"
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorEl={anchorEl}
            aria-labelledby="demo-positioned-button"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <CustomMenuItem dense onClick={handleAccount}>
              <AccountCircle
                sx={{ width: 20, height: 20, marginRight: "10px" }}
                color="inherit"
              />
              My Account
            </CustomMenuItem>
            <CustomMenuItem dense onClick={handleLogout}>
              <Logout
                sx={{ width: 20, height: 20, marginRight: "10px" }}
                color="inherit"
              />
              Log Out
            </CustomMenuItem>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoading}
            >
              <CircularProgress
                size={22}
                thickness={3}
                sx={{ color: "#1D4ED8" }}
              />
            </Backdrop>

            <CustomMenuItem dense disabled>
              <Typography
                component="p"
                sx={{
                  fontSize: ".75rem",
                }}
              >
                {decryptedLoggedUser.type}
                {/* 
                TODO: Check new API
                -{" "}
                {decryptedLoggedUser.organization.company.name} 
                */}
              </Typography>
            </CustomMenuItem>
          </Menu>
        </Toolbar>
      </CustomAppBar>
    </>
  );
};

export default Navbar;
