import { useSelector } from "react-redux";

import { Box, Container, CssBaseline, Paper, Toolbar } from "@mui/material";

import { TypographyNormal, TypographyXLarge } from "../customComponent";

import Sidebar from "../Sidebar";
import AnnouncementSuccessPrompt from "./AnnouncementSuccessPrompt";
import AnnouncementErrorPrompt from "./AnnouncementErrorPrompt";
import CreateAnnouncement from "./CreateAnnouncement";
import AnnouncementList from "./AnnouncementList";
import AnnouncementFilter from "./AnnouncementFilter";
import { decrypt } from "../../helpers/crypto";
import Role from "../../helpers/roles";

const Announcement = () => {
  const { loggedUser } = useSelector((state) => state.auth);
  const decryptedLoggedUser = decrypt(loggedUser);
  const userRole = decryptedLoggedUser.type;

  return (
    <div style={{ display: "flex" }}>
      <Sidebar />

      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Toolbar />
        <CssBaseline />

        <Container maxWidth="xl">
          <Box sx={{ mb: 1 }}>
            <TypographyXLarge sx={{ fontWeight: 700 }}>
              Announcement
            </TypographyXLarge>
            <TypographyNormal sx={{ fontWeight: 400 }}>
              Manage your digital signage announcement
            </TypographyNormal>
          </Box>

          <AnnouncementSuccessPrompt />
          <AnnouncementErrorPrompt />

          {userRole === Role.Admin && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <CreateAnnouncement />
            </Box>
          )}

          <Paper
            component="section"
            elevation={2}
            sx={{
              borderRadius: "6px",
              p: 2,
              my: 2,
            }}
          >
            <AnnouncementFilter />
          </Paper>

          <Paper
            component="section"
            elevation={2}
            sx={{
              borderRadius: "6px",
              p: 2,
              my: 2,
            }}
          >
            <AnnouncementList />
          </Paper>
        </Container>
      </Box>
    </div>
  );
};

export default Announcement;
