import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, InputLabel } from "@mui/material";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import ContainerItem from "./ContainerItem";
import { setContentLayers, setLayerMedias } from "../../../../store/actions/cmsAction";
import update from 'immutability-helper';
import { v4 as uuidv4 } from "uuid";

const StyledBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  ":last-child": {
    marginBottom: 0,
  },
}));

const ContainersList = ({ resType }) => {
  const dispatch = useDispatch();
  const { contentLayers, layerMedias } = useSelector((state) => state.cms);

  const updateLayerContext = useCallback(
    (updatedLayers) => {
      const updated = update(contentLayers, { $merge: updatedLayers });
      dispatch(setContentLayers({ ...updated }));
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contentLayers],
  );

  const addContainer = useCallback(
    () => {
      const currentNumOfLayer = Object.keys(contentLayers).length;
      const newLayer = {
        id: uuidv4(),
        indexLayer: currentNumOfLayer + 1,
        active: false,
        dimensions: {
          width: 400,
          height: 400,
          left: 0,
          top: 0,
        },
      };
      
      const updatedLayers = update(contentLayers, { $merge: { [newLayer.id]: newLayer } });
      updateLayerContext(updatedLayers);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contentLayers],
  );

  const removeContainer = useCallback(
    (id) => {
      const updatedLayers = update(contentLayers, { $unset: [id] });
      const updatedMedias = update(layerMedias, { $unset: [id] });
      dispatch(setContentLayers({ ...updatedLayers }));
      dispatch(setLayerMedias({ forceUpdate: { ...updatedMedias } }));
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contentLayers],
  );

  return (
    <Box>
      <StyledBox>
        <InputLabel>Layers (Contents)</InputLabel>

        <Box sx={{ mt: 2 }}>
          {Object.keys(contentLayers).map((key) => {
            const { id } = contentLayers[key];
            return (
              <ContainerItem
                key={id}
                container={contentLayers[key]}
                removeContainer={removeContainer}
              />
            )
          })}
        </Box>

        <Box
          sx={{ width: "100%" }}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          {resType === "" && (<p style={{color:"red"}}>Please select the device's resolution before continuing.</p>)}
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            sx={{
              boxShadow: 3,
              marginTop: resType === "" ? 2 : undefined,
            }}
            disabled={resType === ""}
            onClick={addContainer}
          >
            Add Container
          </Button>
        </Box>
      </StyledBox>
    </Box>
  );
};
export default ContainersList;
