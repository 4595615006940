import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputLabel,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { initiateAXIOS } from "../../config/axios";
import { API_LIMIT, initiateOrganizationAPI } from "../../api";
import { createViewer } from "../../store/actions/viewerAction";
import { decrypt } from "../../helpers/crypto";
import Role from "../../helpers/roles";

const StyledBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  ":last-child": {
    marginBottom: theme.spacing(0),
  },
}));

const CreateViewer = () => {
  const dispatch = useDispatch();

  const { loggedUser } = useSelector((state) => state.auth);
  const decryptedLoggedUser = decrypt(loggedUser);
  const userRole = decryptedLoggedUser.type;
  const userOrg = decryptedLoggedUser.organization.id;

  const [open, setOpen] = useState(false);
  const [viewerName, setViewerName] = useState("");
  const [viewerKey, setViewerKey] = useState("");
  const [organizations, setOrganizations] = useState([]);
  const [selectOrganization, setSelectOrganization] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setViewerName("");
    setOrganizations([]);
    setSelectOrganization("");
    setOpen(false);
  };

  const submitForm = () => {
    const payload = {
      name: viewerName,
      key: viewerKey,
      organizationId:
        selectOrganization === "" ? +userOrg : +selectOrganization,
    };

    dispatch(createViewer(payload));
    handleClickClose();
  };

  const getOrganizations = async () => {
    const access_token = sessionStorage.getItem("access_token");
    try {
      const { data } = await initiateAXIOS.get(
        initiateOrganizationAPI + `?limit=${API_LIMIT}`,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      const listOrganization = data.items;

      let organizationData = [];
      listOrganization.map((item) => organizationData.push(item));
      setOrganizations(organizationData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (open && userRole === Role.Eins) {
      getOrganizations();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        startIcon={<AddIcon fontSize="small" />}
        sx={{ boxShadow: 3 }}
      >
        Create New Viewer
      </Button>

      <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClickClose}>
        <Box>
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            New Viewer
            <IconButton onClick={handleClickClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <Divider />

          <DialogContent>
            <StyledBox>
              <InputLabel>Viewer Name</InputLabel>

              <TextField
                autoFocus
                margin="dense"
                fullWidth
                name="viewerName"
                placeholder="Enter viewer name"
                value={viewerName}
                onChange={(e) => {
                  setViewerName(e.target.value);
                }}
              />
            </StyledBox>

            <StyledBox>
              <InputLabel>Viewer Key</InputLabel>

              <TextField
                autoFocus
                margin="dense"
                fullWidth
                name="viewerKey"
                placeholder="Enter viewer key"
                value={viewerKey}
                onChange={(e) => {
                  setViewerKey(e.target.value);
                }}
              />
            </StyledBox>

            {userRole === Role.Eins && (
              <StyledBox>
                <InputLabel>Organization</InputLabel>

                <TextField
                  select
                  margin="dense"
                  fullWidth
                  required
                  SelectProps={{
                    native: true,
                  }}
                  value={selectOrganization}
                  onChange={(e) => {
                    setSelectOrganization(e.target.value);
                  }}
                >
                  <option value="">Select Organization</option>
                  {organizations?.map((organization) => (
                    <option key={organization.id} value={organization.id}>
                      {organization.name}
                    </option>
                  ))}
                </TextField>
              </StyledBox>
            )}
          </DialogContent>

          <DialogActions>
            <Button fullWidth variant="outlined" onClick={handleClickClose}>
              Cancel
            </Button>
            <Button fullWidth variant="contained" onClick={submitForm}>
              Submit
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default CreateViewer;
