import React from "react";

const TopLeftIcon = () => {
  return (
    <div>
      <svg
        width="724"
        height="186"
        viewBox="0 0 724 186"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.2">
          <path
            d="M539.249 -117.675C510.497 -125.027 482.117 -137.865 455.449 -154.67C455.421 -154.67 455.393 -154.68 455.365 -154.68C454.9 -155.024 454.416 -155.284 453.951 -155.601C453.104 -156.141 452.267 -156.662 451.42 -157.211C414.219 -178.38 366.364 -128.685 409.222 -100.388C411.39 -98.9791 413.558 -97.5553 415.754 -96.181C624.687 33.9605 798.215 -100.268 689.626 -325.901C751.774 -187.219 676.86 -82.4896 539.249 -117.675"
            fill="white"
          />
          <path
            d="M62.6606 -52.8999C62.6606 -52.8999 62.6606 -52.8999 62.6512 -52.9092C73.5008 -54.3328 83.8758 -56.5828 93.525 -59.0319C93.525 -59.0309 93.5251 -59.0291 93.5344 -59.0272C99.2848 -60.4462 105.259 -57.4761 108.674 -52.416C108.692 -52.4067 108.72 -52.3974 108.748 -52.3788C125.878 -27.0878 145.521 -3.11818 167.118 18.8881C181.41 32.9201 196.549 46.1146 212.321 58.36C244.944 83.5021 278.237 43.9466 243.4 18.9906C243.4 18.9626 243.4 18.944 243.409 18.9254C229.331 7.89898 215.69 -3.95563 202.663 -16.6569C181.671 -38.1794 162.903 -61.3256 146.573 -85.4404C146.573 -85.4376 146.563 -85.4431 146.563 -85.4431C146.507 -85.5418 146.442 -85.6218 146.386 -85.7186C145.242 -87.4018 144.125 -89.1001 143.009 -90.7982C126.623 -113.218 104.57 -111.635 77.8648 -104.794C77.8648 -104.791 77.8649 -104.796 77.8556 -104.791C71.0629 -102.767 63.8795 -100.781 56.5751 -99.2722C49.9407 -98.2022 40.645 -100.113 35.8344 -110.847C7.24031 -187.087 3.6393 -266.226 32.9033 -331.91C16.9826 -297.342 6.08657 -263.249 1.89935 -227.806C1.89935 -227.797 1.89935 -227.778 1.89935 -227.769C-0.594375 -206.637 -0.687424 -185.022 1.94587 -162.541C2.79262 -150.975 3.9557 -140.012 5.21187 -130.135C7.77073 -110.984 11.7626 -93.907 16.0894 -79.1828C25.6735 -49.9316 51.6156 -51.7833 62.6512 -52.8906C62.6512 -52.8906 62.6606 -52.8906 62.6606 -52.8999"
            fill="white"
          />
          <path
            d="M138.088 47.9196C122.53 32.036 108.228 15.1753 95.2571 -2.37393C95.2291 -2.39254 95.2105 -2.40185 95.1733 -2.42046C77.0845 -29.498 44.2288 -9.92957 56.7346 11.4161C58.028 13.5376 59.433 15.8639 60.9776 18.4135C75.0467 39.8894 91.228 59.4857 108.935 77.0722C126.522 94.7703 146.118 110.952 167.594 125.03C195.536 141.965 196.067 142.282 211.532 149.586C225.787 155.718 263.183 173.732 316.138 180.795C326.01 182.051 336.981 183.214 348.547 184.061C371.018 186.685 392.643 186.592 413.765 184.108C413.774 184.108 413.784 184.108 413.784 184.108C449.236 179.92 483.347 169.024 517.915 153.103C403.306 204.16 247.718 155.309 138.088 47.9196"
            fill="white"
          />
          <path
            d="M368.164 89.7546C376.752 74.4478 384.577 58.4525 391.37 41.4616C399.577 21.1488 418.336 -14.4615 372.49 -41.8554C372.49 -41.874 372.5 -41.8833 372.5 -41.9019C343.98 -59.7061 316.661 -81.3057 291.789 -105.795C266.06 -131.925 243.459 -160.745 225.165 -190.856C201.614 -229.602 186.001 -268.655 177.943 -305.866C177.933 -305.866 177.933 -305.866 177.924 -305.866C168.973 -343.616 117.898 -325.472 121.713 -287.089C135.075 -207.28 180.613 -124.959 245.087 -59.0966C269.913 -34.7929 297.083 -13.2052 325.575 5.00465C325.575 5.02326 325.575 5.03257 325.584 5.05118C333.698 10.2899 333.903 12.0392 331.558 18.3201C325.333 33.6268 317.6 47.91 308.789 62.3234C308.789 62.3327 308.789 62.342 308.789 62.3513C299.233 77.4906 288.42 106.68 325.407 123.634C386.355 147.743 450.624 156.211 511.646 142.356C575.626 127.821 611.413 94.6303 650.503 44.3741C585.592 121.122 475.57 129.989 368.164 89.7546"
            fill="white"
          />
          <path
            d="M602.088 8.33464C602.079 8.33464 602.069 8.34396 602.06 8.35327C601.921 8.37188 601.79 8.40908 601.66 8.42769C600.283 8.77198 598.896 9.09761 597.519 9.41398C563.277 19.1842 559.211 65.6999 598.133 55.3435C676.965 27.0749 708.574 -40.4514 720.252 -124.243C709.495 -52.3711 664.208 -7.32567 602.088 8.33464Z"
            fill="white"
          />
          <path
            d="M498.095 9.33039C497.964 9.31178 497.844 9.28381 497.713 9.2745C457.739 4.10092 434.561 56.8417 476.507 64.8067C476.796 64.8533 477.084 64.8905 477.373 64.9277C519.301 68.9009 541.93 19.9287 504.348 10.4842C502.273 10.1213 500.188 9.73981 498.095 9.33039"
            fill="url(#paint0_linear_1001_72977)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_1001_72977"
            x1="170.989"
            y1="-462.396"
            x2="561.721"
            y2="151.61"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#48E9FF" />
            <stop offset="1" stop-color="#377DFF" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default TopLeftIcon;
