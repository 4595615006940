import React, { useState } from "react";
import { useDispatch } from "react-redux";

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { TypographyNormal, TypographySmall } from "../customComponent";
import FormConfirmation from "./FormConfirmation";
import { createContentUpload } from "../../store/actions/contentUploadAction";

const StyledBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  ":last-child": {
    marginBottom: 0,
  },
}));

const CreateContent = ({ disable }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [fileUpload, setFileUpload] = useState({});
  const [selectContentType, setSelectContentType] = useState("");
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const [formErrorPrompt, setFormErrorPrompt] = useState(false);
  const [formErrorMsg, setFormErrorMsg] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
    cleanForm();
  };

  const cleanForm = () => {
    setFileUpload({});
    setSelectContentType("");
    setFormErrorMsg(false);
    setFormErrorMsg("");
  };

  const handleSelectFile = (event) => {
    const allowedType = [
      "application/zip",
      "application/x-zip-compressed",
      "application/x-zip",
      "application/zip-compressed",
    ];

    if (!allowedType.includes(event.target.files[0].type)) {
      setFormErrorPrompt(true);
      setFormErrorMsg("File upload only accept zip file.");
      event.target.value = null;
      return false;
    }
    setFileUpload(event.target.files[0]);
  };

  const handleOpenConfirmation = (e) => {
    e.preventDefault();
    setOpenConfirmation(true);
  };

  const handleSubmit = () => {
    setOpenConfirmation(false);
    setOpen(false);

    const payload = { name, type: selectContentType };

    dispatch(createContentUpload(payload, fileUpload));

    cleanForm();
  };
  return (
    <>
      <Button
        {...(!disable && { onClick: handleClickOpen })}
        disabled={disable}
        variant="contained"
        startIcon={<AddIcon />}
        sx={{ boxShadow: 3 }}
      >
        Create New Offline Content
      </Button>

      <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClickClose}>
        <Box
          component="form"
          onSubmit={(e) => {
            handleOpenConfirmation(e);
          }}
        >
          <DialogTitle
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            Create New Offline Content
            <IconButton onClick={handleClickClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            {formErrorPrompt && (
              <Alert sx={{ my: 2 }} severity="error" variant="filled">
                <AlertTitle>Form Validation</AlertTitle>
                {formErrorMsg}
              </Alert>
            )}

            <StyledBox>
              <InputLabel>Content Type</InputLabel>

              <TextField
                margin="dense"
                select
                SelectProps={{
                  native: true,
                }}
                required
                fullWidth
                size="small"
                onChange={(e) => {
                  setSelectContentType(e.target.value);
                }}
                value={selectContentType}
              >
                <option value="" disabled>
                  Select Content Type
                </option>
                <option value="ASSET">Common Assets</option>
                <option value="CONTENT">Offline Content</option>
              </TextField>
            </StyledBox>

            <StyledBox sx={{ mb: 1 }}>
              <InputLabel>Name</InputLabel>

              <TextField
                id="content-name"
                autoFocus
                fullWidth
                margin="dense"
                placeholder="Enter content name"
                value={name}
                size="small"
                onChange={(e) => setName(e.target.value)}
                required
              />
            </StyledBox>

            <StyledBox>
              <InputLabel>File Upload</InputLabel>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  component="label"
                  size="small"
                  sx={{ mr: 2 }}
                >
                  Choose File
                  <input
                    type="file"
                    accept="application/zip"
                    hidden
                    onChange={handleSelectFile}
                  />
                </Button>
                <TypographyNormal>{fileUpload.name}</TypographyNormal>
              </Box>
              <TypographySmall sx={{ color: "#cc3737" }}>
                *only accept .zip file
              </TypographySmall>
            </StyledBox>
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" onClick={handleClickClose} fullWidth>
              Cancel
            </Button>

            <Button variant="contained" type="submit" fullWidth>
              Create
            </Button>

            <FormConfirmation
              open={openConfirmation}
              handleClose={setOpenConfirmation}
              handleSubmit={handleSubmit}
              dialogText={"Confirm create offline content?"}
              alertText={
                "Confirmation will proceed to file upload. Please don't leave the page while upload in progress."
              }
            />
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default CreateContent;
