import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Button,
  IconButton,
  Tooltip,
  TextField,
  InputAdornment,
  Menu,
  RadioGroup,
  Radio,
  FormControlLabel,
  MenuItem,
} from "@mui/material";
import SortIcon from "@mui/icons-material/Sort";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import SearchIcon from "@mui/icons-material/Search";

import {
  filterSchedule,
  getSchedules,
} from "../../store/actions/scheduleAction";
import { ascending, descending } from "../../helpers/sorting";
import { SET_SCHEDULE } from "../../store/actionTypes/scheduleActionType";

const ScheduleFilter = () => {
  const dispatch = useDispatch();

  const { schedules } = useSelector((state) => state.schedule);

  const [scheduleName, setScheduleName] = useState("");

  const [status, setStatus] = useState("");

  const [type, setType] = useState("");

  const [nextDate, setNextDate] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);

  const openSortButton = Boolean(anchorEl);

  const [sortBy, setSortBy] = useState("Oldest");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const resetState = () => {
    setScheduleName("");
    setStatus("");
    setType("");
    setNextDate("");
    setSortBy("Soon");
  };

  const handleChangeSortBy = (event) => {
    const value = event.target.value;
    setSortBy(value);

    let sorted;

    switch (value) {
      case "Oldest":
        sorted = ascending(schedules, "nextAt");
        break;

      case "Newest":
        sorted = descending(schedules, "nextAt");
        break;

      case "A-Z":
        sorted = ascending(schedules, "name");
        break;

      case "Z-A":
        sorted = descending(schedules, "name");
        break;

      default:
        break;
    }

    dispatch({ type: SET_SCHEDULE, payload: sorted });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const query = [];

    if (scheduleName) query.push(`nameLike=${scheduleName}`);

    if (type) query.push(`type=${type}`);

    if (status) query.push(`status=${status}`);

    if (nextDate) {
      const newDate = new Date(nextDate);
      const formattedISO = newDate.toISOString();
      query.push(`nextAtGte=${formattedISO}`);
    }

    const payload = `&${query.join("&")}`;

    dispatch(filterSchedule(payload));
  };

  const handleRefresh = () => {
    dispatch(getSchedules());
    resetState();
  };

  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <TextField
          value={scheduleName}
          onChange={(e) => setScheduleName(e.target.value)}
          fullWidth
          type="Search"
          placeholder="Search schedule..."
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />

        <TextField
          size="small"
          fullWidth
          select
          SelectProps={{ native: true }}
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          sx={{ mx: 2 }}
        >
          <option disabled defaultValue value="">
            Any Status
          </option>
          <option value="PENDING">Pending</option>
          <option value="ACTIVE">Active</option>
          <option value="IMMEDIATE_PENDING">Immediate Pending</option>
          <option value="IMMEDIATE_ACTIVE">Immediate Active</option>
        </TextField>

        <TextField
          size="small"
          fullWidth
          select
          SelectProps={{ native: true }}
          value={type}
          onChange={(e) => setType(e.target.value)}
        >
          <option disabled defaultValue value="">
            Any Type
          </option>
          <option value="BROWSER">Browser</option>
          <option value="TV">TV</option>
          <option value="PC">PC</option>
        </TextField>

        <TextField
          type="date"
          size="small"
          fullWidth
          value={nextDate}
          onChange={(e) => setNextDate(e.target.value)}
          sx={{ mx: 2 }}
        />

        <Button variant="contained" type="submit">
          Search
        </Button>

        <Box sx={{ ml: 2, display: "flex", alignItems: "center" }}>
          <IconButton sx={{ color: "#111827" }} onClick={handleClick}>
            <Tooltip title="Sort">
              <SortIcon fontSize="small" />
            </Tooltip>
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openSortButton}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <RadioGroup value={sortBy} onChange={handleChangeSortBy}>
              <MenuItem>
                <FormControlLabel
                  disableTypography
                  value="Oldest"
                  control={<Radio size="small" />}
                  label="Oldest"
                  sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
                />
              </MenuItem>
              <MenuItem>
                <FormControlLabel
                  disableTypography
                  value="Newest"
                  control={<Radio size="small" />}
                  label="Newest"
                  sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
                />
              </MenuItem>
              <MenuItem>
                <FormControlLabel
                  disableTypography
                  value="A-Z"
                  control={<Radio size="small" />}
                  label="A-Z"
                  sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
                />
              </MenuItem>
              <MenuItem>
                <FormControlLabel
                  disableTypography
                  value="Z-A"
                  control={<Radio size="small" />}
                  label="Z-A"
                  sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
                />
              </MenuItem>
            </RadioGroup>
          </Menu>

          <IconButton sx={{ ml: 2, color: "#111827" }} onClick={handleRefresh}>
            <Tooltip title="Clear">
              <ClearOutlinedIcon fontSize="small" />
            </Tooltip>
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export default ScheduleFilter;
