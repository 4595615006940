import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import { TypographyNormal, TypographySmall } from "../customComponent";
import { ViewerBlueIcon } from "../../icons";
import { formattedDate } from "../../helpers/dateFormat";
import { colorStyling } from "../../helpers/color";
// import ViewerScreenshot from "./id/ViewerScreenshot";
import { decrypt } from "../../helpers/crypto";
import Role from "../../helpers/roles";
import { red } from "@mui/material/colors";
import DialogViewerDelete from "./DialogViewerDelete";

const ViewerItem = ({ viewer }) => {
  const userRole = localStorage.getItem("permissionRole");
  const decryptedUserRole = decrypt(userRole);

  const viewerNameComponent = (
    <TypographyNormal
      sx={
        decryptedUserRole === Role.Eins
          ? { fontWeight: 600 }
          : {
              fontWeight: 600,
              cursor: "pointer",
              "&:hover": {
                textDecoration: "underline",
                color: colorStyling.primary,
              },
            }
      }
    >
      {viewer.name}
    </TypographyNormal>
  );
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        p: 2,
      }}
    >
      <Box display="flex" alignItems="center">
        <ViewerBlueIcon />

        <Box sx={{ ml: 2, width: 250 }}>
          {decryptedUserRole === Role.Eins ? (
            viewerNameComponent
          ) : (
            <Link
              style={{ textDecoration: "none" }}
              to={`/viewer/detail/${viewer.id}`}
            >
              {viewerNameComponent}
            </Link>
          )}
          <TypographySmall sx={{ fontWeight: 300 }}>
            {viewer.screenGroup !== null ? viewer.screenGroup.name : null}
          </TypographySmall>
        </Box>

        {/* TODO: Will work on it when working on the feature */}
        {/* <ViewerScreenshot viewer={viewer} size="sm" /> */}

        {/* {decryptedUserRole !== Role.User && (
          <BlockViewer viewer={viewer} size="sm" />
        )} */}
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", mx: 1 }}>
        <TypographySmall
          sx={{
            alignItems: "center",
            borderRadius: "15px",
            color:
              viewer.status.connectionStatus === "ONLINE"
                ? colorStyling.green
                : colorStyling.black,
            backgroundColor:
              viewer.status.connectionStatus === "ONLINE"
                ? colorStyling.white.active
                : colorStyling.white.inactive,
            p: 1,
            mr: 1,
          }}
        >
          <FiberManualRecordIcon
            sx={{
              color:
                viewer.status.connectionStatus === "ONLINE"
                  ? colorStyling.green
                  : colorStyling.gray,
              fontSize: 8,
              mr: 1,
            }}
          />
          {viewer.status.connectionStatus === "ONLINE" ? "Online" : "Offline"}
        </TypographySmall>

        <TypographySmall
          sx={{
            display: "flex",
            alignItems: "center",
            borderRadius: "15px",
            color:
              viewer.status?.displayStatus === "ON"
                ? colorStyling.green
                : colorStyling.black,
            backgroundColor:
              viewer.status?.displayStatus === "ON"
                ? colorStyling.white.active
                : colorStyling.white.inactive,
            p: 1,
            textTransform: "capitalize",
          }}
        >
          <FiberManualRecordIcon
            sx={{
              color:
                viewer.status.displayStatus === "ON"
                  ? colorStyling.green
                  : colorStyling.gray,
              fontSize: 8,
              mr: 1,
            }}
          />
          Display :{" "}
          {viewer.status.displayStatus === "ON"
            ? "On"
            : viewer.status.displayStatus === "OFF"
            ? "Off"
            : "No Data"}
        </TypographySmall>
      </Box>

      <Box minWidth={150}>
        <TypographyNormal sx={{ fontWeight: 300 }}>
          Last Active Time
        </TypographyNormal>
        <TypographyNormal sx={{ fontWeight: 600 }}>
          {viewer.activeAt ? formattedDate(viewer.activeAt) : "-"}
        </TypographyNormal>
      </Box>

      {decryptedUserRole !== Role.Superadmin &&
        decryptedUserRole !== Role.Eins && (
          <Link to={`/viewer/detail/${viewer.id}`}>
            <IconButton size="small" sx={{ color: "#111827" }}>
              <NavigateNextIcon />
            </IconButton>
          </Link>
        )}

      {decryptedUserRole === Role.Eins && <ViewerItemMenu viewer={viewer} />}
    </Box>
  );
};

const ViewerItemMenu = ({ viewer }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeleteViewer, setOpenDeleteViewer] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const handleOpenDeleteViewer = () => setOpenDeleteViewer(true);
  const handleCloseDeleteViewer = () => setOpenDeleteViewer(false);

  return (
    <>
      <IconButton
        sx={{ color: "#111827" }}
        aria-controls={openMenu ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          sx={{ color: red[600] }}
          onClick={handleOpenDeleteViewer}
          dense
        >
          <DeleteOutlinedIcon fontSize="small" sx={{ mr: "5px" }} />
          Delete Viewer
        </MenuItem>
        <DialogViewerDelete
          open={openDeleteViewer}
          handleClose={handleCloseDeleteViewer}
          viewer={viewer}
        />
      </Menu>
    </>
  );
};

export default ViewerItem;
