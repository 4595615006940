import React from "react";
import { Alert, AlertTitle } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { SET_SUCCESS_PROMPT_SCHEDULE_PAGE } from "../../store/actionTypes/scheduleActionType";

const ScheduleSuccessPrompt = () => {
  const dispatch = useDispatch();

  const {
    isSuccessPromptSchedulePage: isSuccess,
    successMessageSchedulePage: successMessage,
  } = useSelector((state) => state.schedule);

  const handleClose = (event, reason) => {
    dispatch({ type: SET_SUCCESS_PROMPT_SCHEDULE_PAGE, payload: false });
  };

  return (
    isSuccess && (
      <Alert sx={{ my: 2 }} severity="success" variant="filled" onClose={handleClose}>
        <AlertTitle>Success!</AlertTitle>
        {successMessage}
      </Alert>
    )
  );
};

export default ScheduleSuccessPrompt;
