import React, { useState, useEffect } from "react";
import {
  alpha,
  CircularProgress,
  FormControlLabel,
  Paper,
  styled,
  Switch,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { instanceEquipmentBreakdownAPI } from "../../../api";
import { bplrtAXIOS } from "../../../config/axios";
import { TypographyLarge } from "../../customComponent";
import {
  SET_ERROR_MESSAGE_EQUIPMENT_BREAKDOWN,
  SET_ERROR_PROMPT_EQUIPMENT_BREAKDOWN,
  SET_SUCCESS_MESSAGE_EQUIPMENT_BREAKDOWN,
  SET_SUCCESS_PROMPT_EQUIPMENT_BREAKDOWN,
} from "../../../store/actionTypes/equipmentActionType";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#10e02f",
    "&:hover": {
      backgroundColor: alpha("#10e02f", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#10e02f",
  },
}));

const TogglePeakHour = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isPeak, setIsPeak] = useState(false);

  const handleToggle = (e) => {
    const payload = { isPeakHour: e.target.checked };
    updatePeakData(payload);
  };

  useEffect(() => {
    const getPeakData = async () => {
      setLoading(true);
      const access_token = sessionStorage.getItem("access_token");
      try {
        const { data } = await bplrtAXIOS.get(
          instanceEquipmentBreakdownAPI + "config",
          {
            headers: { authorization: `Bearer ${access_token}` },
          }
        );

        setIsPeak(data.isPeakHour);
      } catch (error) {
        // setIsError(true);
        // setErrorMessage(error.response.data.message);
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    getPeakData();
  }, []);

  const updatePeakData = async (payload) => {
    setLoading(true);
    const access_token = sessionStorage.getItem("access_token");
    try {
      const { data } = await bplrtAXIOS.patch(
        instanceEquipmentBreakdownAPI + "config",
        payload,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      setIsPeak(data.isPeakHour);

      dispatch({
        type: SET_SUCCESS_PROMPT_EQUIPMENT_BREAKDOWN,
        payload: true,
      });
      dispatch({
        type: SET_SUCCESS_MESSAGE_EQUIPMENT_BREAKDOWN,
        payload: `Successfully changed breakdown type to ${
          data.isPeakHour ? "Peak Hour" : "Off Peak"
        }`,
      });

      setTimeout(() => {
        dispatch({
          type: SET_SUCCESS_PROMPT_EQUIPMENT_BREAKDOWN,
          payload: false,
        });
      }, 5000);
    } catch (error) {
      console.log(error);

      dispatch({
        type: SET_ERROR_PROMPT_EQUIPMENT_BREAKDOWN,
        payload: true,
      });
      dispatch({
        type: SET_ERROR_MESSAGE_EQUIPMENT_BREAKDOWN,
        payload: error.response.data.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Paper sx={{ pl: 2, minWidth: "145px" }}>
      <FormControlLabel
        control={
          <CustomSwitch
            checked={isPeak}
            onChange={handleToggle}
            disabled={loading}
          />
        }
        label={
          loading ? (
            <CircularProgress size={20} />
          ) : isPeak ? (
            <TypographyLarge sx={{ fontWeight: "500", color: "green" }}>
              Peak Hour
            </TypographyLarge>
          ) : (
            <TypographyLarge sx={{ fontWeight: "500", color: "gray" }}>
              Off Peak
            </TypographyLarge>
          )
        }
      />
    </Paper>
  );
};

export default TogglePeakHour;
