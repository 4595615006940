import React, { forwardRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import {
  Box,
  CircularProgress,
  CssBaseline,
  Typography,
  Button,
  Snackbar,
  AlertTitle,
  Input,
  IconButton,
  InputAdornment,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { initiateAXIOS } from "../../config/axios";

import ForgotPassword from "./ForgotPassword";
import GlobalSuccessPrompt from "../global/GlobalSuccessPrompt";
import {
  SET_GLOBAL_ERROR,
  SET_GLOBAL_ERROR_MESSAGE,
  SET_GLOBAL_SUCCESS,
  SET_GLOBAL_SUCCESS_MESSAGE,
} from "../../store/actionTypes/globalActionType";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import { Typography2XLarge } from "../../components/customComponent";
import { encrypt } from "../../helpers/crypto";
import { SET_AUTH_USER } from "../../store/actionTypes/userActionType";
import GlobalErrorPrompt from "../global/GlobalErrorPrompt";

const RootBox = styled(Box)(() => ({
  minHeight: "100vh",
  width: "100vw",
  display: "flex",
}));

const LeftBox = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(11.25),
  paddingRight: theme.spacing(11.25),
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  minWidth: "540px",
  [theme.breakpoints.down("lg")]: {
    minWidth: "100%",
  },
}));

const RightBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#1E4ED8",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
  [theme.breakpoints.down("lg")]: {
    display: "none",
  },
}));

const UsernameInput = styled(Input)(() => ({
  outline: "none",
  border: `1px solid ${grey[300]}`,
  backgroundColor: "white",
  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
  borderRadius: "6px 6px 0px 0px",
  padding: "13px 9px 13px 9px",
  fontFamily: "Inter, sans-serif",
  width: "100%",
  fontSize: "1rem",
  lineHeight: "1.625rem",
  "&:focus": {
    border: "2px solid #1E4ED8",
  },
  "&.Mui-focused": {
    border: "2px solid #1E4ED8",
  },
}));

const PasswordInput = styled(Input)(() => ({
  outline: "none",
  border: `1px solid ${grey[300]}`,
  backgroundColor: "white",
  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
  borderRadius: "0px 0px 6px 6px",
  padding: "13px 9px 13px 9px",
  fontFamily: "Inter, sans-serif",
  width: "100%",
  fontSize: "1rem",
  lineHeight: "1.625rem",
  "&:focus": {
    border: "2px solid #1E4ED8",
  },
  "&.Mui-focused": {
    border: "2px solid #1E4ED8",
  },
}));

const Image = styled("img")(({ theme }) => ({
  display: "block",
  marginLeft: "auto",
  marginRight: "auto",
}));

const RightImageContainer = styled("div")(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
}));

const RightImage = styled("img")(({ theme }) => ({
  objectFit: "cover",
  display: "block",
}));

const ForgotPasswordButton = styled("button")(({ theme }) => ({
  border: "none",
  backgroundColor: "#ffff",
  color: "#1C4ED8",
  fontSize: "1rem",
  fontWeight: 400,
  lineHeight: "1.625rem",
  textAlign: "right",
  cursor: "pointer",
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  ":hover": {
    textDecoration: "underline",
  },
}));

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ErrorPrompt = ({ isError, errorMessage, onClose }) => {
  const vertical = "bottom";
  const horizontal = "left";

  return (
    <Snackbar
      open={isError}
      anchorOrigin={{ vertical, horizontal }}
      autoHideDuration={6000}
      onClose={onClose}
    >
      <Alert onClose={onClose} severity="error" sx={{ width: "100%" }}>
        {errorMessage}
      </Alert>
    </Snackbar>
  );
};

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [userForm, setUserForm] = useState({
    name: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);

  const [isError, setIsError] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const [openForgotPassword, setOpenForgotPassword] = useState(false);

  // const showBPLRT = process.env.REACT_APP_SHOW_BPLRT;

  const handleChange = (e) => {
    setUserForm({ ...userForm, [e.target.name]: e.target.value });
  };

  const handleCloseErrorPrompt = () => {
    setIsError(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      username: userForm.name,
      password: userForm.password,
    };

    await userLogin(payload);
  };

  const userLogin = async (payload) => {
    setLoading(true);

    try {
      const { data } = await initiateAXIOS.post("/api/auth/login", payload);

      const userID = data.user.id;

      if (!userID) {
        const requestToken = data.token;
        const emailUser = data.user.email;

        sessionStorage.setItem("requestToken", requestToken);
        sessionStorage.setItem("emailUser", emailUser);

        await navigateOtp(requestToken);
      } else {
        const access_token = data.token;
        sessionStorage.setItem("access_token", access_token);

        if (data.isPasswordExpired) await navigateExpiredPassword();
        else navigateHome(data);
      }

      // TODO : Will work on it when linking API
      // if (data.isPasswordExpired) {
      //   const access_token = data.token;
      //   sessionStorage.setItem("access_token", access_token);

      //   await navigateExpiredPassword();
      // } else if (userID) {
      //   const access_token = data.token;
      //   sessionStorage.setItem("access_token", access_token);

      //   const user = data.user;
      //   const encryptedUser = encrypt(user);

      //   const userROLE = data.user.type;
      //   const encryptedRole = encrypt(userROLE);
      //   localStorage.setItem("permissionRole", encryptedRole);

      //   // if (showBPLRT === "TRUE") {
      //   //   try {
      //   //     const { data: bplrtUserData } = await bplrtAXIOS.get(
      //   //       instanceBPLRTUserAPI + user.id,
      //   //       {
      //   //         headers: { authorization: `Bearer ${access_token}` },
      //   //       }
      //   //     );

      //   //     const permissionFlag = {
      //   //       canAccessSMP: bplrtUserData.canAccessSMP,
      //   //     };
      //   //     localStorage.setItem("permissionFlag", encrypt(permissionFlag));
      //   //   } catch (error) {
      //   //     console.log(error);
      //   //   }
      //   // }

      //   dispatch({ type: SET_AUTH_USER, payload: encryptedUser });
      //   dispatch({ type: SET_GLOBAL_SUCCESS, payload: true });
      //   dispatch({
      //     type: SET_GLOBAL_SUCCESS_MESSAGE,
      //     payload: "Login success",
      //   });

      //   if (userROLE === "ADMIN" || userROLE === "USER") {
      //     navigate("/dashboard");
      //   } else {
      //     navigate("/");
      //   }
      // } else {
      //   const requestToken = data.token;
      //   const emailUser = data.user.email;

      //   sessionStorage.setItem("requestToken", requestToken);
      //   sessionStorage.setItem("emailUser", emailUser);

      //   await navigateOtp(requestToken);
      // }
    } catch (e) {
      const errorMessage = e.response.data.message;
      setIsError(true);
      setErrorMessage(errorMessage);

      sessionStorage.clear();

      setTimeout(() => {
        setIsError(false);
      }, 5000);
      setUserForm({
        name: "",
        password: "",
      });
      setLoading(false);
    }
  };

  const navigateExpiredPassword = async () => {
    dispatch({ type: SET_GLOBAL_ERROR, payload: true });
    dispatch({
      type: SET_GLOBAL_ERROR_MESSAGE,
      payload: "Password Expired",
    });
    navigate("/expired-password");
  };

  const navigateOtp = async (requestToken) => {
    const { data } = await initiateAXIOS.post(
      "/api/auth/login/request-otp",
      { method: "EMAIL" },
      {
        headers: { authorization: `Bearer ${requestToken}` },
      }
    );

    sessionStorage.setItem("otpKey", data.token);

    navigate("/authorization/request-otp");
  };

  const navigateHome = (data) => {
    const user = data.user;
    const encryptedUser = encrypt(user);

    const userROLE = data.user.type;
    const encryptedRole = encrypt(userROLE);
    localStorage.setItem("permissionRole", encryptedRole);
    dispatch({ type: SET_AUTH_USER, payload: encryptedUser });
    dispatch({ type: SET_GLOBAL_SUCCESS, payload: true });
    dispatch({
      type: SET_GLOBAL_SUCCESS_MESSAGE,
      payload: "Login success",
    });

    if (userROLE === "ADMIN" || userROLE === "USER") {
      navigate("/dashboard");
    } else {
      navigate("/");
    }
  };

  return (
    <Box component="main">
      <CssBaseline />

      <GlobalSuccessPrompt />
      <GlobalErrorPrompt />
      <ErrorPrompt
        isError={isError}
        errorMessage={errorMessage}
        onClose={() => handleCloseErrorPrompt()}
      />

      <RootBox>
        <LeftBox
          component="form"
          onSubmit={handleSubmit}
          sx={{ backgroundColor: "#ffff" }}
        >
          <Image src="./assets/itxcast-logo.png" alt="ItxCast logo" />
          <Typography
            component="h1"
            fontWeight="600"
            textAlign="center"
            sx={{
              fontSize: "2rem",
              lineHeight: "38px",
              mt: 2,
              mb: 0.5,
            }}
          >
            Welcome to <span style={{ color: "#1D4ED8" }}>ITX Cast</span>
          </Typography>
          <Typography
            component="p"
            textAlign="center"
            sx={{
              fontSize: "1.125rem",
              lineHeight: "1.6875rem",
              fontWeight: 300,
              color: "#454E5C",
              mb: 4,
            }}
          >
            Sign in to your account below.
          </Typography>
          {location?.state && (
            <Alert sx={{ mb: 2 }} severity="error" variant="filled">
              <AlertTitle>{location?.state?.error}</AlertTitle>
            </Alert>
          )}
          <UsernameInput
            type="text"
            name="name"
            placeholder="Username"
            onChange={handleChange}
            value={userForm.name}
            disabled={loading}
            disableUnderline={true}
          />
          <PasswordInput
            type={showPassword ? "text" : "password"}
            autoComplete="off"
            name="password"
            placeholder="Password"
            onChange={handleChange}
            value={userForm.password}
            disabled={loading}
            disableUnderline={true}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            }
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <ForgotPasswordButton
              type="button"
              onClick={() => setOpenForgotPassword(true)}
            >
              Forgot password?
            </ForgotPasswordButton>

            <ForgotPassword
              open={openForgotPassword}
              onClose={() => setOpenForgotPassword(false)}
            />
          </Box>
          {loading ? (
            <Button fullWidth variant="contained" disabled>
              <CircularProgress color="inherit" size={20} thickness={2} />
            </Button>
          ) : (
            <Button type="submit" variant="contained" fullWidth>
              Sign in
            </Button>
          )}
        </LeftBox>

        <RightBox>
          <RightImageContainer>
            <RightImage src="./assets/Illustration.png" alt="Itxcast screen" />
          </RightImageContainer>

          <Typography2XLarge
            sx={{
              color: "white",
              textAlign: "center",
              px: 14.125,
              letterSpacing: "0.01em",
            }}
          >
            Versatile and Scalable Content Management Platform that drives
            Engagement, Productivity, and Sales such as Digital Signage and IoT
          </Typography2XLarge>
        </RightBox>
      </RootBox>
    </Box>
  );
};

export default Login;
