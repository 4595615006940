import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import {
  Container,
  Box,
  CssBaseline,
  CircularProgress,
  Alert,
  Button,
  Paper,
  Link,
} from "@mui/material";

import TimerCountdown from "./TimerCountdown";
import { encrypt } from "../../helpers/crypto";
import {
  CustomTextField,
  Typography2XLarge,
  TypographyNormal,
} from "../customComponent";
import { bplrtAXIOS, initiateAXIOS } from "../../config/axios";
import {
  SET_GLOBAL_SUCCESS,
  SET_GLOBAL_SUCCESS_MESSAGE,
} from "../../store/actionTypes/globalActionType";
import { SET_AUTH_USER } from "../../store/actionTypes/userActionType";
import dayjs from "dayjs";
import { instanceBPLRTUserAPI } from "../../api";

const Error = ({ handleOnCloseErrorPrompt, errorMessage }) => {
  const handleClose = () => {
    handleOnCloseErrorPrompt(false);
  };

  return (
    <Alert onClose={handleClose} severity="error" sx={{ my: 1 }}>
      {errorMessage}
    </Alert>
  );
};

const TwoFactorAuthentication = () => {
  const [otp, setOtp] = useState("");
  const [counterError, setCounterError] = useState(1);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const showBPLRT = process.env.REACT_APP_SHOW_BPLRT;

  const emailUser = sessionStorage.getItem("emailUser");

  const handleOnCloseErrorPrompt = ({ close }) => {
    setIsError(close);
  };

  const removeOTPToken = () => {
    sessionStorage.removeItem("otpKey");
    sessionStorage.removeItem("requestToken");
    sessionStorage.removeItem("emailUser");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    const otpKey = sessionStorage.getItem("otpKey");

    try {
      const { data } = await initiateAXIOS.post(
        "/api/auth/login/otp",
        {
          otp: otp,
        },
        { headers: { authorization: `Bearer ${otpKey}` } }
      );

      const user = data.user;
      const encryptedUser = encrypt(user);

      const access_token = data.token;

      const timeStamp = dayjs();
      sessionStorage.setItem("access_token", access_token);
      sessionStorage.setItem("lastTimeStamp", timeStamp);

      const userRole = user.type;
      const encryptedRole = encrypt(userRole);
      localStorage.setItem("permissionRole", encryptedRole);

      if (showBPLRT === "TRUE") {
        try {
          const { data: bplrtUserData } = await bplrtAXIOS.get(
            instanceBPLRTUserAPI + user.id,
            {
              headers: { authorization: `Bearer ${access_token}` },
            }
          );

          const permissionFlag = {
            canAccessSMP: bplrtUserData.canAccessSMP,
          };
          localStorage.setItem("permissionFlag", encrypt(permissionFlag));
        } catch (error) {
          console.log(error);
        }
      }

      dispatch({ type: SET_AUTH_USER, payload: encryptedUser });
      dispatch({ type: SET_GLOBAL_SUCCESS, payload: true });
      dispatch({
        type: SET_GLOBAL_SUCCESS_MESSAGE,
        payload: "Login success",
      });

      if (user.type === "EINS" || user.type === "SUPERADMIN") {
        navigate("/users");
      } else {
        navigate("/dashboard");
      }

      removeOTPToken();
    } catch (e) {
      setCounterError((prevCount) => prevCount + 1);

      const errorMessage = e.response.data.message;

      if (
        errorMessage ===
        "Authentication error, too many multi factor authentication attempt."
      ) {
        setCounterError(+process.env.REACT_APP_MAX_OTP_TRIES);
      }

      setOtp("");
      setIsError(true);
      setErrorMessage(errorMessage);

      if (counterError === +process.env.REACT_APP_MAX_OTP_TRIES) {
        sessionStorage.clear();

        setTimeout(() => {
          navigate("/login");
        }, 3000);
      }

      setTimeout(() => {
        setIsError(false);
      }, 5000);

      setOtp("");
      setLoading(false);
    }
  };

  return (
    <>
      <CssBaseline />

      <Container
        maxWidth="xl"
        sx={{
          width: "100vw",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Paper
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: "50%",
          }}
          component="form"
          onSubmit={handleSubmit}
        >
          <img
            src="/assets/itxcast-logo.png"
            alt="ITXCast logo"
            style={{ marginLeft: "auto", marginRight: "auto" }}
          />

          <Typography2XLarge sx={{ mt: 2, textAlign: "center" }} gutterBottom>
            Authenticate Your Account
          </Typography2XLarge>

          <TypographyNormal sx={{ fontWeight: 300, textAlign: "center" }}>
            Verification code has been sent to {emailUser}. Please check your
            email and login with your verification code.{" "}
            <Link
              underline="hover"
              onClick={removeOTPToken}
              href="/login"
              sx={{ textDecoration: "none", color: "#1C4ED8" }}
            >
              Back to login page.
            </Link>
          </TypographyNormal>

          {counterError < 4 && <TimerCountdown />}

          {isError && (
            <Error
              handleOnCloseErrorPrompt={handleOnCloseErrorPrompt}
              errorMessage={errorMessage}
            />
          )}

          <CustomTextField
            autoFocus
            placeholder="Enter the 6 digit code"
            fullWidth
            margin="normal"
            disabled={counterError === 4}
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />

          <Box>
            {loading ? (
              <Button disabled fullWidth variant="contained">
                <CircularProgress color="inherit" size={20} thickness={2} />
              </Button>
            ) : (
              <Button
                disabled={counterError === 4}
                variant="contained"
                fullWidth
                type="submit"
              >
                Submit
              </Button>
            )}
          </Box>

          {counterError === 4 && (
            <Alert severity="warning" sx={{ mt: 2 }}>
              You will be redirect to login page in few seconds.
            </Alert>
          )}
        </Paper>
      </Container>
    </>
  );
};

export default TwoFactorAuthentication;
