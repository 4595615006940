import React from "react";

const GlobeIcon = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.89945 10.7999H7.23426C7.3413 8.94423 7.69463 7.23479 8.23963 5.85864C6.50092 6.92552 5.25006 8.71008 4.89945 10.7999ZM11.9999 2.3999C6.69797 2.3999 2.3999 6.69797 2.3999 11.9999C2.3999 17.3018 6.69797 21.5999 11.9999 21.5999C17.3018 21.5999 21.5999 17.3018 21.5999 11.9999C21.5999 6.69797 17.3018 2.3999 11.9999 2.3999ZM11.9999 4.7999C11.9086 4.7999 11.7213 4.83797 11.4415 5.11411C11.1566 5.39534 10.8449 5.86179 10.5573 6.53291C10.0909 7.62123 9.74979 9.1026 9.63874 10.7999H14.3611C14.25 9.1026 13.9089 7.62123 13.4425 6.53291C13.1549 5.86179 12.8432 5.39534 12.5583 5.11411C12.2785 4.83797 12.0912 4.7999 11.9999 4.7999ZM16.7655 10.7999C16.6585 8.94423 16.3052 7.23479 15.7602 5.85864C17.4989 6.92552 18.7497 8.71008 19.1004 10.7999H16.7655ZM14.3611 13.1999H9.63874C9.74979 14.8972 10.0909 16.3786 10.5573 17.4669C10.8449 18.138 11.1566 18.6045 11.4415 18.8857C11.7213 19.1618 11.9086 19.1999 11.9999 19.1999C12.0912 19.1999 12.2785 19.1618 12.5583 18.8857C12.8432 18.6045 13.1549 18.138 13.4425 17.4669C13.9089 16.3786 14.25 14.8972 14.3611 13.1999ZM15.7602 18.1412C16.3052 16.765 16.6585 15.0556 16.7655 13.1999H19.1004C18.7497 15.2897 17.4989 17.0743 15.7602 18.1412ZM8.23963 18.1412C7.69464 16.765 7.3413 15.0556 7.23426 13.1999H4.89945C5.25006 15.2897 6.50092 17.0743 8.23963 18.1412Z"
          fill="#9CA3AF"
        />
      </svg>
    </>
  );
};

export default GlobeIcon;
