import { initiateAXIOS } from "../../config/axios";
import { handleError } from "../handleError";
import User from "../../models/UserModel";
import {
  SET_DELETE_USER,
  SET_DELETE_USER_GROUP,
  SET_EDIT_USER,
  SET_EDIT_USER_GROUP,
  SET_ERROR_MESSAGE_USER_PAGE,
  SET_ERROR_PROMPT_USER_PAGE,
  SET_LOADING_USER,
  SET_NEW_USER,
  SET_NEW_USER_GROUP,
  SET_SUCCESS_MESSAGE_USER_PAGE,
  SET_SUCCESS_PROMPT_USER_PAGE,
  SET_USER,
  SET_USER_GROUP,
  SET_USER_HISTORY,
} from "../actionTypes/userActionType";
import Role from "../../helpers/roles";
import { decrypt } from "../../helpers/crypto";
import {
  API_LIMIT,
  initiateUserAPI,
  initiateUserGroupAPI,
  instanceUserHistoryAPI,
} from "../../api";
import { descending } from "../../helpers/sorting";
import {
  SET_GLOBAL_ERROR,
  SET_GLOBAL_ERROR_MESSAGE,
  SET_GLOBAL_SUCCESS,
  SET_GLOBAL_SUCCESS_MESSAGE,
} from "../actionTypes/globalActionType";

const handleTimeoutSuccessPrompt = (dispatch) => {
  setTimeout(() => {
    dispatch({ type: SET_SUCCESS_PROMPT_USER_PAGE, payload: false });
  }, 3000);
};

const handleTimeoutErrorPrompt = (dispatch) => {
  setTimeout(() => {
    dispatch({ type: SET_ERROR_PROMPT_USER_PAGE, payload: false });
  }, 5000);
};

export const getUsers = () => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");
    dispatch({ type: SET_LOADING_USER, payload: true });
    try {
      const { data } = await initiateAXIOS.get(
        initiateUserAPI + `?limit=${API_LIMIT}&sortBy=name&sortOrder=asc`,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      const listUsers = data.items;

      let users = [];
      listUsers.map((item) => users.push(item));

      const userRole = localStorage.getItem("permissionRole");
      const decryptedUserRole = decrypt(userRole);

      if (decryptedUserRole === Role.Admin) {
        const filtered = users.filter((user) => user.type === Role.User);
        users = filtered;
      }

      dispatch({ type: SET_USER, payload: users });
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_USER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_USER_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_USER, payload: false });
    }
  };
};

export const getUserGroups = () => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");
    dispatch({ type: SET_LOADING_USER, payload: true });
    try {
      const { data } = await initiateAXIOS.get(
        initiateUserGroupAPI + `?limit=${API_LIMIT}`,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({ type: SET_USER_GROUP, payload: data.items });
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_USER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_USER_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_USER, payload: false });
    }
  };
};

export const createUserGroup = (payload) => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");

    dispatch({ type: SET_LOADING_USER, payload: true });

    try {
      const { data } = await initiateAXIOS.post(initiateUserGroupAPI, payload, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      dispatch({
        type: SET_NEW_USER_GROUP,
        payload: data,
      });

      dispatch({
        type: SET_SUCCESS_PROMPT_USER_PAGE,
        payload: true,
      });
      dispatch({
        type: SET_SUCCESS_MESSAGE_USER_PAGE,
        payload: "A new user group has been created successfully.",
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_USER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_USER_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_USER, payload: false });
    }
  };
};

export const editUserGroup = ({ id, payload }) => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");
    dispatch({ type: SET_LOADING_USER, payload: true });

    try {
      const { data } = await initiateAXIOS.patch(
        initiateUserGroupAPI + id,
        payload,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );
      dispatch({ type: SET_EDIT_USER_GROUP, payload: data });
      dispatch({ type: SET_SUCCESS_PROMPT_USER_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_USER_PAGE,
        payload: "The user group data is updated.",
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_USER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_USER_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_USER, payload: false });
    }
  };
};

export const filterUser = (query) => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");
    dispatch({ type: SET_LOADING_USER, payload: true });
    try {
      const { data } = await initiateAXIOS.get(
        initiateUserAPI + `?limit=${API_LIMIT}` + query,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      let admins = [];
      const listAdmin = data.items;
      listAdmin.map((item) => admins.push(item));

      const userRole = localStorage.getItem("permissionRole");
      const decryptedUserRole = decrypt(userRole);

      if (decryptedUserRole === Role.Admin) {
        const filtered = admins.filter((admin) => admin.role === Role.User);
        admins = filtered;
      }

      dispatch({ type: SET_USER, payload: admins });
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_USER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_USER_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_USER, payload: false });
    }
  };
};

export const createUser = (payload) => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");
    dispatch({ type: SET_LOADING_USER, payload: true });

    try {
      const { data } = await initiateAXIOS.post(initiateUserAPI, payload, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      dispatch({ type: SET_NEW_USER, payload: data });
      dispatch({ type: SET_SUCCESS_PROMPT_USER_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_USER_PAGE,
        payload: "A new user has been created successfully.",
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_USER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_USER_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_USER, payload: false });
    }
  };
};

export const deleteUser = (id) => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");
    dispatch({ type: SET_LOADING_USER, payload: true });

    try {
      await initiateAXIOS.delete(initiateUserAPI + id, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      dispatch({ type: SET_SUCCESS_PROMPT_USER_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_USER_PAGE,
        payload: "The user is deleted.",
      });
      dispatch({ type: SET_DELETE_USER, payload: id });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_USER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_USER_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_USER, payload: false });
    }
  };
};

export const deleteUsergroup = (id) => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");

    dispatch({ type: SET_LOADING_USER, payload: true });

    try {
      await initiateAXIOS.delete(initiateUserGroupAPI + id, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      dispatch({ type: SET_DELETE_USER_GROUP, payload: id });
      dispatch({
        type: SET_SUCCESS_PROMPT_USER_PAGE,
        payload: true,
      });
      dispatch({
        type: SET_SUCCESS_MESSAGE_USER_PAGE,
        payload: "The user group is deleted.",
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({
        type: SET_ERROR_PROMPT_USER_PAGE,
        payload: true,
      });
      dispatch({
        type: SET_ERROR_MESSAGE_USER_PAGE,
        payload: message,
      });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_USER, payload: false });
    }
  };
};

export const editUser = ({ id, user }) => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");
    dispatch({ type: SET_LOADING_USER, payload: true });

    let bodyRequest;
    let url;

    bodyRequest = user;
    url = initiateUserAPI + id;

    try {
      const { data } = await initiateAXIOS.patch(url, bodyRequest, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      const OOP = data;
      dispatch({ type: SET_EDIT_USER, payload: OOP });
      dispatch({ type: SET_SUCCESS_PROMPT_USER_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_USER_PAGE,
        payload: "The user data is updated.",
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_USER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_USER_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_USER, payload: false });
    }
  };
};

export const unlockUser = (user) => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");
    dispatch({ type: SET_LOADING_USER, payload: true });

    if (user.isLocked) {
      try {
        const { data } = await initiateAXIOS.patch(
          initiateUserAPI + user.id,
          {
            user: { isLocked: false },
          },
          {
            headers: {
              authorization: `Bearer ${access_token}`,
            },
          }
        );

        const OOP = new User(data);
        dispatch({ type: SET_EDIT_USER, payload: OOP });
        dispatch({ type: SET_SUCCESS_PROMPT_USER_PAGE, payload: true });
        dispatch({
          type: SET_SUCCESS_MESSAGE_USER_PAGE,
          payload: "The user is locked and have no access to the systems.",
        });

        handleTimeoutSuccessPrompt(dispatch);
      } catch (error) {
        const message = handleError(error);
        dispatch({ type: SET_ERROR_PROMPT_USER_PAGE, payload: true });
        dispatch({ type: SET_ERROR_MESSAGE_USER_PAGE, payload: message });

        handleTimeoutErrorPrompt(dispatch);
      } finally {
        dispatch({ type: SET_LOADING_USER, payload: false });
      }
    } else {
      try {
        const { data } = await initiateAXIOS.post(
          initiateUserAPI + user.id + "/unrestrict",
          {},
          {
            headers: {
              authorization: `Bearer ${access_token}`,
            },
          }
        );

        const OOP = new User(data);
        dispatch({ type: SET_EDIT_USER, payload: OOP });
        dispatch({ type: SET_SUCCESS_PROMPT_USER_PAGE, payload: true });
        dispatch({
          type: SET_SUCCESS_MESSAGE_USER_PAGE,
          payload:
            "The user has been given back his/her access to the systems.",
        });

        handleTimeoutSuccessPrompt(dispatch);
      } catch (e) {
        const message = handleError(e);
        dispatch({ type: SET_ERROR_PROMPT_USER_PAGE, payload: true });
        dispatch({ type: SET_ERROR_MESSAGE_USER_PAGE, payload: message });

        handleTimeoutErrorPrompt(dispatch);
      } finally {
        dispatch({ type: SET_LOADING_USER, payload: false });
      }
    }
  };
};

export const lockUser = (userId) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_USER, payload: true });
    const access_token = sessionStorage.getItem("access_token");

    try {
      const { data } = await initiateAXIOS.patch(
        initiateUserAPI + userId,
        {
          user: { isLocked: true },
        },
        {
          headers: {
            authorization: `Bearer ${access_token}`,
          },
        }
      );

      const OOP = new User(data);
      dispatch({ type: SET_EDIT_USER, payload: OOP });
      dispatch({ type: SET_SUCCESS_PROMPT_USER_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_USER_PAGE,
        payload: "The user is locked and have no access to the systems.",
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (error) {
      const message = handleError(error);
      dispatch({ type: SET_ERROR_PROMPT_USER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_USER_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_USER, payload: false });
    }
  };
};

export const forceLogoutUser = (userId) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_USER, payload: true });
    const access_token = sessionStorage.getItem("access_token");

    try {
      await initiateAXIOS.post(
        initiateUserAPI + "/logout/" + userId,
        {},
        {
          headers: {
            authorization: `Bearer ${access_token}`,
          },
        }
      );
      dispatch({ type: SET_SUCCESS_PROMPT_USER_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_USER_PAGE,
        payload: "The user is successfully logged out.",
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (error) {
      const message = handleError(error);
      dispatch({ type: SET_ERROR_PROMPT_USER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_USER_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_USER, payload: false });
    }
  };
};

export const getUserHistory = (query) => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");
    dispatch({ type: SET_LOADING_USER, payload: true });
    try {
      const { data } = await initiateAXIOS.get(instanceUserHistoryAPI + query, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      dispatch({
        type: SET_USER_HISTORY,
        payload: descending(data.items, "id"),
      });
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_USER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_USER_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_USER, payload: false });
    }
  };
};

export const changeSelfPassword = (payload, changeSelfPasswordCallback) => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");
    dispatch({ type: SET_LOADING_USER, payload: true });
    try {
      await initiateAXIOS.patch(initiateUserAPI + "password", payload, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      dispatch({ type: SET_GLOBAL_SUCCESS, payload: true });
      dispatch({
        type: SET_GLOBAL_SUCCESS_MESSAGE,
        payload: "Your password has been updated.",
      });
      if (changeSelfPasswordCallback) changeSelfPasswordCallback();
    } catch (e) {
      const errorMessage = e.response.data.message;
      dispatch({ type: SET_GLOBAL_ERROR, payload: true });
      dispatch({ type: SET_GLOBAL_ERROR_MESSAGE, payload: errorMessage });
    } finally {
      dispatch({ type: SET_LOADING_USER, payload: false });
    }
  };
};
