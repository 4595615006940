import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Alert,
  Box,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { TypographyNormal, TypographySmall } from "../../../customComponent";
import Role from "../../../../helpers/roles";
import { red } from "@mui/material/colors";
import ViewerRemoveGroup from "./ViewerRemoveGroup";
import { useSelector } from "react-redux";
import { decrypt } from "../../../../helpers/crypto";
import { GroupBlueIcon } from "../../../../icons";

const ViewerGroup = ({ viewer, loading }) => {
  const navigate = useNavigate();

  const { loggedUser } = useSelector((state) => state.auth);
  const decryptedLoggedUser = decrypt(loggedUser);
  const userRole = decryptedLoggedUser.type;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openDialogRemove, setOpenDialogRemove] = useState(false);

  const handleOpenDialogRemove = () => {
    setOpenDialogRemove(true);
  };

  const handleCloseDialogRemove = () => {
    setOpenDialogRemove(false);
  };

  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <CircularProgress size={20} thickness={3} sx={{ color: "#1D4ED8" }} />
        <TypographyNormal sx={{ color: "#1D4ED8", mt: 2, fontWeight: 300 }}>
          Loading Data...
        </TypographyNormal>
      </Box>
    );

  return (
    <>
      <Box>
        {!viewer.screenGroup && !loading ? (
          <Alert severity="info" variant="filled">
            No Group
          </Alert>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <GroupBlueIcon />

              <Box sx={{ ml: 2 }}>
                <TypographySmall sx={{ fontWeight: 300 }}>
                  Group Name
                </TypographySmall>
                <TypographyNormal sx={{ fontWeight: 600 }}>
                  {viewer.screenGroup.name}
                </TypographyNormal>
              </Box>
            </Box>

            <IconButton sx={{ color: "#111827" }} onClick={handleClick}>
              <MoreVertIcon fontSize="small" />
            </IconButton>

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                dense
                onClick={() =>
                  navigate("/group/detail/" + viewer.screenGroup.id)
                }
              >
                <InfoOutlinedIcon fontSize="small" sx={{ mr: "5px" }} />
                See Details
              </MenuItem>
              {userRole === Role.Admin && (
                <MenuItem
                  sx={{ color: red[600] }}
                  dense
                  onClick={() => handleOpenDialogRemove(true)}
                >
                  <RemoveCircleOutlinedIcon
                    sx={{ mr: "5px" }}
                    fontSize="small"
                  />
                  Remove from Group
                </MenuItem>
              )}
              <ViewerRemoveGroup
                open={openDialogRemove}
                onClose={() => handleCloseDialogRemove()}
                viewer={viewer}
                onCloseModal={() => handleClose()}
              />
            </Menu>
          </Box>
        )}
      </Box>
    </>
  );
};

export default ViewerGroup;
