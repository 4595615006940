import { SET_AUTH_USER } from "../actionTypes/userActionType";

const initialState = {
  loggedUser: {},
  otpToken: "",
};

export const authReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_AUTH_USER:
      return { ...state, loggedUser: payload };

    default:
      return state;
  }
};
