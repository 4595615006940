import { useEffect } from "react";
import {
  Box,
  Container,
  CssBaseline,
  Grid,
  Paper,
  Toolbar,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Sidebar from "../../Sidebar";
import AnnouncementBreadCrumb from "./AnnouncementBreadCrumb";
import AnnouncementInfo from "./AnnouncementInfo";
import AnnouncementSuccessPrompt from "../AnnouncementSuccessPrompt";
import AnnouncementErrorPrompt from "../AnnouncementErrorPrompt";
import AnnouncementUpdateStatus from "./AnnouncementUpdateStatus";
import EditAnnouncement from "./EditAnnouncement";
import DeleteAnnouncement from "./DeleteAnnouncement";

import { getAnnouncement } from "../../../store/actions/announcementAction";
import { decrypt } from "../../../helpers/crypto";
import Role from "../../../helpers/roles";
import AssignComponent from "../../assign/AssignComponent";
import AssignedComponentList from "../../assign/AssignedComponentList";
import ApproveAnnouncement from "./ApproveAnnouncement";

const AnnouncementDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getAnnouncement(id));
  }, [dispatch, id]);

  const { announcement, loadingAnnouncementDetail: loading } = useSelector(
    (state) => state.announcement
  );

  const { loggedUser } = useSelector((state) => state.auth);
  const decryptedLoggedUser = decrypt(loggedUser);
  const userRole = decryptedLoggedUser.type;
  const userPermission = decryptedLoggedUser.userGroup.permission.schedule;

  return (
    <div style={{ display: "flex" }}>
      <Sidebar />

      <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
        <Toolbar />
        <CssBaseline />

        <Container maxWidth="xl">
          <Box sx={{ mb: 2 }}>
            <AnnouncementBreadCrumb announcement={announcement} />
          </Box>

          <AnnouncementSuccessPrompt />
          <AnnouncementErrorPrompt />
          {!loading && (
            <Box
              sx={{ mb: 2 }}
              display={"flex"}
              flexDirection={"row"}
              flexWrap={"nowrap"}
              justifyContent={"space-between"}
            >
              <Box>
                {userPermission.approve && (
                  <ApproveAnnouncement announcement={announcement} />
                )}
              </Box>

              {userRole === Role.Admin ? (
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <EditAnnouncement announcement={announcement} />
                  <AnnouncementUpdateStatus announcement={announcement} />
                  <DeleteAnnouncement announcement={announcement} />
                </Box>
              ) : userRole === Role.User && userPermission.activate ? (
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <AnnouncementUpdateStatus announcement={announcement} />
                </Box>
              ) : null}
            </Box>
          )}
          <Grid container spacing={2} direction="column">
            <Grid item xs={4}>
              <Paper elevation={2} sx={{ p: 2, borderRadius: "6px", mb: 2 }}>
                <AnnouncementInfo
                  announcement={announcement}
                  loading={loading}
                />
              </Paper>
            </Grid>

            {!loading && userRole === Role.Admin ? (
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <AssignComponent
                  getName={"viewer"}
                  typeName="announcement"
                  typeData={announcement}
                />

                <AssignComponent
                  getName={"group"}
                  typeName="announcement"
                  typeData={announcement}
                />
              </Box>
            ) : null}

            <Grid item xs={4}>
              <Paper elevation={2} sx={{ borderRadius: "6px" }}>
                <AssignedComponentList
                  key={"group"}
                  type={"group"}
                  listData={announcement.screenGroups}
                  sourceData={{
                    id: announcement.id,
                    name: announcement.name,
                    type: "announcement",
                  }}
                  loading={loading}
                />
              </Paper>
            </Grid>

            <Grid item xs={4}>
              <Paper elevation={2} sx={{ borderRadius: "6px" }}>
                <AssignedComponentList
                  key={"viewer"}
                  type={"viewer"}
                  listData={announcement.screens}
                  sourceData={{
                    id: announcement.id,
                    name: announcement.name,
                    type: "announcement",
                  }}
                  loading={loading}
                />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default AnnouncementDetail;
