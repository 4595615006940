import React from "react";
import { CssBaseline, Box, CircularProgress, Alert } from "@mui/material";
import {
  CstTypo20W600,
  CustomTextField,
  PrimaryButton,
} from "../components/customComponent";
import { GlobalSuccessPrompt, GlobalErrorPrompt } from "../components";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { expiredPasswordSchema } from "../schemas/expiredPasswordSchema";
import { useDispatch, useSelector } from "react-redux";
import { changeSelfPassword } from "../store/actions/userAction";

const ExpiredPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loadingUser } = useSelector((state) => state.user);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(expiredPasswordSchema) });

  const handleExpiredPassword = async (data) => {
    const payload = {
      currentPassword: data["Current Password"],
      newPassword: data["New Password"],
    };
    dispatch(changeSelfPassword(payload, handleSuccessChangePassword)).then(
      () =>
        reset({
          "Current Password": "",
          "New Password": "",
          "Confirm Password": "",
        })
    );
  };

  const handleSuccessChangePassword = () => {
    sessionStorage.clear();
    navigate("/login");
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        minWidth: "100wh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CssBaseline />
      <GlobalSuccessPrompt />
      <GlobalErrorPrompt />

      <Box
        component="main"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1,
        }}
      >
        <Box
          component="div"
          sx={{
            p: 2,
            border: "1px solid lightgrey",
            width: "37.5rem",
            borderRadius: "6px",
          }}
        >
          <Alert severity="warning">
            <strong>Security Checking:</strong> your active password has
            expired. Please re-new your password.
          </Alert>

          <Box
            sx={{ px: 4, py: 2 }}
            component="form"
            onSubmit={handleSubmit(handleExpiredPassword)}
          >
            <CstTypo20W600 textAlign="center">
              Renew your password
            </CstTypo20W600>
            <CustomTextField
              fullWidth
              margin="normal"
              label="Enter your current password"
              type="password"
              {...register("Current Password")}
              error={errors["Current Password"]}
              helperText={errors["Current Password"]?.message}
            />

            <CustomTextField
              fullWidth
              margin="normal"
              label="Enter your new password"
              type="password"
              {...register("New Password")}
              error={errors["New Password"]}
              helperText={errors["New Password"]?.message}
            />

            <CustomTextField
              fullWidth
              margin="normal"
              label="Enter your confirm password"
              type="password"
              {...register("Confirm Password")}
              error={errors["Confirm Password"]}
              helperText={
                errors["Confirm Password"] && "Confirm Password does not match"
              }
            />

            {loadingUser ? (
              <PrimaryButton size="large" sx={{ mt: 1 }} disabled fullWidth>
                <CircularProgress size={20} style={{ color: "#ffff" }} />
              </PrimaryButton>
            ) : (
              <PrimaryButton
                size="large"
                sx={{ mt: 1 }}
                fullWidth
                variant="outlined"
                type="submit"
              >
                Update your password
              </PrimaryButton>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ExpiredPassword;
