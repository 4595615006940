import React from "react";

import { Breadcrumbs, Link } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const AnnouncementBreadCrumb = ({ announcement }) => {
  return (
    <Breadcrumbs
      separator={<NavigateNextIcon sx={{ color: "#1C4ED8" }} fontSize="small" />}
      aria-label="breadcrumb"
    >
      <Link href="/announcements" underline="always" sx={{ cursor: "pointer" }}>
        All Announcements
      </Link>
      <Link href="#" underline="hover" sx={{ cursor: "pointer" }}>
        {announcement.name}
      </Link>
    </Breadcrumbs>
  );
};

export default AnnouncementBreadCrumb;
