import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createUserRoleSchema } from "../../schemas/createUserSchema";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_ERROR_MESSAGE_USER_PAGE,
  SET_ERROR_PROMPT_USER_PAGE,
} from "../../store/actionTypes/userActionType";
import Role from "../../helpers/roles";
import { decrypt } from "../../helpers/crypto";
import {
  API_LIMIT,
  initiateOrganizationAPI,
  initiateUserGroupAPI,
} from "../../api";
import { initiateAXIOS } from "../../config/axios";
import { createUser } from "../../store/actions/userAction";

const StyledBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  ":last-child": {
    marginBottom: theme.spacing(0),
  },
}));

const CreateUser = () => {
  const { loggedUser } = useSelector((state) => state.auth);
  const decryptedLoggedUser = decrypt(loggedUser);
  const userRole = decryptedLoggedUser.type;
  const userOrg = decryptedLoggedUser.organization.id;

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();

  const access_token = sessionStorage.getItem("access_token");

  const resolver = createUserRoleSchema;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(resolver),
  });

  const [organizations, setOrganizations] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [selectOrganization, setSelectOrganization] = useState("");
  const [selectGroup, setSelectGroup] = useState("");

  const getOrganizations = async (query) => {
    try {
      const { data } = await initiateAXIOS.get(
        initiateOrganizationAPI + `?limit=${API_LIMIT}` + (query ?? ""),
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      setOrganizations(data.items);
    } catch (error) {
      const errorMessage = error.response.data.message;
      dispatch({ type: SET_ERROR_PROMPT_USER_PAGE, payload: true });
      dispatch({
        type: SET_ERROR_MESSAGE_USER_PAGE,
        payload: errorMessage,
      });
    }
  };

  const getUserGroups = async (query) => {
    try {
      const { data } = await initiateAXIOS.get(
        initiateUserGroupAPI + `?limit=${API_LIMIT}` + (query ?? ""),
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      setUserGroups(data.items);
    } catch (error) {
      const errorMessage = error.response.data.message;
      dispatch({ type: SET_ERROR_PROMPT_USER_PAGE, payload: true });
      dispatch({
        type: SET_ERROR_MESSAGE_USER_PAGE,
        payload: errorMessage,
      });
    }
  };

  useEffect(() => {
    if (selectOrganization !== "")
      getUserGroups(`&organizationId=${selectOrganization}`);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOrganization]);

  useEffect(() => {
    if (open) {
      if (userRole === Role.Eins) {
        getOrganizations();
      } else {
        getUserGroups(`&organizationId=${userOrg}`);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const submitUserForm = async (data) => {
    const { name, email, password, role } = data;

    let payload = {
      name,
      email,
      password,
      type: role,
      organizationId:
        selectOrganization === "" ? +userOrg : +selectOrganization,
      userGroupId: +selectGroup,
    };

    dispatch(createUser(payload));

    reset({
      name: "",
      password: "",
      confirmPassword: "",
      role: "",
      email: "",
    });

    handleClickClose();
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        startIcon={<AddIcon />}
      >
        Create New User
      </Button>

      <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClickClose}>
        <Box component="form" onSubmit={handleSubmit(submitUserForm)}>
          <DialogTitle
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            Create New User
            <IconButton onClick={handleClickClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <StyledBox>
              <InputLabel>Username</InputLabel>
              <TextField
                margin="dense"
                {...register("name")}
                fullWidth
                required
                placeholder="Enter username"
                helperText={errors.name?.message}
                error={errors.name ? true : false}
              />
            </StyledBox>

            <StyledBox>
              <InputLabel>Email</InputLabel>
              <TextField
                margin="dense"
                {...register("email")}
                fullWidth
                required
                type="email"
                placeholder="Enter email"
                helperText={errors.email?.message}
                error={errors.email ? true : false}
              />
            </StyledBox>

            <Box sx={{ display: "flex" }}>
              <StyledBox sx={{ width: "100%" }}>
                <InputLabel>Password</InputLabel>

                <TextField
                  margin="dense"
                  fullWidth
                  {...register("password")}
                  type="password"
                  required
                  placeholder="Enter password"
                  helperText={errors.password?.message}
                  error={errors.password ? true : false}
                />
              </StyledBox>

              <StyledBox sx={{ width: "100%", ml: 2 }}>
                <InputLabel>Confirm Password</InputLabel>
                <TextField
                  margin="dense"
                  fullWidth
                  {...register("confirmPassword")}
                  type="password"
                  required
                  placeholder="Enter confirm password"
                  helperText={
                    errors.confirmPassword && "password does not match"
                  }
                  error={errors.confirmPassword ? true : false}
                />
              </StyledBox>
            </Box>

            <StyledBox>
              <InputLabel>Role</InputLabel>
              <TextField
                margin="dense"
                {...register("role")}
                select
                fullWidth
                required
                SelectProps={{
                  native: true,
                }}
                helperText={errors.role?.message}
                error={errors.role}
              >
                <option value="">Select Role</option>
                {userRole === Role.Eins && <option value="ADMIN">Admin</option>}

                <option value="BASIC">Basic</option>
              </TextField>
            </StyledBox>

            {userRole === Role.Eins && (
              <StyledBox>
                <InputLabel>Organization</InputLabel>

                <TextField
                  select
                  margin="dense"
                  fullWidth
                  required
                  SelectProps={{
                    native: true,
                  }}
                  onChange={(e) => setSelectOrganization(e.target.value)}
                  value={selectOrganization}
                >
                  <option value="" disabled>
                    Select Organization
                  </option>
                  {organizations?.map((organization) => (
                    <option key={organization.id} value={organization.id}>
                      {organization.name}
                    </option>
                  ))}
                </TextField>
              </StyledBox>
            )}

            <StyledBox>
              <InputLabel>User Group</InputLabel>

              <TextField
                select
                margin="dense"
                fullWidth
                required
                SelectProps={{
                  native: true,
                }}
                onChange={(e) => setSelectGroup(e.target.value)}
                value={selectGroup}
              >
                <option value="">Select Group</option>
                {userGroups?.map((group) => (
                  <option key={group.id} value={group.id}>
                    {group.name}
                  </option>
                ))}
              </TextField>
            </StyledBox>
          </DialogContent>

          <DialogActions>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => {
                setOpen(false);

                reset({
                  name: "",
                  password: "",
                  confirmPassword: "",
                  role: "",
                  organization: "",
                  email: "",
                });
              }}
            >
              Cancel
            </Button>

            <Button fullWidth type="submit" variant="contained">
              Create
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default CreateUser;
