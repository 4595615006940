import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Role from "../helpers/roles";
import { decrypt } from "../helpers/crypto";

import {
  Home,
  Group,
  TwoFactorAuthentication,
  GroupDetail,
  Viewer,
  ViewerDetail,
  JobHistory,
  User,
  Schedule,
  ScheduleDetail,
  MyAccount,
  Announcement,
  AnnouncementDetail,
  Login,
  ExpiredPassword,
  UserManual,
  ResetPassword,
  NotFound,
  ContentHistory,
  UserHistory,
  Organization,
  UserGroup,
  ContentTemplate,
  CreateContentTemplate,
} from "../components";

import {
  AuthorizationLoginRoute,
  AuthorizationRoute,
  AuthorizationRouteRequestToken,
  AuthorizationForgotPasswordRoute,
  AuthorizationUsersRoute,
} from "../auth/AuthorizationRoute";

const RouterProvider = () => {
  const { loggedUser } = useSelector((state) => state.auth);
  const [userRole, setUserRole] = useState();

  useEffect(() => {
    if (Object.keys(loggedUser).length !== 0) {
      const decryptedLoggedUser = decrypt(loggedUser);
      setUserRole(decryptedLoggedUser.type);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser]);

  const landingRoute = (
    <Route
      path="/"
      element={
        userRole === Role.Admin || userRole === Role.User ? (
          <Navigate to="/dashboard" />
        ) : (
          <Navigate to="/users" />
        )
      }
    />
  );

  const superAdminRoute = (
    <>
      <Route
        path="/viewers"
        element={
          <AuthorizationRoute>
            <Viewer />
          </AuthorizationRoute>
        }
      />
      <Route
        path="/users"
        element={
          <AuthorizationRoute>
            <User />
          </AuthorizationRoute>
        }
      />
      <Route
        path="/user/groups"
        element={
          <AuthorizationRoute>
            <UserGroup />
          </AuthorizationRoute>
        }
      />

      <Route
        path="/users/history"
        element={
          <AuthorizationRoute>
            <UserHistory />
          </AuthorizationRoute>
        }
      />

      <Route
        path="/organizations"
        element={
          <AuthorizationRoute>
            <Organization />
          </AuthorizationRoute>
        }
      />

      <Route
        path="/myAccount"
        element={
          <AuthorizationRoute>
            <MyAccount />
          </AuthorizationRoute>
        }
      />

      <Route path="*" element={<NotFound status={404} />} />
    </>
  );

  const normalRoute = (
    <>
      <Route
        path="/dashboard"
        element={
          <AuthorizationRoute>
            <Home />
          </AuthorizationRoute>
        }
      />

      <Route
        path="/groups"
        element={
          <AuthorizationRoute>
            <Group />
          </AuthorizationRoute>
        }
      />

      <Route
        path="/group/detail/:id"
        element={
          <AuthorizationRoute>
            <GroupDetail />
          </AuthorizationRoute>
        }
      />

      <Route
        path="/viewers"
        element={
          <AuthorizationRoute>
            <Viewer />
          </AuthorizationRoute>
        }
      />

      <Route
        path="/viewer/detail/:id"
        element={
          <AuthorizationRoute>
            <ViewerDetail />
          </AuthorizationRoute>
        }
      />

      <Route
        path="/schedules"
        element={
          <AuthorizationRoute>
            <Schedule />
          </AuthorizationRoute>
        }
      />

      <Route
        path="/schedule/detail/:id"
        element={
          <AuthorizationRoute>
            <ScheduleDetail />
          </AuthorizationRoute>
        }
      />

      <Route
        path="/jobs/history"
        element={
          <AuthorizationRoute>
            <JobHistory />
          </AuthorizationRoute>
        }
      />

      <Route
        path="/announcements"
        element={
          <AuthorizationRoute>
            <Announcement />
          </AuthorizationRoute>
        }
      />

      <Route
        path="/announcement/detail/:id"
        element={
          <AuthorizationRoute>
            <AnnouncementDetail />
          </AuthorizationRoute>
        }
      />

      <Route
        path="/users"
        element={
          <AuthorizationUsersRoute>
            <User />
          </AuthorizationUsersRoute>
        }
      />
      {userRole !== Role.User && (
        <Route
          path="/user/groups"
          element={
            <AuthorizationRoute>
              <UserGroup />
            </AuthorizationRoute>
          }
        />
      )}

      {userRole !== Role.User && (
        <Route
          path="/users/history"
          element={
            <AuthorizationRoute>
              <UserHistory />
            </AuthorizationRoute>
          }
        />
      )}

      <Route
        path="/myAccount"
        element={
          <AuthorizationRoute>
            <MyAccount />
          </AuthorizationRoute>
        }
      />

      <Route
        path="/user/manual"
        element={
          <AuthorizationRoute>
            <UserManual />
          </AuthorizationRoute>
        }
      />

      {/* Start CMS */}
      <Route
        path="cms/content-template"
        element={
          <AuthorizationRoute>
            <ContentTemplate />
          </AuthorizationRoute>
        }
      />
      <Route
        path="cms/content-template/create"
        element={
          <AuthorizationRoute>
            <CreateContentTemplate />
          </AuthorizationRoute>
        }
      />
      {/* End CMS */}

      <Route
        path="/content-upload/history"
        element={
          <AuthorizationRoute>
            <ContentHistory />
          </AuthorizationRoute>
        }
      />

      <Route path="*" element={<NotFound status={404} />} />
    </>
  );

  return (
    <Routes>
      {landingRoute}

      {userRole === Role.Superadmin || userRole === Role.Eins ? (
        <>{superAdminRoute}</>
      ) : (
        <>{normalRoute}</>
      )}

      <Route
        path="/authorization/request-otp"
        element={
          <AuthorizationRouteRequestToken>
            <TwoFactorAuthentication />
          </AuthorizationRouteRequestToken>
        }
      />

      <Route
        path="/reset-password"
        element={
          <AuthorizationForgotPasswordRoute>
            <ResetPassword />
          </AuthorizationForgotPasswordRoute>
        }
      />

      <Route path="/expired-password" element={<ExpiredPassword />} />

      <Route
        path="/login"
        element={
          <AuthorizationLoginRoute>
            <Login />
          </AuthorizationLoginRoute>
        }
      />
    </Routes>
  );
};

export default RouterProvider;
