import React from "react";
import { useDispatch } from "react-redux";

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";

import { removeGroupFromViewer } from "../../../../store/actions/viewerAction";

const RemoveGroupFromViewer = ({ open, onClose, viewer, onCloseModal }) => {
  const dispatch = useDispatch();

  const handleRemove = () => {
    dispatch(removeGroupFromViewer(viewer.id));

    onClose();
    onCloseModal();
  };

  return (
    <>
      <Dialog maxWidth="sm" open={open} onClose={onClose}>
        <DialogContent>
          <DialogContentText
            gutterBottom
            sx={{
              textAlign: "center",
              mb: 2,
            }}
          >
            Are you sure want to remove group{" "}
            <strong>{viewer.screenGroup.name}</strong> from viewer{" "}
            <strong>{viewer.name}?</strong>
          </DialogContentText>
          <Alert severity="warning" variant="outlined">
            <strong>You won't be able to revert this!</strong>
          </Alert>
        </DialogContent>

        <DialogActions>
          <Button
            color="error"
            variant="outlined"
            fullWidth
            startIcon={<ClearIcon />}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            color="error"
            variant="contained"
            fullWidth
            startIcon={<DoneIcon />}
            onClick={handleRemove}
          >
            Yes, Remove It!
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RemoveGroupFromViewer;
