import React from "react";
import { Alert, AlertTitle } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { SET_SUCCESS_PROMPT_CONTENT_UPLOAD_PAGE } from "../../store/actionTypes/contentUploadActionType";

const ContentUploadSuccessPrompt = () => {
  const dispatch = useDispatch();

  const {
    isSuccessPromptContentUploadPage: isSuccess,
    successMessageContentUploadPage: successMessage,
  } = useSelector((state) => state.contentUpload);

  const handleClose = (event, reason) => {
    dispatch({ type: SET_SUCCESS_PROMPT_CONTENT_UPLOAD_PAGE, payload: false });
  };

  return (
    isSuccess && (
      <Alert
        sx={{ my: 2 }}
        severity="success"
        variant="filled"
        onClose={handleClose}
      >
        <AlertTitle>Success!</AlertTitle>
        {successMessage}
      </Alert>
    )
  );
};

export default ContentUploadSuccessPrompt;
