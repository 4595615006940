import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Box, CssBaseline, Toolbar } from "@mui/material";
import BackToContent from "./BackToContent";
import CreateContent from "./CreateContent";
import PreviewBox from "./PreviewBox";
import ContentTemplateSuccessPrompt from "../ContentTemplateSuccessPrompt";
import ContentTemplateErrorPrompt from "../ContentTemplateErrorPrompt";
import Sidebar from "../../../Sidebar";
import { ResolutionContext } from "../../../../helpers/resolution";
import { setContentLayers, setLayerMedias } from "../../../../store/actions/cmsAction";

const CreateContentTemplate = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setContentLayers({}));
    dispatch(setLayerMedias({ reset: true }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [resolution, setResolution] = useState({
    height: 0,
    width: 0,
    custom: false,
  });

  const resolutionContextSetup = {
    width: resolution.width,
    height: resolution.height,
    custom: resolution.custom,
    orientation: resolution.orientation,
    setResolution,
  };
  
  return (
    <div style={{ display: "flex" }}>
      <Sidebar />

      <Box component="main" sx={{ flexGrow: 1, p: 1, overflowX: "auto" }}>
        <Toolbar />
        <CssBaseline />

        <Box
          sx={{
            px: 1,
            py: 2,
            borderTop: "2px solid #1C4ED8",
          }}
        >
          <BackToContent />
        </Box>

        <ResolutionContext.Provider value={resolutionContextSetup}>
          <ContentTemplateSuccessPrompt />
          <ContentTemplateErrorPrompt />

          <Box display={"flex"} gap={3} sx={{ px: 2 }}>
            <CreateContent />
            <PreviewBox />
          </Box>
        </ResolutionContext.Provider>
      </Box>
    </div>
  );
};

export default CreateContentTemplate;
