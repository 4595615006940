import { initiateAXIOS } from "../../config/axios";
import { handleError } from "../handleError";

import {
  SET_ANNOUNCEMENT,
  SET_ANNOUNCEMENT_DETAIL,
  SET_DELETE_ANNOUNCEMENT,
  SET_ERROR_MESSAGE_ANNOUNCEMENT_PAGE,
  SET_ERROR_PROMPT_ANNOUNCEMENT_PAGE,
  SET_LOADING_ANNOUNCEMENT,
  SET_LOADING_ANNOUNCEMENT_DETAIL,
  SET_NEW_ANNOUNCEMENT,
  SET_SUCCESS_MESSAGE_ANNOUNCEMENT_PAGE,
  SET_SUCCESS_PROMPT_ANNOUNCEMENT_PAGE,
} from "../actionTypes/announcementActionType";
import {
  API_LIMIT,
  announcementAPILimit,
  initiateAnnouncementAPI,
  initiateScheduleAPI,
  instanceCMSContentAPI,
} from "../../api";
import Schedule from "../../models/ScheduleModel";
import Viewer from "../../models/ViewerModel";
import Group from "../../models/GroupModel";
import { fetchScheduleById } from "./scheduleAction";
import { ascending } from "../../helpers/sorting";

const handleTimeoutSuccessPrompt = (dispatch) => {
  setTimeout(() => {
    dispatch({ type: SET_SUCCESS_PROMPT_ANNOUNCEMENT_PAGE, payload: false });
  }, 3000);
};

const handleTimeoutErrorPrompt = (dispatch) => {
  setTimeout(() => {
    dispatch({ type: SET_ERROR_PROMPT_ANNOUNCEMENT_PAGE, payload: false });
  }, 5000);
};

export const getContentById = async (dispatch, id) => {
  const access_token = sessionStorage.getItem("access_token");
  try {
    const { data } = await initiateAXIOS.get(instanceCMSContentAPI + id, {
      headers: { authorization: `Bearer ${access_token}` },
    });

    return data;
  } catch (e) {
    const message = handleError(e);
    dispatch({ type: SET_ERROR_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
    dispatch({ type: SET_ERROR_MESSAGE_ANNOUNCEMENT_PAGE, payload: message });

    handleTimeoutErrorPrompt(dispatch);
    return null;
  }
};

export const getAnnouncements = () => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_ANNOUNCEMENT, payload: true });

    try {
      const { data } = await initiateAXIOS.get(announcementAPILimit, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      const announcements = [];
      await Promise.all(
        data.items.map(async (ann) => {
          const contentData = await getContentById(dispatch, ann.contentId);
          announcements.push(new Schedule({ ...ann, content: contentData }));
        })
      );
      dispatch({
        type: SET_ANNOUNCEMENT,
        payload: ascending(announcements, "id"),
      });
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_ANNOUNCEMENT_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_ANNOUNCEMENT, payload: false });
    }
  };
};

export const createAnnouncement = (payload) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_ANNOUNCEMENT, payload: true });

    try {
      const { data } = await initiateAXIOS.post(initiateScheduleAPI, payload, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      const announcement = data;
      dispatch({
        type: SET_NEW_ANNOUNCEMENT,
        payload: new Schedule(announcement),
      });
      dispatch({ type: SET_SUCCESS_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_ANNOUNCEMENT_PAGE,
        payload: "A new announcement has been created successfully.",
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_ANNOUNCEMENT_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_ANNOUNCEMENT, payload: false });
    }
  };
};

export const filterAnnouncement = (query) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_ANNOUNCEMENT, payload: true });

    try {
      const { data } = await initiateAXIOS.get(
        initiateAnnouncementAPI + `&limit=${API_LIMIT}` + query,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      const announcements = [];
      await Promise.all(
        data.items.map(async (ann) => {
          const contentData = await getContentById(dispatch, ann.contentId);
          announcements.push(new Schedule({ ...ann, content: contentData }));
        })
      );
      dispatch({ type: SET_ANNOUNCEMENT, payload: announcements });
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_ANNOUNCEMENT_PAGE, payload: message });

      dispatch(handleTimeoutErrorPrompt(dispatch));
    } finally {
      dispatch({ type: SET_LOADING_ANNOUNCEMENT, payload: false });
    }
  };
};

export const getAnnouncement = (id) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_ANNOUNCEMENT_DETAIL, payload: true });

    try {
      const { data } = await initiateAXIOS.get(initiateScheduleAPI + id, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      let screenData = [];
      data.screens.map((item) => screenData.push(new Viewer(item)));
      let screenGroupData = [];
      data.screenGroups.map((item) => screenGroupData.push(new Group(item)));

      dispatch({
        type: SET_ANNOUNCEMENT_DETAIL,
        payload: new Schedule({
          ...data,
          screens: screenData,
          screenGroups: screenGroupData,
        }),
      });
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_ANNOUNCEMENT_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_ANNOUNCEMENT_DETAIL, payload: false });
    }
  };
};

export const editAnnouncement = (payload) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_ANNOUNCEMENT_DETAIL, payload: true });

    try {
      await initiateAXIOS.patch(
        initiateScheduleAPI + payload.id,
        payload.announcement,
        { headers: { authorization: `Bearer ${access_token}` } }
      );

      const { data } = await initiateAXIOS.get(
        initiateScheduleAPI + payload.id,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      let screenData = [];
      data.screens.map((item) => screenData.push(new Viewer(item)));
      let screenGroupData = [];
      data.screenGroups.map((item) => screenGroupData.push(new Group(item)));

      const contentData = await getContentById(dispatch, data.contentId);
      const ann = new Schedule({
        ...data,
        content: contentData,
        screens: screenData,
        screenGroups: screenGroupData,
      });
      dispatch({
        type: SET_ANNOUNCEMENT_DETAIL,
        payload: ann,
      });
      dispatch({ type: SET_SUCCESS_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_ANNOUNCEMENT_PAGE,
        payload: "The announcement is updated.",
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_ANNOUNCEMENT_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_ANNOUNCEMENT_DETAIL, payload: false });
    }
  };
};

export const editAnnouncementStatus = ({ id, payload }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_ANNOUNCEMENT_DETAIL, payload: true });

    try {
      await initiateAXIOS.patch(initiateScheduleAPI + id, payload, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      const { data } = await initiateAXIOS.get(initiateScheduleAPI + id, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      let screenData = [];
      data.screens.map((item) => screenData.push(new Viewer(item)));
      let screenGroupData = [];
      data.screenGroups.map((item) => screenGroupData.push(new Group(item)));

      dispatch({
        type: SET_ANNOUNCEMENT_DETAIL,
        payload: new Schedule({
          ...data,
          screens: screenData,
          screenGroups: screenGroupData,
        }),
      });
      dispatch({ type: SET_SUCCESS_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_ANNOUNCEMENT_PAGE,
        payload: "The announcement is updated.",
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_ANNOUNCEMENT_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_ANNOUNCEMENT_DETAIL, payload: false });
    }
  };
};

export const assignAnnouncementToGroup = ({ id, assignData }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_ANNOUNCEMENT_DETAIL, payload: true });

    try {
      await initiateAXIOS.patch(
        initiateScheduleAPI + id + "/screen-group",
        { add: assignData },
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({
        type: SET_ANNOUNCEMENT_DETAIL,
        payload: new Schedule(await fetchScheduleById(id, access_token)),
      });
      dispatch({ type: SET_SUCCESS_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_ANNOUNCEMENT_PAGE,
        payload: "Groups have been assigned to the announcement.",
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_ANNOUNCEMENT_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_ANNOUNCEMENT_DETAIL, payload: false });
    }
  };
};

export const removeGroupFromAnnouncement = ({ id, remove }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_ANNOUNCEMENT_DETAIL, payload: true });

    try {
      await initiateAXIOS.patch(
        initiateScheduleAPI + id + "/screen-group",
        { remove },
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({
        type: SET_ANNOUNCEMENT_DETAIL,
        payload: new Schedule(await fetchScheduleById(id, access_token)),
      });
      dispatch({ type: SET_SUCCESS_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_ANNOUNCEMENT_PAGE,
        payload: "The group has been removed from the announcement.",
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_ANNOUNCEMENT_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_ANNOUNCEMENT_DETAIL, payload: false });
    }
  };
};

export const assignAnnouncementToViewer = ({ id, assignData }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_ANNOUNCEMENT_DETAIL, payload: true });

    try {
      await initiateAXIOS.patch(
        initiateScheduleAPI + id + "/screen",
        { add: assignData },
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({
        type: SET_ANNOUNCEMENT_DETAIL,
        payload: new Schedule(await fetchScheduleById(id, access_token)),
      });
      dispatch({ type: SET_SUCCESS_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_ANNOUNCEMENT_PAGE,
        payload: "Viewers have been assigned to the announcement.",
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_ANNOUNCEMENT_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_ANNOUNCEMENT_DETAIL, payload: false });
    }
  };
};

export const removeViewerFromAnnouncement = ({ id, remove }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_ANNOUNCEMENT, payload: true });
    dispatch({ type: SET_LOADING_ANNOUNCEMENT_DETAIL, payload: true });

    try {
      await initiateAXIOS.patch(
        initiateScheduleAPI + id + "/screen",
        { remove },
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({
        type: SET_ANNOUNCEMENT_DETAIL,
        payload: new Schedule(await fetchScheduleById(id, access_token)),
      });
      dispatch({ type: SET_SUCCESS_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_ANNOUNCEMENT_PAGE,
        payload: "The viewer has been removed from the announcement.",
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_ANNOUNCEMENT_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_ANNOUNCEMENT_DETAIL, payload: false });
    }
  };
};

export const deleteAnnouncement = (id) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_ANNOUNCEMENT_DETAIL, payload: true });

    try {
      await initiateAXIOS.delete(initiateScheduleAPI + id, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      dispatch({ type: SET_DELETE_ANNOUNCEMENT, payload: id });

      dispatch({ type: SET_SUCCESS_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_ANNOUNCEMENT_PAGE,
        payload: "The announcement is deleted.",
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_ANNOUNCEMENT_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_ANNOUNCEMENT_DETAIL, payload: false });
    }
  };
};
