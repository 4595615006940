import React, { useState, useEffect } from "react";
import { mapData } from "./mapconfig";
import { Box, CircularProgress, Tooltip, Grid } from "@mui/material";
import GradientAnimation from "./GradientAnimation";
import { TypographyNormal, TypographyLarge } from "../../../customComponent";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
const varStyle = {
  colorScheme: {
    active: "#D32F2F" /* red */,
    inactive: "#189000" /* green */,
    // inactive: "#1D4ED8", /* blue */
    none: "#ffffff" /* white */,
  },
  pointer: {
    cursor: "pointer",
  },
};

const Image = styled("img")(() => ({
  display: "block",
  marginLeft: "auto",
  marginRight: "auto",
  width: "2vw",
  height: "2vw",
}));

const MapComponents = ({
  platformData,
  trackData,
  platformChangeDisruption,
  trackChangeDisruption,
  textData,
  loadingText,
}) => {
  const { loadingSMPDisruption } = useSelector((state) => state.smp);

  const [text, setText] = useState("");
  useEffect(() => {
    setText(textData[0]);
  }, [textData]);

  const findDisruption = (id, array) => {
    const results = array.find((item) => item.id === parseInt(id));
    return results;
  };

  return (
    <>
      {loadingSMPDisruption ? (
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          height={500}
        >
          <CircularProgress size={22} thickness={3} sx={{ color: "#1D4ED8" }} />
          <TypographyNormal
            sx={{ color: "#1D4ED8", marginTop: "15px", fontWeight: 300 }}
          >
            Loading Map...
          </TypographyNormal>
        </Box>
      ) : (
        <>
          <Grid
            container
            sx={{
              position: "relative",
              top: "9%",
            }}
            flexWrap="nowrap"
            alignItems="center"
            justifyContent="center"
            spacing="8px"
          >
            {loadingText ? (
              <Box sx={{ width: "500px", textAlign: "center" }}>
                <CircularProgress />
              </Box>
            ) : text?.includes("No train") ? (
              <>
                <Grid item>
                  <Image src="/assets/disruption.bmp" alt="Disruption Logo" />
                </Grid>
                <Grid item>
                  <TypographyLarge
                    sx={{
                      fontSize: "calc(1vw + 1vh)",
                      lineHeight: "100%",
                      fontWeight: "bold",
                      color: "#D32F2F",
                    }}
                  >
                    {text}
                  </TypographyLarge>
                </Grid>
              </>
            ) : (
              <Grid item>
                <TypographyLarge
                  sx={{
                    fontSize: "calc(1vw + 1vh)",
                    lineHeight: "100%",
                    fontWeight: "bold",
                    color: "#D32F2F",
                  }}
                >
                  {text}
                </TypographyLarge>
              </Grid>
            )}
          </Grid>
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 1052 547"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <GradientAnimation
              id="gradient-vertical"
              rotation="90"
              color={varStyle.colorScheme}
              reverse={false}
            />
            <GradientAnimation
              id="gradient-vertical-reverse"
              rotation="90"
              color={varStyle.colorScheme}
              reverse={true}
            />
            <GradientAnimation
              id="gradient-horizontal"
              rotation="0"
              color={varStyle.colorScheme}
              reverse={false}
            />
            <GradientAnimation
              id="gradient-horizontal-reverse"
              rotation="0"
              color={varStyle.colorScheme}
              reverse={true}
            />
            {mapData.map((data) =>
              data.type === "platform" ? (
                <PlatformComponent
                  data={data}
                  disruption={findDisruption(data.id, platformData)}
                  handleSubmitDisruption={platformChangeDisruption}
                  key={data.type + "_" + data.id}
                />
              ) : (
                <TrackComponent
                  data={data}
                  disruption={findDisruption(data.id, trackData)}
                  handleSubmitDisruption={trackChangeDisruption}
                  key={data.type + "_" + data.id}
                />
              )
            )}
          </svg>
        </>
      )}
    </>
  );
};

const PlatformComponent = ({ data, disruption, handleSubmitDisruption }) => {
  const mapData = data.data;
  const isDisrupted = disruption?.isDisrupted;

  return (
    <Tooltip title={data.name} placement="top" arrow disableInteractive>
      <g
        id={data.id}
        style={varStyle.pointer}
        onClick={() => {
          handleSubmitDisruption(disruption);
        }}
      >
        <path
          d={mapData.shape}
          fill={
            isDisrupted
              ? varStyle.colorScheme.active
              : varStyle.colorScheme.inactive
          }
        />
        <text
          id="text"
          fill="white"
          style={{ whiteSpace: "pre" }}
          fontFamily="Inter"
          fontSize="12"
          fontWeight="800"
          letterSpacing="0.1em"
        >
          <tspan x={mapData.text?.coor?.x} y={mapData.text?.coor?.y}>
            {mapData.text?.name}
          </tspan>
        </text>
      </g>
    </Tooltip>
  );
};

const TrackComponent = ({ data, disruption, handleSubmitDisruption }) => {
  const mapData = data.data;
  const isDisrupted = disruption?.isDisrupted;
  return (
    <Tooltip title={data.name} placement="top" arrow disableInteractive>
      <g id={data.id}>
        <path
          // className={classes.animated}
          id="dot"
          fillRule="evenodd"
          clipRule="evenodd"
          d={mapData.shape}
          fill={
            isDisrupted
              ? varStyle.colorScheme.active
              : `url(#gradient-${mapData.orientation.type})`
          }
          stroke={
            isDisrupted
              ? varStyle.colorScheme.active
              : varStyle.colorScheme.inactive
          }
        />
        <rect
          style={varStyle.pointer}
          onClick={() => {
            handleSubmitDisruption(disruption);
          }}
          id="clickable"
          x={mapData.clickable.x}
          y={mapData.clickable.y}
          width={mapData.clickable.width}
          height={mapData.clickable.height}
          rx={mapData.clickable.rx}
          transform={mapData.clickable.transform}
          fill="#00FFE0"
          fillOpacity="0"
        />
      </g>
    </Tooltip>
  );
};

export default MapComponents;
