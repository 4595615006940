import React from "react";

const DashboardIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.85713 11.429C1.27918 11.429 0 12.7082 0 14.2861V17.1432C0 18.7212 1.27918 20.0004 2.85713 20.0004H5.71425C7.2922 20.0004 8.57138 18.7212 8.57138 17.1432V14.2861C8.57138 12.7082 7.2922 11.429 5.71425 11.429H2.85713Z"
        fill="#FFBD80"
      />
      <path
        d="M11.4285 2.85761C11.4285 1.27967 12.7077 0.000488281 14.2856 0.000488281H17.1428C18.7207 0.000488281 19.9999 1.27967 19.9999 2.85761V5.71474C19.9999 7.29269 18.7207 8.57187 17.1428 8.57187H14.2856C12.7077 8.57187 11.4285 7.29269 11.4285 5.71474V2.85761Z"
        fill="#FFBD80"
      />
      <path
        d="M11.4287 14.2853C11.4287 12.7074 12.7079 11.4282 14.2858 11.4282H17.143C18.7209 11.4282 20.0001 12.7074 20.0001 14.2853V17.1425C20.0001 18.7204 18.7209 19.9996 17.143 19.9996H14.2858C12.7079 19.9996 11.4287 18.7204 11.4287 17.1425V14.2853Z"
        fill="#FBE4D7"
      />
      <path
        d="M0 14.2853C0 12.7074 1.27918 11.4282 2.85713 11.4282H5.71425C7.2922 11.4282 8.57138 12.7074 8.57138 14.2853V17.1425C8.57138 18.7204 7.2922 19.9996 5.71425 19.9996H2.85713C1.27918 19.9996 0 18.7204 0 17.1425V14.2853Z"
        fill="#F55858"
      />
      <path
        d="M2.85713 0C1.27918 0 0 1.27918 0 2.85713V5.71425C0 7.2922 1.27918 8.57138 2.85713 8.57138H5.71425C7.2922 8.57138 8.57138 7.2922 8.57138 5.71425V2.85713C8.57138 1.27918 7.2922 0 5.71425 0H2.85713Z"
        fill="#00D4FF"
      />
    </svg>
  );
};

export default DashboardIcon;
