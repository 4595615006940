import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { createUserGroup } from "../../../store/actions/userAction";
import {
  SET_ERROR_MESSAGE_USER_PAGE,
  SET_ERROR_PROMPT_USER_PAGE,
} from "../../../store/actionTypes/userActionType";
import { API_LIMIT, initiateOrganizationAPI } from "../../../api";
import { initiateAXIOS } from "../../../config/axios";
import Role from "../../../helpers/roles";
import { decrypt } from "../../../helpers/crypto";
import { TypographyLarge } from "../../customComponent";

const CreateUserGroup = () => {
  const { loggedUser } = useSelector((state) => state.auth);
  const decryptedLoggedUser = decrypt(loggedUser);
  const userOrg = decryptedLoggedUser.organization.id;
  const userRole = decryptedLoggedUser.type;

  const dispatch = useDispatch();

  const [organizations, setOrganizations] = useState([]);
  const [selectOrg, setSelectOrg] = useState("");
  const [groupName, setGroupName] = useState("");
  const [permission, setPermission] = useState({
    asset: {
      read: false,
      create: false,
      update: false,
      delete: false,
      approve: false,
    },
    auditLog: {
      read: false,
      create: false,
      update: false,
      delete: false,
    },
    content: {
      read: false,
      create: false,
      update: false,
      delete: false,
    },
    schedule: {
      read: false,
      create: false,
      update: false,
      delete: false,
      activate: false,
      approve: false,
    },
    screen: {
      read: false,
      create: false,
      update: false,
      delete: false,
    },
    screenGroup: {
      read: false,
      create: false,
      update: false,
      delete: false,
    },
    user: {
      read: false,
      create: false,
      update: false,
      delete: false,
    },
    userGroup: {
      read: false,
      create: false,
      update: false,
      delete: false,
    },
  });

  const [open, setOpen] = useState(false);

  const getOrganizations = async (query) => {
    const access_token = sessionStorage.getItem("access_token");
    try {
      const { data } = await initiateAXIOS.get(
        initiateOrganizationAPI + `?limit=${API_LIMIT}` + (query ?? ""),
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      setOrganizations(data.items);
    } catch (error) {
      const errorMessage = error.response.data.message;
      dispatch({ type: SET_ERROR_PROMPT_USER_PAGE, payload: true });
      dispatch({
        type: SET_ERROR_MESSAGE_USER_PAGE,
        payload: errorMessage,
      });
    }
  };

  useEffect(() => {
    if (open) {
      if (userRole === Role.Eins) {
        getOrganizations();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
    setGroupName("");
    setSelectOrg("");
    handleChangeAll(false);
  };

  const handleGroupName = (e) => {
    setGroupName(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      name: groupName,
      permission,
      organizationId: selectOrg === "" ? +userOrg : +selectOrg,
    };
    dispatch(createUserGroup(payload));

    handleClickClose();
  };

  const handleChangePermission = (e, menu, perm) => {
    setPermission({
      ...permission,
      [menu]: { ...permission[menu], [perm]: e.target.checked },
    });
  };

  const handleChangeAll = (val) => {
    let newObj = {};
    Object.keys(permission).forEach((m) => {
      Object.keys(permission[m]).forEach((p) => {
        newObj = { ...newObj, [m]: { ...newObj[m], [p]: val } };
      });
    });
    setPermission(newObj);
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        startIcon={<AddIcon />}
      >
        Create New Group
      </Button>

      <Dialog
        maxWidth="md"
        fullWidth
        open={open}
        keepMounted
        onClose={handleClickClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Box component="form" onSubmit={handleSubmit}>
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            Create New User Group
            <IconButton onClick={handleClickClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <Box sx={{ mb: 2 }}>
              <InputLabel>Group Name</InputLabel>
              <TextField
                fullWidth
                size="small"
                margin="dense"
                name="groupName"
                value={groupName}
                onChange={handleGroupName}
                placeholder="Enter group name"
                required
              />
            </Box>

            {userRole === Role.Eins && (
              <Box>
                <InputLabel>Organization</InputLabel>

                <TextField
                  select
                  size="small"
                  margin="dense"
                  fullWidth
                  required
                  SelectProps={{
                    native: true,
                  }}
                  onChange={(e) => setSelectOrg(e.target.value)}
                  value={selectOrg}
                >
                  <option value="">Select Organization</option>
                  {organizations?.map((organization) => (
                    <option key={organization.id} value={organization.id}>
                      {organization.name}
                    </option>
                  ))}
                </TextField>
              </Box>
            )}

            <Box sx={{ mt: 2 }}>
              <Box
                sx={{ mb: 1 }}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <InputLabel>Permissions</InputLabel>
                <Box>
                  <Button
                    sx={{ mr: "4px" }}
                    onClick={() => handleChangeAll(true)}
                    variant="contained"
                    size="small"
                  >
                    Check All
                  </Button>
                  <Button
                    onClick={() => handleChangeAll(false)}
                    variant="contained"
                    size="small"
                  >
                    Uncheck All
                  </Button>
                </Box>
              </Box>
              <Grid container>
                {Object.keys(permission).map((key) => (
                  <Grid item md={3} sx={{ mb: 1 }}>
                    <TypographyLarge
                      sx={{
                        fontWeight: 600,
                        color: "#3e3e3e",
                        textTransform: "capitalize",
                      }}
                    >
                      {key.replace(/([A-Z])/g, " $1").trim()}
                    </TypographyLarge>
                    <Box display={"flex"} flexDirection={"column"}>
                      {Object.keys(permission[key]).map((perm) => (
                        // <Box>{perm === "read" ? "view" : perm}</Box>

                        <FormControlLabel
                          key={key + perm}
                          control={<Checkbox size="small" />}
                          checked={permission[key][perm]}
                          onChange={(e) => {
                            handleChangePermission(e, key, perm);
                          }}
                          label={
                            perm === "read"
                              ? "view"
                              : perm === "update"
                              ? "edit"
                              : perm
                          }
                        />
                      ))}
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClickClose} variant="outlined" fullWidth>
              Cancel
            </Button>
            <Button type="submit" variant="contained" fullWidth>
              Create
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default CreateUserGroup;
