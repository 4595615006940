import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Alert, AlertTitle } from "@mui/material";

import { SET_ERROR_PROMPT_ORGANIZATION_PAGE } from "../../store/actionTypes/organizationActionType";

const OrganizationErrorPrompt = () => {
  const dispatch = useDispatch();

  const {
    isErrorPromptOrganizationPage: isError,
    errorMessageOrganizationPageL: errorMessage,
  } = useSelector((state) => state.organization);

  const handleClose = (event, reason) => {
    dispatch({ type: SET_ERROR_PROMPT_ORGANIZATION_PAGE, payload: false });
  };

  return (
    isError && (
      <Alert
        sx={{ my: 2 }}
        severity="error"
        variant="filled"
        onClose={handleClose}
      >
        <AlertTitle>Something went wrong!</AlertTitle>
        {errorMessage}
      </Alert>
    )
  );
};

export default OrganizationErrorPrompt;
