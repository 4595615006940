import React from "react";
import { colorStyling } from "../../helpers/color";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  CircularProgress,
} from "@mui/material";
import { TypographyLarge, TypographyNormal } from "../customComponent";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AssignedComponentItem from "./AssignedComponentItem";

const AssignedComponentList = ({ type, listData, loading, sourceData }) => {
  return (
    <>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          sx={{ p: 2 }}
        >
          <CircularProgress
            size={20}
            thickness={3}
            sx={{ color: colorStyling.primary }}
          />
          <TypographyNormal
            sx={{ color: colorStyling.primary, mt: 2, fontWeight: 300 }}
          >
            Loading Data...
          </TypographyNormal>
        </Box>
      ) : (
        <Box>
          {Array.isArray(listData) && listData?.length < 1 ? (
            <Alert
              severity="info"
              variant="filled"
              sx={{ mt: 1, textTransform: "capitalize" }}
            >
              {"No " + type}
            </Alert>
          ) : (
            <Accordion
              disableGutters
              sx={{ backgroundColor: "transparent" }}
              defaultExpanded
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "#111827" }} />}
              >
                <TypographyLarge
                  sx={{ fontWeight: 600, textTransform: "capitalize" }}
                >
                  {listData?.length > 0
                    ? type + "s (" + listData?.length + ")"
                    : type + " (0)"}
                </TypographyLarge>
              </AccordionSummary>
              <Box sx={{ px: 2, pb: 2 }}>
                <AccordionDetails
                  sx={{
                    maxHeight: "30vh",
                    overflowY: "auto",
                    backgroundColor: "#FFFF",
                    border: "1px solid #DCDEE3",
                    borderRadius: "6px",
                    p: 2,
                  }}
                >
                  {listData?.map((item) => (
                    <AssignedComponentItem
                      key={type + item.id}
                      type={type}
                      item={item}
                      sourceData={sourceData}
                    />
                  ))}
                </AccordionDetails>
              </Box>
            </Accordion>
          )}
        </Box>
      )}
    </>
  );
};

export default AssignedComponentList;
