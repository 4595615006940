export const SET_SCHEDULE = "SET_SCHEDULE";
export const SET_LOADING_SCHEDULE = "SET_LOADING_SCHEDULE";
export const SET_NEW_SCHEDULE = "SET_NEW_SCHEDULE";
export const SET_SCHEDULE_DETAIL = "SET_SCHEDULE_DETAIL";
export const SET_LOADING_SCHEDULE_DETAIL = "SET_LOADING_SCHEDULE_DETAIL";
export const SET_DELETE_SCHEDULE = "SET_DELETE_SCHEDULE";
export const SET_EDIT_SCHEDULE = "SET_EDIT_SCHEDULE";
export const SET_SUCCESS_PROMPT_SCHEDULE_PAGE = "SET_SUCCESS_PROMPT_SCHEDULE_PAGE";
export const SET_ERROR_PROMPT_SCHEDULE_PAGE = "SET_ERROR_PROMPT_SCHEDULE_PAGE";
export const SET_SUCCESS_MESSAGE_SCHEDULE_PAGE = "SET_SUCCESS_MESSAGE_SCHEDULE_PAGE";
export const SET_ERROR_MESSAGE_SCHEDULE_PAGE = "SET_ERROR_MESSAGE_SCHEDULE_PAGE";
