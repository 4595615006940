import React from "react";

import { DialogContent } from "@material-ui/core";
import { Dialog, DialogTitle, Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { dateFormatDDMMYYYY } from "../../helpers/dateFormat";
import { switchColorOnJobHistoryStatus } from "../../helpers/switchColor";
import { CstTypo14W300 } from "../customComponent";

const JobHistoryDetail = ({ open, onClose, job }) => {
  const handleClose = () => {
    onClose(false);
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={handleClose}>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Details
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={1}>
          {job.type !== "CONTENT" &&
            job.type !== "VIEWER" &&
            job.command !== "DELETE" &&
            (job.command !== "BLOCK" || job.command !== "UNBLOCK") && (
              <>
                <Grid item xs={6}>
                  <CstTypo14W300>Schedule Name</CstTypo14W300>
                </Grid>
                <Grid item xs={6}>
                  {job.scheduleName}
                </Grid>

                <Grid item xs={6}>
                  <CstTypo14W300>Viewer Name</CstTypo14W300>
                </Grid>
                <Grid item xs={6}>
                  {job.viewerName}
                </Grid>
              </>
            )}

          <Grid item xs={6}>
            <CstTypo14W300>Status</CstTypo14W300>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              textTransform: "lowercase",
              color: switchColorOnJobHistoryStatus(job.status),
              fontWeight: 600,
            }}
          >
            {job.status}
          </Grid>

          <Grid item xs={6}>
            <CstTypo14W300>Type</CstTypo14W300>
          </Grid>
          <Grid item xs={6}>
            {job.type}
          </Grid>

          <Grid item xs={6}>
            <CstTypo14W300>Command</CstTypo14W300>
          </Grid>
          <Grid item xs={6}>
            {job.command}
          </Grid>

          {job.command === "DELETE" ? (
            <>
              {job.type === "CONTENT" ? (
                <>
                  <Grid item xs={6}>
                    <CstTypo14W300>Content Type</CstTypo14W300>
                  </Grid>
                  <Grid item xs={6}>
                    {job.contentType}
                  </Grid>
                </>
              ) : null}

              <Grid item xs={6}>
                <CstTypo14W300 sx={{ textTransform: "capitalize" }}>
                  {job.type.replace("_", " ").toLowerCase()}
                </CstTypo14W300>
              </Grid>
              <Grid item xs={6}>
                {job.args[1]}
              </Grid>
            </>
          ) : job.type === "CONTENT" ? (
            <>
              <Grid item xs={6}>
                <CstTypo14W300>Content Type</CstTypo14W300>
              </Grid>
              <Grid item xs={6}>
                {job.contentType}
              </Grid>

              <Grid item xs={6}>
                <CstTypo14W300>Content Name</CstTypo14W300>
              </Grid>
              <Grid item xs={6}>
                {job.contentName}
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={6}>
                <CstTypo14W300>
                  {job.type === "VIEWER" && job.command === "BLOCK"
                    ? "Viewer Name"
                    : "URL"}
                </CstTypo14W300>
              </Grid>
              <Grid item xs={6}>
                {job.url === undefined ? "-" : job.url}
              </Grid>
            </>
          )}

          <Grid item xs={6}>
            <CstTypo14W300>Completed Time</CstTypo14W300>
          </Grid>
          <Grid item xs={6}>
            {job.completedAt ? dateFormatDDMMYYYY(job.completedAt) : "-"}
          </Grid>

          <Grid item xs={6}>
            <CstTypo14W300>Created By</CstTypo14W300>
          </Grid>
          <Grid item xs={6}>
            {job.createdByName ?? "-"}
          </Grid>

          <Grid item xs={6}>
            <CstTypo14W300>Created Time</CstTypo14W300>
          </Grid>
          <Grid item xs={6}>
            {job.createdAt ? dateFormatDDMMYYYY(job.createdAt) : "-"}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default JobHistoryDetail;
