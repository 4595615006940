import React, { useState } from "react";

import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { Clear, Done } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RemoveCircleOutlined from "@mui/icons-material/RemoveCircleOutlineOutlined";
import ViewDetailViewer from "../viewDetails/viewer/ViewDetailViewer";
import { red } from "@mui/material/colors";
import { useDispatch } from "react-redux";
import {
  removeScheduleFromGroup,
  removeViewerFromGroup,
} from "../../store/actions/groupAction";
import ViewDetailSchedule from "../viewDetails/schedule/ViewDetailSchedule";
import { useNavigate } from "react-router-dom";
import {
  removeGroupFromSchedule,
  removeViewerFromSchedule,
} from "../../store/actions/scheduleAction";
import {
  removeGroupFromAnnouncement,
  removeViewerFromAnnouncement,
} from "../../store/actions/announcementAction";

const AssignedComponentActionMenu = ({ type, item, sourceData }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClickOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClickClose = (e) => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton sx={{ color: "#111827" }} onClick={handleClickOpen}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClickClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <DetailsMenu item={item} type={type} />
        <RemoveMenu item={item} type={type} sourceData={sourceData} />
      </Menu>
    </>
  );
};

export default AssignedComponentActionMenu;

const DetailsMenu = ({ type, item }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    type === "group" ? navigate("/group/detail/" + item.id) : setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <MenuItem dense onClick={handleOpen}>
        <InfoOutlinedIcon fontSize="small" sx={{ marginRight: "5px" }} />
        See Details
      </MenuItem>

      {type === "viewer" && (
        <ViewDetailViewer
          openDetail={open}
          viewer={item}
          onClose={handleClose}
        />
      )}

      {type === "schedule" && (
        <ViewDetailSchedule
          openDetail={open}
          schedule={item}
          onClose={handleClose}
        />
      )}
    </>
  );
};

const RemoveMenu = ({ type, item, sourceData }) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRemove = () => {
    const payload = { id: sourceData.id };
    let apiCall;
    if (type === "viewer" && sourceData.type === "group") {
      apiCall = removeViewerFromGroup;
    }
    if (type === "schedule" && sourceData.type === "group") {
      apiCall = removeScheduleFromGroup;
    }
    if (type === "group" && sourceData.type === "schedule") {
      apiCall = removeGroupFromSchedule;
    }
    if (type === "viewer" && sourceData.type === "schedule") {
      apiCall = removeViewerFromSchedule;
    }
    if (type === "group" && sourceData.type === "announcement") {
      apiCall = removeGroupFromAnnouncement;
    }
    if (type === "viewer" && sourceData.type === "announcement") {
      apiCall = removeViewerFromAnnouncement;
    }
    payload["remove"] = [{ id: +item.id }];
    dispatch(apiCall(payload));
    handleClose();
  };

  return (
    <>
      <MenuItem sx={{ color: red[600] }} dense onClick={handleOpen}>
        <RemoveCircleOutlined fontSize="small" sx={{ marginRight: "5px" }} />
        Remove from Group
      </MenuItem>

      <Dialog maxWidth="sm" open={open} onClose={handleClose}>
        <DialogContent>
          <DialogContentText
            gutterBottom
            sx={{
              textAlign: "center",
              mb: 2,
            }}
          >
            Are you sure want to remove {type} <strong>{item.name}</strong> from{" "}
            {sourceData.type} <strong>{sourceData.name}?</strong>
          </DialogContentText>

          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <Alert severity="warning" variant="outlined">
              <strong>You won't be able to revert this!</strong>
            </Alert>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            color="error"
            fullWidth
            startIcon={<Clear />}
            onClick={handleClose}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            color="error"
            fullWidth
            startIcon={<Done />}
            onClick={handleRemove}
          >
            Yes, Remove It!
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
