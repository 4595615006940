import React from "react";

const ScheduleGreyIcon = () => {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.19991 0.400024C4.53717 0.400024 3.99991 0.937283 3.99991 1.60002V2.80002H2.79991C1.47442 2.80002 0.399902 3.87454 0.399902 5.20002V17.2C0.399902 18.5255 1.47442 19.6 2.79991 19.6H17.1999C18.5254 19.6 19.5999 18.5255 19.5999 17.2V5.20002C19.5999 3.87454 18.5254 2.80002 17.1999 2.80002H15.9999V1.60002C15.9999 0.937283 15.4627 0.400024 14.7999 0.400024C14.1372 0.400024 13.5999 0.937283 13.5999 1.60002V2.80002H6.39991V1.60002C6.39991 0.937283 5.86265 0.400024 5.19991 0.400024ZM5.19991 6.40002C4.53717 6.40002 3.99991 6.93728 3.99991 7.60002C3.99991 8.26276 4.53717 8.80002 5.19991 8.80002H14.7999C15.4627 8.80002 15.9999 8.26276 15.9999 7.60002C15.9999 6.93728 15.4627 6.40002 14.7999 6.40002H5.19991Z"
          fill="#9CA3AF"
        />
      </svg>
    </>
  );
};

export default ScheduleGreyIcon;
