import {
  SET_DELETE_SCHEDULE,
  SET_EDIT_SCHEDULE,
  SET_LOADING_SCHEDULE,
  SET_LOADING_SCHEDULE_DETAIL,
  SET_NEW_SCHEDULE,
  SET_SCHEDULE,
  SET_SCHEDULE_DETAIL,
  SET_SUCCESS_MESSAGE_SCHEDULE_PAGE,
  SET_SUCCESS_PROMPT_SCHEDULE_PAGE,
  SET_ERROR_PROMPT_SCHEDULE_PAGE,
  SET_ERROR_MESSAGE_SCHEDULE_PAGE,
} from "../actionTypes/scheduleActionType";

const initialState = {
  loadingListOfSchedules: false,
  schedules: [],
  schedule: {},
  loadingScheduleDetail: false,
  isSuccessPromptSchedulePage: false,
  isErrorPromptSchedulePage: false,
  successMessageSchedulePage: "",
  errorMessageSchedulePage: "",
};

export const scheduleReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_SCHEDULE:
      return { ...state, schedules: payload };

    case SET_LOADING_SCHEDULE:
      return { ...state, loadingListOfSchedules: payload };

    case SET_NEW_SCHEDULE:
      const listOfSchedules = state.schedules;
      return { ...state, schedules: [payload, ...listOfSchedules] };

    case SET_SCHEDULE_DETAIL:
      return { ...state, schedule: payload };

    case SET_LOADING_SCHEDULE_DETAIL:
      return { ...state, loadingScheduleDetail: payload };

    case SET_DELETE_SCHEDULE:
      return {
        ...state,
        schedules: state.schedules.filter(
          (schedule) => schedule.id.toString() !== payload.toString()
        ),
      };

    case SET_EDIT_SCHEDULE:
      const data = state.schedules;
      const objIndex = data.findIndex((obj) => obj.id === payload.id);
      data[objIndex] = payload;
      return {
        ...state,
        schedules: [...data],
      };

    case SET_SUCCESS_PROMPT_SCHEDULE_PAGE:
      return { ...state, isSuccessPromptSchedulePage: payload };

    case SET_SUCCESS_MESSAGE_SCHEDULE_PAGE:
      return { ...state, successMessageSchedulePage: payload };

    case SET_ERROR_PROMPT_SCHEDULE_PAGE:
      return { ...state, isErrorPromptSchedulePage: payload };

    case SET_ERROR_MESSAGE_SCHEDULE_PAGE:
      return { ...state, errorMessageSchedulePage: payload };

    default:
      return state;
  }
};
