import React from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";

import {
  CssBaseline,
  Drawer,
  List,
  Box,
  Typography,
  ListItem,
  ListItemText,
} from "@mui/material";
// TODO Job History
// import HistoryIcon from "@mui/icons-material/History";
import GroupsIcon from "@mui/icons-material/Groups";

import {
  DashboardIcon,
  ScheduleIcon,
  ViewerIcon,
  GroupIcon,
  LogoIcon,
  AnnouncementIcon,
  AdminIcon,
  CompanyIcon,
  UserManualIcon,
} from "../icons";
import BackupTableIcon from "@mui/icons-material/BackupTable";

import Navbar from "./Navbar";

import Role from "../helpers/roles";
import { useSelector } from "react-redux";
import { decrypt } from "../helpers/crypto";
import { styled } from "@mui/material/styles";

const drawerWidth = 240;

const StyledListItem = styled(ListItem)(({ theme }) => ({
  borderRadius: "6px",
  "&:hover": {
    backgroundColor: "#183CB2",
    cursor: "pointer",
  },
  marginBottom: theme.spacing(1),
}));

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  fontSize: "0.875rem",
  fontWeight: 400,
  marginLeft: theme.spacing(1.75),
  lineHeight: "1.625rem",
}));

const Sidebar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { loggedUser } = useSelector((state) => state.auth);
  const decryptedLoggedUser = decrypt(loggedUser);
  const userRole = decryptedLoggedUser.type;

  const handlePage = (pageName) => {
    navigate("/" + pageName);
  };

  const itxcastLogo = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <LogoIcon />
      <Typography
        component="h2"
        sx={{
          color: "#FFFF",
          marginLeft: "0.5rem",
          cursor: "pointer",
          fontSize: "2rem",
          fontWeight: 700,
          lineHeight: "2.375rem",
        }}
        onClick={() =>
          userRole === "SUPERADMIN"
            ? navigate("/users")
            : navigate("/dashboard")
        }
      >
        ITX<span style={{ color: "#63D5F9" }}>Cast</span>
      </Typography>
    </Box>
  );

  const link_Dashboard = (
    <Link style={{ textDecoration: "none" }} to="/dashboard">
      <StyledListItem
        button
        dense
        sx={{
          backgroundColor: pathname === "/dashboard" ? "#183CB2" : null,
        }}
      >
        {pathname === "/dashboard" ? (
          <DashboardIcon colorI={"#FFFF"} colorII={"#63D5F9"} />
        ) : (
          <DashboardIcon colorI={"#A7C6EF"} colorII={"#A7C6EF"} />
        )}

        <StyledListItemText
          disableTypography
          sx={{
            color: pathname === "/dashboard" ? "#FFFF" : "#A7C6EF",
          }}
          primary="Dashboard"
        />
      </StyledListItem>
    </Link>
  );

  const link_Group = (
    <Link style={{ textDecoration: "none" }} to="/groups">
      <StyledListItem
        dense
        button
        sx={{
          backgroundColor:
            pathname === "/groups" || pathname.slice(1, 6) === "group"
              ? "#183CB2"
              : null,
        }}
      >
        {pathname === "/groups" || pathname.slice(1, 6) === "group" ? (
          <GroupIcon colorI={"#FFFF"} colorII={"#63D5F9"} />
        ) : (
          <GroupIcon colorI={"#A7C6EF"} colorII={"#A7C6EF"} />
        )}

        <StyledListItemText
          disableTypography
          sx={{
            color:
              pathname === "/groups" ||
              pathname.slice(11, 16) === "group" ||
              pathname.slice(1, 6) === "group"
                ? "#FFFF"
                : "#A7C6EF",
          }}
          primary="Group"
        />
      </StyledListItem>
    </Link>
  );

  const link_Viewers = (
    <Link style={{ textDecoration: "none" }} to="/viewers">
      <StyledListItem
        button
        dense
        style={{
          backgroundColor:
            pathname === "/viewers" || pathname.slice(1, 7) === "viewer"
              ? "#183CB2"
              : null,
        }}
        onClick={() => {
          handlePage("viewers");
        }}
      >
        {pathname === "/viewers" ? (
          <ViewerIcon colorI={"#FFFF"} colorII={"#63D5F9"} />
        ) : (
          <ViewerIcon colorI={"#A7C6EF"} colorII={"#244EBA"} />
        )}

        <StyledListItemText
          disableTypography
          sx={{
            color:
              pathname === "/viewers" || pathname.slice(1, 7) === "viewer"
                ? "#FFFF"
                : "#A7C6EF",
          }}
          primary="Viewer"
        />
      </StyledListItem>
    </Link>
  );

  const link_Schedules = (
    <Link style={{ textDecoration: "none" }} to="/schedules">
      <StyledListItem
        button
        dense
        sx={{
          backgroundColor:
            pathname === "/schedules" || pathname.slice(1, 9) === "schedule"
              ? "#183CB2"
              : null,
          borderRadius: "6px",
        }}
      >
        <ScheduleIcon colorI={"#F55858"} colorII={"#FFBD80"} />

        <StyledListItemText
          disableTypography
          sx={{
            color:
              pathname === "/schedules" || pathname.slice(1, 9) === "schedule"
                ? "#FFFF"
                : "#A7C6EF",
          }}
          primary="Schedule"
        />
      </StyledListItem>
    </Link>
  );

  // TODO when working on the API
  // const link_History = (
  //   <Link style={{ textDecoration: "none" }} to="/jobs/history">
  //     <StyledListItem
  //       button
  //       dense
  //       sx={{
  //         backgroundColor: pathname === "/jobs/history" ? "#183CB2" : null,
  //         borderRadius: "6px",
  //       }}
  //     >
  //       <HistoryIcon sx={{ color: "#F55858" }} />

  //       <StyledListItemText
  //         disableTypography
  //         sx={{
  //           color: pathname === "/jobs/history" ? "#FFFF" : "#A7C6EF",
  //         }}
  //         primary="Job History"
  //       />
  //     </StyledListItem>
  //   </Link>
  // );

  const link_announcements = (
    <StyledListItem
      button
      dense
      onClick={() => handlePage("announcements")}
      sx={{
        backgroundColor:
          pathname === "/announcements" ||
          pathname.slice(1, 13) === "announcement"
            ? "#183CB2"
            : null,
        borderRadius: "6px",
      }}
    >
      {pathname === "/announcements" ? (
        <AnnouncementIcon colorI={"#FFFF"} colorII={"#63D5F9"} />
      ) : (
        <AnnouncementIcon colorI={"#A7C6EF"} colorII={"#A7C6EF"} />
      )}

      <StyledListItemText
        sx={{
          color:
            pathname === "/announcements" ||
            pathname.slice(1, 13) === "announcement"
              ? "#FFFF"
              : "#A7C6EF",
        }}
        primary="Announcement"
      />
    </StyledListItem>
  );

  const link_Users = (
    <StyledListItem
      button
      dense
      onClick={() => handlePage("users")}
      sx={{
        backgroundColor: pathname === "/users" ? "#183CB2" : null,
      }}
    >
      {pathname === "/users" ? (
        <AdminIcon colorI={"#FFFF"} />
      ) : (
        <AdminIcon colorI={"#A7C6EF"} />
      )}

      <StyledListItemText
        sx={{
          color: pathname === "/users" ? "#FFFF" : "#A7C6EF",
        }}
        primary="User"
      />
    </StyledListItem>
  );

  const link_User_Groups = (
    <StyledListItem
      button
      dense
      onClick={() => handlePage("user/groups")}
      sx={{
        backgroundColor: pathname === "/user/groups" ? "#183CB2" : null,
      }}
    >
      <GroupsIcon sx={{ color: "#F55858" }} />

      <StyledListItemText
        sx={{
          color: pathname === "/user/groups" ? "#FFFF" : "#A7C6EF",
        }}
        primary="User Group"
      />
    </StyledListItem>
  );

  const link_Organization = (
    <StyledListItem
      dense
      onClick={() => handlePage("organizations")}
      style={{
        backgroundColor: pathname === "/organizations" ? "#183CB2" : null,
      }}
    >
      {pathname === "/organizations" ? (
        <CompanyIcon colorI={"#FFFF"} />
      ) : (
        <CompanyIcon colorI={"#A7C6EF"} />
      )}

      <StyledListItemText
        sx={{
          color: pathname === "/organizations" ? "#FFFF" : "#A7C6EF",
        }}
        primary="Organization"
      />
    </StyledListItem>
  );

  const link_UserManual = (
    <StyledListItem
      button
      dense
      onClick={() => handlePage("user/manual")}
      sx={{
        backgroundColor: pathname === "/user/manual" ? "#183CB2" : null,
      }}
    >
      <UserManualIcon />
      <StyledListItemText
        sx={{
          color: pathname === "/user/manual" ? "#FFFF" : "#A7C6EF",
        }}
        primary="User Manual"
      />
    </StyledListItem>
  );

  // CMS
  const link_ContentTemplate = (
    <StyledListItem
      button
      dense
      onClick={() => handlePage("cms/content-template")}
      sx={{
        backgroundColor:
          pathname === "/cms/content-template" ||
          pathname === "/cms/content-template/create"
            ? "#183CB2"
            : null,
      }}
    >
      <BackupTableIcon
        fontSize="medium"
        sx={{
          color: "#F55858",
        }}
      />

      <StyledListItemText
        sx={{
          color: pathname === "/cms/content-template" ? "#FFFF" : "#A7C6EF",
        }}
        primary="ContentTemplate"
      />
    </StyledListItem>
  );

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <Navbar />

        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              backgroundColor: "#1E4ED8",
              paddingY: 3,
            },
          }}
          variant="permanent"
          anchor="left"
        >
          {itxcastLogo}

          <Box p={2}>
            <Typography
              component="h6"
              sx={{ color: "#EFF6FF", opacity: 0.45, fontSize: 12 }}
              gutterBottom
            >
              MANAGE
            </Typography>

            <List>
              {(userRole === Role.Admin || userRole === Role.User) && (
                <> {link_Dashboard} </>
              )}

              {(userRole === Role.Admin || userRole === Role.User) && (
                <> {link_Group} </>
              )}

              <> {link_Viewers} </>

              {(userRole === Role.Admin || userRole === Role.User) && (
                <>{link_Schedules}</>
              )}

              {/* TODO Job History */}
              {/* {(userRole === Role.Admin || userRole === Role.User) && ( 
                 <>{link_History}</>
              )} */}

              {userRole === Role.Admin || userRole === Role.User ? (
                <>{link_announcements}</>
              ) : null}

              {userRole === Role.Admin ? <>{link_ContentTemplate}</> : null}

              {(userRole === Role.Admin || userRole === Role.User) && (
                <Box component="hr" border={1} borderColor="#2563EB" mb={2} />
              )}

              {userRole === Role.Admin ||
              userRole === Role.Superadmin ||
              userRole === Role.Eins ? (
                <> {link_Users} </>
              ) : null}

              {userRole === Role.Admin || userRole === Role.Eins ? (
                <> {link_User_Groups} </>
              ) : null}

              {userRole === Role.Eins && <> {link_Organization} </>}

              {(userRole === Role.Admin || userRole === Role.User) && (
                <> {link_UserManual} </>
              )}
            </List>
          </Box>
        </Drawer>
      </Box>
    </>
  );
};

export default Sidebar;
