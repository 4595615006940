import React from "react";
import { useSelector } from "react-redux";
import { Box, CircularProgress, Paper } from "@mui/material";
import { Typography2XLarge, TypographyLarge } from "../customComponent";

const ActiveViewerCard = ({ activeViewersData }) => {
  const { loadingViewersData } = useSelector((state) => state.home);

  if (loadingViewersData)
    return (
      <Paper
        elevation={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "6px",
        }}
      >
        <Box
          p={2}
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <CircularProgress size={22} thickness={3} sx={{ color: "#1D4ED8" }} />
        </Box>
      </Paper>
    );

  return (
    <>
      <Paper
        elevation={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "6px",
        }}
      >
        <Box display="flex" p={2}>
          <Box p={1} alignSelf="center">
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.4001 5.3999C4.40599 5.3999 3.6001 6.20579 3.6001 7.1999C3.6001 8.19401 4.40599 8.9999 5.4001 8.9999V23.3999C5.4001 25.3881 7.01187 26.9999 9.0001 26.9999H13.6545L11.3273 29.3271C10.6244 30.0301 10.6244 31.1697 11.3273 31.8727C12.0302 32.5756 13.1699 32.5756 13.8729 31.8727L18.0001 27.7455L22.1273 31.8727C22.8302 32.5756 23.9699 32.5756 24.6729 31.8727C25.3758 31.1697 25.3758 30.0301 24.6729 29.3271L22.3457 26.9999H27.0001C28.9883 26.9999 30.6001 25.3881 30.6001 23.3999V8.9999C31.5942 8.9999 32.4001 8.19401 32.4001 7.1999C32.4001 6.20579 31.5942 5.3999 30.6001 5.3999H5.4001ZM25.2001 12.5999C25.2001 11.6058 24.3942 10.7999 23.4001 10.7999C22.406 10.7999 21.6001 11.6058 21.6001 12.5999V19.7999C21.6001 20.794 22.406 21.5999 23.4001 21.5999C24.3942 21.5999 25.2001 20.794 25.2001 19.7999V12.5999ZM19.8001 14.3999C19.8001 13.4058 18.9942 12.5999 18.0001 12.5999C17.006 12.5999 16.2001 13.4058 16.2001 14.3999V19.7999C16.2001 20.794 17.006 21.5999 18.0001 21.5999C18.9942 21.5999 19.8001 20.794 19.8001 19.7999V14.3999ZM14.4001 16.1999C14.4001 15.2058 13.5942 14.3999 12.6001 14.3999C11.606 14.3999 10.8001 15.2058 10.8001 16.1999V19.7999C10.8001 20.794 11.606 21.5999 12.6001 21.5999C13.5942 21.5999 14.4001 20.794 14.4001 19.7999V16.1999Z"
                fill="#2563EB"
              />
              <circle cx="29" cy="27" r="5" fill="#34D399" />
            </svg>
          </Box>

          <Box ml={3}>
            <Typography2XLarge>
              {!activeViewersData ? 0 : activeViewersData.length}
            </Typography2XLarge>
            <TypographyLarge sx={{ fontWeight: 400 }}>Active Viewers</TypographyLarge>
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default ActiveViewerCard;
