import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import { initiateAXIOS } from "../../../../config/axios";
import { instanceCMSContentAPI } from "../../../../api";
import { TypographyLarge } from "../../../customComponent";
import ContentViewer from "./ContentViewer";
import LayerItem from "./LayerItem";
import LayerAssetItem from "./LayerAssetItem";
import update from "immutability-helper";

const StyledBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  ":last-child": {
    marginBottom: 0,
  },
}));

const ContentDetails = ({ open, handleClose, data }) => {
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState({});
  const [layers, setLayers] = useState([]);
  const [canvasRes, setCanvasRes] = useState({ width: 0, height: 0 });
  const [ratiodRes, setRatiodRes] = useState({ width: 0, height: 0 });

  const [openContentViewer, setOpenContentViewer] = useState(false);

  const setupCanvas = (data) => {
    const updatedCanvasRes = { width: 0, height: 0 };
    if (data.width < data.height) {
      updatedCanvasRes.width = 360;
      updatedCanvasRes.height = 640;
    } else {
      updatedCanvasRes.width = 640;
      updatedCanvasRes.height = 360;
    }
    
    const ratiodWidth = Math.round(data.width / updatedCanvasRes.width);
    const ratiodHeight = Math.round(data.height / updatedCanvasRes.height);
    setRatiodRes({ width: ratiodWidth, height: ratiodHeight });
    setCanvasRes(updatedCanvasRes);
  };

  const getContent = async () => {
    const access_token = sessionStorage.getItem("access_token");
    const getUrl = `${instanceCMSContentAPI}${data.id}`;
    try {
      const { data } = await initiateAXIOS.get(getUrl, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      setContent(data);
      setupCanvas(data);
      setLayers(data.layers);
      setLoading(false);
    } catch (error) {
      console.error(`getContent-ERR:`, error);
    }
  };

  const resetData = () => {
    const resetRes = { width: 0, height: 0 };
    setCanvasRes(resetRes);
    setRatiodRes(resetRes);
    setContent({});
    setLayers([]);
  };

  useEffect(() => {
    resetData();
    setLoading(true);
    if (data?.id > 0 && open) {
      getContent();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const moveLayerAssets = (layerIndex, updatedAssets) => {
    if (!layers[layerIndex]) {
      console.error(`error:`, `Invalid access to array.`);
      return;
    }

    const updated = update(layers, { [layerIndex]: { layerAssets: { $set: [ ...updatedAssets ] } } });
    setLayers(updated);
  };

  const handleOpenContentViewer = () => {
    setOpenContentViewer(true);
  };

  const handleCloseContentViewer = () => {
    setOpenContentViewer(false);
  };

  let previewResLabel;
  if (content.width > 0 && content.height > 0) {
    previewResLabel = <InputLabel>({ content.width } x { content.height })</InputLabel>;
  }

  return (
    <Dialog open={open} maxWidth="lg" fullWidth onClose={() => handleClose()}>
      <DialogTitle
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        Content Details [{loading ? ". . . . ." : content.name}]
        <IconButton
          onClick={() => handleClose()}
          sx={{
            color: "black",
            backgroundColor: "red",
            ":hover": {
              backgroundColor: "orangered",
              boxShadow: 2,
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{
          minHeight: "60vh",
          backgroundColor: "#f9f9f9",
          px: 5,
          py: 0,
        }}
      >
        <>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{ py: 2 }}
          >
            <Box alignSelf={"start"} flex={1}>
              <StyledBox>
                <InputLabel>Layer</InputLabel>

                {!loading && (
                  <Box sx={{
                    mt: 2,
                    width: "500px",
                  }}>
                    {layers.map((layer, index) => {
                      return (
                        <LayerItem
                          key={layer.id}
                          index={index+1}
                          layer={layer}
                          moveAssets={moveLayerAssets}
                        />
                      )
                    })}
                  </Box>
                )}
              </StyledBox>

              {!loading && (
                <Box sx={{ display: "flex", position: "relative" }}>
                  <Box
                    textAlign={"center"}
                    sx={{
                      width: "100%",
                      pt: 2,
                      px: 2,
                    }}
                  >
                    <Button
                      variant="contained"
                      startIcon={<InfoIcon />}
                      sx={{ boxShadow: 3 }}
                      onClick={() => handleOpenContentViewer()}
                    >
                      {/* TODO */}
                      Show Usage
                    </Button>
                  </Box>
                  
                  {/* TODO : Update order/duration directly (may needed) */}
                  {/* <Box
                    sx={{
                      position: "absolute",
                      right: 0,
                      pt: 2,
                      px: 2,
                    }}
                  >
                    <Button
                      variant="contained"
                      startIcon={<InfoIcon />}
                      sx={{ boxShadow: 3 }}
                      onClick={() => handleOpenContentViewer()}
                    >
                      Update
                    </Button>
                  </Box> */}
                </Box>
              )}
            </Box>

            <Box sx={{
              width: "380px",
              height: "auto",
            }}>
              <Box display={"flex"} flexDirection={"column"}>
                <InputLabel>Preview</InputLabel>
                {previewResLabel}
              </Box>

              {!loading && (<Box
                boxShadow={1}
                sx={{
                  ml: 1,
                  mb: 2,
                  mt: 2,
                  width: canvasRes.width,
                  height: canvasRes.height,
                  position: "relative",
                  backgroundColor: "#e9e9e9",
                }}
              >
                {layers.map((layer, i) => {
                  return (
                    <LayerAssetItem
                      key={i}
                      index={i}
                      layer={layer}
                      ratiodRes={ratiodRes}
                      open={open}
                    />
                  )
                })}
              </Box>)}
            </Box>
          </Box>
                
          {loading && (
            <Box
              sx={{ height: "40vh" }}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <CircularProgress size={20} thickness={4} sx={{ color: "#1D4ED8" }} />
              <TypographyLarge sx={{ color: "#1D4ED8", mt: 2, fontWeight: 400 }}>
                Loading...
              </TypographyLarge>
            </Box>
          )}

          <ContentViewer
            open={openContentViewer}
            handleClose={handleCloseContentViewer}
            data={data}
          />
        </>
      </DialogContent>

      <DialogActions sx={{ mx: 2, pb: 1 }}>
        <Button variant="outlined" onClick={() => handleClose()}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContentDetails;
