import React, { useState } from "react";
import { Box } from "@mui/material";
import ContainersList from "./ContainersList";
import Form from "./Form";

const CreateContent = () => {
  const [resType, setResType] = useState("");

  return (
    <Box sx={{ width: "50%", overflowY: "auto", maxHeight: "80vh" }}>
      <Form resType={resType} setResType={setResType} />
      <ContainersList resType={resType} />
    </Box>
  );
};

export default CreateContent;
