import React from "react";
import { useDispatch } from "react-redux";

import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";

import { deleteUser } from "../../store/actions/userAction";

const DeleteUser = ({ open, onClose, user }) => {
  const dispatch = useDispatch();

  const handleClickClose = () => {
    onClose(false);
  };

  const handleDelete = (id) => {
    dispatch(deleteUser(id));
    onClose(false);
  };

  const stopPropagationForTab = (event) => {
    if (event.key === "Tab") event.stopPropagation();

    if (event.key === "e") event.stopPropagation();
  };

  return (
    <>
      <Dialog
        maxWidth="sm"
        open={open}
        onKeyDown={stopPropagationForTab}
        onClose={handleClickClose}
      >
        <DialogContent>
          <DialogContentText sx={{ textAlign: "center", mb: 2 }}>
            Are you sure want to delete the <strong>{user.name}?</strong>
          </DialogContentText>

          <Box sx={{ textAlign: "center" }}>
            <Alert severity="warning" variant="outlined">
              <strong>You won't be able to revert this!</strong>
            </Alert>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            color="error"
            variant="outlined"
            fullWidth
            startIcon={<ClearIcon />}
            onClick={handleClickClose}
          >
            Cancel
          </Button>

          <Button
            color="error"
            variant="contained"
            fullWidth
            startIcon={<DoneIcon />}
            onClick={() => handleDelete(user.id)}
          >
            Yes, Delete It!
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteUser;
