import React from "react";
import { Box } from "@mui/material";
import dayjs from "dayjs";
import "./templateStyling.css";

const disruptionImages = require.context("./assets", true);

/**
 * Component for showing Disruption or Affected Preview based on type and order
 * @param {Array} disruptions Disruption Data Array Object
 * @param {String} type Type of Disruption "DISRUPTION|AFFECTED"
 * @param {Int16Array} order Order of the disruption data shown
 */
const PreviewTemplate = ({ disruptions, type, order }) => {
  return (
    <Box
      sx={{
        border: "1vw solid #ffd342",
        width: "100%",
        height: "100%",
      }}
    >
      {type === "DISRUPTION" ? (
        <DisruptionTemplate data={disruptions[order]} />
      ) : (
        <AffectedTemplate data={disruptions[order]} />
      )}
    </Box>
  );
};

/** Object Containing SMRT Line Color for background and Contrast Color for Text */
const lineColorConfig = {
  CAL: { backgroundColor: "#009c4b", color: "#000000" },
  CCL: { backgroundColor: "#f99c25", color: "#000000" },
  CCLE: { backgroundColor: "#f99c25", color: "#000000" },
  DTL: { backgroundColor: "#005bc0", color: "#ffffff" },
  EWL: { backgroundColor: "#009c4b", color: "#000000" },
  NEL: { backgroundColor: "#8e3494", color: "#ffffff" },
  NSL: { backgroundColor: "#e02725", color: "#ffffff" },
  TEL: { backgroundColor: "#bb5117", color: "#ffffff" },
  LRT: { backgroundColor: "#dcdcdc", color: "#000000" },
};

/** Get Direction Image name based on Direction and Station order   */
const getDirectionImage = (dir) => {
  if (dir.name === "BOTH") return "whole-line-down";

  if (dir.name === "ASC") return "one-way-right-direction";

  if (dir.name === "DESC") return "one-way-left-direction";
};

/** Generate Disruption Template Component */
const DisruptionTemplate = ({ data }) => {
  return (
    <Box className="disruption__content-container">
      <Box className="disruption__service-affected_container">
        <Box className="disruption__top-text-container">
          MRT Service Affected
        </Box>
        <Box
          className="disruption__line-text-container"
          sx={
            lineColorConfig[
              data.line.code.includes("LRT") ? "LRT" : data.line.code
            ]
          }
        >
          {data.line.name}
        </Box>
        {data.betweenFrom.abbr !== "ALL" && data.betweenTo.abbr !== "ALL" && (
          <Box className="disruption__line-image-container">
            <img
              src={disruptionImages(
                "./line/" +
                  data.line.code +
                  "/" +
                  data.betweenFrom.abbr +
                  "-" +
                  data.betweenFrom.code +
                  "-L.png"
              )}
              alt={data.betweenFrom.name}
            />
            <img
              style={{ width: "24%" }}
              src={disruptionImages(
                "./direction/" + getDirectionImage(data.direction) + ".png"
              )}
              alt={getDirectionImage(data.direction)}
            />
            <img
              src={disruptionImages(
                "./line/" +
                  data.line.code +
                  "/" +
                  data.betweenTo.abbr +
                  "-" +
                  data.betweenTo.code +
                  "-L.png"
              )}
              alt={data.betweenTo.name}
            />
          </Box>
        )}
      </Box>
      <Box className="disruption__time-container">
        {data.isMessageHidden ? null : (
          <>
            <Box className="disruption__time-info-text">
              {data.type === "DISRUPTION"
                ? "Train service is expected to resume at :"
                : "Estimated additional traveling time (minutes) :"}
            </Box>
            <Box className="disruption__time-text">
              {data.type === "DISRUPTION"
                ? dayjs(data.resumeTime).format("hh:mm A")
                : data.delayTime + " min"}
            </Box>
          </>
        )}
      </Box>
      <Box className="bottom-text-container">
        We apologise for any inconvenience caused.
      </Box>
    </Box>
  );
};

/** Generate Affected Stations Template Component */
const AffectedTemplate = ({ data }) => {
  return (
    <Box className="disruption__content-container">
      <Box className="affected__service-affected_container">
        <img
          className="no-service-img"
          src={disruptionImages("./no-service.png")}
          alt={"no-service"}
        />
        <Box className="affected__top-container">
          <Box className="affected__top-text-container">
            MRT Service Affected
          </Box>
          <Box
            className="affected__line-text-container"
            sx={lineColorConfig[data.line.code]}
          >
            {data.line.name}
          </Box>
        </Box>
      </Box>
      <Box className="affected__station-list">
        {data.affectedStations.map((station) => (
          <Box
            className="affected__station-list_item"
            key={station.abbr + "-" + station.code}
          >
            <img
              src={disruptionImages(
                "./line/" +
                  data.line.code +
                  "/" +
                  station.abbr +
                  "-" +
                  station.code +
                  "-L.png"
              )}
              alt={station.name}
            />
          </Box>
        ))}
      </Box>
      <Box className="bottom-text-container">
        We apologise for any inconvenience caused.
      </Box>
    </Box>
  );
};

export default PreviewTemplate;
