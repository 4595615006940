import React from "react";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs, Link } from "@mui/material";

import { TypographyLarge } from "../../customComponent";

const ViewerBreadCrumb = ({ viewer }) => {
  return (
    <Breadcrumbs
      separator={<NavigateNextIcon sx={{ color: "#1C4ED8" }} fontSize="small" />}
    >
      <Link underline="hover" href="/viewers">
        <TypographyLarge sx={{ color: "#1C4ED8", cursor: "pointer", fontWeight: 400 }}>
          All Viewers
        </TypographyLarge>
      </Link>
      <Link underline="always">
        <TypographyLarge sx={{ color: "#1C4ED8", cursor: "pointer" }}>
          {viewer.name}
        </TypographyLarge>
      </Link>
    </Breadcrumbs>
  );
};

export default ViewerBreadCrumb;
