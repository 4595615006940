export default class Announcement {
  constructor(payload) {
    this.id = payload.id;
    this.name = payload.name;
    this.startAt = payload.startAt;
    this.endAt = payload.endAt;
    this.nextAt = payload.nextAt;
    this.frequency = payload.frequency;
    this.frequencyName = payload.frequencyName;
    this.status = payload.status;
    this.type = payload.type;
    this.command = payload.command;
    this.args = payload.args;
    this.viewers = payload.viewers;
    this.viewerGroups = payload.viewerGroups;
    this.createdAt = payload.createdAt;
    this.updatedAt = payload.updatedAt;
  }
}
