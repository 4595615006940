import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import ContentItem from "./ContentItem";
import { TypographyLarge } from "../../../customComponent";
import MediaPicker from "../../assetsManager/mediaPicker/MediaPicker";
import { setLayerMedias, setContentLayers, setErrorHandler } from "../../../../store/actions/cmsAction";
import update from 'immutability-helper';
import { v4 as uuidv4 } from "uuid";

const ContainerItem = ({ container, removeContainer }) => {
  const dispatch = useDispatch();
  const { layerMedias, contentLayers } = useSelector((state) => state.cms);

  const [openMediaPicker, setOpenMediaPicker] = useState(false);
  const [contents, setContents] = useState([]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const addContent = (data) => {
    if (!data.id) {
      dispatch(setErrorHandler({ selectedMedia: true }));
      return;
    }

    let updatedMedias = layerMedias[container.id];
    if (!updatedMedias) {
      updatedMedias = [];
    }

    const indexedID = uuidv4();
    updatedMedias.push({ ...data, assetID: data.id });
    dispatch(setLayerMedias({ layerID: container.id, layerMedias: updatedMedias }));
    setContents([...contents, { ...data, id: indexedID, assetID: data.id }]);
    handleCloseMediaPicker();
  };

  const removeContent = (id, index) => {
    let updatedMedias = layerMedias[container.id];
    if (updatedMedias) {
      updatedMedias = updatedMedias.filter((media) => media.id !== id);
      dispatch(setLayerMedias({ layerID: container.id, layerMedias: updatedMedias }));
      setContents([...updatedMedias]);

    } else {
      contents.splice(index, 1);
      setContents([...contents]);
    }
  };

  const handleOpenMediaPicker = () => {
    setOpenMediaPicker(true);
  };

  const handleCloseMediaPicker = () => {
    setOpenMediaPicker(false);
  };

  const handleChangeDim = useCallback(
    (which, event) => {
      const value = parseInt(event.target.value);
      const updated = update(contentLayers, { [container.id]: { dimensions: { $merge: { [which]: value } } } });
      dispatch(setContentLayers({ ...updated }));
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contentLayers],
  );

  function handleDragEnd(event) {
    const { active, over } = event;
    if (active.id !== over.id) {
      setContents((contents) => {
        const oldIndex = contents.findIndex((i) => i.id === active.id);
        const newIndex = contents.findIndex((i) => i.id === over.id);
        const move = arrayMove(contents, oldIndex, newIndex);
        dispatch(setLayerMedias({ layerID: container.id, layerMedias: move }));
        return move;
      });
    }
  }

  function handleIntervalChange(index, value) {
    setContents((contents) => {
      const updated = update(contents, { [index]: { $merge: { interval: +value } } });
      dispatch(setLayerMedias({ layerID: container.id, layerMedias: updated }));
      return updated;
    });
  };

  return (
    <Paper sx={{ p: 2, mb: 2 }} elevation={2}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <TypographyLarge sx={{ mt: 1 }}>Layer {container.indexLayer}</TypographyLarge>

        <IconButton color="error" onClick={() => removeContainer(container.id)}>
          <DeleteIcon />
        </IconButton>
      </Box>

      <Divider sx={{ mt: 1 }} />
      <Accordion sx={{ mt: 1 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Dimensions
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                sx={{ width: "120px" }}
                size="small"
                variant="standard"
                placeholder="100"
                defaultValue={container.dimensions.width}
                onChange={(e) => handleChangeDim("width", e)}
                InputProps={{
                  sx:{ width: "120px", textAlignLast: "right" },
                  startAdornment: (
                    <InputAdornment position="start">Width</InputAdornment>
                  ),
                  endAdornment: (<InputAdornment position="end">px</InputAdornment>),
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                sx={{ width: "120px" }}
                size="small"
                variant="standard"
                placeholder="100"
                defaultValue={container.dimensions.height}
                onChange={(e) => handleChangeDim("height", e)}
                InputProps={{
                  sx:{ width: "120px", textAlignLast: "right" },
                  startAdornment: (
                    <InputAdornment position="start">Height</InputAdornment>
                  ),
                  endAdornment: (<InputAdornment position="end">px</InputAdornment>),
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Box></Box>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={contents.map((i) => i.id)}
          strategy={verticalListSortingStrategy}
        >
          {contents.map((content, index) => (
            <ContentItem
              key={content.id}
              content={content}
              index={index}
              removeContent={removeContent}
              handleIntervalChange={handleIntervalChange}
            />
          ))}
        </SortableContext>
      </DndContext>

      <Box
        sx={{ mt: 2, width: "100%" }}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          sx={{ boxShadow: 3 }}
          onClick={handleOpenMediaPicker}
        >
          Add Content
        </Button>
        <MediaPicker
          open={openMediaPicker}
          handleClose={handleCloseMediaPicker}
          name={`[Layer ${container.indexLayer}]`}
          addContent={addContent}
        />
      </Box>
    </Paper>
  );
};

export default ContainerItem;
