import React, { useEffect, useRef, useState } from "react";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { formattedDate } from "../../helpers/dateFormat";
import { CSVLink } from "react-csv";

const JobHistoryCSV = () => {
  const { jobHistories } = useSelector((state) => state.jobHistory);
  const csvRef = useRef();
  const [csvData, setCsvData] = useState([]);
  const headers = [
    { label: "Job ID", key: "id" },
    { label: "Viewer Name", key: "viewerName" },
    { label: "Schedule Name", key: "scheduleName" },
    { label: "Type", key: "type" },
    { label: "Command", key: "command" },
    { label: "URL", key: "url" },
    { label: "Status", key: "status" },
    { label: "Completed Time", key: "completedAt" },
  ];

  useEffect(() => {
    if (csvData.length !== 0) csvRef.current.link.click();
  }, [csvData]);

  const getData = () => {
    setCsvData(
      jobHistories.map(
        ({
          id,
          viewerName,
          scheduleName,
          type,
          command,
          url,
          status,
          completedAt,
        }) => ({
          id,
          viewerName,
          scheduleName,
          type,
          command,
          url: !url ? "-" : url,
          status: !completedAt && status === "COMPLETED" ? "SKIPPED" : status,
          completedAt: !completedAt ? "-" : formattedDate(completedAt),
        })
      )
    );
  };

  return (
    <>
      <Button variant="contained" onClick={() => getData()}>
        Export CSV
      </Button>
      <CSVLink
        data={csvData}
        headers={headers}
        filename="job_history.csv"
        ref={csvRef}
      ></CSVLink>
    </>
  );
};

export default JobHistoryCSV;
