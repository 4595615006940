import React from "react";

const ViewerIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="6" y="2" width="12" height="20" rx="2" fill="#FFBD80" />
      <circle cx="12" cy="6" r="2" fill="#F55858" />
      <circle cx="12" cy="12" r="2" fill="#244EBA" />
      <circle cx="12" cy="18" r="2" fill="#FBE4D7" />
    </svg>
  );
};

export default ViewerIcon;
