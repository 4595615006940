import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, CircularProgress } from "@mui/material";
import { TypographyNormal } from "../../../customComponent";
import { getAssetData } from "../../../../store/actions/cmsAction";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";

const LayerAssetItem = ({ index, layer, ratiodRes, open }) => {
  const dispatch = useDispatch();
  const { assetData } = useSelector((state) => state.cms);

  const [layerRes, setLayerRes] = useState({ width: 0, height: 0 });
  const [ratiodPos, setRatiodPos] = useState({ x: 0, y: 0 });
  const [assetsPreview, setAssetsPreview] = useState([]);
  const splideOptions = {
    type: "fade",
    rewind: true,
    gap: "1rem",
    resetProgress: false,
    fixedWidth: layerRes.width,
    fixedHeight: layerRes.height,
  };

  const splider = useRef(Splide);
  const assetRefs = useRef([]);
  const autoplayTimer = useRef();
  const handleAutoplay = (interval) => {
    clearTimeout(autoplayTimer.current);
    const timer = setTimeout(() => {
      if (splider.current) {
        splider.current.splide.go(">");
      }
    }, interval);

    autoplayTimer.current = timer;
    return () => clearTimeout(timer);
  };

  useEffect(() => {
    const assetsElm = [];
    if (layer.layerAssets?.length > 0) {
      layer.layerAssets.forEach((asset, i) => {
        assetsElm.push(parseAssetPreview(asset, i));
      });
      setAssetsPreview(assetsElm);
    }

    if (assetsElm.length <= 1) {
      return;
    }

    if (!splider.current.splide) {
      return;
    }

    splider.current.splide.go(0);
    if (assetRefs.current[0]) {
      const first = layer.layerAssets[0];
      const firstAsset = assetData[first.id ?? first.assetId];
      if (firstAsset && firstAsset.type === "VIDEO") {
        const assetEl = assetRefs.current[0];
        assetEl.pause();
        assetEl.currentTime = 0;
        assetEl.play();
      }
    }

    splider.current.splide.off("moved");
    if (Object.keys(assetData).length > 0) {
      splider.current.splide.on("moved", (index, prev, dest) => {
        const previous = layer.layerAssets[prev];
        const previousAsset = assetData[previous.asset?.id ?? previous.assetId];
        if (previousAsset && previousAsset.type === "VIDEO") {
          const previousEl = assetRefs.current[prev];
          previousEl.pause();
        }

        const active = layer.layerAssets[index];
        const activeAsset = assetData[active.asset?.id ?? active.assetId];
        if (activeAsset && activeAsset.type === "VIDEO") {
          const assetEl = assetRefs.current[index];
          assetEl.pause();
          assetEl.currentTime = 0;
          assetEl.play();
        }
        handleAutoplay(active.duration ?? active.interval*1000);
      });
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layer, assetData]);

  useEffect(() => {
    if (!(layer?.layerAssets?.length > 0)) {
      return;
    }

    if (open === false) {
      return;
    }

    dispatch(getAssetData(layer.layerAssets));
    if (layer.layerAssets.length > 1) {
      const firstAsset = layer.layerAssets[0];
      handleAutoplay(firstAsset.duration ?? firstAsset.interval*1000);
    }

    const ratiodPosX = Math.round(layer.x / ratiodRes.width);
    const ratiodPosY = Math.round(layer.y / ratiodRes.height);
    const updatedPosition = { x: ratiodPosX, y: ratiodPosY };
    setRatiodPos(updatedPosition);

    const ratiodResWidth = Math.round(layer.width / ratiodRes.width);
    const ratiodResHeight = Math.round(layer.height / ratiodRes.height);
    const updatedLayerRes = { width: ratiodResWidth, height: ratiodResHeight };
    setLayerRes(updatedLayerRes);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const parseAssetPreview = (asset, i) => {
    let previewEl = null;
    let fileSrc = null;
    const media = { ...assetData[asset.asset.id] };
    if (!media.type) {
      return <Box
        sx={{ height: layerRes.height }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <CircularProgress size={20} thickness={3} sx={{ color: "#1D4ED8" }} />
        <TypographyNormal sx={{ color: "#1D4ED8", mt: 2, fontWeight: 400 }}>
          Loading...
        </TypographyNormal>
      </Box>;
    }

    switch(media.type) {
      case "VIDEO":
        fileSrc = media.vidSrc;
        previewEl = <video
          is="x-muted"
          ref={(el) => assetRefs.current[i] = el}
          draggable={false}
          src={media.url ?? fileSrc}
          alt={media.name}
          controls={true}
          width={layerRes.width}
          height={layerRes.height}
          style={{
            objectFit: "fill",
          }}
        />;
        break;

      case "IMAGE":
      default:
        fileSrc = media.thumbnailUrl ?? media.src;
        previewEl = <img
          ref={(el) => assetRefs.current[i] = el}
          draggable={false}
          src={fileSrc}
          alt={`preview img`}
          width={layerRes.width}
          height={layerRes.height}
          style={{
            objectFit: "fill",
          }}
        />;
        break;
    }
    return previewEl;
  };

  return (
    <Box
      sx={{
        top: ratiodPos.y,
        left: ratiodPos.x,
        width: layerRes.width,
        height: layerRes.height,
        lineHeight: 0,
        position: "absolute",
        backgroundColor: "#d9d9d9",
        border: "1px solid rgba(28, 78, 216, 0.5)",
      }}
    >
      {layer.layerAssets.length === 1 && (
        assetsPreview[0]
      )}
      {layer.layerAssets.length > 1 && (
        <Splide
          ref={splider}
          className="splider"
          options={splideOptions}
        >
            {assetsPreview.map((asset, i) => {
              return (
                <SplideSlide key={i}>
                  {asset}
                </SplideSlide>
              )
            })}
        </Splide>
      )}
    </Box>
  );
};

export default LayerAssetItem;
