import React from "react";
import {
  Box,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const LayerAsset = ({ content, index }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: content.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Box
      ref={setNodeRef}
      style={style}
      key={"content" + index}
      sx={{
        mt: 1,
        borderRadius: "4px",
        ":hover": {
          backgroundColor: "rgba(28, 78, 216, 0.1)",
        },
      }}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Box display={"flex"} alignItems={"center"}>
        <Box sx={{ mx: 1 }}>
          <UnfoldMoreIcon
            sx={{
              verticalAlign: "middle",
              ":hover": { border: "2px solid #1E4ED8", borderRadius: "4px" },
            }}
            {...listeners}
            {...attributes}
          />
        </Box>
        <Box>{index + 1}</Box>
        <Box sx={{ ml: 2 }}>{content.name}</Box>
      </Box>

      <Box display={"flex"}>
        <Tooltip
          arrow
          disableInteractive
          title={"Unable to change the content duration here."}
        >
          <TextField
            sx={{ width: "100px" }}
            size="small"
            placeholder="0"
            defaultValue={content.interval}
            InputProps={{
              readOnly: true,
              endAdornment: <InputAdornment position="end">Sec</InputAdornment>,
            }}
          />
        </Tooltip>
      </Box>
    </Box>
  );
};

export default LayerAsset;
