export const SET_DELETE_CONTENT_UPLOAD = "SET_DELETE_CONTENT_UPLOAD";
export const SET_LOADING_CONTENT_UPLOAD = "SET_LOADING_CONTENT_UPLOAD";
export const SET_CONTENT_UPLOAD = "SET_CONTENT_UPLOAD";
export const SET_CONTENT_HISTORY = "SET_CONTENT_HISTORY";
export const SET_NEW_CONTENT_UPLOAD = "SET_NEW_CONTENT_UPLOAD";
export const SET_EDIT_CONTENT_UPLOAD = "SET_EDIT_CONTENT_UPLOAD";
export const SET_SUCCESS_MESSAGE_CONTENT_UPLOAD_PAGE =
  "SET_SUCCESS_MESSAGE_CONTENT_UPLOAD_PAGE";
export const SET_SUCCESS_PROMPT_CONTENT_UPLOAD_PAGE =
  "SET_SUCCESS_PROMPT_CONTENT_UPLOAD_PAGE";
export const SET_ERROR_PROMPT_CONTENT_UPLOAD_PAGE =
  "SET_ERROR_PROMPT_CONTENT_UPLOAD_PAGE";
export const SET_ERROR_MESSAGE_CONTENT_UPLOAD_PAGE =
  "SET_ERROR_MESSAGE_CONTENT_UPLOAD_PAGE";
export const SET_OPEN_UPLOAD_WINDOW = "SET_OPEN_UPLOAD_WINDOW";
export const SET_UPLOAD_PROGRESS = "SET_UPLOAD_PROGRESS";
export const SET_UPLOAD_FILENAME = "SET_UPLOAD_FILENAME";
export const SET_UPLOAD_STATUS = "SET_UPLOAD_STATUS";
