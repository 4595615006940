import React from "react";

const AnnouncementPurpleIcon = () => {
  return (
    <>
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="24" cy="24" r="24" fill="#F3F0FF" />
        <path
          d="M23.0001 26.1178V31.2402C23.0001 32.2121 22.2122 33 21.2402 33C20.4965 33 19.8331 32.5325 19.5829 31.8321L17.4365 25.6829"
          stroke="#FFBD80"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30 25C31.6569 25 33 23.6569 33 22C33 20.3431 31.6569 19 30 19"
          stroke="#FFBD80"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23 17.8822V25M20.8321 18H19C16.7909 18 15 19.7909 15 22C15 23.6543 16.0043 25.0741 17.4365 25.6829C17.9168 25.887 18.4452 26 19 26L20.8321 26C24.9327 26 28.4569 27.2341 30 29L30 15C28.4569 16.7659 24.9327 18 20.8321 18Z"
          stroke="#F55858"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default AnnouncementPurpleIcon;
