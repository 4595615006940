import React, { useState } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
} from "@mui/material";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import SortIcon from "@mui/icons-material/Sort";
import SearchIcon from "@mui/icons-material/Search";

import { useDispatch, useSelector } from "react-redux";
import {
  filterAnnouncement,
  getAnnouncements,
} from "../../store/actions/announcementAction";
import { ascending, descending } from "../../helpers/sorting";
import { SET_ANNOUNCEMENT } from "../../store/actionTypes/announcementActionType";

const AnnouncementFilter = () => {
  const dispatch = useDispatch();

  const { announcements } = useSelector((state) => state.announcement);

  const [announcementName, setAnnouncementName] = useState("");

  const [status, setStatus] = useState("");

  const [sortBy, setSortBy] = useState("Newest");

  const [anchorEl, setAnchorEl] = useState(null);

  const openSortButton = Boolean(anchorEl);

  const handleChangeSortBy = (event) => {
    const value = event.target.value;
    setSortBy(value);

    let sorted;

    //TODO use API param for sorting when implementing pagination
    switch (value) {
      case "Oldest":
        sorted = ascending(announcements, "id");
        break;

      case "Newest":
        sorted = descending(announcements, "id");
        break;

      case "A-Z":
        sorted = ascending(announcements, "name");
        break;

      case "Z-A":
        sorted = descending(announcements, "name");
        break;

      default:
        break;
    }

    dispatch({ type: SET_ANNOUNCEMENT, payload: sorted });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const resetState = () => {
    setAnnouncementName("");
    setStatus("");
    setSortBy("Newest");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const query = [];

    if (announcementName) query.push(`nameLike=${announcementName}`);

    if (status) query.push(`isActive=${status}`);

    const payload = `&${query.join("&")}`;
    dispatch(filterAnnouncement(payload));
  };

  const handleRefresh = () => {
    resetState();
    dispatch(getAnnouncements());
  };

  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <TextField
          onChange={(e) => setAnnouncementName(e.target.value)}
          value={announcementName}
          fullWidth
          type="Search"
          placeholder="Search announcement..."
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />

        <TextField
          size="small"
          fullWidth
          select
          SelectProps={{ native: true }}
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          sx={{ mx: 2 }}
        >
          <option disabled defaultValue value="">
            Any Status
          </option>
          <option value="true">Announcement On</option>
          <option value="false">Announcement Off</option>
        </TextField>

        <Button variant="contained" type="submit">
          Search
        </Button>

        <IconButton sx={{ ml: 2, color: "#111827" }} onClick={handleClick}>
          <Tooltip title="Sort">
            <SortIcon fontSize="small" />
          </Tooltip>
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openSortButton}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <RadioGroup value={sortBy} onChange={handleChangeSortBy}>
            <MenuItem>
              <FormControlLabel
                disableTypography
                value="Newest"
                control={<Radio size="small" />}
                label="Newest"
                sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
              />
            </MenuItem>

            <MenuItem>
              <FormControlLabel
                disableTypography
                value="Oldest"
                control={<Radio size="small" />}
                label="Oldest"
                sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
              />
            </MenuItem>

            <MenuItem>
              <FormControlLabel
                disableTypography
                value="A-Z"
                control={<Radio size="small" />}
                label="A-Z"
                sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
              />
            </MenuItem>
            <MenuItem>
              <FormControlLabel
                disableTypography
                value="Z-A"
                control={<Radio size="small" />}
                label="Z-A"
                sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
              />
            </MenuItem>
          </RadioGroup>
        </Menu>

        <IconButton sx={{ ml: 1, color: "#111827" }} onClick={handleRefresh}>
          <Tooltip title="Refresh">
            <ClearOutlinedIcon fontSize="small" />
          </Tooltip>
        </IconButton>
      </Box>
    </>
  );
};

export default AnnouncementFilter;
