import {
  Box,
  Container,
  CssBaseline,
  Grid,
  Paper,
  Toolbar,
} from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSchedule } from "../../../store/actions/scheduleAction";

import Sidebar from "../../Sidebar";
import ScheduleBreadCrumb from "./ScheduleBreadCrumb";
import ScheduleInfo from "./ScheduleInfo";
import DeleteSchedule from "./DeleteSchedule";
import EditSchedule from "./EditSchedule";
import ScheduleSuccessPrompt from "../ScheduleSuccessPrompt";
import ScheduleErrorPrompt from "../ScheduleErrorPrompt";
import { decrypt } from "../../../helpers/crypto";
import Role from "../../../helpers/roles";
import AssignedComponentList from "../../assign/AssignedComponentList";
import AssignComponent from "../../assign/AssignComponent";
import ApproveSchedule from "./ApproveSchedule";
import ActivateSchedule from "./ActivateSchedule";

const ScheduleDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getSchedule(id));
  }, [dispatch, id]);

  const { schedule, loadingScheduleDetail: loading } = useSelector(
    (state) => state.schedule
  );

  const { loggedUser } = useSelector((state) => state.auth);
  const decryptedLoggedUser = decrypt(loggedUser);
  const userRole = decryptedLoggedUser.type;
  const userPermission = decryptedLoggedUser.userGroup.permission.schedule;

  return (
    <div style={{ display: "flex" }}>
      <Sidebar />

      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Toolbar />
        <CssBaseline />

        <Container maxWidth="xl">
          <Box sx={{ mb: 2 }}>
            <ScheduleBreadCrumb schedule={schedule} />
          </Box>

          <ScheduleSuccessPrompt />
          <ScheduleErrorPrompt />

          {!loading && (
            <Box
              sx={{ mb: 2 }}
              display={"flex"}
              flexDirection={"row"}
              flexWrap={"nowrap"}
              justifyContent={"space-between"}
            >
              <Box display={"flex"}>
                {userPermission.approve && (
                  <ApproveSchedule schedule={schedule} />
                )}
                {userPermission.activate && (
                  <ActivateSchedule schedule={schedule} />
                )}
              </Box>

              <Box display={"flex"}>
                {userPermission.update && !schedule.isApproved && (
                  <EditSchedule schedule={schedule} />
                )}
                {userPermission.delete && (
                  <DeleteSchedule schedule={schedule} />
                )}
              </Box>
            </Box>
          )}

          <Grid container spacing={2} direction="column">
            <Grid item xs={4}>
              <Paper elevation={2} sx={{ p: 2, borderRadius: "6px", mb: 2 }}>
                <ScheduleInfo schedule={schedule} loading={loading} />
              </Paper>
            </Grid>

            {!loading && userRole === Role.Admin ? (
              <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
                {/* Assign Viewer */}
                <AssignComponent
                  getName={"viewer"}
                  typeName="schedule"
                  typeData={schedule}
                />

                {/* Assign Group */}
                <AssignComponent
                  getName={"group"}
                  typeName="schedule"
                  typeData={schedule}
                />
              </Box>
            ) : null}

            {/* Group List */}
            <Grid item xs={4}>
              <Paper elevation={2} sx={{ borderRadius: "6px" }}>
                <AssignedComponentList
                  key={"group"}
                  type={"group"}
                  listData={schedule.screenGroups}
                  sourceData={{
                    id: schedule.id,
                    name: schedule.name,
                    type: "schedule",
                  }}
                  loading={loading}
                />
              </Paper>
            </Grid>

            {/* Viewer List */}
            <Grid item xs={4}>
              <Paper elevation={2} sx={{ borderRadius: "6px" }}>
                <AssignedComponentList
                  key={"viewer"}
                  type={"viewer"}
                  listData={schedule.screens}
                  sourceData={{
                    id: schedule.id,
                    name: schedule.name,
                    type: "schedule",
                  }}
                  loading={loading}
                />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default ScheduleDetail;
