import React, { useState } from "react";
import { useDispatch } from "react-redux";

import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import { editAnnouncementStatus } from "../../../store/actions/announcementAction";

const ApproveAnnouncement = ({ announcement }) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let status;

    announcement.isApproved ? (status = false) : (status = true);

    const payload = {
      id: announcement.id,
      payload: { isApproved: status },
    };

    dispatch(editAnnouncementStatus(payload));
    setOpen(false);
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        color={announcement.isApproved ? "warning" : "success"}
        variant="contained"
        startIcon={
          announcement.isApproved ? (
            <UnpublishedIcon fontSize="small" />
          ) : (
            <CheckCircleIcon fontSize="small" />
          )
        }
        sx={{ boxShadow: 3 }}
      >
        {announcement.isApproved
          ? "Unapprove Announcement"
          : "Approve Announcement"}
      </Button>

      <Dialog maxWidth="sm" open={open} onClose={handleClickClose}>
        <DialogContent>
          <DialogContentText
            sx={{
              textAlign: "center",
              mb: 2,
            }}
          >
            Are you sure want to{" "}
            <strong>{announcement.isApproved ? "Unapprove" : "Approve"}</strong>{" "}
            announcement <strong>{announcement.name}?</strong>{" "}
          </DialogContentText>

          {announcement.isApproved && (
            <Box>
              <Alert severity="warning" variant="outlined">
                <strong>
                  Unapproving will automatically deactivate the announcement
                </strong>
              </Alert>
            </Box>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            color="error"
            variant="outlined"
            fullWidth
            startIcon={<ClearIcon />}
            onClick={handleClickClose}
          >
            Cancel
          </Button>

          <Button
            color={announcement.isApproved ? "warning" : "primary"}
            variant="contained"
            fullWidth
            startIcon={<DoneIcon />}
            onClick={handleSubmit}
          >
            {announcement.isApproved
              ? "Unapprove Announcement"
              : "Approve Announcement"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ApproveAnnouncement;
