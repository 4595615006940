import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  Box,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Button,
  CircularProgress,
  Alert,
  Checkbox,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import { styled } from "@mui/material/styles";

import { initiateAXIOS } from "../../config/axios";
import { filterDuplicateData } from "../../helpers/filterDuplicateData";
import {
  SET_ERROR_MESSAGE_VIEWER_PAGE,
  SET_ERROR_PROMPT_VIEWER_PAGE,
} from "../../store/actionTypes/viewerActionTypes";
import { TypographyNormal } from "../customComponent";
import { useDebounce } from "../hooks";
import { assignData } from "../../helpers/assignHelper";
import AssignItem from "./AssignItem";
import { colorStyling } from "../../helpers/color";

const InputSearch = styled("input")(() => ({
  outline: "none",
  border: "none",
  fontSize: "16px",
  lineHeight: "26px",
  fontWeight: 400,
  width: "100%",
  marginLeft: "10px",
  fontFamily: "Inter, sans-serif",
}));

const BoxInput = styled(Box)(({ theme }) => ({
  border: "1px solid #DCDEE3",
  display: "flex",
  alignItems: "center",
  paddingLeft: theme.spacing(1.875),
  paddingRight: theme.spacing(1.875),
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
  borderRadius: "6px",
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

/**
 * Assign Component for Viewer, Viewer Group, and Schedule.
 * @param {*} objectParam
 * typeData: Data of var type (announcement type will return announcement data)
 * typeName: Type Name that assigns
 * getName: Type Name that receive assignment
 * e.g Announcement assign viewer => {typeName: "announcement", getName: "viewer"}
 * @returns JSX component
 */
const AssignComponent = ({ typeData, typeName, getName }) => {
  const dispatch = useDispatch();

  const [assignHelper, setAssignHelper] = useState({});

  const [open, setOpen] = useState(false);

  const [selectedItem, setSelectedItem] = useState([]);

  const [loading, setLoading] = useState(false);

  const [listGetData, setListGetData] = useState([]);

  const [search, setSearch] = useState("");

  /**
   * 1 Second delay after input value change to do API fetch
   */
  const debouncedSearchTerm = useDebounce(search, 1000);

  const [isError, setIsError] = useState(false);

  const [openConfirmation, setOpenConfirmation] = useState(false);

  /**
   * Fetching schedule data
   * @param {string} name Search input value
   */
  const getData = async (name) => {
    const access_token = sessionStorage.getItem("access_token");
    setLoading(true);
    try {
      let query = name ? "&nameLike=" + name : "";
      if (getName === "viewer" && typeName === "group")
        query = query + "&isUngrouped=true";

      const { data } = await initiateAXIOS.get(
        assignHelper.fetchingAPI + query,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      let dataList = [];
      const removeDuplicateData = filterDuplicateData(
        data.items,
        typeData[assignHelper.getVarName]
      );

      removeDuplicateData.map((item) =>
        dataList.push(new assignHelper.model(item))
      );
      setListGetData(dataList);
    } catch (e) {
      const errorMessage = e.response.data.message;
      dispatch({ type: SET_ERROR_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({
        type: SET_ERROR_MESSAGE_VIEWER_PAGE,
        payload: errorMessage,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setAssignHelper(assignData(typeName, getName));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeName, getName]);

  /**
   * Inital API call when dialog open
   */
  useEffect(() => {
    if (open) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  /**
   * Run api call after set value (1 second) when search box value change
   */
  useEffect(
    () => {
      if (debouncedSearchTerm) {
        getData(debouncedSearchTerm);
      } else if (debouncedSearchTerm === "" && open) {
        getData();
      } else {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );

  const handleClickClose = () => {
    setOpen(false);
    setListGetData([]);
    setSearch("");
    setSelectedItem([]);
  };

  const handleCloseAlert = () => {
    setIsError(false);
  };

  /**
   * Handling schedule checkbox
   * @param {*} event
   */
  const handleChangeCheckbox = (event) => {
    if (event.target.checked) {
      const payload = { id: +event.target.value };
      selectedItem.push(payload);
    } else {
      const filtered = selectedItem.filter(
        (item) => item.id !== +event.target.value
      );
      setSelectedItem(filtered);
    }
  };

  /**
   * Show error alert when there is no selected schedule
   * Otherwise open confirmation dialog
   */
  const handleConfirmation = () => {
    if (!selectedItem.length) setIsError(true);
    else {
      setIsError(false);
      setOpenConfirmation(true);
    }
  };

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
  };

  const handleSubmit = () => {
    const payload = {
      id: typeData.id,
      assignData: selectedItem,
    };

    dispatch(assignHelper.submitCall(payload));
    handleCloseConfirmation(false);
    handleClickClose();
  };

  return (
    <>
      <Button
        sx={{ ml: 2, boxShadow: 3, textTransform: "capitalize" }}
        onClick={() => setOpen(true)}
        variant="contained"
        startIcon={<AddIcon />}
      >
        Assign New{" " + getName}
      </Button>

      <Dialog maxWidth="sm" open={open} onClose={handleClickClose} fullWidth>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            textTransform: "capitalize",
          }}
        >
          Assign New{" " + getName}
          <IconButton onClick={handleClickClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <Divider />

        <DialogContent>
          <BoxInput>
            <SearchIcon fontSize="medium" sx={{ color: "#9CA3AF" }} />

            <InputSearch
              type="search"
              placeholder={"Search " + getName + "..."}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </BoxInput>

          {isError && (
            <Alert
              severity="error"
              variant="outlined"
              onClose={handleCloseAlert}
            >
              Please assign at least one of {getName} to selected {typeName}
            </Alert>
          )}

          <Box
            sx={{
              maxHeight: 300,
              overflowY: "auto",
              p: 2,
              borderRadius: "6px",
              border: "1px solid #DCDEE3",
              mt: 1,
            }}
          >
            {!loading && !listGetData.length ? (
              <Alert severity="info" variant="outlined">
                <strong>No {getName}s found!</strong> Currently there are no
                available {getName}s or We couldn't find what you're looking for
              </Alert>
            ) : null}

            {loading && (
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
                sx={{ py: 1 }}
              >
                <CircularProgress
                  size={20}
                  thickness={3}
                  sx={{ color: colorStyling.primary }}
                />
                <TypographyNormal
                  sx={{
                    color: colorStyling.primary,
                    marginTop: "15px",
                    fontWeight: 300,
                  }}
                >
                  Loading Data...
                </TypographyNormal>
              </Box>
            )}

            {listGetData?.map((item) => (
              <Box
                key={item.id}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mb: 2,
                }}
              >
                <AssignItem getName={getName} data={item} />

                <Checkbox value={item.id} onChange={handleChangeCheckbox} />
              </Box>
            ))}
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" fullWidth onClick={handleClickClose}>
            Cancel
          </Button>

          <Button onClick={handleConfirmation} variant="contained" fullWidth>
            Confirm
          </Button>
          <Confirmation
            open={openConfirmation}
            onClose={() => handleCloseConfirmation()}
            selectedItem={selectedItem}
            getName={getName}
            handleSubmit={() => handleSubmit()}
            typeData={typeData}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AssignComponent;

const Confirmation = ({
  selectedItem,
  open,
  onClose,
  handleSubmit,
  getName,
  typeData,
}) => {
  const handleCallbackSubmit = (event) => {
    event.preventDefault();

    handleSubmit();
  };
  return (
    <Dialog maxWidth="sm" open={open} onClose={onClose}>
      <DialogContent>
        <DialogContentText sx={{ textAlign: "center" }}>
          You are selecting{" "}
          <strong>
            {selectedItem.length}{" "}
            {selectedItem.length === 1 ? getName : getName + "s"}
          </strong>
          . Are you sure want to assign them to{" "}
          <strong>{typeData.name}?</strong>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          startIcon={<ClearIcon />}
          fullWidth
          variant="outlined"
          onClick={onClose}
        >
          No
        </Button>

        <Button
          onClick={handleCallbackSubmit}
          startIcon={<DoneIcon />}
          fullWidth
          variant="contained"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
