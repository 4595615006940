import React from "react";

const GlobePurpleIcon = () => {
  return (
    <>
      <svg
        width="33"
        height="32"
        viewBox="0 0 33 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="16.6667" cy="16" r="16" fill="#7B61FF" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.933 15.2H13.4895C13.5609 13.9629 13.7964 12.8232 14.1598 11.9058C13.0006 12.6171 12.1667 13.8068 11.933 15.2ZM16.6666 9.59998C13.132 9.59998 10.2666 12.4654 10.2666 16C10.2666 19.5346 13.132 22.4 16.6666 22.4C20.2012 22.4 23.0666 19.5346 23.0666 16C23.0666 12.4654 20.2012 9.59998 16.6666 9.59998ZM16.6666 11.2C16.6058 11.2 16.4809 11.2254 16.2944 11.4094C16.1044 11.5969 15.8966 11.9079 15.7049 12.3553C15.3939 13.0809 15.1665 14.0684 15.0925 15.2H18.2407C18.1667 14.0684 17.9393 13.0809 17.6283 12.3553C17.4366 11.9079 17.2288 11.5969 17.0388 11.4094C16.8523 11.2254 16.7274 11.2 16.6666 11.2ZM19.8437 15.2C19.7723 13.9629 19.5368 12.8232 19.1734 11.9058C20.3326 12.6171 21.1665 13.8068 21.4002 15.2H19.8437ZM18.2407 16.8H15.0925C15.1665 17.9315 15.3939 18.9191 15.7049 19.6446C15.8966 20.0921 16.1044 20.403 16.2944 20.5905C16.4809 20.7746 16.6058 20.8 16.6666 20.8C16.7274 20.8 16.8523 20.7746 17.0388 20.5905C17.2288 20.403 17.4366 20.0921 17.6283 19.6446C17.9393 18.9191 18.1667 17.9315 18.2407 16.8ZM19.1734 20.0941C19.5368 19.1767 19.7723 18.0371 19.8437 16.8H21.4002C21.1665 18.1932 20.3326 19.3829 19.1734 20.0941ZM14.1598 20.0941C13.7964 19.1767 13.5609 18.0371 13.4895 16.8H11.933C12.1667 18.1932 13.0006 19.3829 14.1598 20.0941Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export default GlobePurpleIcon;
