import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import {
  TypographyLarge,
  TypographyNormal,
  TypographySmall,
} from "../../customComponent";
import { makeStyles } from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ViewerBlueIcon } from "../../../icons";
import { initiateAXIOS } from "../../../config/axios";
import { instanceCMSContentAPI } from "../../../api";

import RemoveViewer from "./RemoveViewer";

const useStyles = makeStyles(() => ({
  hoverPanel: {
    "&:hover": {
      backgroundColor: "#DBE9FE",
      cursor: "pointer",
    },
    "&.Mui-disabled": { opacity: 1 },
  },
}));

const DetailViewerList = ({ content, open }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [toggleRemove, setToggleRemove] = useState(false);
  const [viewerData, setViewerData] = useState([]);

  // Effect for API call
  useEffect(
    () => {
      const getViewers = async () => {
        const access_token = sessionStorage.getItem("access_token");
        setLoading(true);
        try {
          const { data } = await initiateAXIOS.get(
            instanceCMSContentAPI + content.id,
            {
              headers: { authorization: `Bearer ${access_token}` },
            }
          );

          setViewerData(data.viewers);
        } catch (error) {
          // setIsError(true);
          // setErrorMessage(error.response.data.message);
          console.log(error);
        } finally {
          setLoading(false);
        }
      };

      if (open) {
        getViewers();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open] // Only call effect if open
  );
  return (
    <>
      <Accordion elevation={4}>
        <AccordionSummary
          className={classes.hoverPanel}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          disabled={viewerData.length === 0 ? true : false}
        >
          <Grid container>
            <Grid item>
              <TypographyLarge>Assigned Viewer</TypographyLarge>
            </Grid>
            <Grid item>
              <TypographyLarge>&nbsp;</TypographyLarge>
            </Grid>
            <Grid item>
              {loading ? (
                <CircularProgress size={"20px"} />
              ) : (
                <TypographyLarge>{`( ${viewerData.length} )`}</TypographyLarge>
              )}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ pb: "6 px" }}>
            <FormControlLabel
              control={<Switch color="error" />}
              value={toggleRemove}
              onChange={() => {
                setToggleRemove(!toggleRemove);
              }}
              label={"Show remove button"}
            />
          </Box>
          {loading ? (
            <Box
              sx={{
                height: "150px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {viewerData?.map((viewer) => (
                <Box
                  key={viewer.id}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mb: 2,
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <ViewerBlueIcon />
                    <Box
                      sx={{ ml: 2, display: "flex", flexDirection: "column" }}
                    >
                      <TypographyNormal sx={{ fontWeight: 600 }}>
                        {viewer.name}
                      </TypographyNormal>
                      {viewer.status !== undefined && (
                        <Box sx={{ display: "flex" }}>
                          <TypographySmall>Status :&nbsp;</TypographySmall>
                          <TypographySmall
                            sx={
                              viewer.status === "ERROR"
                                ? { color: red[700] }
                                : viewer.status === "COMPLETED"
                                ? { color: green[700] }
                                : {}
                            }
                          >
                            {viewer.status}
                          </TypographySmall>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <RemoveViewer
                    content={content}
                    viewer={viewer}
                    toggleRemove={toggleRemove}
                    type={"viewer"}
                  />
                </Box>
              ))}
            </>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default DetailViewerList;
