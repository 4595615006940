import React from "react";

const ViewerBlueIcon = () => {
  return (
    <>
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="24" cy="24" r="24" fill="#EFF6FF" />
        <circle cx="24" cy="18" r="2" fill="#91FCBC" />
        <circle cx="24" cy="24" r="2" fill="#FF8D8D" />
        <circle cx="24" cy="30" r="2" fill="#1D4ED8" />
        <rect x="18" y="14" width="12" height="20" rx="2" fill="#FBE4D7" />
        <circle cx="24" cy="18" r="2" fill="#F55858" />
        <circle cx="24" cy="24" r="2" fill="#244EBA" />
        <circle cx="24" cy="30" r="2" fill="#FFBD80" />
      </svg>
    </>
  );
};

export default ViewerBlueIcon;
