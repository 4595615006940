import React from "react";
import { Box, CssBaseline, Container, Grid, Toolbar } from "@mui/material";
import { Sidebar, TableComponent } from "..";

const UserManual = () => {
  return (
    <div style={{ display: "flex" }}>
      <Sidebar />

      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Toolbar />
        <CssBaseline />
        <Container maxWidth={false}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TableComponent />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default UserManual;
