import {
  SET_LOADING_CMS,
  SET_CMS_DATA,
  SET_ASSET_DATA,
  SET_ADD_MEDIA_DATA,
  SET_REMOVE_MEDIA_DATA,
  SET_ERROR_MESSAGE_CMS_PAGE,
  SET_ERROR_PROMPT_CMS_PAGE,
  SET_LOADING_MEDIA,
  SET_MEDIA_DATA,
  SET_SUCCESS_MESSAGE_CMS_PAGE,
  SET_SUCCESS_PROMPT_CMS_PAGE,
  SET_CONTENT_NAME,
  SET_UPDATE_UPLOAD_STATE,
  SET_UPLOAD_MEDIA_DATA,
  SET_CONTENT_LAYERS,
  SET_CANVAS_RES,
  SET_LAYER_MEDIAS,
  SET_ERROR_HANDLER,
} from "../actionTypes/cmsActionType";
import update from 'immutability-helper';

const initialState = {
  loadingCMS: false,
  cmsData: {
    items: [
      {
        "id": 11,
        "name": "Test Content1",
        "width": 720,
        "height": 1280,
        "organizationId": 1,
        "createdAt": "2024-05-27T00:00:00.000Z",
        "updatedAt": "2024-05-23T00:54:16.403Z",
        "updatedById": 6
      },
      {
        "id": 14,
        "name": "test-02",
        "width": 1080,
        "height": 1920,
        "organizationId": 1,
        "createdAt": "2024-05-27T00:00:00.000Z",
        "updatedAt": "2024-11-27T22:41:31.634Z",
        "updatedById": 1
      },
    ],
    totalItem: 2,
  },
  isSuccessPromptCMSPage: false,
  isErrorPromptCMSPage: false,
  successMessageCMSPage: "",
  errorMessageCMSPage: "",
  media: [
    //// For testing purposes
    {
      id: 1,
      type: "IMAGE",
      name: "Placeholder 1",
      src: "https://placehold.co/300x300",
      size: 1024, // in kb
      organizationId: 1,
      textAsset: null,
      unapprovedTextAsset: null,
    },
    {
      id: 2,
      type: "IMAGE",
      name: "Placeholder 2",
      src: "https://placehold.co/250x150",
      size: 1024, // in kb
      organizationId: 1,
      textAsset: null,
      unapprovedTextAsset: null,
    },
    {
      id: 3,
      type: "VIDEO",
      name: "Placeholder 3",
      src: "https://placehold.co/150x250",
      size: 1024, // in kb
      organizationId: 1,
      textAsset: null,
      unapprovedTextAsset: null,
    },
  ],
  uploadingMedia: [],
  loadingMedia: false,
  contentName: "",
  contentLayers: {},
  canvasRes: { // preview resolution (NOT content)
    width: 360,
    height: 640,
    orientation: "P",
  },
  layerMedias: {},
  errorHandler: {
    contentName: false,
    contentLayers: false,
    resolution: false,
    selectedMedia: false,
  },
  assetData: {},
};

export const cmsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  let reset;
  let updatedMedia;
  switch (type) {
    case SET_LOADING_CMS:
      return { ...state, loadingCMS: payload };

    case SET_CMS_DATA:
      return { ...state, cmsData: payload };

    case SET_MEDIA_DATA:
      return { ...state, media: payload };

    case SET_ADD_MEDIA_DATA:
      const filteredUploadMedia = state.uploadingMedia.filter((el) => {
        return el.index !== payload.index;
      });

      const { index, ...newObj } = payload;
      updatedMedia = [newObj, ...state.media];
      return {
        ...state,
        media: [ ...updatedMedia ],
        uploadingMedia: filteredUploadMedia,
      };

    case SET_REMOVE_MEDIA_DATA:
      updatedMedia = state.media.filter((el) => {
        return el.id !== payload.id;
      });

      return {
        ...state,
        media: [ ...updatedMedia ],
      };

    case SET_UPLOAD_MEDIA_DATA:
      return {
        ...state,
        uploadingMedia: [...state.uploadingMedia].concat(payload),
      };

    case SET_UPDATE_UPLOAD_STATE:
      const objIndex = payload.index;
      const updateUploadMediaArr = state.uploadingMedia;
      if (updateUploadMediaArr[objIndex]) {
        if (payload.status) {
          updateUploadMediaArr[objIndex]["status"] = payload.status;
        }
        if (payload.progress) {
          updateUploadMediaArr[objIndex]["progress"] = payload.progress;
        }
      }

      return {
        ...state,
        uploadingMedia: state.uploadingMedia,
      };

    case SET_LOADING_MEDIA:
      return { ...state, loadingMedia: payload };

    case SET_SUCCESS_PROMPT_CMS_PAGE:
      return { ...state, isSuccessPromptCMSPage: payload };

    case SET_SUCCESS_MESSAGE_CMS_PAGE:
      return { ...state, successMessageCMSPage: payload };

    case SET_ERROR_PROMPT_CMS_PAGE:
      return { ...state, isErrorPromptCMSPage: payload };

    case SET_ERROR_MESSAGE_CMS_PAGE:
      return { ...state, errorMessageCMSPage: payload };

    case SET_CONTENT_NAME:
      return { ...state, contentName: payload };

    case SET_CONTENT_LAYERS:
      return { ...state, contentLayers: payload };

    case SET_CANVAS_RES:
      return { ...state, canvasRes: payload };

    case SET_LAYER_MEDIAS:
      const { layerID, layerMedias, forceUpdate } = payload;
      reset = payload["reset"];
      if (!layerID) {
        if (reset) {
          return { ...state, layerMedias: {} };
        }

        if (forceUpdate) {
          return { ...state, layerMedias: forceUpdate };
        }
      }

      // update() is updating value easier
      const updatedState = update(state, { layerMedias: { $merge: { [layerID]: [...layerMedias] } } });
      return { ...state, layerMedias: updatedState.layerMedias};

    case SET_ERROR_HANDLER:
      const { contentName, contentLayers, resolution, selectedMedia } = payload;
      reset = payload["reset"];
      if (reset) {
        return { ...state, errorHandler: {
          contentName: false,
          contentLayers: false,
          resolution: false,
          selectedMedia: false,
        }};
      }

      const updated = state.errorHandler;
      if (contentName !== undefined) {
        updated.contentName = contentName;
      }
      if (contentLayers !== undefined) {
        updated.contentLayers = contentLayers;
      }
      if (resolution !== undefined) {
        updated.resolution = resolution;
      }
      if (selectedMedia !== undefined) {
        updated.selectedMedia = selectedMedia;
      }
      return { ...state, errorHandler: { ...updated } };

    case SET_ASSET_DATA:
      const assetIDs = Object.keys(payload);
      const updatedAssetData = { ...state.assetData };
      assetIDs.forEach((assetID) => {
        updatedAssetData[assetID] = payload[assetID];
      });
      return { ...state, assetData: { ...updatedAssetData } };

    default:
      return state;
  }
};
