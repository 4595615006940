import React from "react";

import { Breadcrumbs, Link } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const ScheduleBreadCrumb = ({ schedule }) => {
  return (
    <Breadcrumbs
      separator={<NavigateNextIcon sx={{ color: "#1C4ED8" }} fontSize="small" />}
      aria-label="breadcrumb"
    >
      <Link href="/schedules" underline="always" sx={{ cursor: "pointer" }}>
        All Schedules
      </Link>
      <Link href="#" underline="hover" sx={{ cursor: "pointer" }}>
        {schedule.name}
      </Link>
    </Breadcrumbs>
  );
};

export default ScheduleBreadCrumb;
