export const switchColorOnScheduleStatus = (status) => {
  switch (status) {
    case "NOT_APPROVED":
      return "#ffffff";

    case "APPROVED":
      return "#ffffff";

    case "PENDING":
      return "#827717";

    case "ACTIVE":
      return "#065F46";

    case "COMPLETED":
      return "#FFFF";

    case "EXPIRED":
      return "#FFFF";

    case "IMMEDIATE_PENDING":
      return "#827717";

    case "IMMEDIATE_ACTIVE":
      return "#065F46";

    case "IMMEDIATE_COMPLETED":
      return "#FFFF";

    case "IMMEDIATE_EXPIRED":
      return "#FFFF";

    case "ANNOUNCEMENT_OFF":
      return "#111827";

    case "ANNOUNCEMENT_ON":
      return "#25D3B4";

    default:
      return "";
  }
};

export const switchBackgroundColorBasedOnStatus = (status) => {
  switch (status) {
    case "NOT_APPROVED":
      return "#ed6c02";

    case "APPROVED":
      return "#237961";

    case "PENDING":
      return "#F9EFDD";

    case "ACTIVE":
      return "#D1FAE5";

    case "COMPLETED":
      return "#2c387e";

    case "EXPIRED":
      return "#a31545";

    case "IMMEDIATE_PENDING":
      return "#F9EFDD";

    case "IMMEDIATE_ACTIVE":
      return "#D1FAE5";

    case "IMMEDIATE_COMPLETED":
      return "#2c387e";

    case "IMMEDIATE_EXPIRED":
      return "#a31545";

    case "ANNOUNCEMENT_OFF":
      return "#DD132B";

    case "ANNOUNCEMENT_ON":
      return "#00695f";

    default:
      return "";
  }
};

export const switchColorOnJobHistoryStatus = (status) => {
  switch (status) {
    case "PENDING":
      return "#827717";

    case "ACTIVE":
      return "#065F46";

    case "COMPLETED":
      return "#2c387e";

    case "EXPIRED":
      return "#a31545";

    case "IMMEDIATE_PENDING":
      return "#827717";

    case "IMMEDIATE_ACTIVE":
      return "#065F46";

    case "IMMEDIATE_COMPLETED":
      return "#2c387e";

    case "IMMEDIATE_EXPIRED":
      return "#a31545";

    case "ANNOUNCEMENT_OFF":
      return "#DD132B";

    case "ANNOUNCEMENT_ON":
      return "#00695f";

    default:
      return "";
  }
};

export const switchColorBasedOnFrequency = (frequency) => {
  switch (frequency) {
    case "ONCE":
      return "#551071";

    case "DAILY":
      return "#811412";

    case "WEEKLY":
      return "#12812D";

    case "MONTHLY":
      return "#125581";

    default:
      return "";
  }
};
