import {
  SET_SMP_DISRUPTION,
  SET_LOADING_SMP_DISRUPTION,
  SET_ERROR_MESSAGE_SMP_DISRUPTION,
  SET_ERROR_PROMPT_SMP_DISRUPTION,
  SET_ERROR_MESSAGE_ANNOUNCEMENT_SMP_DISRUPTION,
  SET_ERROR_PROMPT_ANNOUNCEMENT_SMP_DISRUPTION,
  SET_SUCCESS_MESSAGE_SMP_DISRUPTION,
  SET_SUCCESS_PROMPT_SMP_DISRUPTION,
  SET_EDIT_SMP_LINK_ANNOUNCEMENT,
  SET_LOADING_ANNOUNCEMENT_SMP_DISRUPTION,
  SET_LOADING_LINK_SMP_DISRUPTION,
} from "../actionTypes/smpActionType";

const initialState = {
  stationDisruptions: [],
  platformDisruptions: [],
  trackDisruptions: [],
  textDisruptions: [],
  loadingSMPDisruption: false,
  loadingAnnouncementSMPDisruption: false,
  loadingLinkSMPDisruption: false,
  loadingDisruptionText: false,
  isSuccessPromptSMPDisruptionPage: false,
  successMessageSMPDisruptionPage: "",
  isErrorPromptSMPDisruptionPage: false,
  isErrorPromptSMPAnnouncementPage: false,
  errorMessageSMPDisruptionPage: "",
  errorMessageSMPAnnouncementPage: "",
};

export const smpReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_SMP_DISRUPTION:
      return {
        ...state,
        stationDisruptions: payload?.stations,
        platformDisruptions: payload?.platforms,
        trackDisruptions: payload?.tracks,
        textDisruptions: payload?.disruptions,
      };

    case SET_LOADING_SMP_DISRUPTION:
      return { ...state, loadingSMPDisruption: payload };

    case SET_LOADING_ANNOUNCEMENT_SMP_DISRUPTION:
      return { ...state, loadingAnnouncementSMPDisruption: payload };

    case SET_LOADING_LINK_SMP_DISRUPTION:
      return { ...state, loadingLinkSMPDisruption: payload };

    case SET_SUCCESS_PROMPT_SMP_DISRUPTION:
      return { ...state, isSuccessPromptSMPDisruptionPage: payload };

    case SET_SUCCESS_MESSAGE_SMP_DISRUPTION:
      return { ...state, successMessageSMPDisruptionPage: payload };

    case SET_ERROR_PROMPT_SMP_DISRUPTION:
      return { ...state, isErrorPromptSMPDisruptionPage: payload };

    case SET_ERROR_MESSAGE_SMP_DISRUPTION:
      return { ...state, errorMessageSMPDisruptionPage: payload };

    case SET_ERROR_PROMPT_ANNOUNCEMENT_SMP_DISRUPTION:
      return { ...state, isErrorPromptSMPAnnouncementPage: payload };

    case SET_ERROR_MESSAGE_ANNOUNCEMENT_SMP_DISRUPTION:
      return { ...state, errorMessageSMPAnnouncementPage: payload };

    case SET_EDIT_SMP_LINK_ANNOUNCEMENT:
      const linkData = state.stationDisruptions;
      const linkObjIndex = linkData.findIndex((obj) => obj.id === payload.id);
      linkData[linkObjIndex] = payload;
      return { ...state, stationDisruptions: [...linkData] };

    default:
      return state;
  }
};
