import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  Box,
  CircularProgress,
  Alert,
  Button,
} from "@mui/material";
import { CustomTextField } from "../customComponent";
import { Email } from "@mui/icons-material";
import axios from "../../config/axios";
import { useNavigate } from "react-router-dom";

const ErrorPrompt = ({ errorMessage }) => {
  return (
    <Box mb={2}>
      <Alert severity="error">{errorMessage}</Alert>
    </Box>
  );
};

const ForgotPassword = ({ open, onClose }) => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [isError, setIsError] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const handleClickClose = () => {
    setEmail("");
    onClose();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const abortController = new AbortController();
    setIsLoading(true);

    try {
      const { data } = await axios({
        url: "/api/auth/reset-password",
        method: "POST",
        data: { usernameOrEmail: email },
        signal: abortController.signal,
      });

      const resetPasswordToken = data.token;
      sessionStorage.setItem("resetPasswordToken", resetPasswordToken);

      await axios({
        url: "/api/auth/reset-password/request-otp",
        method: "POST",
        headers: { authorization: `Bearer ${resetPasswordToken}` },
        data: { method: "EMAIL" },
      }).then(({ data }) => {
        const resetPasswordOTPKey = data.token;
        sessionStorage.setItem("resetPasswordOTPKey", resetPasswordOTPKey);
        navigate("/reset-password");
      });
    } catch (error) {
      const errorMessage = error.response.data.message;
      setIsError(true);
      setErrorMessage(errorMessage);

      const FOUR_SECONDS = 4000; // in milliseconds

      if (
        errorMessage ===
        "Your account has been locked because you have attempted too many password reset. Please ask your supervisor to unlock."
      ) {
        sessionStorage.clear();
        setTimeout(() => {
          navigate("/login");
        }, FOUR_SECONDS);
      }

      setTimeout(() => {
        setIsError(false);
      }, FOUR_SECONDS);
    } finally {
      setIsLoading(false);
      abortController.abort();
    }
  };

  return (
    <>
      <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClickClose}>
        <Box component="form">
          <DialogTitle sx={{ textAlign: "center" }}>
            Forgot Password
          </DialogTitle>

          <DialogContent>
            {isError && <ErrorPrompt errorMessage={errorMessage} />}

            <DialogContentText>
              Enter your email address and we will send you the code to reset
              your password.
            </DialogContentText>

            <CustomTextField
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              label="Email Address"
              fullWidth
              margin="normal"
              placeholder="youremail@domain.com"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Email />
                  </InputAdornment>
                ),
              }}
            />
          </DialogContent>

          <DialogActions>
            {isLoading ? (
              <Button disabled fullWidth variant="contained">
                <CircularProgress size={20} thickness={2} color="inherit" />
              </Button>
            ) : (
              <Button fullWidth onClick={handleSubmit} variant="contained">
                Send
              </Button>
            )}
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default ForgotPassword;
