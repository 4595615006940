import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import rootReducers from "./reducers";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { authReducer } from "./reducers/authReducer";

const persistConfig = {
  storage,
  key: "loggedUser",
  whiteList: ["loggedUser"],
};

const persistedReducer = persistReducer(persistConfig, authReducer);
const combinedReducers = combineReducers({
  auth: persistedReducer,
  ...rootReducers,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleWare = applyMiddleware(thunk);

const composeMiddleware = composeEnhancers(middleWare);

const store = createStore(combinedReducers, composeMiddleware);

const persistor = persistStore(store);

export { persistor, store };
