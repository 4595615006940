import React from "react";
import { useNavigate } from "react-router-dom";

import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  GroupBlueIcon,
  ScheduleOrangeIcon,
  SmallScheduleIcon,
  ViewerGreyIcon,
} from "../../icons";

import { TypographyNormal, TypographySmall } from "../customComponent";
import { colorStyling } from "../../helpers/color";

const useStyles = makeStyles(() => ({
  hover: {
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
      color: colorStyling.primary,
    },
  },
  hoverPaper: {
    "&:hover": { backgroundColor: colorStyling.white.hover, cursor: "pointer" },
  },
}));

const GroupItem = ({ group }) => {
  const classes = useStyles();

  const navigate = useNavigate();

  const redirectToGroupDetailPage = (id) => {
    navigate("/group/detail/" + id);
  };

  return (
    <>
      <Box
        className={classes.hoverPaper}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: "6px",
          mb: 2,
          p: 2,
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{ display: "flex", alignItems: "center", width: 400 }}
            ml={2}
            onClick={() => redirectToGroupDetailPage(group.id)}
          >
            <GroupBlueIcon />
            <Box ml={2}>
              <TypographyNormal
                sx={{
                  fontWeight: 600,
                  cursor: "pointer",
                  "&:hover": {
                    textDecoration: "underline",
                    color: "#1C4ED8",
                  },
                }}
              >
                {group.name}
              </TypographyNormal>
            </Box>
          </Box>
        </Box>

        <Box
          onClick={() => redirectToGroupDetailPage(group.id)}
          sx={{
            maxWidth: "350px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <Box
            mx={2}
            display="flex"
            alignItems="center"
            className={classes.hover}
          >
            <ViewerGreyIcon />
            <TypographySmall sx={{ marginLeft: "5px", fontWeight: 300 }}>
              {group.screensCount} viewers
            </TypographySmall>
          </Box>

          <SmallScheduleIcon />

          <Box
            ml={2}
            display="flex"
            alignItems="center"
            className={classes.hover}
          >
            <ScheduleOrangeIcon />
            <TypographySmall sx={{ marginLeft: "5px", fontWeight: 300 }}>
              {group.schedulesCount} schedules
            </TypographySmall>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default GroupItem;
