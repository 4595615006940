export const colorStyling = {
  // Blue
  primary: "#1C4ED8",
  // Green
  green: "#065F46",
  // Black
  black: "#111827",
  // White
  white: {
    //For active status
    active: "#D1FAE5",
    //For inactive status
    inactive: "#DFDFDF",
    // Hover over item
    hover: "#F3F8FD",
    // Hover over panel
    panel: "#DBE9FE",
    //border color
    border: "#DCDEE3",
  },
  // Gray
  gray: "#9CA3AF",
  // Blue
  oldPrimary: "#1D4ED8",
  // Red
  red: "#EB4826",
  // Light Blue
  lightblue: "#3366BB",
};
