import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  CircularProgress,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { TypographyNormal } from "../../customComponent";
import { usePagination } from "../../hooks";
import UserHistoryItem from "./UserHistoryItem";
import { getUserHistory } from "../../../store/actions/userAction";
import Role from "../../../helpers/roles";

const UserHistoryList = ({ role }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserHistory(""));
  }, [dispatch]);

  const { userHistory, loadingUser: loading } = useSelector(
    (state) => state.user
  );

  const [page, setPage] = useState(1);

  const DATA_PER_PAGE = 5;

  const count = Math.ceil(userHistory.length / DATA_PER_PAGE);

  const _DATA = usePagination(userHistory, DATA_PER_PAGE);

  const handleChangePagination = (e, page) => {
    setPage(page);
    _DATA.jump(page);
  };

  if (loading)
    return (
      <Box
        sx={{ height: "20vh" }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <CircularProgress size={20} thickness={3} sx={{ color: "#1D4ED8" }} />
        <TypographyNormal sx={{ color: "#1D4ED8", mt: 2, fontWeight: 300 }}>
          Loading Data...
        </TypographyNormal>
      </Box>
    );

  return (
    <>
      {!userHistory.length || userHistory === undefined ? (
        <Alert severity="info" variant="filled">
          <strong>No User Action Data</strong>
        </Alert>
      ) : (
        <TableContainer
          component={Paper}
          sx={{ overflowY: "auto", maxHeight: 500, boxShadow: 3 }}
        >
          <Table>
            <TableHead>
              <TableRow>
                {role === Role.Eins && <TableCell></TableCell>}
                <TableCell>ID</TableCell>
                <TableCell>User</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Action</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Organization</TableCell>
                <TableCell>Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userHistory &&
                _DATA
                  .currentData()
                  .map((data, index) => (
                    <UserHistoryItem
                      key={data.id}
                      data={data}
                      index={index}
                      role={role}
                    />
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {!loading && userHistory.length ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            pt: 4,
            pb: 2,
            pr: 2,
          }}
        >
          <Pagination
            count={count}
            page={page}
            shape="rounded"
            color="primary"
            onChange={handleChangePagination}
          />
        </Box>
      ) : null}
    </>
  );
};

export default UserHistoryList;
