import React, { useEffect, useRef, useState } from "react";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { formattedDate } from "../../../helpers/dateFormat";
import { CSVLink } from "react-csv";

const ExportContentHistory = () => {
  const { contentHistory } = useSelector((state) => state.contentUpload);
  const csvRef = useRef();
  const [csvData, setCsvData] = useState([]);
  const headers = [
    { label: "ID", key: "id" },
    { label: "Content Name", key: "name" },
    { label: "Type", key: "type" },
    { label: "File Name", key: "fileName" },
    { label: "Status", key: "status" },
    { label: "Viewer ID", key: "viewerId" },
    { label: "Viewer Name", key: "viewerName" },
    { label: "Group ID", key: "viewerGroupId" },
    { label: "Group Name", key: "viewerGroupName" },
    { label: "Time", key: "recordedAt" },
  ];

  useEffect(() => {
    if (csvData.length !== 0) csvRef.current.link.click();
  }, [csvData]);

  const getData = () => {
    setCsvData(
      contentHistory.map(
        ({
          id,
          name,
          type,
          fileName,
          status,
          viewerId,
          viewerName,
          viewerGroupId,
          viewerGroupName,
          recordedAt,
        }) => ({
          id,
          name,
          type,
          fileName,
          status,
          viewerId,
          viewerName,
          viewerGroupId: viewerGroupId ?? "-",
          viewerGroupName: viewerGroupName ?? "-",
          recordedAt: formattedDate(recordedAt),
        })
      )
    );
  };

  return (
    <>
      <Button variant="contained" onClick={() => getData()}>
        Export CSV
      </Button>
      <CSVLink
        data={csvData}
        headers={headers}
        filename="content_history.csv"
        ref={csvRef}
      ></CSVLink>
    </>
  );
};

export default ExportContentHistory;
