import React, { useState, useEffect } from "react";
import {
  Box,
  Divider,
  Paper,
} from "@mui/material";

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import LayerAsset from "./LayerAsset";
import { TypographyLarge } from "../../../customComponent";

const LayerItem = ({ layer, index, moveAssets }) => {
  const [contents, setContents] = useState([]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    if (layer?.layerAssets?.length > 0) {
      const layerAssets = [];
      layer.layerAssets.forEach((asset) => {
        const obj = asset.id >= 0 ? asset : {
          id: asset.order + 1,
          name: asset.asset.name,
          interval: asset.duration / 1000,
          asset: asset.asset,
        };
        layerAssets.push(obj);
      });

      setContents(layerAssets);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layer]);

  function handleDragEnd(event) {
    const { active, over } = event;
    if (active.id !== over.id) {
      setContents((contents) => {
        const oldIndex = contents.findIndex((i) => i.id === active.id);
        const newIndex = contents.findIndex((i) => i.id === over.id);
        const move = arrayMove(contents, oldIndex, newIndex);
        moveAssets(index - 1, move);
        return move;
      });
    }
  }

  return (
    <Paper sx={{ p: 2, mb: 2 }} elevation={2}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <TypographyLarge sx={{ mb: "2px" }}>Layer {index}</TypographyLarge>
      </Box>

      <Divider sx={{ mt: 1 }} />

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={contents.map((i) => i.id)}
          strategy={verticalListSortingStrategy}
        >
          {contents.map((content, index) => (
            <LayerAsset
              key={content.id}
              content={content}
              index={index}
            />
          ))}
        </SortableContext>
      </DndContext>
    </Paper>
  );
};

export default LayerItem;
