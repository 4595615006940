import React from "react";
import { useDispatch } from "react-redux";
import { Box, ButtonBase, Grid, Tooltip } from "@mui/material";
import { TypographyLarge, TypographySmall } from "../../../customComponent";
import { setErrorHandler } from "../../../../store/actions/cmsAction"; // TODO: removeAsset

const MediaItem = ({ media, selectedMedia, setSelectedMedia }) => {
  const dispatch = useDispatch();

  let fileSrc = media.thumbnailUrl;
  if (!fileSrc) {
    fileSrc = media.src ?? "/media_not_found.png";
  }

  let showElement;
  showElement = <img
    style={{ maxWidth: "100%", maxHeight: "80%", m: 1 }}
    src={fileSrc}
    alt={media.name}
  />;

  const parseTypeBgColor = () => {
    // ADDED when needed for other file types:
    // burlywood, darkorange, darksalmon, skyblue, sandybrown, thistle
    switch(media.type.toUpperCase()) {
      case "IMAGE":
        return "sandybrown";

      case "VIDEO":
        return "skyblue";

      default:
        return "gainsboro";
    }
  };

  return (
    <Tooltip arrow
      title={media.name}
    >
      <Grid item sx={{ height: "fit-content" }}>
        <ButtonBase
          sx={{
            border:
              media.id === selectedMedia.id
                ? "2px solid rgba(28, 78, 216, 0.8)"
                : "2px solid rgba(132, 137, 151,0.5)",
            backgroundColor:
              media.id === selectedMedia.id
                ? "rgba(28, 78, 216, 0.1)"
                : "transparent",
            borderRadius: "8px",
            width: "150px",
            height: "200px",
            p: 2,
            ":hover": {
              border: "2px solid rgba(28, 78, 216, 1)",
              backgroundColor: "rgba(28, 78, 216, 0.2)",
            },
          }}
          onClick={() => {
            setSelectedMedia(media);
            dispatch(setErrorHandler({ selectedMedia: false }));
          }}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{ width: "100%", height: "100%" }}
          >
            <Box
              display={"flex"}
              alignSelf={"start"}
              position={"absolute"}
              bgcolor={parseTypeBgColor()}
              paddingY={"4px"}
              width={"58px"}
              justifyContent={"center"}
              sx={{ marginTop: -2, marginLeft: -2 }}
            >
              <TypographySmall fontWeight={"medium"}>
                {media.type.toUpperCase()}
              </TypographySmall>
            </Box>
            <Box
              display={"flex"}
              alignContent={"center"}
              alignItems={"center"}
              sx={{ height: "80%" }}
            >
              {showElement}
            </Box>
            <TypographyLarge>
              {media.name.substring(0, 10) + "..."}
            </TypographyLarge>
          </Box>
        </ButtonBase>
      </Grid>
    </Tooltip>
  );
};

export default MediaItem;
