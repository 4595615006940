export default class JobHistory {
  constructor(payload) {
    this.id = payload.id;
    this.jobId = payload.jobId;
    this.scheduleId = payload.scheduleId;
    this.scheduleName = payload.scheduleName;
    this.status = payload.status;
    this.viewerId = payload.viewerId;
    this.viewerName = payload.viewerName;
    this.url = payload.args[0];
    this.args = payload.args;
    this.contentName = payload.args[1];
    this.contentType = payload.args[2];
    this.command = payload.command;
    this.type = payload.type;
    this.completedAt = payload.completedAt;
    this.updatedAt = payload.updatedAt;
    this.createdAt = payload.createdAt;
    this.createdBy = payload.createdBy;
    this.createdByName = payload.createdByName;
    this.removedAt = payload.removedAt;
  }
}
