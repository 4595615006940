import React from "react";

const TerminalIcon = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.3999 5.99997C2.3999 4.67449 3.47442 3.59998 4.7999 3.59998H19.1999C20.5254 3.59998 21.5999 4.67449 21.5999 5.99998V18C21.5999 19.3255 20.5254 20.4 19.1999 20.4H4.7999C3.47442 20.4 2.3999 19.3255 2.3999 18V5.99997ZM6.35137 7.55145C6.82 7.08282 7.5798 7.08282 8.04843 7.55145L11.6484 11.1514C12.1171 11.6201 12.1171 12.3799 11.6484 12.8485L8.04843 16.4485C7.5798 16.9171 6.82 16.9171 6.35137 16.4485C5.88275 15.9799 5.88275 15.2201 6.35137 14.7514L9.10285 12L6.35137 9.2485C5.88275 8.77987 5.88275 8.02008 6.35137 7.55145ZM13.1999 14.4C12.5372 14.4 11.9999 14.9372 11.9999 15.6C11.9999 16.2627 12.5372 16.8 13.1999 16.8H16.7999C17.4626 16.8 17.9999 16.2627 17.9999 15.6C17.9999 14.9372 17.4626 14.4 16.7999 14.4H13.1999Z"
          fill="#9CA3AF"
        />
      </svg>
    </>
  );
};

export default TerminalIcon;
