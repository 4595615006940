import {
  Container,
  CssBaseline,
  // Paper,
  Toolbar,
} from "@mui/material";
import { Box } from "@mui/system";
import { TypographyLarge, TypographyXLarge } from "../../customComponent";
import Sidebar from "../../Sidebar";
import UserGroupList from "./UserGroupList";
import CreateUserGroup from "./CreateUserGroup";
import UserSuccessPrompt from "../UserSuccessPrompt";
import UserErrorPrompt from "../UserErrorPrompt";

const UserGroup = () => {
  return (
    <div style={{ display: "flex" }}>
      <Sidebar />

      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Toolbar />
        <CssBaseline />

        <Container maxWidth="xl">
          <Box sx={{ mb: 1 }}>
            <TypographyXLarge sx={{ fontWeight: 700 }}>
              User Group
            </TypographyXLarge>
            <TypographyLarge sx={{ fontWeight: 400 }}>
              Group user and manage their permission
            </TypographyLarge>
          </Box>

          <UserSuccessPrompt />
          <UserErrorPrompt />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              mb: 2,
            }}
          >
            <CreateUserGroup />
          </Box>
          {/* 
            TODO: will work on it while doing User Group Filter
          */}
          {/* <Paper
            component="section"
            elevation={2}
            sx={{ p: 2, my: 2, borderRadius: "6px" }}
          >
            <UserFilter />
          </Paper> */}

          <UserGroupList />
        </Container>
      </Box>
    </div>
  );
};

export default UserGroup;
