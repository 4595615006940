export const setPriorityName = (priority) => {
  switch (priority) {
    case 0:
      return "Highest";

    case 1:
      return "High";

    case 2:
      return "Normal";

    case 3:
      return "Low";

    case 4:
      return "Lowest";

    default:
      return null;
  }
};

export const formatMs = (ms) => {
  return ms / 1000 + "second";
};
