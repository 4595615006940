export const SET_EQUIPMENT_BREAKDOWN = "SET_EQUIPMENT_BREAKDOWN";
export const SET_EQUIPMENT = "SET_EQUIPMENT";
export const SET_BREAKDOWN_ANNOUNCEMENT = "SET_BREAKDOWN_ANNOUNCEMENT";
export const SET_EDIT_BREAKDOWN_ANNOUNCEMENT =
  "SET_EDIT_BREAKDOWN_ANNOUNCEMENT";
export const SET_EDIT_EQUIPMENT = "SET_EDIT_EQUIPMENT";
export const SET_ERROR_MESSAGE_ANNOUNCEMENT_EQUIPMENT_BREAKDOWN =
  "SET_ERROR_MESSAGE_ANNOUNCEMENT_EQUIPMENT_BREAKDOWN";
export const SET_ERROR_PROMPT_ANNOUNCEMENT_EQUIPMENT_BREAKDOWN =
  "SET_ERROR_PROMPT_ANNOUNCEMENT_EQUIPMENT_BREAKDOWN";
export const SET_ERROR_MESSAGE_EQUIPMENT_BREAKDOWN =
  "SET_ERROR_MESSAGE_EQUIPMENT_BREAKDOWN";
export const SET_ERROR_PROMPT_EQUIPMENT_BREAKDOWN =
  "SET_ERROR_PROMPT_EQUIPMENT_BREAKDOWN";
export const SET_SUCCESS_MESSAGE_EQUIPMENT_BREAKDOWN =
  "SET_SUCCESS_MESSAGE_EQUIPMENT_BREAKDOWN";
export const SET_SUCCESS_PROMPT_EQUIPMENT_BREAKDOWN =
  "SET_SUCCESS_PROMPT_EQUIPMENT_BREAKDOWN";
export const SET_EDIT_EQUIPMENT_LINK_ANNOUNCEMENT =
  "SET_EDIT_EQUIPMENT_LINK_ANNOUNCEMENT";
export const SET_LOADING_ANNOUNCEMENT_EQUIPMENT_BREAKDOWN =
  "SET_LOADING_ANNOUNCEMENT_EQUIPMENT_BREAKDOWN";
export const SET_LOADING_LINK_EQUIPMENT_BREAKDOWN =
  "SET_LOADING_LINK_EQUIPMENT_BREAKDOWN";
