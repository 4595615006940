import {
  SET_DELETE_GROUP,
  SET_DETAIL_GROUP,
  SET_EDIT_GROUP,
  SET_ERROR_MESSAGE_GROUP_PAGE,
  SET_ERROR_PROMPT_GROUP_PAGE,
  SET_GROUP,
  SET_LOADING_GROUP,
  SET_NEW_GROUP,
  SET_SUCCESS_MESSAGE_GROUP_PAGE,
  SET_SUCCESS_PROMPT_GROUP_PAGE,
} from "../actionTypes/groupActionType";

const initialState = {
  groups: [],
  group: {},
  loadingGroup: false,
  isSuccessPromptGroupPage: false,
  isErrorPromptGroupPage: false,
  successMessageGroupPage: "",
  errorMessageGroupPage: "",
};

export const groupReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_GROUP:
      return { ...state, groups: payload };

    case SET_LOADING_GROUP:
      return { ...state, loadingGroup: payload };

    case SET_NEW_GROUP:
      const listOfGroups = state.groups;
      return { ...state, groups: [payload, ...listOfGroups] };

    case SET_DELETE_GROUP:
      const groupsState = state.groups;
      const filtered = groupsState.filter(
        (group) => group.id.toString() !== payload.toString()
      );
      return { ...state, groups: filtered };

    case SET_EDIT_GROUP:
      const data = state.groups;
      const objIndex = data.findIndex((obj) => obj.id === payload.id);
      data[objIndex] = payload;
      return { ...state, groups: [...data] };

    case SET_DETAIL_GROUP:
      return { ...state, group: payload };

    case SET_SUCCESS_PROMPT_GROUP_PAGE:
      return { ...state, isSuccessPromptGroupPage: payload };

    case SET_SUCCESS_MESSAGE_GROUP_PAGE:
      return { ...state, successMessageGroupPage: payload };

    case SET_ERROR_PROMPT_GROUP_PAGE:
      return { ...state, isErrorPromptGroupPage: payload };

    case SET_ERROR_MESSAGE_GROUP_PAGE:
      return { ...state, errorMessageGroupPage: payload };

    default:
      return state;
  }
};
