import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Alert, AlertTitle } from "@mui/material";

import { SET_SUCCESS_PROMPT_GROUP_PAGE } from "../../store/actionTypes/groupActionType";

const GroupSuccessPrompt = () => {
  const dispatch = useDispatch();

  const { isSuccessPromptGroupPage: isSuccess, successMessageGroupPage: successMessage } =
    useSelector((state) => state.group);

  const handleClose = () => {
    dispatch({ type: SET_SUCCESS_PROMPT_GROUP_PAGE, payload: false });
  };

  return (
    isSuccess && (
      <Alert sx={{ my: 2 }} severity="success" variant="filled" onClose={handleClose}>
        <AlertTitle>Success!</AlertTitle>
        {successMessage}
      </Alert>
    )
  );
};

export default GroupSuccessPrompt;
