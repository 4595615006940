import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ascending, descending } from "../../../helpers/sorting";
import {
  filterContentHistory,
  getContentHistory,
} from "../../../store/actions/contentUploadAction";
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SortIcon from "@mui/icons-material/Sort";
import ClearIcon from "@mui/icons-material/ClearOutlined";
import { SET_CONTENT_HISTORY } from "../../../store/actionTypes/contentUploadActionType";

const ContentHistoryFilter = () => {
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [searchType, setSearchType] = useState("content");
  const [type, setType] = useState("");
  const [status, setStatus] = useState("");

  const { contentHistory } = useSelector((state) => state.contentUpload);

  const [anchorEl, setAnchorEl] = useState(null);

  const openSortButton = Boolean(anchorEl);

  const [sortBy, setSortBy] = useState("Newest");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeSortBy = (event) => {
    const value = event.target.value;
    setSortBy(value);

    let sorted;

    switch (value) {
      case "Oldest":
        sorted = ascending(contentHistory, "id");
        break;

      case "Newest":
        sorted = descending(contentHistory, "id");
        break;

      case "A-Z":
        sorted = ascending(contentHistory, "name");
        break;

      case "Z-A":
        sorted = descending(contentHistory, "name");
        break;

      default:
        break;
    }

    dispatch({ type: SET_CONTENT_HISTORY, payload: sorted });
  };

  const handleResetState = () => {
    setName("");
    setType("");
    setSortBy("Oldest");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const query = [];

    if (name) {
      if (searchType === "content") {
        query.push(`nameLike=${name}`);
      }

      if (searchType === "viewer") {
        query.push(`viewerNameLike=${name}`);
      }

      if (searchType === "viewerGroup") {
        query.push(`viewerGroupNameLike=${name}`);
      }

      if (searchType === "file") {
        query.push(`fileNameLike=${name}`);
      }
    }

    if (type) {
      query.push(`type=${type}`);
    }

    if (status) {
      query.push(`status=${status}`);
    }

    const payload = `&${query.join("&")}`;

    dispatch(filterContentHistory(payload));
  };

  const handleRefresh = () => {
    dispatch(getContentHistory());
    handleResetState();
  };

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
      onSubmit={handleSubmit}
    >
      <TextField
        size="small"
        SelectProps={{ native: true }}
        select
        fullWidth
        value={searchType}
        onChange={(e) => setSearchType(e.target.value)}
        sx={{ mr: 2, maxWidth: 170 }}
      >
        <option value="content">Search Content</option>
        <option value="viewer">Search Viewer</option>
        <option value="viewerGroup">Search Group</option>
        <option value="file">Search File</option>
      </TextField>

      <TextField
        fullWidth
        size="small"
        sx={{ mr: 2 }}
        placeholder="Search ..."
        type="search"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        value={name}
        onChange={(e) => setName(e.target.value)}
      />

      <TextField
        size="small"
        SelectProps={{ native: true }}
        select
        fullWidth
        value={type}
        onChange={(e) => setType(e.target.value)}
        sx={{ mr: 2, maxWidth: 150 }}
      >
        <option defaultValue value="">
          Any Type
        </option>
        <option value="CONTENT">Content</option>
        <option value="ASSET">Asset</option>
      </TextField>

      <TextField
        size="small"
        SelectProps={{ native: true }}
        select
        fullWidth
        value={status}
        onChange={(e) => setStatus(e.target.value)}
        sx={{ mr: 2, maxWidth: 150 }}
      >
        <option defaultValue value="">
          Any Status
        </option>
        <option value="DOWNLOAD_SUCCESS">Download Success</option>
        <option value="DOWNLOAD_FAILED">Download Failed</option>
        <option value="ERROR">Error</option>
        <option value="EXTRACT_FAILED">Extract Failed</option>
      </TextField>

      <Button type="submit" variant="contained">
        Search
      </Button>

      <IconButton sx={{ color: "#111827", mx: 2 }} onClick={handleClick}>
        <Tooltip title="Sort">
          <SortIcon fontSize="small" />
        </Tooltip>
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openSortButton}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <RadioGroup value={sortBy} onChange={handleChangeSortBy}>
          <MenuItem>
            <FormControlLabel
              disableTypography
              value="Oldest"
              control={<Radio size="small" />}
              label="Oldest"
              sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
            />
          </MenuItem>
          <MenuItem>
            <FormControlLabel
              disableTypography
              value="Newest"
              control={<Radio size="small" />}
              label="Newest"
              sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
            />
          </MenuItem>
          <MenuItem>
            <FormControlLabel
              disableTypography
              value="A-Z"
              control={<Radio size="small" />}
              label="A-Z"
              sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
            />
          </MenuItem>
          <MenuItem>
            <FormControlLabel
              disableTypography
              value="Z-A"
              control={<Radio size="small" />}
              label="Z-A"
              sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
            />
          </MenuItem>
        </RadioGroup>
      </Menu>

      <IconButton onClick={handleRefresh} sx={{ color: "#111827" }}>
        <Tooltip title="Clear">
          <ClearIcon fontSize="small" />
        </Tooltip>
      </IconButton>
    </Box>
  );
};

export default ContentHistoryFilter;
