import React from "react";

const TVGreenIcon = () => {
  return (
    <>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="16" cy="16" r="16" fill="#5DCEDD" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.3999 21.6C9.95803 21.6 9.59985 21.2418 9.59985 20.8C9.59985 20.3581 9.95803 20 10.3999 20V13.6C10.3999 12.7163 11.1162 12 11.9999 12H14.0685L13.0342 10.9657C12.7217 10.6532 12.7217 10.1467 13.0342 9.83429C13.3466 9.52187 13.8531 9.52187 14.1655 9.83429L15.9999 11.6686L17.8342 9.83429C18.1466 9.52187 18.6531 9.52187 18.9655 9.83429C19.278 10.1467 19.278 10.6532 18.9655 10.9657L17.9312 12H19.9999C20.8835 12 21.5999 12.7163 21.5999 13.6V20C22.0417 20 22.3999 20.3581 22.3999 20.8C22.3999 21.2418 22.0417 21.6 21.5999 21.6H10.3999ZM19.9999 17.6C19.9999 18 19.9999 19.6 19.9999 20C19.9999 20 19.1999 20 18.7999 20H17.9999H16.7999C16.3999 20 14.7999 20 14.3999 20H11.9999C11.9999 19.2 11.9999 14 11.9999 13.6C12.3999 13.6 12.3999 13.6 12.7999 13.6H13.5999H15.1999C15.9999 13.6 19.1999 13.6 19.9999 13.6V17.6Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export default TVGreenIcon;
