import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { StyledTableRow } from "../../customComponent";
import { IconButton, TableCell } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PreviewIcon from "@mui/icons-material/Preview";
import { removeContent } from "../../../store/actions/cmsAction";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const ContentTemplateItem = ({ data, openContentDetails, currentPayload }) => {
  const dispatch = useDispatch();

  const [loadingDelete, setLoadingDelete] = useState(false);

  const parseDate = (dt) => {
    const zeroPadding = (num, digit) => {
      const numOfPadding = digit-1;
      if (num / (10**numOfPadding) < 1) {
        let ret = "";
        for (let i=0; i<numOfPadding-(num/10); i++) {
          ret += "0";
        }
        return `${ret}${num}`;
      } else {
        return num;
      }
    };

    // Ignore comments for future uses.
    const dtObj = new Date(dt);
    const dtDate = `${zeroPadding(dtObj.getMonth()+1,2)}/${zeroPadding(dtObj.getDate(),2)}`; // /${dtObj.getFullYear()}
    const dtTime = `${zeroPadding(dtObj.getHours(),2)}:${zeroPadding(dtObj.getMinutes(),2)}`; // :${zeroPadding(dtObj.getSeconds(),2)}
    return `${dtTime} [${dtDate}]`;
  };

  const removeContentThenRefresh = async () => {
    setLoadingDelete(true);
    const { isConfirmed } = await withReactContent(Swal).fire({
      title: "Confirmation",
      html: `Are you sure to delete this content "${data.name}"?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    });

    if (!isConfirmed) {
      setLoadingDelete(false);
      return;
    }

    const payload = currentPayload();
    dispatch(removeContent(data, payload, () => {
      setLoadingDelete(false);
    }));
  };

  return (
    <StyledTableRow>
      <TableCell>{data.name}</TableCell>
      <TableCell align="center">{parseDate(data.updatedAt)}</TableCell>
      <TableCell align="center">{data.lastEditor?.name ?? "—"}</TableCell>

      <TableCell align="right">
        <Tooltip
          arrow
          title="Delete Content"
        >
          <IconButton
            color="warning"
            disabled={loadingDelete}
            onClick={() => removeContentThenRefresh()}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>

        <Tooltip
          arrow
          title="Edit Content"
        >
          <IconButton disabled={loadingDelete}>
            <EditIcon />
          </IconButton>
        </Tooltip>

        <Tooltip
          arrow
          title="View Details"
        >
        <IconButton
          disabled={loadingDelete}
          onClick={() => openContentDetails(data)}
        >
            <PreviewIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </StyledTableRow>
  );
};

export default ContentTemplateItem;
