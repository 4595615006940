export const SET_VIEWER = "SET_VIEWER";
export const SET_LOADING_VIEWER = "SET_LOADING_VIEWER";
export const SET_CREATE_VIEWER = "SET_CREATE_VIEWER";
export const SET_VIEWER_DETAIL = "SET_VIEWER_DETAIL";
export const SET_LOADING_VIEWER_DETAIL = "SET_LOADING_VIEWER_DETAIL";
export const SET_DELETE_VIEWER = "SET_DELETE_VIEWER";
export const SET_EDIT_VIEWER = "SET_EDIT_VIEWER";
export const SET_SUCCESS_PROMPT_VIEWER_PAGE = "SET_SUCCESS_PROMPT_VIEWER_PAGE";
export const SET_ERROR_PROMPT_VIEWER_PAGE = "SET_ERROR_PROMPT_VIEWER_PAGE";
export const SET_SUCCESS_MESSAGE_VIEWER_PAGE =
  "SET_SUCCESS_MESSAGE_VIEWER_PAGE";
export const SET_ERROR_MESSAGE_VIEWER_PAGE = "SET_ERROR_MESSAGE_VIEWER_PAGE";
