import React from "react";
import SessionTimeout from "./components/SessionTimeout";
import UploadingWindow from "./components/contentUpload/UploadingWindow";
import RouterProvider from "./router/RouterProvider";

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const App = () => {
  return (
    <DndProvider backend={HTML5Backend}>
      <UploadingWindow />
      <RouterProvider />
      <SessionTimeout />
    </DndProvider>
  );
};


export default App;
