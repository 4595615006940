import {
  SET_LOADING_CMS,
  SET_CMS_DATA,
  SET_ASSET_DATA,
  SET_ADD_MEDIA_DATA,
  SET_REMOVE_MEDIA_DATA,
  SET_ERROR_PROMPT_CMS_PAGE,
  SET_ERROR_MESSAGE_CMS_PAGE,
  SET_MEDIA_DATA,
  SET_LOADING_MEDIA,
  SET_SUCCESS_PROMPT_CMS_PAGE,
  SET_SUCCESS_MESSAGE_CMS_PAGE,
  SET_UPDATE_UPLOAD_STATE,
  SET_UPLOAD_MEDIA_DATA,
  SET_CONTENT_NAME,
  SET_CONTENT_LAYERS,
  SET_CANVAS_RES,
  SET_LAYER_MEDIAS,
  SET_ERROR_HANDLER,
} from "../actionTypes/cmsActionType";
import { handleError } from "../handleError";

import {
  assetCMSAPILimit,
  instanceCMSAssetAPI,
  instanceCMSContentAPI,
  contentCMSAPILimit,
  initiateUserAPI,
} from "../../api";
import { initiateAXIOS } from "../../config/axios";
import { dataURLtoFile, resizeImage } from "../../helpers/fileProcessing";

const handleTimeoutSuccessPrompt = (dispatch) => {
  setTimeout(() => {
    dispatch({ type: SET_SUCCESS_PROMPT_CMS_PAGE, payload: false });
  }, 3000);
};

const handleTimeoutErrorPrompt = (dispatch) => {
  setTimeout(() => {
    dispatch({ type: SET_ERROR_PROMPT_CMS_PAGE, payload: false });
  }, 5000);
};

export const setLoadingMedia = (payload) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_MEDIA, payload: payload });
  };
};

export const setContentName = (payload) => {
  return async (dispatch) => {
    dispatch({ type: SET_CONTENT_NAME, payload: payload });
  };
};

export const setContentLayers = (payload) => {
  return async (dispatch) => {
    dispatch({ type: SET_CONTENT_LAYERS, payload: payload });
  };
};

export const setCanvasRes = (payload) => {
  return async (dispatch) => {
    dispatch({ type: SET_CANVAS_RES, payload: payload });
  };
};

export const setLayerMedias = (payload) => {
  return async (dispatch) => {
    dispatch({ type: SET_LAYER_MEDIAS, payload: payload });
  };
};

const getUserData = (userID) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    const getUrl = `${initiateUserAPI}${userID}`;

    try {
      const { data } = await initiateAXIOS.get(getUrl, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      return data;
    } catch (error) {
      dispatch({ type: SET_ERROR_PROMPT_CMS_PAGE, payload: true });
      dispatch({
        type: SET_ERROR_MESSAGE_CMS_PAGE,
        payload: "Error found while loading content data. Please contact admin with this message immediately.",
      });
  
      handleTimeoutErrorPrompt(dispatch);
    }
  };
};

export const getAllContents = (payload) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    const { limit, offset, searchIDs, searchNames, nameLike } = payload;
    dispatch({ type: SET_LOADING_CMS, payload: true });

    let getURL = `${contentCMSAPILimit}`;
    if (limit >= 0) {
      getURL = `${instanceCMSContentAPI}?limit=${limit || 10}`;
    }

    // parsing payload to query
    getURL += `&offset=${offset || 0}`;
    if (searchIDs?.length > 0) {
      searchIDs.forEach((id) => {
        getURL += `&id=${id}`;
      });
    }
    if (searchNames?.length > 0) {
      searchNames.forEach((name) => {
        getURL += `&name=${name}`;
      });
    }
    if (nameLike?.length > 0) {
      getURL += `&nameLike=${nameLike}`;
    }

    try {
      const { data } = await initiateAXIOS.get(getURL, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      let fetchedData = [];
      let fetchedUsers = {};
      const items = data.items;
      for (let i=0; i<items.length; i++) {
        const dt = { ...items[i] };
        if (!fetchedUsers[dt.updatedById]) {
          fetchedUsers[dt.updatedById] = await dispatch(getUserData(dt.updatedById));
        }

        dt["lastEditor"] = fetchedUsers[dt.updatedById];
        fetchedData.push(dt);
      }

      dispatch({ type: SET_CMS_DATA, payload: {
        items: fetchedData,
        totalItem: data.totalItem,
      }});
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_CMS_PAGE, payload: true });
      dispatch({
        type: SET_ERROR_MESSAGE_CMS_PAGE,
        payload: message,
      });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_CMS, payload: false });
    }
  };
};

export const getAllAssets = (payload) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    const { limit, offset, type, searchIDs, searchNames, nameLike } = payload;
    dispatch({ type: SET_LOADING_MEDIA, payload: true });

    let getURL = `${assetCMSAPILimit}`;
    if (limit >= 0) {
      getURL = `${instanceCMSAssetAPI}?limit=${limit || 10}`;
    }

    // parsing payload to query
    getURL += `&offset=${offset || 0}`;
    if (searchIDs?.length > 0) {
      searchIDs.forEach((id) => {
        getURL += `&id=${id}`;
      });
    }
    if (searchNames?.length > 0) {
      searchNames.forEach((name) => {
        getURL += `&name=${name}`;
      });
    }
    if (nameLike?.length > 0) {
      getURL += `&nameLike=${nameLike}`;
    }
    if (type?.length > 0) {
      getURL += `&type=${type || "IMAGE"}`;
    }

    try {
      const { data } = await initiateAXIOS.get(getURL, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      let fetchedMedia = [];
      for (let i=0; i<data.items.length; i++) {
        const dt = { ...data.items[i] };
        fetchedMedia.push(dt);
      }
      dispatch({ type: SET_MEDIA_DATA, payload: fetchedMedia });
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_CMS_PAGE, payload: true });
      dispatch({
        type: SET_ERROR_MESSAGE_CMS_PAGE,
        payload: message,
      });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_MEDIA, payload: false });
    }
  };
};

export const uploadMedia = (payload) => {
  const access_token = sessionStorage.getItem("access_token");
  return async (dispatch) => {
    const updateUploadState = (payload) => {
      const { status, progress } = payload;
      dispatch({
        type: SET_UPDATE_UPLOAD_STATE,
        payload: { name: payload.name, index: payload.index, status, progress },
      });
    };

    // Insert uploaded file
    const initMediaData = [];
    for (let index=0; index<payload.length; index++) {
      const media = payload[index];
      const mediaDim = media.dimension;
      const mediaType = media.type.substring(0, media.type.indexOf("/"));
      const initMedia = {
        index,
        file: media.file,
        name: media.name,
        type: mediaType.toUpperCase(),
        src: media.fileSrc,
        vidSrc: media.videoSrc,
        dimension: mediaDim,
        status: "queued",
        progress: 0,
      };

      let thumbnail = null;
      if (media.videoSrc) {
        thumbnail = dataURLtoFile(media.fileSrc, media.name);
        thumbnail = await resizeImage(thumbnail, `image/webp`);
      } else {
        thumbnail = await resizeImage(media.file, `${mediaType}/webp`);
      }

      initMedia["thumbnailUrl"] = thumbnail;
      initMedia["thumbnailFile"] = dataURLtoFile(thumbnail, `${media.name}.webp`);
      initMediaData.push(initMedia);
    }

    dispatch({ type: SET_UPLOAD_MEDIA_DATA, payload: initMediaData });

    for (let i=0; i<initMediaData.length; i++) {
      const media = initMediaData[i];
      let formdata = new FormData();
      formdata.append("type", media.type);
      formdata.append("name", media.name);
      formdata.append("file", media.file);
      if (media.type === "VIDEO") {
        formdata.append("thumbnail", media.thumbnailFile);
      }

      let uploadedAsset = null;
      try {
        const { data } = await initiateAXIOS.post(
          instanceCMSAssetAPI,
          formdata,
          {
            headers: {
              authorization: `Bearer ${access_token}`,
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progress) => {
              const { total, loaded } = progress;
              const totalSizeInMB = total / 1000000;
              const loadedSizeInMB = loaded / 1000000;
              const uploadPercentage = Math.round(
                (loadedSizeInMB / totalSizeInMB) * 100
              );
              updateUploadState({ ...media, status: "uploading", progress: uploadPercentage });
            },
            encType: "multipart/form-data",
          }
        );
        uploadedAsset = data;
      } catch (e) {
        const message = handleError(e);
        dispatch({ type: SET_ERROR_PROMPT_CMS_PAGE, payload: true });
        dispatch({
          type: SET_ERROR_MESSAGE_CMS_PAGE,
          payload: message,
        });
  
        handleTimeoutErrorPrompt(dispatch);
      } finally {
        updateUploadState({ ...media, status: "completed" });
        const uploaded = {
          index: initMediaData[i].index,
          src: initMediaData[i].src,
          vidSrc: initMediaData[i].vidSrc,
          id: uploadedAsset.id,
          name: uploadedAsset.name,
          type: uploadedAsset.type,
          size: uploadedAsset.size,
          organizationId: uploadedAsset.organizationId,
          textAsset: uploadedAsset.textAsset ?? null,
          unapprovedTextAsset: uploadedAsset.unapprovedTextAsset ?? null,
        };
        
        dispatch({
          type: SET_ADD_MEDIA_DATA,
          payload: uploaded,
        });
      }
    }
  };
};

export const downloadAsset = async ({ id }) => {
  const access_token = sessionStorage.getItem("access_token");
  let getURL = `${instanceCMSAssetAPI}/${id}/download`;

  try {
    const { data } = await initiateAXIOS.get(getURL, {
      headers: { authorization: `Bearer ${access_token}` },
      responseType: "arraybuffer",
    });

    const blobParse = new Blob([data], { type: "image/png" });
    const srcFile = URL.createObjectURL(blobParse);
    return srcFile;
  } catch (e) {
    const message = handleError(e);
    if (message) {
      return "";
    }
  }
};

export const removeAsset = ({ id }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    const payload = { id };
    let getURL = `${instanceCMSAssetAPI}/${id}`;

    try {
      await initiateAXIOS.delete(getURL, {
        headers: { authorization: `Bearer ${access_token}` },
      });
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_CMS_PAGE, payload: true });
      dispatch({
        type: SET_ERROR_MESSAGE_CMS_PAGE,
        payload: message,
      });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_REMOVE_MEDIA_DATA, payload: payload });
    }
  };
};

export const submitContent = (payload, callback) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    const contentLayers = payload.contentLayers;
    const layerMedias = payload.layerMedias;
    const layersID = Object.keys(contentLayers);
    dispatch({
      type: SET_ERROR_HANDLER,
      payload: { reset: true },
    });

    // TODO : error handling
    const errorObj = { response: {
      status: 901,
      data: { message: "" },
    }};

    let missingInputs = "";
    if (!payload.name || payload.name.length === 0) {
      missingInputs += "Content Name";
      dispatch({
        type: SET_ERROR_HANDLER,
        payload: { contentName: true },
      });
    }
    if (payload.width === 0 || payload.height === 0) {
      if (missingInputs.length > 0) {
        missingInputs += ", ";
      }
      missingInputs += "Resolution";
      dispatch({
        type: SET_ERROR_HANDLER,
        payload: { resolution: true },
      });
    }

    let checkEmptyMedias = false;
    layersID.forEach((layerId) => {
      if (!layerMedias[layerId]) {
        checkEmptyMedias = true;
      }
    });
    if (checkEmptyMedias) {
      if (missingInputs.length > 0) {
        missingInputs += ", ";
      }
      missingInputs += "Layers (Contents)";
      dispatch({
        type: SET_ERROR_HANDLER,
        payload: { contentLayers: true },
      });
    } else {
      if (!contentLayers || layersID.length === 0 || Object.keys(layerMedias).length === 0) {
        if (missingInputs.length > 0) {
          missingInputs += ", ";
        }
        missingInputs += "Layers (Contents)";
        dispatch({
          type: SET_ERROR_HANDLER,
          payload: { contentLayers: true },
        });
      }
    }

    if (missingInputs.length > 0) {
      errorObj.response.data.message = `Missing inputs: ${missingInputs}.`;
      const message = handleError(errorObj);
      dispatch({ type: SET_ERROR_PROMPT_CMS_PAGE, payload: true });
      dispatch({
        type: SET_ERROR_MESSAGE_CMS_PAGE,
        payload: message,
      });
      handleTimeoutErrorPrompt(dispatch);
      return;
    }

    let parsedLayers = [];
    for (let i=0; i<layersID.length; i++) {
      const layer = contentLayers[layersID[i]];
      const parsed = {
        x: layer.dimensions.left * payload.ratiodWidth,
        y: layer.dimensions.top * payload.ratiodHeight,
        z: 0,
        width: layer.dimensions.width,
        height: layer.dimensions.height,
      };

      let layerAssets = [];
      const medias = layerMedias[layersID[i]];
      for (let j=0; j<medias.length; j++) {
        layerAssets.push({
          id: medias[j].assetID,
          duration: medias[j].interval * 1000, // in ms
          order: j,
        });
      }

      parsed["assets"] = layerAssets;
      parsedLayers.push(parsed);
    }

    let body = {
      name: payload.name,
      width: payload.width,
      height: payload.height,
      organizationId: payload.organizationId,
      layers: parsedLayers,
    };

    try {
      await initiateAXIOS.post(
        instanceCMSContentAPI,
        JSON.stringify(body),
        {
          headers: {
            authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      dispatch({ type: SET_SUCCESS_PROMPT_CMS_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_CMS_PAGE,
        payload: "Submit content success!",
      });
      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_CMS_PAGE, payload: true });
      dispatch({
        type: SET_ERROR_MESSAGE_CMS_PAGE,
        payload: message,
      });
      handleTimeoutErrorPrompt(dispatch);
    } finally {
      callback();
    }
  };
};

export const setErrorHandler = (payload) => {
  return async (dispatch) => {
    dispatch({ type: SET_ERROR_HANDLER, payload: payload });
  };
};

const getAsset = (assetID) => {
  if (!assetID) {
    console.error(`error: Asset not found [ID: ${assetID}].`);
  }

  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    const getUrl = `${instanceCMSAssetAPI}/${assetID}`;

    try {
      const { data } = await initiateAXIOS.get(getUrl, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      return data;
    } catch (error) {
      console.error(`error: Asset not found [ID: ${assetID}].`);
    }
  };
};

export const getAssetData = (payload) => {
  return async (dispatch) => {
    const results = {};
    for (let i=0; i<payload.length; i++) {
      const assetID = payload[i].asset?.id;
      const asset = await dispatch(getAsset(assetID));
      results[assetID] = asset;
    };
    dispatch({ type: SET_ASSET_DATA, payload: results });
  };
};

export const removeContent = ({ id, name }, payload, callback) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    let getURL = `${instanceCMSContentAPI}${id}`;

    try {
      await initiateAXIOS.delete(getURL, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      dispatch({ type: SET_SUCCESS_PROMPT_CMS_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_CMS_PAGE,
        payload: `Delete content "${name}" success!`,
      });
      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_CMS_PAGE, payload: true });
      dispatch({
        type: SET_ERROR_MESSAGE_CMS_PAGE,
        payload: message,
      });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch(getAllContents(payload));
      callback();
    }
  };
};
