import React, { useState } from "react";
import { useDispatch } from "react-redux";

import {
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  DialogTitle,
  IconButton,
  Divider,
  Button,
  InputLabel,
  TextField,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

import { createGroup } from "../../store/actions/groupAction";

const CreateGroup = () => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setName("");
    setOpen(false);
  };

  const submitForm = () => {
    const payload = { name };

    dispatch(createGroup(payload));
    handleClickClose();
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        startIcon={<AddIcon fontSize="small" />}
        sx={{ boxShadow: 3 }}
      >
        Create New Group
      </Button>

      <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClickClose}>
        <Box>
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            New Group
            <IconButton onClick={handleClickClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <Divider />

          <DialogContent>
            <InputLabel>Group Name</InputLabel>

            <TextField
              autoFocus
              margin="dense"
              fullWidth
              name="groupName"
              placeholder="Enter group name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </DialogContent>

          <DialogActions>
            <Button fullWidth variant="outlined" onClick={handleClickClose}>
              Cancel
            </Button>
            <Button fullWidth variant="contained" onClick={submitForm}>
              Submit
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default CreateGroup;
