import React, { useState } from "react";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const BackToContent = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const backNavigate = () => {
    navigate("/cms/content-template");
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Button onClick={handleOpen} startIcon={<ChevronLeftIcon />}>
        {"Back to content list"}
      </Button>

      <Dialog open={open} onClose={handleClose} maxWidth="sm">
        <DialogContent>
          <DialogContentText
            sx={{
              textAlign: "center",
              mb: 2,
            }}
          >
            Are you sure to go back to Content page?
          </DialogContentText>

          <Box>
            <Alert severity="warning" variant="outlined">
              <strong>All changes in this page will be lost</strong>
            </Alert>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            fullWidth
            startIcon={<ClearIcon />}
            onClick={handleClose}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            fullWidth
            startIcon={<DoneIcon />}
            onClick={backNavigate}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BackToContent;
