export default class Viewer {
  constructor(payload) {
    this.id = payload.id ?? null;
    this.key = payload.key ?? null;
    this.name = payload.name ?? null;
    this.schedules = payload.schedules ?? [];
    this.screenshotUpdatedAt = payload.screenshotUpdatedAt;
    this.status = {
      connectionStatus: payload?.connectionStatus ?? "OFFLINE",
      displayStatus: payload?.displayStatus ?? "OFF",
      ipAddress: payload?.status?.ipAddress ?? null,
      contentId: payload?.status?.contentId ?? null,
      contentName: payload?.status?.contentName ?? null,
      version: payload?.status?.version ?? null,
    };
    this.activeAt = payload.activeAt ?? null;
    this.screenGroup = payload.screenGroup ?? null;
    this.screenGroupId = payload.screenGroupId ?? null;
    this.organizationId = payload.organizationId ?? null;
  }
}
