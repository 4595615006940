import {
  SET_HOME_ACTIVE_SCHEDULE,
  SET_HOME_ACTIVE_VIEWER,
  SET_HOME_GROUP,
  SET_HOME_LAST_VIEWER_ACTIVE,
  SET_HOME_LOADING_GROUP,
  SET_HOME_LOADING_LAST_VIEWER_ACTIVE,
  SET_HOME_LOADING_SCHEDULE,
  SET_HOME_LOADING_SCHEDULE_PENDING_ACTIVE_STATUS,
  SET_HOME_LOADING_VIEWER,
  SET_HOME_SCHEDULE_PENDING_ACTIVE_STATUS,
  SET_HOME_VIEWER,
  SET_HOME_ACTIVE_ANNOUNCEMENT,
  SET_HOME_LOADING_ACTIVE_ANNOUNCEMENT,
} from "../actionTypes/homeActionType";

const initialState = {
  loadingViewersData: false,
  viewersData: [],
  activeViewersData: [],
  loadingGroupsData: false,
  groupsData: [],
  loadingSchedulesData: false,
  activeSchedulesData: [],
  loadingLastActiveViewers: false,
  lastActiveViewers: [],
  pendingActiveSchedulesData: [],
  loadingPendingActiveSchedulesData: false,
  activeAnnouncements: [],
  loadingActiveAnnouncementsData: false,
};

export const homeReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_HOME_VIEWER:
      return { ...state, viewersData: payload };

    case SET_HOME_ACTIVE_VIEWER:
      return { ...state, activeViewersData: payload };

    case SET_HOME_GROUP:
      return { ...state, groupsData: payload };

    case SET_HOME_ACTIVE_SCHEDULE:
      return { ...state, activeSchedulesData: payload };

    case SET_HOME_LAST_VIEWER_ACTIVE:
      return { ...state, lastActiveViewers: payload };

    case SET_HOME_SCHEDULE_PENDING_ACTIVE_STATUS:
      return { ...state, pendingActiveSchedulesData: payload };

    case SET_HOME_LOADING_VIEWER:
      return { ...state, loadingViewersData: payload };

    case SET_HOME_LOADING_GROUP:
      return { ...state, loadingGroupsData: payload };

    case SET_HOME_LOADING_SCHEDULE:
      return { ...state, loadingSchedulesData: payload };

    case SET_HOME_LOADING_LAST_VIEWER_ACTIVE:
      return { ...state, loadingLastActiveViewers: payload };

    case SET_HOME_LOADING_SCHEDULE_PENDING_ACTIVE_STATUS:
      return { ...state, loadingPendingActiveSchedulesData: payload };

    case SET_HOME_ACTIVE_ANNOUNCEMENT:
      return { ...state, activeAnnouncements: payload };

    case SET_HOME_LOADING_ACTIVE_ANNOUNCEMENT:
      return { ...state, loadingActiveAnnouncementsData: payload };

    default:
      return state;
  }
};
