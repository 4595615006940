import {
  SET_DELETE_ORGANIZATION,
  SET_ORGANIZATION,
  SET_EDIT_ORGANIZATION,
  SET_ERROR_MESSAGE_ORGANIZATION_PAGE,
  SET_ERROR_PROMPT_ORGANIZATION_PAGE,
  SET_LOADING_ORGANIZATION,
  SET_NEW_ORGANIZATION,
  SET_SUCCESS_MESSAGE_ORGANIZATION_PAGE,
  SET_SUCCESS_PROMPT_ORGANIZATION_PAGE,
} from "../actionTypes/organizationActionType";

const initialState = {
  organizations: [],
  loadingOrganization: false,
  isSuccessPromptOrganizationPage: false,
  isErrorPromptOrganizationPage: false,
  successMessageOrganizationPage: "",
  errorMessageOrganizationPage: "",
};

export const organizationReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_ORGANIZATION:
      return { ...state, organizations: payload };

    case SET_LOADING_ORGANIZATION:
      return { ...state, loadingOrganization: payload };

    case SET_NEW_ORGANIZATION:
      return { ...state, organizations: [payload, ...state.organizations] };

    case SET_DELETE_ORGANIZATION:
      return {
        ...state,
        organizations: state.organizations.filter(
          (organization) => organization.id.toString() !== payload.toString()
        ),
      };

    case SET_EDIT_ORGANIZATION:
      const data = state.organizations;
      const objIndex = data.findIndex((obj) => obj.id === payload.id);
      data[objIndex] = payload;
      return { ...state, organizations: [...data] };

    case SET_SUCCESS_PROMPT_ORGANIZATION_PAGE:
      return { ...state, isSuccessPromptOrganizationPage: payload };

    case SET_ERROR_PROMPT_ORGANIZATION_PAGE:
      return { ...state, isErrorPromptOrganizationPage: payload };

    case SET_SUCCESS_MESSAGE_ORGANIZATION_PAGE:
      return { ...state, successMessageOrganizationPage: payload };

    case SET_ERROR_MESSAGE_ORGANIZATION_PAGE:
      return { ...state, errorMessageOrganizationPage: payload };
    default:
      return state;
  }
};
