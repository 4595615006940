import React from "react";

const AnnouncementIcon = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.0001 14.1178V19.2402C11.0001 20.2121 10.2122 21 9.24023 21C8.49651 21 7.83307 20.5325 7.58293 19.8321L5.43652 13.6829"
          stroke="#FFBD80"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18 13C19.6569 13 21 11.6569 21 10C21 8.34315 19.6569 7 18 7"
          stroke="#FFBD80"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11 5.88218V13M8.83208 6H6.99999C4.79086 6 3 7.79086 3 10C3 11.6543 4.0043 13.0741 5.43647 13.6829C5.91677 13.887 6.44518 14 6.99998 14L8.83208 14C12.9327 14 16.4569 15.2341 18 17L18 3C16.4569 4.7659 12.9327 6 8.83208 6Z"
          stroke="#F55858"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default AnnouncementIcon;
