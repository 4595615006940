import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  CssBaseline,
  Box,
  Toolbar,
  Container,
  Grid,
  Divider,
} from "@mui/material";
import {
  Typography2XLarge,
  TypographyLarge,
  TypographyNormal,
} from "../customComponent";

import { GlobalErrorPrompt, GlobalSuccessPrompt, Sidebar } from "..";
import ViewerCard from "./ViewerCard";
import ActiveViewerCard from "./ActiveViewerCard";
import GroupCard from "./GroupCard";
import ScheduleCard from "./ScheduleCard";
import HomeScheduleList from "./HomeScheduleList";
import HomeLastActiveViewerList from "./HomeLastActiveViewerList";
import ActiveAnnouncementCard from "./ActiveAnnouncementCard";

import { getAllViewers } from "../../store/actions/homeAction";
import { decrypt } from "../../helpers/crypto";

const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllViewers());
  }, [dispatch]);

  const { loggedUser } = useSelector((state) => state.auth);
  const decryptedLoggedUser = decrypt(loggedUser);
  const username = decryptedLoggedUser.name;

  const { viewersData, activeViewersData } = useSelector((state) => state.home);

  return (
    <div style={{ display: "flex" }}>
      <Sidebar />

      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Toolbar />
        <CssBaseline />

        <GlobalErrorPrompt />
        <GlobalSuccessPrompt />

        <Container maxWidth="xl">
          <Typography2XLarge>{username}</Typography2XLarge>
          <TypographyLarge sx={{ fontWeight: 300, mb: 1 }}>
            Manage your digital signage viewers, schedules and groups.
          </TypographyLarge>

          <TypographyNormal sx={{ fontWeight: 300, mb: 1 }}>
            OVERVIEW
          </TypographyNormal>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={3}>
              <ViewerCard viewersData={viewersData} />
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <ActiveViewerCard activeViewersData={activeViewersData} />
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <GroupCard />
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <ScheduleCard />
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <ActiveAnnouncementCard />
            </Grid>

            <Grid item xs={12} lg={6}>
              <HomeLastActiveViewerList />
            </Grid>

            <Grid item xs={12} lg={6}>
              <HomeScheduleList />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default Home;
