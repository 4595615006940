import React from "react";
import { colorStyling } from "../../helpers/color";
import { dateFormat } from "../../helpers/dateFormat";
import {
  ClockIcon,
  DotIcon,
  GroupBlueIcon,
  ScheduleYellowIcon,
  ViewerBlueIcon,
} from "../../icons";
import { Box } from "@mui/material";
import { TypographyNormal, TypographySmall } from "../customComponent";

const AssignItem = ({ getName, data }) => {
  return (
    <>
      {getName === "schedule" && <ScheduleItem schedule={data} />}
      {getName === "viewer" && <ViewerItem viewer={data} />}
      {getName === "group" && <GroupItem group={data} />}
    </>
  );
};

export default AssignItem;

const ScheduleItem = ({ schedule }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <ScheduleYellowIcon />

      <Box
        sx={{
          ml: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TypographyNormal sx={{ fontWeight: 600, mb: 1 }}>
          {schedule.name}
        </TypographyNormal>

        <Box display="flex" alignItems="center">
          <ClockIcon />
          <TypographySmall
            sx={{
              marginLeft: "5px",
              marginRight: "5px",
              textTransform: "lowercase",
              color: colorStyling.primary,
              fontWeight: 300,
            }}
          >
            {schedule.frequency === "OFF" ? "ONCE" : schedule.frequency}
          </TypographySmall>
          <DotIcon />
          <TypographySmall sx={{ marginLeft: "5px", fontWeight: 300 }}>
            {dateFormat(schedule.startAt)}
            {schedule.endAt ? " - " + dateFormat(schedule.endAt) : ""}
          </TypographySmall>
        </Box>
      </Box>
    </Box>
  );
};

const ViewerItem = ({ viewer }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <ViewerBlueIcon />

      <Box sx={{ ml: 2, display: "flex", alignItems: "center" }}>
        <TypographyNormal sx={{ fontWeight: 600 }}>
          {viewer.name}
        </TypographyNormal>
      </Box>
    </Box>
  );
};

const GroupItem = ({ group }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <GroupBlueIcon />

      <Box sx={{ ml: 2, display: "flex", alignItems: "center" }}>
        <TypographyNormal sx={{ fontWeight: 600 }}>
          {group.name}
        </TypographyNormal>
      </Box>
    </Box>
  );
};
