import {
  SET_EQUIPMENT,
  SET_EQUIPMENT_BREAKDOWN,
  SET_ERROR_MESSAGE_ANNOUNCEMENT_EQUIPMENT_BREAKDOWN,
  SET_ERROR_PROMPT_ANNOUNCEMENT_EQUIPMENT_BREAKDOWN,
  SET_ERROR_MESSAGE_EQUIPMENT_BREAKDOWN,
  SET_ERROR_PROMPT_EQUIPMENT_BREAKDOWN,
  SET_SUCCESS_MESSAGE_EQUIPMENT_BREAKDOWN,
  SET_SUCCESS_PROMPT_EQUIPMENT_BREAKDOWN,
  SET_EDIT_EQUIPMENT_LINK_ANNOUNCEMENT,
  SET_LOADING_ANNOUNCEMENT_EQUIPMENT_BREAKDOWN,
  SET_LOADING_LINK_EQUIPMENT_BREAKDOWN,
  SET_EDIT_EQUIPMENT,
  SET_BREAKDOWN_ANNOUNCEMENT,
  SET_EDIT_BREAKDOWN_ANNOUNCEMENT,
} from "../actionTypes/equipmentActionType";

const initialState = {
  equipment: [],
  equipmentBreakdown: [],
  breakdownAnnouncement: {},
  loadingAnnouncement: false,
  loadingLinkAnnouncement: false,
  isSuccessPromptEquipmentPage: false,
  successMessageEquipmentPage: "",
  isErrorPromptEquipmentPage: false,
  isErrorPromptEquipmentAnnouncementPage: false,
  errorMessageEquipmentPage: "",
  errorMessageEquipmentAnnouncementPage: "",
};

export const equipmentReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_EQUIPMENT_BREAKDOWN:
      return {
        ...state,
        equipmentBreakdown: payload,
      };

    case SET_EQUIPMENT:
      return {
        ...state,
        equipment: payload,
      };

    case SET_BREAKDOWN_ANNOUNCEMENT:
      return { ...state, breakdownAnnouncement: payload };

    case SET_EDIT_BREAKDOWN_ANNOUNCEMENT:
      let annData = state.breakdownAnnouncement;
      annData.status = "ANNOUNCEMENT_" + payload.status;
      return { ...state, breakdownAnnouncement: annData };

    case SET_EDIT_EQUIPMENT:
      const editedData = state.equipment;
      payload.forEach((item) => {
        const editedObjIndex = editedData.findIndex(
          (obj) => obj.id === item.id
        );
        editedData[editedObjIndex] = item;
      });
      return { ...state, equipment: [...editedData] };

    case SET_ERROR_MESSAGE_ANNOUNCEMENT_EQUIPMENT_BREAKDOWN:
      return { ...state, errorMessageEquipmentAnnouncementPage: payload };

    case SET_ERROR_PROMPT_ANNOUNCEMENT_EQUIPMENT_BREAKDOWN:
      return { ...state, isErrorPromptEquipmentAnnouncementPage: payload };

    case SET_ERROR_MESSAGE_EQUIPMENT_BREAKDOWN:
      return { ...state, errorMessageEquipmentPage: payload };

    case SET_ERROR_PROMPT_EQUIPMENT_BREAKDOWN:
      return { ...state, isErrorPromptEquipmentPage: payload };

    case SET_SUCCESS_MESSAGE_EQUIPMENT_BREAKDOWN:
      return { ...state, successMessageEquipmentPage: payload };

    case SET_SUCCESS_PROMPT_EQUIPMENT_BREAKDOWN:
      return { ...state, isSuccessPromptEquipmentPage: payload };

    case SET_EDIT_EQUIPMENT_LINK_ANNOUNCEMENT:
      const linkData = state.equipmentBreakdown;
      const filterObjResData = [];
      payload.forEach(({ station, ...item }) => filterObjResData.push(item));
      filterObjResData.forEach((item) => {
        const linkObjIndex = linkData.findIndex(
          (obj) => obj.id === item.stationId
        );
        const stationData = linkData[linkObjIndex];
        const equipObjIndex = stationData.equipments.findIndex(
          (obj) => obj.id === item.id
        );
        stationData.equipments[equipObjIndex] = item;
        linkData[linkObjIndex] = stationData;
      });
      return { ...state, equipmentBreakdown: [...linkData] };

    case SET_LOADING_ANNOUNCEMENT_EQUIPMENT_BREAKDOWN:
      return { ...state, loadingAnnouncement: payload };

    case SET_LOADING_LINK_EQUIPMENT_BREAKDOWN:
      return { ...state, loadingLinkAnnouncement: payload };

    default:
      return state;
  }
};
