import React from "react";

import { Box, CircularProgress } from "@mui/material";

import { TypographyNormal, TypographyXLarge } from "../../customComponent";
import {
  DotIcon,
  GroupBigBlueIcon,
  ViewerGreyIcon,
  ScheduleOrangeIcon,
} from "../../../icons";

const GroupInfo = ({ group, loading }) => {
  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <CircularProgress size={20} thickness={3} sx={{ color: "#1D4ED8" }} />
        <TypographyNormal sx={{ color: "#1D4ED8", mt: 2, fontWeight: 300 }}>
          Loading Data...
        </TypographyNormal>
      </Box>
    );

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <GroupBigBlueIcon />

        <Box sx={{ ml: 2 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <TypographyXLarge sx={{ fontWeight: 700 }}>
              {group.name}
            </TypographyXLarge>
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <TypographyNormal
          sx={{ fontWeight: 500, display: "flex", alignItems: "center", mr: 2 }}
        >
          <ViewerGreyIcon />
          {group.screensCount ?? 0} viewers
        </TypographyNormal>

        <DotIcon />

        <TypographyNormal
          sx={{ fontWeight: 500, display: "flex", alignItems: "center", ml: 2 }}
        >
          <ScheduleOrangeIcon />
          <Box component="span" sx={{ ml: 1 }}>
            {group.schedulesCount ?? 0} schedules
          </Box>
        </TypographyNormal>
      </Box>
    </Box>
  );
};

export default GroupInfo;
