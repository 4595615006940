import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  IconButton,
  InputLabel,
  TextField,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  AlertTitle,
  Alert,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

import {
  CustomTextField,
  TypographyNormal,
  TypographySmall,
} from "../customComponent";
import { createSchedule } from "../../store/actions/scheduleAction";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createScheduleSchema } from "../../schemas/createScheduleSchema";
import { initiateAXIOS } from "../../config/axios";
import { contentCMSAPILimit } from "../../api";

const StyledBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  ":last-child": {
    marginBottom: 0,
  },
}));

const CreateSchedule = () => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("false");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [duration, setDuration] = useState(0);

  const [loadingContentList, setLoadingContentList] = useState(false);
  const [customContentList, setCustomContentList] = useState([]);
  const [selectCustomContent, setSelectCustomContent] = useState("");

  const [selectPriority, setSelectPriority] = useState(2);

  const [checkboxEndDate, setCheckboxEndDate] = useState(false);
  const [checkboxIndefiniteDuration, setCheckboxIndefiniteDuration] =
    useState(false);

  const [radioValue, setRadioValue] = useState(0);
  const [dayCheckbox, setDayCheckbox] = useState([]);
  // TODO Next month pick feature implementation
  // const [monthCheckbox, setMonthCheckbox] = useState([]);

  /**
   * Handle opening dialog
   */
  const handleClickOpen = () => {
    setOpen(true);
  };

  /**
   * Handle closing dialog
   */
  const handleClickClose = () => {
    handleResetForm();
    setOpen(false);
  };

  /**
   * Handle closing error alert
   */
  const handleCloseErr = () => {
    setIsError(false);
  };

  /**
   * Reset form data
   */
  const handleResetForm = () => {
    reset({
      name: "",
      frequency: "",
      TYPE: "",
      COMMAND: "",
    });

    setStartDate("");
    setEndDate("");
    setCustomContentList([]);
    setSelectCustomContent("");
    setSelectPriority(2);
    setCheckboxEndDate(false);
    setCheckboxIndefiniteDuration(false);
    setDuration(0);
  };

  /**
   *  Handling start date value
   */
  const handleStartDate = (e) => {
    setStartDate(e.target.value);
    setEndDate(e.target.value);
  };

  /**
   * Handling end date value
   * @param {*} e Input Event
   */
  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  /**
   * Handling duration field change
   * @param {*} e Input Value
   */
  const handleChangeDuration = (e) => {
    const newValue = +e.target.value;
    if (
      Number.isInteger(newValue) &&
      newValue >= 0 &&
      newValue.toString().length < 9
    )
      setDuration(newValue);
  };

  /**
   * Yup form validation and function handling
   */
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(createScheduleSchema),
  });

  /**
   * to get Yup form value
   */
  const watchAllFields = watch();

  /**
   * Calculate cron according to frequency and recurrence option
   * @param {*} frequency
   * @param {*} date Form start date
   * @returns cron string
   */
  const calculateCron = (frequency, date) => {
    let cron;

    const dateUTC = date.getUTCDate();
    const hour = date.getUTCHours();
    const minute = date.getUTCMinutes();

    switch (frequency) {
      case "ONCE":
        cron = null;
        break;

      case "DAILY":
        let dailyDayofWeek;
        if (+radioValue === 0) dailyDayofWeek = "*";
        if (+radioValue === 1) dailyDayofWeek = "1-5";
        if (+radioValue === 2) dailyDayofWeek = "0,6";
        cron = `${minute} ${hour} * * ${dailyDayofWeek}`;
        break;

      case "WEEKLY":
        cron = `${minute} ${hour} * * ${dayCheckbox.toString()}`;
        break;

      case "MONTHLY":
        cron = `${minute} ${hour} ${dateUTC} * *`;
        break;

      default:
        return frequency;
    }

    return cron;
  };

  /**
   * Form submit function, handling payload to sent to api
   * @param {*} data Form data from Yup
   */
  const submitForm = (data) => {
    let { name, frequency, COMMAND } = data;

    const sDate = new Date(startDate);
    const eDate = new Date(endDate);

    const startDateISO = sDate.toISOString();
    const endDateISO = eDate.toISOString();

    var payload = {
      name,
      startAt: startDateISO,
      cron: calculateCron(frequency, sDate),
      command: COMMAND,
      type: "SCHEDULE",
    };

    if (checkboxEndDate) {
      payload["endAt"] = endDateISO;
    }

    if (COMMAND === "PLAY_CONTENT") {
      payload["contentId"] = +selectCustomContent;
      payload["priority"] = +selectPriority;

      if (!checkboxIndefiniteDuration) {
        payload["duration"] = 1000 * +duration;
      }
    } else if (frequency === "ONCE") payload["endAt"] = startDateISO;

    dispatch(createSchedule(payload));

    handleResetForm();
    setOpen(false);
  };

  /**
   * API call to get cms content list
   */
  const getCMSContent = async () => {
    const access_token = sessionStorage.getItem("access_token");
    setLoadingContentList(true);
    try {
      const { data } = await initiateAXIOS.get(contentCMSAPILimit, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      setCustomContentList(data.items);
    } catch (error) {
      setIsError(true);
      setErrorMessage("Cannot retrieve content list");
      setTimeout(handleCloseErr, 5000);
    } finally {
      setLoadingContentList(false);
    }
  };

  /**
   * Running cms content fetch when user selecting Play Content action
   */
  useEffect(() => {
    if (watchAllFields.COMMAND === "PLAY_CONTENT") {
      getCMSContent();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAllFields.COMMAND]);

  /**
   * Returning list UI and function for weekly day checkbox
   * @returns Checkbox Day JSX element
   */
  const weeklyCheckbox = () => {
    const dayData = [
      { text: "Monday", value: "1" },
      { text: "Tuesday", value: "2" },
      { text: "Wednesday", value: "3" },
      { text: "Thursday", value: "4" },
      { text: "Friday", value: "5" },
      { text: "Saturday", value: "6" },
      { text: "Sunday", value: "0" },
    ];

    const handleChangeDayCheckbox = (e, day) => {
      if (e.target.checked) setDayCheckbox([...dayCheckbox, day]);
      else setDayCheckbox(dayCheckbox.filter((d) => d !== day));
    };

    return dayData.map((day) => (
      <Box
        key={"check" + day.text}
        display={"flex"}
        flexDirection={"column"}
        flexWrap={"nowrap"}
        alignItems={"center"}
      >
        <TypographyNormal>{day.text}</TypographyNormal>
        <Checkbox
          checked={dayCheckbox.includes(day.value)}
          onChange={(e) => {
            handleChangeDayCheckbox(e, day.value);
          }}
        />
      </Box>
    ));
  };

  // TODO Next month pick feature implementation
  // const monthlyCheckbox = () => {
  //   const monthData = [
  //     { text: "Jan", value: "1" },
  //     { text: "Feb", value: "2" },
  //     { text: "Mar", value: "3" },
  //     { text: "Apr", value: "4" },
  //     { text: "May", value: "5" },
  //     { text: "Jun", value: "6" },
  //     { text: "Jul", value: "7" },
  //     { text: "Aug", value: "8" },
  //     { text: "Sep", value: "9" },
  //     { text: "Oct", value: "10" },
  //     { text: "Nov", value: "11" },
  //     { text: "Dec", value: "12" },
  //   ];

  //   const handleChangeMonthCheckbox = (e, day) => {
  //     if (e.target.checked) setMonthCheckbox([...monthCheckbox, day]);
  //     else setMonthCheckbox(monthCheckbox.filter((d) => d !== day));
  //   };

  //   return monthData.map((month) => (
  //     <Box
  //       key={"check" + month.text}
  //       display={"flex"}
  //       flexDirection={"column"}
  //       flexWrap={"nowrap"}
  //       alignItems={"center"}
  //     >
  //       <TypographyNormal>{month.text}</TypographyNormal>
  //       <Checkbox
  //         checked={monthCheckbox.includes(month.value)}
  //         onChange={(e) => {
  //           handleChangeMonthCheckbox(e, month.value);
  //         }}
  //       />
  //     </Box>
  //   ));
  // };
  return (
    <>
      <Button
        onClick={handleClickOpen}
        variant="contained"
        startIcon={<AddIcon />}
        sx={{ boxShadow: 3 }}
      >
        Create New Schedule
      </Button>

      <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClickClose}>
        <Box component="form" onSubmit={handleSubmit(submitForm)}>
          <DialogTitle
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            Create New Schedule
            <IconButton onClick={handleClickClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            {isError && (
              <Alert
                sx={{ my: 2 }}
                severity="error"
                variant="filled"
                onClose={handleCloseErr}
              >
                <AlertTitle>Something went wrong!</AlertTitle>
                {errorMessage}
              </Alert>
            )}

            <StyledBox>
              <InputLabel>Schedule Name</InputLabel>

              <TextField
                size="small"
                margin="dense"
                {...register("name")}
                fullWidth
                variant="outlined"
                placeholder="Enter schedule name"
                helperText={errors.name?.message}
              />
            </StyledBox>

            <StyledBox>
              <InputLabel>Frequency</InputLabel>

              <TextField
                size="small"
                margin="dense"
                {...register("frequency")}
                select
                SelectProps={{
                  native: true,
                }}
                required
                fullWidth
                helperText={errors.frequency?.message}
              >
                <option value="">Select Frequency</option>
                <option value="ONCE">Once</option>
                <option value="DAILY">Daily</option>
                <option value="WEEKLY">Weekly</option>
                <option value="MONTHLY">Monthly</option>
              </TextField>

              {watchAllFields.frequency !== "ONCE" &&
                watchAllFields.frequency !== "MONTHLY" &&
                watchAllFields.frequency !== undefined &&
                watchAllFields.frequency !== "" && (
                  <Box
                    sx={{
                      mt: 0.25,
                      px: 1,
                      pt: 1,
                      borderRadius: "4px",
                      border: "1px solid gray",
                    }}
                  >
                    <InputLabel>Recurrence Option</InputLabel>
                    {watchAllFields.frequency === "DAILY" && (
                      <RadioGroup
                        value={radioValue}
                        onChange={(e) => {
                          setRadioValue(e.target.value);
                        }}
                      >
                        <FormControlLabel
                          value={0}
                          control={<Radio size="small" />}
                          label="Everyday"
                        />
                        <FormControlLabel
                          value={1}
                          control={<Radio size="small" />}
                          label="Weekdays (Mon-Fri)"
                        />
                        <FormControlLabel
                          value={2}
                          control={<Radio size="small" />}
                          label="Weekends (Sat-Sun)"
                        />
                      </RadioGroup>
                    )}

                    {watchAllFields.frequency === "WEEKLY" && (
                      <Box
                        sx={{ mt: 0.5 }}
                        display={"flex"}
                        justifyContent={"space-around"}
                      >
                        {weeklyCheckbox()}
                      </Box>
                    )}

                    {/**
                     * TODO Next month pick feature implementation
                     */}
                    {/* {watchAllFields.frequency === "MONTHLY" && (
                      <Box
                        sx={{ mt: 0.5 }}
                        display={"flex"}
                        justifyContent={"space-around"}
                      >
                        {monthlyCheckbox()}
                      </Box>
                    )} */}
                  </Box>
                )}
            </StyledBox>

            <Box sx={{ display: "flex", alignItems: "end", mb: 1 }}>
              <StyledBox sx={{ width: "100%", mb: 0 }}>
                <InputLabel>Start Date</InputLabel>

                <TextField
                  size="small"
                  fullWidth
                  value={startDate}
                  onChange={handleStartDate}
                  required
                  margin="dense"
                  type="datetime-local"
                  variant="outlined"
                />
              </StyledBox>

              {watchAllFields.frequency === "ONCE" ? null : (
                <Box
                  sx={{
                    width: "100%",
                    ml: 2,
                  }}
                >
                  <Box sx={{ pb: checkboxEndDate ? 0 : 0.5 }}>
                    <FormControlLabel
                      control={<Checkbox />}
                      value={checkboxEndDate}
                      checked={checkboxEndDate}
                      name={"showEndDate"}
                      onChange={(e) => {
                        setCheckboxEndDate(e.target.checked);
                      }}
                      label={"Assign End Date"}
                    />
                  </Box>

                  {checkboxEndDate && (
                    <StyledBox sx={{ width: "100%" }}>
                      <InputLabel>End Date</InputLabel>

                      <TextField
                        size="small"
                        fullWidth
                        value={endDate}
                        onChange={handleEndDate}
                        margin="dense"
                        type="datetime-local"
                        variant="outlined"
                      />
                    </StyledBox>
                  )}
                </Box>
              )}
            </Box>

            <Box sx={{ display: "flex" }}>
              <StyledBox sx={{ width: "100%" }}>
                <InputLabel>Action Type</InputLabel>

                <TextField
                  size="small"
                  fullWidth
                  {...register("TYPE")}
                  select
                  margin="dense"
                  SelectProps={{
                    native: true,
                  }}
                  required
                  helperText={errors.TYPE?.message}
                >
                  <option value="">Select Type</option>
                  <option value="CONTENT">Content</option>
                  <option value="DISPLAY">Display</option>
                  <option value="PC">PC</option>
                </TextField>
              </StyledBox>

              <StyledBox sx={{ width: "100%", ml: 2 }}>
                <InputLabel>Action</InputLabel>

                <TextField
                  size="small"
                  {...register("COMMAND")}
                  select
                  margin="dense"
                  SelectProps={{
                    native: true,
                  }}
                  required
                  fullWidth
                  helperText={errors.COMMAND && "command is required field"}
                >
                  <option value="">Select Action</option>
                  {watchAllFields.TYPE === "CONTENT" ? (
                    <option value="PLAY_CONTENT">Play Content</option>
                  ) : null}

                  {watchAllFields.TYPE === "PC" ? (
                    <option value="REBOOT">Reboot</option>
                  ) : null}
                  {watchAllFields.TYPE === "PC" ? (
                    <option value="SHUTDOWN">Shut Down</option>
                  ) : null}

                  {watchAllFields.TYPE === "DISPLAY" ? (
                    <option value="DISPLAY_ON">Turn On</option>
                  ) : null}
                  {watchAllFields.TYPE === "DISPLAY" ? (
                    <option value="DISPLAY_OFF">Turn Off</option>
                  ) : null}
                  {watchAllFields.TYPE === "DISPLAY" ? (
                    <option value="DISPLAY_HDMI1">HDMI 1</option>
                  ) : null}
                  {watchAllFields.TYPE === "DISPLAY" ? (
                    <option value="DISPLAY_HDMI2">HDMI 2</option>
                  ) : null}
                </TextField>

                <TypographySmall>
                  Fill "Action Type" field first!
                </TypographySmall>
              </StyledBox>
            </Box>

            {loadingContentList && (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ width: "100%", height: 200 }}
              >
                <CircularProgress />
              </Box>
            )}

            {watchAllFields.TYPE === "CONTENT" &&
            watchAllFields.COMMAND === "PLAY_CONTENT" &&
            !loadingContentList ? (
              <>
                <StyledBox>
                  <InputLabel>Select Content</InputLabel>
                  <CustomTextField
                    size="small"
                    select
                    required
                    SelectProps={{ native: true }}
                    fullWidth
                    margin="dense"
                    name="contentSelect"
                    value={selectCustomContent}
                    onChange={(e) => {
                      setSelectCustomContent(e.target.value);
                    }}
                  >
                    <option value="" disabled>
                      Choose Content
                    </option>
                    {customContentList.map((content) => (
                      <option value={content.id} key={content.id}>
                        {content.name}
                      </option>
                    ))}
                  </CustomTextField>
                </StyledBox>

                <Box sx={{ display: "flex" }}>
                  <StyledBox sx={{ width: "100%" }}>
                    <InputLabel>{"Duration (in second)"}</InputLabel>

                    <TextField
                      size="small"
                      margin="dense"
                      fullWidth
                      variant="outlined"
                      type="number"
                      InputProps={{
                        inputProps: { min: 0 },
                      }}
                      value={duration.toString()}
                      disabled={checkboxIndefiniteDuration}
                      onChange={(e) => {
                        handleChangeDuration(e);
                      }}
                      helperText={
                        duration === 0 && !checkboxIndefiniteDuration
                          ? "Please input the content duration"
                          : ""
                      }
                    />

                    <FormControlLabel
                      control={<Checkbox size="small" />}
                      value={checkboxIndefiniteDuration}
                      onChange={(e) => {
                        setCheckboxIndefiniteDuration(e.target.checked);
                        setDuration(0);
                      }}
                      label={
                        <TypographySmall>Play indefinitely</TypographySmall>
                      }
                    />
                  </StyledBox>

                  <StyledBox sx={{ width: "100%", ml: 2 }}>
                    <InputLabel>Select Priority</InputLabel>
                    <CustomTextField
                      size="small"
                      select
                      required
                      SelectProps={{ native: true }}
                      fullWidth
                      margin="dense"
                      name="contentSelect"
                      value={selectPriority}
                      onChange={(e) => {
                        setSelectPriority(+e.target.value);
                      }}
                    >
                      <option value="0">Highest</option>
                      <option value="1">High</option>
                      <option value="2">Normal</option>
                      <option value="3">Low</option>
                      <option value="4">Lowest</option>
                    </CustomTextField>
                  </StyledBox>
                </Box>
              </>
            ) : null}
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" onClick={handleClickClose} fullWidth>
              Cancel
            </Button>

            <Button variant="contained" type="submit" fullWidth>
              Create
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default CreateSchedule;
