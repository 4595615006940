import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Alert, Box, CircularProgress, Pagination } from "@mui/material";

import ScheduleItem from "./ScheduleItem";
import { TypographyNormal } from "../customComponent";
import { usePagination } from "../hooks";
import { getSchedules } from "../../store/actions/scheduleAction";

const ScheduleList = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSchedules());
  }, [dispatch]);

  const { schedules, loadingListOfSchedules: loading } = useSelector(
    (state) => state.schedule
  );

  let [page, setPage] = useState(1);
  const PER_PAGE = 5;
  const count = Math.ceil(schedules.length / PER_PAGE);
  const _DATA = usePagination(schedules, PER_PAGE);

  const handleChangePagination = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  useEffect(() => {
    setPage(1);
    _DATA.jump(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedules]);

  if (loading)
    return (
      <Box
        sx={{ height: "20vh" }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <CircularProgress size={22} thickness={3} sx={{ color: "#1D4ED8" }} />
        <TypographyNormal sx={{ color: "#1D4ED8", mt: 2, fontWeight: 300 }}>
          Loading Data...
        </TypographyNormal>
      </Box>
    );

  return (
    <>
      <Box>
        {!loading && !schedules.length ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Alert severity="info" variant="outlined">
              <strong>No results found!</strong> There are no available
              schedules at the moment or We couldn't find what you're looking
              for
            </Alert>
          </Box>
        ) : (
          _DATA
            .currentData()
            .map((schedule) => (
              <ScheduleItem key={schedule.id} schedule={schedule} />
            ))
        )}

        {!loading && schedules.length ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 4,
            }}
          >
            <Pagination
              count={count}
              page={page}
              shape="rounded"
              color="primary"
              onChange={handleChangePagination}
            />
          </Box>
        ) : null}
      </Box>
    </>
  );
};

export default ScheduleList;
