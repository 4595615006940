import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  IconButton,
  InputLabel,
  Alert,
  AlertTitle,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import {
  TypographyLarge,
  TypographyNormal,
  TypographySmall,
} from "../customComponent";
import {
  editContentName,
  updateContent,
} from "../../store/actions/contentUploadAction";
import FormConfirmation from "./FormConfirmation";

const StyledBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  ":last-child": {
    marginBottom: 0,
  },
}));

const EditContentUpload = ({ data, open, handleClickClose }) => {
  const dispatch = useDispatch();

  const [formErrorPrompt, setFormErrorPrompt] = useState(false);

  const [formErrorMsg, setFormErrorMsg] = useState("");

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [fileUpload, setFileUpload] = useState({});
  const [name, setName] = useState(data.name);

  const handleSelectFile = (event) => {
    const allowedType = [
      "application/zip",
      "application/x-zip-compressed",
      "application/x-zip",
      "application/zip-compressed",
    ];

    if (event.target.files.length === 0) return;
    if (!allowedType.includes(event.target.files[0].type)) {
      setFormErrorPrompt(true);
      setFormErrorMsg("File upload only accept zip file.");
      event.target.value = null;
      return false;
    }
    setFileUpload(event.target.files[0]);
  };

  const handleRemoveFile = () => {
    setFileUpload({});
  };

  const handleSubmit = async () => {
    setOpenConfirmation(false);
    handleClickClose();
    let payload = { id: data.id };

    if (data.name !== name) {
      payload["name"] = name;
    }

    if (fileUpload?.name !== undefined) {
      payload["fileUpload"] = fileUpload;
      dispatch(updateContent({ ...payload }));
    } else {
      if (payload.name) dispatch(editContentName({ ...payload }));
    }
    return;
  };

  return (
    <>
      <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClickClose}>
        <Box
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
            setOpenConfirmation(true);
          }}
        >
          <DialogTitle
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            Edit Content Upload
            <IconButton onClick={handleClickClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            {formErrorPrompt && (
              <Alert sx={{ my: 2 }} severity="error" variant="filled">
                <AlertTitle>Form Validation</AlertTitle>
                {formErrorMsg}
              </Alert>
            )}

            <StyledBox sx={{ mb: 1 }}>
              <InputLabel>Name</InputLabel>

              <TextField
                id="content-name"
                autoFocus
                fullWidth
                margin="dense"
                placeholder="Enter content name"
                value={name}
                size="small"
                onChange={(e) => setName(e.target.value)}
                required
              />
            </StyledBox>

            <StyledBox>
              <InputLabel>File Upload</InputLabel>
              <TypographySmall sx={{ color: "#cc3737" }}>
                Ignore to keep the old file
              </TypographySmall>
              <TypographyLarge>Current file : {data.fileName}</TypographyLarge>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  component="label"
                  size="small"
                  sx={{ mr: 2 }}
                >
                  Choose New File
                  <input
                    type="file"
                    accept="application/zip"
                    hidden
                    onChange={handleSelectFile}
                  />
                </Button>
                <TypographyNormal>{fileUpload.name}</TypographyNormal>
                {fileUpload.name && (
                  <IconButton
                    sx={{ ml: 2 }}
                    color="error"
                    onClick={() => {
                      handleRemoveFile();
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>
              <TypographySmall sx={{ color: "#cc3737" }}>
                *only accept .zip file
              </TypographySmall>
            </StyledBox>
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" onClick={handleClickClose} fullWidth>
              Cancel
            </Button>

            <Button variant="contained" type="submit" fullWidth>
              Edit
            </Button>

            <FormConfirmation
              open={openConfirmation}
              handleClose={setOpenConfirmation}
              handleSubmit={handleSubmit}
              dialogText={"Confirm edit offline content?"}
              alertText={`Confirmation will proceed to edit offline content. In case of editing
                file, please don't leave the page while upload in progress.`}
            />
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default EditContentUpload;
