import React from "react";

const LogoIcon = () => {
  return (
    <>
      <svg
        width="43"
        height="43"
        viewBox="0 0 43 43"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M31.7336 9.16058C30.1024 8.34607 28.9072 9.24166 29.4299 10.8744C29.6125 11.4438 29.9737 11.9933 30.4238 12.4441C30.868 12.901 31.409 13.2674 31.9706 13.4528C33.5794 13.9833 34.4619 12.7703 33.6593 11.1149C33.4598 10.7034 33.1763 10.318 32.8462 9.98575C32.5189 9.65077 32.139 9.36259 31.7336 9.16058Z"
          fill="#00D4FF"
        />
        <path
          d="M15.9807 13.4173C16.6632 16.0299 18.0201 16.5591 20.3302 15.5101C20.3772 15.4888 20.4224 15.4707 20.4667 15.4545C20.4658 15.4522 20.4644 15.4503 20.4635 15.4485C21.3546 15.0384 22.2444 14.7118 23.226 14.5149C23.5369 14.4653 23.8711 14.5612 24.0674 14.8471C24.0738 14.8564 24.0802 14.8652 24.087 14.874C24.5851 15.6167 25.1814 16.3501 25.8813 17.0543C30.218 21.4906 35.6778 21.8974 37.5144 18.8177C38.4325 17.1062 36.4694 15.8553 35.6143 16.6962C33.8411 18.2831 30.5289 17.1507 28.1462 14.7554C27.5403 14.1345 27.0112 13.4442 26.588 12.7293C26.588 12.7293 26.5875 12.7288 26.5871 12.7288C25.3786 10.5846 23.6068 11.0794 22.4554 11.3407C22.4554 11.3412 22.4554 11.3412 22.4558 11.3416C21.4985 11.5464 20.6182 11.8425 19.7796 12.1927C19.7764 12.1941 19.7741 12.1941 19.7709 12.1955C19.3655 12.3739 19.16 12.1533 19.0669 11.7688C17.6229 5.16094 22.695 1.62489 29.3724 4.66195C20.1019 0.133928 13.9778 5.45747 15.9807 13.4173Z"
          fill="white"
        />
        <path
          d="M22.2474 18.1217C20.7673 16.6692 18.2202 18.4651 19.8053 20.6793C21.7625 22.7838 24.274 20.4309 22.561 18.5026C22.5583 18.4984 22.5551 18.4947 22.5528 18.4905C22.4492 18.3687 22.3474 18.2459 22.2474 18.1217Z"
          fill="#00D4FF"
        />
        <path
          d="M22.3387 7.85223C21.936 9.97887 25.1305 9.90566 25.6555 8.25717C25.6637 8.22984 25.6719 8.20296 25.6806 8.17563C25.6815 8.17192 25.6833 8.16822 25.6842 8.16451C25.6842 8.16405 25.6847 8.16404 25.6852 8.16404C25.7961 7.82628 25.9618 7.51262 26.1901 7.23092C27.7706 5.27896 31.397 6.2371 33.9714 8.84327C35.4255 10.3222 36.3578 12.1435 36.5449 13.7364C36.5545 13.7498 36.5764 14.1469 36.5769 14.1663C36.8075 15.3955 38.1789 16.1429 38.0643 15.1509C37.6945 12.5938 36.2181 10.2026 34.4179 8.38968C32.6031 6.53409 30.304 5.1168 27.9177 4.71417C24.7821 4.18367 22.8811 5.60235 22.3387 7.85223Z"
          fill="white"
        />
        <path
          d="M30.7143 23.8793C29.3036 23.5132 27.9111 22.874 26.6027 22.0372C26.6013 22.0372 26.5999 22.0367 26.5986 22.0367C26.5757 22.0196 26.552 22.0066 26.5292 21.9909C26.4876 21.964 26.4466 21.938 26.405 21.9107C24.5797 20.8567 22.2317 23.3311 24.3346 24.7401C24.441 24.8102 24.5473 24.8811 24.6551 24.9495C34.9063 31.4296 43.4203 24.746 38.0925 13.5112C41.1417 20.4165 37.4661 25.6313 30.7143 23.8793Z"
          fill="white"
        />
        <path
          d="M8.87683 20.387C8.31803 19.1532 6.5576 19.8625 6.78359 21.2409C6.81463 21.3447 6.84796 21.4484 6.88083 21.5527C7.47571 22.987 9.48176 22.2328 8.96632 20.6376C8.96495 20.6325 8.96312 20.6288 8.96221 20.6242C8.93299 20.545 8.90468 20.4662 8.87683 20.387Z"
          fill="#00D4FF"
        />
        <path
          d="M7.33028 27.1045C7.33028 27.1045 7.33027 27.1045 7.32982 27.1041C7.86215 27.0332 8.37119 26.9211 8.84462 26.7992C8.84462 26.7992 8.84463 26.7993 8.84508 26.7994C9.12723 26.7288 9.42033 26.8767 9.58788 27.1286C9.58879 27.1291 9.59016 27.1295 9.59153 27.1305C10.432 28.3898 11.3958 29.5833 12.4554 30.679C13.1567 31.3777 13.8995 32.0347 14.6733 32.6444C16.2739 33.8963 17.9075 31.9267 16.1982 30.6841C16.1982 30.6827 16.1982 30.6818 16.1986 30.6809C15.5079 30.1318 14.8386 29.5416 14.1994 28.9091C13.1695 27.8375 12.2486 26.685 11.4474 25.4842C11.4474 25.4844 11.4469 25.4841 11.4469 25.4841C11.4442 25.4792 11.441 25.4752 11.4382 25.4704C11.3821 25.3866 11.3273 25.302 11.2725 25.2175C10.4686 24.1011 9.38654 24.18 8.07626 24.5206C8.07626 24.5207 8.07627 24.5205 8.07581 24.5207C7.74253 24.6215 7.39008 24.7204 7.0317 24.7955C6.70618 24.8488 6.25009 24.7536 6.01406 24.2192C4.61111 20.423 4.43442 16.4825 5.87025 13.2119C5.08911 14.9331 4.5545 16.6307 4.34905 18.3955C4.34905 18.396 4.34905 18.3969 4.34905 18.3974C4.2267 19.4496 4.22213 20.5258 4.35134 21.6452C4.39288 22.2211 4.44995 22.767 4.51158 23.2588C4.63713 24.2124 4.83299 25.0627 5.04528 25.7958C5.51552 27.2523 6.78836 27.1601 7.32982 27.105C7.32982 27.105 7.33028 27.105 7.33028 27.1045Z"
          fill="white"
        />
        <path
          d="M11.0308 32.1246C10.2674 31.3337 9.56573 30.4942 8.92931 29.6204C8.92794 29.6195 8.92703 29.619 8.9252 29.6181C8.03768 28.2698 6.42563 29.2442 7.03923 30.307C7.10269 30.4127 7.17162 30.5285 7.24741 30.6554C7.9377 31.7248 8.73163 32.7005 9.60043 33.5762C10.4633 34.4574 11.4248 35.2632 12.4785 35.9642C13.8495 36.8074 13.8755 36.8232 14.6343 37.1869C15.3337 37.4922 17.1685 38.3892 19.7667 38.7408C20.2511 38.8034 20.7894 38.8613 21.3569 38.9035C22.4594 39.0341 23.5204 39.0295 24.5568 38.9058C24.5572 38.9058 24.5577 38.9058 24.5577 38.9058C26.2971 38.6973 27.9708 38.1547 29.6668 37.362C24.0436 39.9042 16.4098 37.4718 11.0308 32.1246Z"
          fill="white"
        />
        <path
          d="M7.97328 16.7438C7.97374 16.7438 7.97374 16.7434 7.9742 16.7429C7.41859 12.7672 8.36317 9.04856 11.2051 6.60964C8.7389 8.55558 7.11087 10.338 6.39775 13.5238C6.10831 14.8164 6.02065 16.1387 6.10237 17.4652C6.28773 19.0025 8.2043 18.0337 7.97328 16.7438Z"
          fill="white"
        />
        <path
          d="M22.3197 34.2077C22.7411 33.4455 23.1251 32.6491 23.4583 31.8031C23.861 30.7916 24.7814 29.0185 22.532 27.6545C22.532 27.6536 22.5325 27.6531 22.5325 27.6522C21.1332 26.7657 19.7928 25.6902 18.5724 24.4708C17.3101 23.1697 16.2011 21.7347 15.3036 20.2354C14.1481 18.3061 13.382 16.3616 12.9866 14.5088C12.9862 14.5088 12.9862 14.5088 12.9857 14.5088C12.5465 12.6291 10.0406 13.5325 10.2277 15.4437C10.8833 19.4176 13.1177 23.5166 16.281 26.796C17.4991 28.0062 18.8322 29.0811 20.2301 29.9878C20.2301 29.9887 20.2301 29.9892 20.2306 29.9901C20.6287 30.251 20.6387 30.3381 20.5237 30.6508C20.2183 31.413 19.8389 32.1241 19.4065 32.8418C19.4065 32.8423 19.4065 32.8428 19.4065 32.8432C18.9377 33.597 18.4072 35.0505 20.2219 35.8946C23.2123 37.0951 26.3656 37.5167 29.3596 36.8268C32.4988 36.1031 34.2547 34.4505 36.1726 31.9481C32.9878 35.7695 27.5896 36.2111 22.3197 34.2077Z"
          fill="white"
        />
        <path
          d="M33.7972 30.1537C33.7968 30.1537 33.7963 30.1542 33.7959 30.1547C33.789 30.1556 33.7826 30.1575 33.7762 30.1584C33.7087 30.1755 33.6406 30.1917 33.5731 30.2075C31.893 30.694 31.6935 33.0101 33.6032 32.4944C37.471 31.0869 39.0219 27.7246 39.5949 23.5524C39.0671 27.1311 36.8451 29.374 33.7972 30.1537Z"
          fill="white"
        />
        <path
          d="M12.8281 9.70597C12.8281 9.70597 12.8281 9.70597 12.8281 9.70551C13.4266 6.26305 15.6957 3.71109 19.4772 3.13657C15.2181 3.73889 11.8151 5.40683 10.5235 9.65084C10.103 11.2794 12.582 11.1117 12.8281 9.70597Z"
          fill="white"
        />
        <path
          d="M28.6945 30.2032C28.6881 30.2023 28.6822 30.2009 28.6758 30.2004C26.7145 29.9428 25.5773 32.5689 27.6353 32.9655C27.6495 32.9678 27.6637 32.9697 27.6778 32.9715C29.735 33.1694 30.8453 30.7309 29.0013 30.2606C28.8995 30.2426 28.7972 30.2236 28.6945 30.2032Z"
          fill="#00D4FF"
        />
      </svg>
    </>
  );
};

export default LogoIcon;
