import React, { useState } from "react";
import { useDispatch } from "react-redux";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";

import { CustomTextField } from "../../customComponent";
import { editGroup } from "../../../store/actions/groupAction";

const EditGroup = ({ group }) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [name, setName] = useState(group.name);

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (name !== group.name) {
      const payload = { id: group.id, data: { name: name } };

      dispatch(editGroup(payload));
    }

    handleClickClose();
  };

  return (
    <>
      <Button
        startIcon={<EditIcon fontSize="small" />}
        variant="contained"
        sx={{ boxShadow: 3, mx: 2 }}
        onClick={() => setOpen(true)}
      >
        Edit Group
      </Button>

      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        keepMounted
        onClose={handleClickClose}
      >
        <Box component="form" onSubmit={handleSubmit}>
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            Edit Group
            <IconButton onClick={handleClickClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <Divider />

          <DialogContent>
            <CustomTextField
              fullWidth
              label="Group Name"
              margin="dense"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter group name"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </DialogContent>

          <DialogActions>
            <Button fullWidth variant="outlined" onClick={handleClickClose}>
              Cancel
            </Button>
            <Button fullWidth variant="contained" onClick={handleSubmit}>
              Save
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default EditGroup;
