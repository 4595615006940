import React from "react";

import { CircularProgress, Box, Grid } from "@mui/material";
import {
  Sort as PriorityIcon,
  FiberManualRecord as FiberManualRecordIcon,
} from "@mui/icons-material";

import {
  TypographyLarge,
  TypographyNormal,
  TypographySmall,
  TypographyXLarge,
} from "../../customComponent";
import {
  AnnouncementPurpleIcon,
  GroupGreyIcon,
  ViewerGreyIcon,
  DotIcon,
  TerminalIcon,
  ScheduleGreyIcon,
  GlobeIcon,
} from "../../../icons";
import { colorStyling } from "../../../helpers/color";
import {
  switchBackgroundColorBasedOnStatus,
  switchColorOnScheduleStatus,
} from "../../../helpers/switchColor";

const AnnouncementInfo = ({ announcement, loading }) => {
  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <CircularProgress size={20} thickness={3} sx={{ color: "#1D4ED8" }} />
        <TypographyNormal sx={{ color: "#1D4ED8", mt: 2, fontWeight: 300 }}>
          Loading Data...
        </TypographyNormal>
      </Box>
    );
  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mt: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <AnnouncementPurpleIcon />
            <Box display={"flex"} flexDirection={"column"} sx={{ ml: 2 }}>
              <Box sx={{ width: "fit-content" }}>
                <TypographySmall
                  sx={{
                    borderRadius: "15px",
                    color: switchColorOnScheduleStatus(
                      announcement.isApproved ? "APPROVED" : "NOT_APPROVED"
                    ),
                    backgroundColor: switchBackgroundColorBasedOnStatus(
                      announcement.isApproved ? "APPROVED" : "NOT_APPROVED"
                    ),
                    fontWeight: 600,
                    px: 1,
                    py: 0.2,
                  }}
                >
                  {announcement.isApproved ? "Approved" : "Not Approved"}
                </TypographySmall>
              </Box>
              <TypographyXLarge sx={{ fontWeight: 700 }}>
                {announcement.name}
              </TypographyXLarge>
            </Box>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TypographyNormal
              sx={{
                display: "flex",
                alignItems: "center",
                borderRadius: "15px",
                color: announcement.isActive
                  ? colorStyling.green
                  : colorStyling.black,
                backgroundColor: announcement.isActive
                  ? colorStyling.white.active
                  : colorStyling.white.inactive,
                p: 1,
                mr: 4,
                fontWeight: 600,
              }}
            >
              <FiberManualRecordIcon
                sx={{
                  color: announcement.isActive
                    ? colorStyling.green
                    : colorStyling.gray,
                  fontSize: 8,
                  mr: 1,
                }}
              />
              {announcement.isActive ? "Announcement On" : "Announcement Off"}
            </TypographyNormal>

            <Box display="flex" alignItems="center" sx={{ mr: 4 }}>
              <GroupGreyIcon />
              <TypographyNormal sx={{ ml: 1 }}>
                {!announcement.screenGroups
                  ? 0
                  : announcement.screenGroups.length}{" "}
                groups
              </TypographyNormal>
            </Box>

            <DotIcon />

            <Box display="flex" alignItems="center" sx={{ ml: 4 }}>
              <ViewerGreyIcon />
              <TypographyNormal sx={{ ml: 1 }}>
                {!announcement.screens ? 0 : announcement.screens.length}{" "}
                viewers
              </TypographyNormal>
            </Box>
          </Box>
        </Box>

        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={4}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <ScheduleGreyIcon />

              <Box sx={{ ml: 2 }}>
                <TypographyNormal sx={{ fontWeight: 300 }}>
                  Type
                </TypographyNormal>
                <TypographyLarge sx={{ fontWeight: 600 }}>
                  {announcement.type}
                </TypographyLarge>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={4}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TerminalIcon />

              <Box sx={{ ml: 2 }}>
                <TypographyNormal sx={{ fontWeight: 300 }}>
                  Command
                </TypographyNormal>
                <TypographyLarge sx={{ fontWeight: 600 }}>
                  {announcement.command}
                </TypographyLarge>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={4}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <PriorityIcon sx={{ color: "#9ca3af" }} />

              <Box sx={{ ml: 2 }}>
                <TypographyNormal sx={{ fontWeight: 300 }}>
                  Priority
                </TypographyNormal>
                <TypographyLarge sx={{ fontWeight: 600 }}>
                  {announcement.priority?.name}
                </TypographyLarge>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={4}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <GlobeIcon />

              <Box sx={{ ml: 2 }}>
                <TypographyNormal sx={{ fontWeight: 300 }}>
                  Content ID
                </TypographyNormal>
                <TypographyLarge sx={{ fontWeight: 600 }}>
                  {announcement?.content?.id}
                </TypographyLarge>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={8}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <GlobeIcon />

              <Box sx={{ ml: 2 }}>
                <TypographyNormal sx={{ fontWeight: 300 }}>
                  Content Name
                </TypographyNormal>
                <TypographyLarge sx={{ fontWeight: 600 }}>
                  {announcement?.content?.name}
                </TypographyLarge>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AnnouncementInfo;
