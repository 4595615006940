import {
  SET_GLOBAL_ERROR,
  SET_GLOBAL_ERROR_MESSAGE,
  SET_GLOBAL_LOADING,
  SET_GLOBAL_SUCCESS,
  SET_GLOBAL_SUCCESS_MESSAGE,
} from "../actionTypes/globalActionType";

const initialState = {
  isSuccess: false,
  successMessage: "",
  isError: false,
  errorMessage: "",
  loading: false,
};

export const globalReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_GLOBAL_LOADING:
      return { ...state, loading: payload };

    case SET_GLOBAL_SUCCESS:
      return { ...state, isSuccess: payload };

    case SET_GLOBAL_SUCCESS_MESSAGE:
      return { ...state, successMessage: payload };

    case SET_GLOBAL_ERROR:
      return { ...state, isError: payload };

    case SET_GLOBAL_ERROR_MESSAGE:
      return { ...state, errorMessage: payload };

    default:
      return state;
  }
};
