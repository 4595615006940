export const SET_LOADING_MEDIA = "SET_LOADING_MEDIA";
export const SET_LOADING_CMS = "SET_LOADING_CMS";
export const SET_CMS_DATA = "SET_CMS_DATA";
export const SET_ASSET_DATA = "SET_ASSET_DATA";
export const SET_ERROR_PROMPT_CMS_PAGE = "SET_ERROR_PROMPT_CMS_PAGE";
export const SET_ERROR_MESSAGE_CMS_PAGE = "SET_ERROR_MESSAGE_CMS_PAGE";
export const SET_SUCCESS_PROMPT_CMS_PAGE = "SET_SUCCESS_PROMPT_CMS_PAGE";
export const SET_SUCCESS_MESSAGE_CMS_PAGE = "SET_SUCCESS_MESSAGE_CMS_PAGE";
export const SET_CMS_CONTENT_DATA = "SET_CMS_CONTENT_DATA";
export const SET_MEDIA_DATA = "SET_MEDIA_DATA";
export const SET_ADD_MEDIA_DATA = "SET_ADD_MEDIA_DATA";
export const SET_REMOVE_MEDIA_DATA = "SET_REMOVE_MEDIA_DATA";
export const SET_UPLOAD_MEDIA_DATA = "SET_UPLOAD_MEDIA_DATA";
export const SET_UPDATE_UPLOAD_STATE = "SET_UPDATE_UPLOAD_STATE";
export const SET_CONTENT_NAME = "SET_CONTENT_NAME";
export const SET_CONTENT_LAYERS = "SET_CONTENT_LAYERS";
export const SET_CANVAS_RES = "SET_CANVAS_RES";
export const SET_LAYER_MEDIAS = "SET_LAYER_MEDIAS";
export const SET_ERROR_HANDLER = "SET_ERROR_HANDLER";
