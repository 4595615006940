import {
  SET_CONTENT_HISTORY,
  SET_CONTENT_UPLOAD,
  SET_DELETE_CONTENT_UPLOAD,
  SET_EDIT_CONTENT_UPLOAD,
  SET_ERROR_MESSAGE_CONTENT_UPLOAD_PAGE,
  SET_ERROR_PROMPT_CONTENT_UPLOAD_PAGE,
  SET_LOADING_CONTENT_UPLOAD,
  SET_NEW_CONTENT_UPLOAD,
  SET_OPEN_UPLOAD_WINDOW,
  SET_SUCCESS_MESSAGE_CONTENT_UPLOAD_PAGE,
  SET_SUCCESS_PROMPT_CONTENT_UPLOAD_PAGE,
  SET_UPLOAD_FILENAME,
  SET_UPLOAD_PROGRESS,
  SET_UPLOAD_STATUS,
} from "../actionTypes/contentUploadActionType";

import { handleError } from "../handleError";

// import moment from "moment";
import {
  contentCMSAPILimit,
  initiateAnnouncementAPI,
  instanceCMSContentAPI,
  instanceCMSScheduleAPI,
  instanceContentHistoryAPI,
} from "../../api";
import { initiateAXIOS } from "../../config/axios";
import Schedule from "../../models/ScheduleModel";
import {
  SET_ERROR_MESSAGE_SCHEDULE_PAGE,
  SET_ERROR_PROMPT_SCHEDULE_PAGE,
  SET_LOADING_SCHEDULE,
  SET_NEW_SCHEDULE,
  SET_SCHEDULE_DETAIL,
  SET_SUCCESS_MESSAGE_SCHEDULE_PAGE,
  SET_SUCCESS_PROMPT_SCHEDULE_PAGE,
} from "../actionTypes/scheduleActionType";

import {
  SET_ANNOUNCEMENT_DETAIL,
  SET_ERROR_MESSAGE_ANNOUNCEMENT_PAGE,
  SET_ERROR_PROMPT_ANNOUNCEMENT_PAGE,
  SET_LOADING_ANNOUNCEMENT,
  SET_LOADING_ANNOUNCEMENT_DETAIL,
  SET_NEW_ANNOUNCEMENT,
  SET_SUCCESS_MESSAGE_ANNOUNCEMENT_PAGE,
  SET_SUCCESS_PROMPT_ANNOUNCEMENT_PAGE,
} from "../actionTypes/announcementActionType";

import Announcement from "../../models/AnnouncementModel";

const handleTimeoutSuccessPrompt = (dispatch) => {
  setTimeout(() => {
    dispatch({ type: SET_SUCCESS_PROMPT_CONTENT_UPLOAD_PAGE, payload: false });
  }, 5000);
};

const handleTimeoutErrorPrompt = (dispatch) => {
  setTimeout(() => {
    dispatch({ type: SET_ERROR_PROMPT_CONTENT_UPLOAD_PAGE, payload: false });
  }, 5000);
};

export const getContentUpload = () => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_CONTENT_UPLOAD, payload: true });
    try {
      const { data } = await initiateAXIOS.get(contentCMSAPILimit, {
        headers: { authorization: `Bearer ${access_token}` },
      });
      dispatch({ type: SET_CONTENT_UPLOAD, payload: data });
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_CONTENT_UPLOAD_PAGE, payload: true });
      dispatch({
        type: SET_ERROR_MESSAGE_CONTENT_UPLOAD_PAGE,
        payload: message,
      });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_CONTENT_UPLOAD, payload: false });
    }
  };
};

export const getContentHistory = () => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_CONTENT_UPLOAD, payload: true });
    try {
      const { data } = await initiateAXIOS.get(
        instanceContentHistoryAPI + `&sortBy=id-`,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );
      dispatch({ type: SET_CONTENT_HISTORY, payload: data });
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_CONTENT_UPLOAD_PAGE, payload: true });
      dispatch({
        type: SET_ERROR_MESSAGE_CONTENT_UPLOAD_PAGE,
        payload: message,
      });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_CONTENT_UPLOAD, payload: false });
    }
  };
};

export const filterContentHistory = (query) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_CONTENT_UPLOAD, payload: true });
    try {
      const { data } = await initiateAXIOS.get(
        instanceContentHistoryAPI + "&sortBy=id-" + query,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );
      dispatch({ type: SET_CONTENT_HISTORY, payload: data });
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_CONTENT_UPLOAD_PAGE, payload: true });
      dispatch({
        type: SET_ERROR_MESSAGE_CONTENT_UPLOAD_PAGE,
        payload: message,
      });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_CONTENT_UPLOAD, payload: false });
    }
  };
};

export const assignContentUpload = ({ id, payload, type }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_CONTENT_UPLOAD, payload: true });
    try {
      const { data } = await initiateAXIOS.patch(
        instanceCMSContentAPI + id + "/" + type,
        payload,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );
      dispatch({ type: SET_EDIT_CONTENT_UPLOAD, payload: data });
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_CONTENT_UPLOAD_PAGE, payload: true });
      dispatch({
        type: SET_ERROR_MESSAGE_CONTENT_UPLOAD_PAGE,
        payload: message,
      });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_CONTENT_UPLOAD, payload: false });
    }
  };
};

export const filterContent = (query) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_CONTENT_UPLOAD, payload: true });
    try {
      const { data } = await initiateAXIOS.get(contentCMSAPILimit + query, {
        headers: { authorization: `Bearer ${access_token}` },
      });
      dispatch({ type: SET_CONTENT_UPLOAD, payload: data });
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_CONTENT_UPLOAD_PAGE, payload: true });
      dispatch({
        type: SET_ERROR_MESSAGE_CONTENT_UPLOAD_PAGE,
        payload: message,
      });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_CONTENT_UPLOAD, payload: false });
    }
  };
};

export const createContentDisplaySchedule = (payload) => {
  const access_token = sessionStorage.getItem("access_token");
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_SCHEDULE, payload: true });

    try {
      const { data } = await initiateAXIOS.post(
        instanceCMSScheduleAPI,
        payload,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      const OOP = new Schedule(data);
      dispatch({ type: SET_NEW_SCHEDULE, payload: OOP });
      dispatch({ type: SET_SCHEDULE_DETAIL, payload: {} });
      dispatch({ type: SET_SUCCESS_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_SCHEDULE_PAGE,
        payload: "A new schedule has been created successfully.",
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_SCHEDULE_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_SCHEDULE, payload: false });
    }
  };
};

export const createContentDisplayAnnouncement = (payload) => {
  const access_token = sessionStorage.getItem("access_token");
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_ANNOUNCEMENT, payload: true });

    try {
      const { data } = await initiateAXIOS.post(
        instanceCMSScheduleAPI,
        payload,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({
        type: SET_NEW_ANNOUNCEMENT,
        payload: new Announcement(data),
      });
      dispatch({ type: SET_SUCCESS_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_ANNOUNCEMENT_PAGE,
        payload: "A new announcement has been created successfully.",
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({
        type: SET_ERROR_MESSAGE_ANNOUNCEMENT_PAGE,
        payload: message,
      });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_ANNOUNCEMENT, payload: false });
    }
  };
};

export const createContentUpload = (payload, fileUpload) => {
  const access_token = sessionStorage.getItem("access_token");
  return async (dispatch) => {
    const openUploadProgressWindow = (payload) => {
      dispatch({ type: SET_OPEN_UPLOAD_WINDOW, payload: payload });
    };

    const updateUploadProgress = (payload) => {
      dispatch({ type: SET_UPLOAD_PROGRESS, payload: payload });
    };

    dispatch({ type: SET_UPLOAD_PROGRESS, payload: 0 });
    dispatch({ type: SET_UPLOAD_FILENAME, payload: fileUpload.name });
    dispatch({ type: SET_UPLOAD_STATUS, payload: "progress" });

    let formdata = new FormData();
    formdata.append("type", payload.type);
    formdata.append("name", payload.name);
    formdata.append("file", fileUpload);

    try {
      openUploadProgressWindow(true);
      const { data } = await initiateAXIOS.post(
        instanceCMSContentAPI,
        formdata,
        {
          headers: {
            authorization: `Bearer ${access_token}`,
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progress) => {
            const { total, loaded } = progress;
            const totalSizeInMB = total / 1000000;
            const loadedSizeInMB = loaded / 1000000;
            const uploadPercentage = Math.round(
              (loadedSizeInMB / totalSizeInMB) * 100
            );
            updateUploadProgress(uploadPercentage);
          },
          encType: "multipart/form-data",
        }
      );

      dispatch({ type: SET_UPLOAD_STATUS, payload: "completed" });
      dispatch({ type: SET_LOADING_CONTENT_UPLOAD, payload: true });
      dispatch({ type: SET_NEW_CONTENT_UPLOAD, payload: data });
      dispatch({ type: SET_SUCCESS_PROMPT_CONTENT_UPLOAD_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_CONTENT_UPLOAD_PAGE,
        payload: "A new content schedule has been created successfully.",
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_UPLOAD_STATUS, payload: "failed" });
      dispatch({ type: SET_ERROR_PROMPT_CONTENT_UPLOAD_PAGE, payload: true });
      dispatch({
        type: SET_ERROR_MESSAGE_CONTENT_UPLOAD_PAGE,
        payload: message,
      });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_CONTENT_UPLOAD, payload: false });
    }
  };
};

export const updateContent = ({ id, fileUpload, name }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    const openUploadProgressWindow = (payload) => {
      dispatch({ type: SET_OPEN_UPLOAD_WINDOW, payload: payload });
    };

    const updateUploadProgress = (payload) => {
      dispatch({ type: SET_UPLOAD_PROGRESS, payload: payload });
    };

    const axiosGeneralSetting = {
      headers: {
        authorization: `Bearer ${access_token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    const axiosUploadSetting = {
      onUploadProgress: (progress) => {
        const { total, loaded } = progress;
        const totalSizeInMB = total / 1000000;
        const loadedSizeInMB = loaded / 1000000;
        const uploadPercentage = Math.round(
          (loadedSizeInMB / totalSizeInMB) * 100
        );
        updateUploadProgress(uploadPercentage);
      },
      encType: "multipart/form-data",
    };

    let formdata = new FormData();
    let axiosSetting = {};
    formdata.append("file", fileUpload);
    if (name) formdata.append("name", name);

    if (fileUpload) {
      axiosSetting = { ...axiosGeneralSetting, ...axiosUploadSetting };
      dispatch({ type: SET_UPLOAD_PROGRESS, payload: 0 });
      dispatch({ type: SET_UPLOAD_FILENAME, payload: fileUpload.name });
      dispatch({ type: SET_UPLOAD_STATUS, payload: "progress" });
      openUploadProgressWindow(true);
    } else {
      axiosSetting = { ...axiosGeneralSetting };
    }
    try {
      const { data } = await initiateAXIOS.post(
        instanceCMSContentAPI + id,
        formdata,
        axiosSetting
      );

      dispatch({ type: SET_UPLOAD_STATUS, payload: "completed" });
      dispatch({ type: SET_LOADING_CONTENT_UPLOAD, payload: true });
      dispatch({ type: SET_EDIT_CONTENT_UPLOAD, payload: data });
      dispatch({ type: SET_SUCCESS_PROMPT_CONTENT_UPLOAD_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_CONTENT_UPLOAD_PAGE,
        payload: "Offline content successfully updated.",
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_UPLOAD_STATUS, payload: "failed" });
      dispatch({ type: SET_ERROR_PROMPT_CONTENT_UPLOAD_PAGE, payload: true });
      dispatch({
        type: SET_ERROR_MESSAGE_CONTENT_UPLOAD_PAGE,
        payload: message,
      });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_CONTENT_UPLOAD, payload: false });
    }
  };
};

export const editContentName = ({ id, name }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_CONTENT_UPLOAD, payload: true });

    try {
      const { data } = await initiateAXIOS.patch(
        instanceCMSContentAPI + id,
        { name },
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({ type: SET_EDIT_CONTENT_UPLOAD, payload: data });
      dispatch({ type: SET_SUCCESS_PROMPT_CONTENT_UPLOAD_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_CONTENT_UPLOAD_PAGE,
        payload: "Offline content successfully updated.",
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_CONTENT_UPLOAD_PAGE, payload: true });
      dispatch({
        type: SET_ERROR_MESSAGE_CONTENT_UPLOAD_PAGE,
        payload: message,
      });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_CONTENT_UPLOAD, payload: false });
    }
  };
};

export const deleteContentUpload = (id) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_CONTENT_UPLOAD, payload: true });
    try {
      await initiateAXIOS.delete(instanceCMSContentAPI + id, {
        headers: { authorization: `Bearer ${access_token}` },
      });
      dispatch({ type: SET_DELETE_CONTENT_UPLOAD, payload: id });
      dispatch({ type: SET_SUCCESS_PROMPT_CONTENT_UPLOAD_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_CONTENT_UPLOAD_PAGE,
        payload: "Content schedule is successfully deleted",
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_CONTENT_UPLOAD_PAGE, payload: true });
      dispatch({
        type: SET_ERROR_MESSAGE_CONTENT_UPLOAD_PAGE,
        payload: message,
      });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_CONTENT_UPLOAD, payload: false });
    }
  };
};

export const updateAnnouncementCMSAction = ({ id, payload }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_ANNOUNCEMENT_DETAIL, payload: true });

    try {
      await initiateAXIOS.patch(instanceCMSScheduleAPI + id, payload, {
        headers: { authorization: `Bearer ${access_token}` },
      });
      const { data } = await initiateAXIOS.get(initiateAnnouncementAPI + id, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      const OOP = new Announcement(data.announcement);
      dispatch({ type: SET_ANNOUNCEMENT_DETAIL, payload: OOP });
      dispatch({ type: SET_SUCCESS_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_ANNOUNCEMENT_PAGE,
        payload: "Schedule successfully updated.",
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_ANNOUNCEMENT_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_ANNOUNCEMENT_DETAIL, payload: false });
    }
  };
};
