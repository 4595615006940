import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, CircularProgress, Paper } from "@mui/material";
import { Typography2XLarge, TypographyLarge } from "../customComponent";

import { getGroups } from "../../store/actions/homeAction";

const GroupCard = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGroups());
  }, [dispatch]);

  const { groupsData, loadingGroupsData } = useSelector((state) => state.home);

  if (loadingGroupsData)
    return (
      <Paper
        elevation={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "6px",
        }}
      >
        <Box
          p={2}
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <CircularProgress size={22} thickness={3} sx={{ color: "#1D4ED8" }} />
        </Box>
      </Paper>
    );

  return (
    <>
      <Paper
        elevation={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "6px",
        }}
      >
        <Box display="flex" p={2}>
          <Box p={1} alignSelf="center">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.2002 7.9998C3.2002 6.23249 4.63288 4.7998 6.4002 4.7998H25.6002C27.3675 4.7998 28.8002 6.23249 28.8002 7.9998V11.1998C28.8002 12.9671 27.3675 14.3998 25.6002 14.3998H6.4002C4.63288 14.3998 3.2002 12.9671 3.2002 11.1998V7.9998ZM25.6002 9.5998C25.6002 10.4835 24.8838 11.1998 24.0002 11.1998C23.1165 11.1998 22.4002 10.4835 22.4002 9.5998C22.4002 8.71615 23.1165 7.9998 24.0002 7.9998C24.8838 7.9998 25.6002 8.71615 25.6002 9.5998Z"
                fill="#2563EB"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.2002 20.7998C3.2002 19.0325 4.63288 17.5998 6.4002 17.5998H25.6002C27.3675 17.5998 28.8002 19.0325 28.8002 20.7998V23.9998C28.8002 25.7671 27.3675 27.1998 25.6002 27.1998H6.4002C4.63288 27.1998 3.2002 25.7671 3.2002 23.9998V20.7998ZM25.6002 22.3998C25.6002 23.2835 24.8838 23.9998 24.0002 23.9998C23.1165 23.9998 22.4002 23.2835 22.4002 22.3998C22.4002 21.5161 23.1165 20.7998 24.0002 20.7998C24.8838 20.7998 25.6002 21.5161 25.6002 22.3998Z"
                fill="#2563EB"
              />
            </svg>
          </Box>

          <Box ml={3}>
            <Typography2XLarge>
              {!groupsData
                ? 0
                : groupsData.filter((item) => item.parent === null).length}
            </Typography2XLarge>
            <TypographyLarge sx={{ fontWeight: 400 }}>
              Top Level Groups
            </TypographyLarge>
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default GroupCard;
