import React from "react";
import { useNavigate } from "react-router-dom";

import { Box, IconButton, Tooltip } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { makeStyles } from "@mui/styles";
import { AnnouncementPurpleIcon } from "../../icons";
import { TypographyNormal, TypographySmall } from "../customComponent";
import { colorStyling } from "../../helpers/color";
import { switchBackgroundColorBasedOnStatus } from "../../helpers/switchColor";

const useStyles = makeStyles(() => ({
  hover: {
    "&:hover": {
      backgroundColor: colorStyling.white.hover,
      cursor: "pointer",
    },
  },
}));

const AnnouncementItem = ({ announcement }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Box
      onClick={() => navigate("/announcement/detail/" + announcement.id)}
      className={classes.hover}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        p: 2,
        borderBottom: "1px solid lightgrey",
      }}
    >
      <Box display={"flex"} flexWrap={"nowrap"} alignItems={"center"}>
        <Box display={"flex"} alignItems={"center"} sx={{ mr: 1 }}>
          <AnnouncementPurpleIcon />
          <Box display={"flex"} alignItems={"center"} sx={{ ml: 2 }}>
            {announcement.isApproved ? (
              <Tooltip title={"Announcement is approved"} placement="top">
                <CheckCircleOutlineIcon
                  fontSize="small"
                  sx={{
                    mr: 0.5,
                    color: switchBackgroundColorBasedOnStatus("APPROVED"),
                    borderRadius: "16px",
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip title={"Announcement is not approved"} placement="top">
                <ErrorOutlineIcon
                  fontSize="small"
                  sx={{
                    mr: 0.5,
                    color: switchBackgroundColorBasedOnStatus("NOT_APPROVED"),
                    borderRadius: "16px",
                  }}
                />
              </Tooltip>
            )}
            {announcement.name.length <= 40 ? (
              <TypographyNormal sx={{ fontWeight: 600, width: "20vw" }}>
                {announcement.name}
              </TypographyNormal>
            ) : (
              <Tooltip title={announcement.name}>
                <TypographyNormal sx={{ fontWeight: 600, width: "20vw" }}>
                  {announcement.name.substring(0, 37) + "..."}
                </TypographyNormal>
              </Tooltip>
            )}
          </Box>
        </Box>
        <Box>
          <TypographySmall
            sx={{
              display: "flex",
              alignItems: "center",
              borderRadius: "15px",
              color:
                announcement.isActive === true
                  ? colorStyling.green
                  : colorStyling.black,
              backgroundColor:
                announcement.isActive === true
                  ? colorStyling.white.active
                  : colorStyling.white.inactive,
              p: 1,
              fontWeight: 600,
              whiteSpace: "nowrap",
            }}
          >
            <FiberManualRecordIcon
              sx={{
                color:
                  announcement.isActive === true
                    ? colorStyling.green
                    : colorStyling.gray,
                fontSize: 8,
                mr: 1,
              }}
            />
            {announcement.isActive === true
              ? "Announcement On"
              : "Announcement Off"}
          </TypographySmall>
        </Box>
      </Box>

      <Box display={"flex"} flexDirection={"column"} sx={{ width: "150px" }}>
        <TypographyNormal>Content name:</TypographyNormal>
        {announcement.content.name.length <= 40 ? (
          <TypographyNormal sx={{ fontWeight: 600 }}>
            {announcement.content.name === "" ? "-" : announcement.content.name}
          </TypographyNormal>
        ) : (
          <Tooltip title={announcement.content.name}>
            <TypographyNormal sx={{ fontWeight: 600 }}>
              {announcement.content.name.substring(0, 37) + "..."}
            </TypographyNormal>
          </Tooltip>
        )}
      </Box>

      <IconButton sx={{ color: colorStyling.black }}>
        <NavigateNextIcon />
      </IconButton>
    </Box>
  );
};

export default AnnouncementItem;
