import React from "react";

const GroupIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6087 9.8261V11.5652H9.39135C8.7578 11.5652 8.16382 11.3959 7.65222 11.0999V15.9131C7.65222 16.8736 8.43085 17.6522 9.39135 17.6522H14.6087V19.3913H9.39135C7.47036 19.3913 5.91309 17.8341 5.91309 15.9131V8.08697V7.65221V7.21741H7.65222V7.65221V8.08697C7.65222 9.04747 8.43085 9.8261 9.39135 9.8261H14.6087Z"
        fill="#FFBD80"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.3042 5.47826C3.3042 7.39925 4.86147 8.95652 6.78246 8.95652C8.70345 8.95652 10.2607 7.39925 10.2607 5.47826C10.2607 3.55727 8.70345 2 6.78246 2C4.86147 2 3.3042 3.55727 3.3042 5.47826ZM8.52164 5.47823C8.52164 6.43872 7.743 7.21736 6.78251 7.21736C5.82201 7.21736 5.04338 6.43872 5.04338 5.47823C5.04338 4.51773 5.82201 3.7391 6.78251 3.7391C7.743 3.7391 8.52164 4.51773 8.52164 5.47823Z"
        fill="#F55858"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7393 18.5218C13.7393 20.4428 15.2965 22 17.2175 22C19.1385 22 20.6958 20.4428 20.6958 18.5218C20.6958 16.6008 19.1385 15.0435 17.2175 15.0435C15.2965 15.0435 13.7393 16.6008 13.7393 18.5218ZM18.9567 18.5218C18.9567 19.4823 18.1781 20.2609 17.2176 20.2609C16.2571 20.2609 15.4784 19.4823 15.4784 18.5218C15.4784 17.5613 16.2571 16.7827 17.2176 16.7827C18.1781 16.7827 18.9567 17.5613 18.9567 18.5218Z"
        fill="#FFBD80"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7393 10.6957C13.7393 12.6167 15.2965 14.1739 17.2175 14.1739C19.1385 14.1739 20.6958 12.6167 20.6958 10.6957C20.6958 8.77468 19.1385 7.21741 17.2175 7.21741C15.2965 7.21741 13.7393 8.77468 13.7393 10.6957ZM18.9567 10.6956C18.9567 11.6561 18.1781 12.4348 17.2176 12.4348C16.2571 12.4348 15.4784 11.6561 15.4784 10.6956C15.4784 9.73513 16.2571 8.9565 17.2176 8.9565C18.1781 8.9565 18.9567 9.73513 18.9567 10.6956Z"
        fill="#00D4FF"
      />
    </svg>
  );
};

export default GroupIcon;
