import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  Box,
  Container,
  CssBaseline,
  Grid,
  Paper,
  Toolbar,
} from "@mui/material";

import Sidebar from "../../Sidebar";
import GroupInfo from "./GroupInfo";
import GroupSuccessPrompt from "../GroupSuccessPrompt";
import GroupErrorPrompt from "../GroupErrorPrompt";
import GroupBreadcrumb from "./GroupBreadcrumb";
import CreateImmediateJobGroup from "./CreateImmediateJobGroup";
import DeleteGroup from "./DeleteGroup";
import EditGroup from "./EditGroup";
import { getGroup } from "../../../store/actions/groupAction";
import { decrypt } from "../../../helpers/crypto";
import Role from "../../../helpers/roles";
import AssignedComponentList from "../../assign/AssignedComponentList";
import AssignComponent from "../../assign/AssignComponent";

const GroupDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getGroup(id));
  }, [dispatch, id]);

  const { group, loadingGroup: loading } = useSelector((state) => state.group);

  const { loggedUser } = useSelector((state) => state.auth);
  const decryptedLoggedUser = decrypt(loggedUser);
  const userRole = decryptedLoggedUser.type;
  return (
    <div style={{ display: "flex" }}>
      <Sidebar />

      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Toolbar />
        <CssBaseline />

        <Container maxWidth="xl">
          <Box sx={{ mb: 2 }}>
            <GroupBreadcrumb group={group} />
          </Box>

          <GroupSuccessPrompt />
          <GroupErrorPrompt />

          <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
            {userRole === Role.Admin && !loading && (
              <>
                <CreateImmediateJobGroup group={group} />
                <EditGroup group={group} />
                <DeleteGroup group={group} />
              </>
            )}
          </Box>

          <Grid container spacing={2} direction="column">
            <Grid item xs={4}>
              <Paper elevation={2} sx={{ p: 2, borderRadius: "6px" }}>
                <GroupInfo group={group} loading={loading} />
              </Paper>
            </Grid>

            {userRole === Role.Admin && (
              <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                <AssignComponent
                  getName={"viewer"}
                  typeName="group"
                  typeData={group}
                />
                <AssignComponent
                  getName={"schedule"}
                  typeName="group"
                  typeData={group}
                />
              </Box>
            )}

            <Grid item xs={4}>
              <Paper elevation={2} sx={{ borderRadius: "6px" }}>
                <AssignedComponentList
                  key={"viewer"}
                  type={"viewer"}
                  listData={group.screens}
                  sourceData={{ id: group.id, name: group.name, type: "group" }}
                  loading={loading}
                />
              </Paper>
            </Grid>

            <Grid item xs={4}>
              <Paper elevation={2} sx={{ borderRadius: "6px" }}>
                <AssignedComponentList
                  key={"schedule"}
                  type={"schedule"}
                  listData={group.schedules}
                  sourceData={{ id: group.id, name: group.name, type: "group" }}
                  loading={loading}
                />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default GroupDetail;
