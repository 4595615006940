import { initiateAXIOS } from "../../config/axios";
import { handleError } from "../handleError";
import {
  SET_DELETE_GROUP,
  SET_DETAIL_GROUP,
  SET_ERROR_MESSAGE_GROUP_PAGE,
  SET_ERROR_PROMPT_GROUP_PAGE,
  SET_GROUP,
  SET_LOADING_GROUP,
  SET_NEW_GROUP,
  SET_SUCCESS_MESSAGE_GROUP_PAGE,
  SET_SUCCESS_PROMPT_GROUP_PAGE,
} from "../actionTypes/groupActionType";
import { groupAPILimit, initiateGroupAPI } from "../../api";
import Group from "../../models/GroupModel";
import Schedule from "../../models/ScheduleModel";
import Viewer from "../../models/ViewerModel";
import { ascending } from "../../helpers/sorting";

export const handleTimeoutSuccessPrompt = (dispatch) => {
  setTimeout(() => {
    dispatch({ type: SET_SUCCESS_PROMPT_GROUP_PAGE, payload: false });
  }, 3000);
};

export const handleTimeoutErrorPrompt = (dispatch) => {
  setTimeout(() => {
    dispatch({ type: SET_ERROR_PROMPT_GROUP_PAGE, payload: false });
  }, 5000);
};

const fetchGroupById = async (id, access_token) => {
  const { data } = await initiateAXIOS.get(initiateGroupAPI + id, {
    headers: { authorization: `Bearer ${access_token}` },
  });

  const schedules = [];
  data.schedules.map((item) => schedules.push(new Schedule(item)));

  const screens = [];
  data.screens.map((item) => screens.push(new Viewer(item)));

  return { ...data, schedules, screens };
};

export const getGroups = (query) => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");
    dispatch({ type: SET_LOADING_GROUP, payload: true });

    try {
      const { data } = await initiateAXIOS.get(initiateGroupAPI + query, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      let groups = [];

      await Promise.all(
        data.items.map(async (grp) => {
          const group = await initiateAXIOS.get(initiateGroupAPI + grp.id, {
            headers: { authorization: `Bearer ${access_token}` },
          });

          const schedules = [];
          group.data.schedules.map((item) =>
            schedules.push(new Schedule(item))
          );

          const screens = [];
          group.data.screens.map((item) => screens.push(new Viewer(item)));

          groups.push(new Group({ ...group.data, schedules, screens }));
        })
      );

      dispatch({ type: SET_GROUP, payload: ascending(groups, "id") });
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_GROUP_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_GROUP_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_GROUP, payload: false });
    }
  };
};

export const createGroup = (payload) => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");
    dispatch({ type: SET_LOADING_GROUP, payload: true });

    try {
      const { data } = await initiateAXIOS.post(initiateGroupAPI, payload, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      const group = new Group(data);
      dispatch({ type: SET_NEW_GROUP, payload: group });
      dispatch({ type: SET_SUCCESS_PROMPT_GROUP_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_GROUP_PAGE,
        payload: "A new group has been created successfully.",
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_GROUP_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_GROUP_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_GROUP, payload: false });
    }
  };
};

export const searchGroup = (query) => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");
    dispatch({ type: SET_LOADING_GROUP, payload: true });

    try {
      const { data } = await initiateAXIOS.get(groupAPILimit + `${query}`, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      const listGroup = data.items;

      let groups = [];
      listGroup.map((item) => groups.push(item));
      dispatch({ type: SET_GROUP, payload: groups });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (error) {
      const message = handleError(error);
      dispatch({ type: SET_ERROR_PROMPT_GROUP_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_GROUP_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_GROUP, payload: false });
    }
  };
};

export const deleteGroup = (payload) => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");
    dispatch({ type: SET_LOADING_GROUP, payload: true });

    try {
      await initiateAXIOS.delete(initiateGroupAPI + payload.id, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      dispatch({ type: SET_SUCCESS_PROMPT_GROUP_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_GROUP_PAGE,
        payload: "The group has been deleted.",
      });

      dispatch({ type: SET_DELETE_GROUP, payload: payload.id });
      dispatch({ type: SET_SUCCESS_PROMPT_GROUP_PAGE, payload: true });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_GROUP_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_GROUP_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_GROUP, payload: false });
    }
  };
};

export const editGroup = (payload) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_GROUP, payload: true });

    try {
      await initiateAXIOS.patch(initiateGroupAPI + payload.id, payload.data, {
        headers: { authorization: `Bearer ${access_token}` },
      });
      const { data } = await initiateAXIOS.get(initiateGroupAPI + payload.id, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      const schedules = [];
      data.schedules.map((item) => schedules.push(new Schedule(item)));

      const screens = [];
      data.screens.map((item) => screens.push(new Viewer(item)));

      const group = new Group({ ...data, schedules, screens });

      dispatch({ type: SET_DETAIL_GROUP, payload: group });
      dispatch({ type: SET_SUCCESS_PROMPT_GROUP_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_GROUP_PAGE,
        payload: "The group has been updated.",
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_GROUP_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_GROUP_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_GROUP, payload: false });
    }
  };
};

export const getGroup = (id) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_GROUP, payload: true });

    try {
      const { data } = await initiateAXIOS.get(initiateGroupAPI + id, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      const schedules = [];
      data.schedules.map((item) => schedules.push(new Schedule(item)));

      const screens = [];
      data.screens.map((item) => screens.push(new Viewer(item)));

      const group = new Group({ ...data, schedules, screens });

      dispatch({
        type: SET_DETAIL_GROUP,
        payload: group,
      });
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_GROUP_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_GROUP_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_GROUP, payload: false });
    }
  };
};

export const createImmediateJobForGroup = ({ id, schedule }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_GROUP, payload: true });

    try {
      await initiateAXIOS.post(initiateGroupAPI + id + "/immediate", schedule, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      dispatch({ type: SET_SUCCESS_PROMPT_GROUP_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_GROUP_PAGE,
        payload: "An immediate job has been created.",
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_GROUP_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_GROUP_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_GROUP, payload: false });
    }
  };
};

export const assignGroupToSchedule = ({ id, assignData }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_GROUP, payload: true });

    try {
      await initiateAXIOS.patch(
        initiateGroupAPI + id + "/schedule",
        { add: assignData },
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({
        type: SET_DETAIL_GROUP,
        payload: new Group(await fetchGroupById(id, access_token)),
      });
      dispatch({ type: SET_SUCCESS_PROMPT_GROUP_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_GROUP_PAGE,
        payload: "The group has been assigned schedules.",
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_GROUP_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_GROUP_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_GROUP, payload: false });
    }
  };
};

export const removeScheduleFromGroup = ({ id, remove }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_GROUP, payload: true });

    try {
      await initiateAXIOS.patch(
        initiateGroupAPI + id + "/schedule",
        { remove },
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({
        type: SET_DETAIL_GROUP,
        payload: new Group(await fetchGroupById(id, access_token)),
      });

      dispatch({ type: SET_SUCCESS_PROMPT_GROUP_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_GROUP_PAGE,
        payload: "The schedule has been removed from the group.",
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_GROUP_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_GROUP_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_GROUP, payload: false });
    }
  };
};

export const assignGroupToViewer = ({ id, assignData }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_GROUP, payload: true });

    try {
      await initiateAXIOS.patch(
        initiateGroupAPI + id + "/screen",
        { add: assignData },
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({
        type: SET_DETAIL_GROUP,
        payload: new Group(await fetchGroupById(id, access_token)),
      });
      dispatch({ type: SET_SUCCESS_PROMPT_GROUP_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_GROUP_PAGE,
        payload: "Viewers have been assigned to the group.",
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_GROUP_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_GROUP_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_GROUP, payload: false });
    }
  };
};

export const removeViewerFromGroup = ({ id, remove }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_GROUP, payload: true });

    try {
      await initiateAXIOS.patch(
        initiateGroupAPI + id + "/screen",
        { remove },
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({
        type: SET_DETAIL_GROUP,
        payload: new Group(await fetchGroupById(id, access_token)),
      });
      dispatch({ type: SET_SUCCESS_PROMPT_GROUP_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_GROUP_PAGE,
        payload: "The viewer has been removed from the group.",
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_GROUP_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_GROUP_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_GROUP, payload: false });
    }
  };
};

export const removeSubgroupFromGroup = ({ id, deleteChildren }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_GROUP, payload: true });

    try {
      const { data } = await initiateAXIOS.patch(
        initiateGroupAPI + id + "/children",
        { deleteChildren: deleteChildren },
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      const OOP = data;

      dispatch({ type: SET_DETAIL_GROUP, payload: OOP });
      dispatch({ type: SET_SUCCESS_PROMPT_GROUP_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_GROUP_PAGE,
        payload: "The subgroup has been removed from the group.",
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_GROUP_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_GROUP_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_GROUP, payload: false });
    }
  };
};
