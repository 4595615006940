import React, { useState } from "react";
import { useDispatch } from "react-redux";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import UpdateIcon from "@mui/icons-material/Update";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import { editSchedule } from "../../../store/actions/scheduleAction";

const ActivateSchedule = ({ schedule }) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let status;

    schedule.isActive ? (status = false) : (status = true);

    const payload = {
      id: schedule.id,
      schedule: { isActive: status },
    };

    dispatch(editSchedule(payload));
    setOpen(false);
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        variant="contained"
        startIcon={<UpdateIcon fontSize="small" />}
        sx={{ mr: 2, boxShadow: 3 }}
      >
        {schedule.isActive ? "Deactivate Schedule" : "Activate Schedule"}
      </Button>

      <Dialog maxWidth="sm" open={open} onClose={handleClickClose}>
        <DialogContent>
          <DialogContentText
            sx={{
              textAlign: "center",
              mb: 2,
            }}
          >
            Are you sure want to{" "}
            <strong>{schedule.isActive ? "Deactivate" : "Activate"}</strong>
            schedule <strong>{schedule.name}?</strong>{" "}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            color="error"
            variant="outlined"
            fullWidth
            startIcon={<ClearIcon />}
            onClick={handleClickClose}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            fullWidth
            startIcon={<DoneIcon />}
            onClick={handleSubmit}
          >
            {schedule.isActive ? "Deactivate Schedule" : "Activate Schedule"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ActivateSchedule;
