import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import {
  Box,
  Toolbar,
  CssBaseline,
  Container,
  Tab,
  Tabs,
  Grid,
  Paper,
} from "@mui/material";

import GlobalSuccessPrompt from "../global/GlobalSuccessPrompt";
import GlobalErrorPrompt from "../global/GlobalErrorPrompt";
import EditPassword from "./EditPassword";
import Sidebar from "../Sidebar";

import { TypographyNormal } from "../customComponent";
import { decrypt } from "../../helpers/crypto";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 2 }}>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const MyAccount = () => {
  const { loggedUser } = useSelector((state) => state.auth);
  const decryptedLoggedUser = decrypt(loggedUser);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div style={{ display: "flex" }}>
      <Sidebar />

      <Box component="main" sx={{ flexGrow: 1, py: 2, px: 1 }}>
        <Toolbar />
        <CssBaseline />
        <GlobalSuccessPrompt />
        <GlobalErrorPrompt />

        <Container maxWidth="md" sx={{ width: "100%" }}>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label={
                    <TypographyNormal
                      sx={{ textTransform: "capitalize", fontWeight: 300 }}
                    >
                      General Information
                    </TypographyNormal>
                  }
                  {...a11yProps(0)}
                />
                <Tab
                  label={
                    <TypographyNormal
                      sx={{ textTransform: "capitalize", fontWeight: 300 }}
                    >
                      Edit Password
                    </TypographyNormal>
                  }
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Paper
                elevation={2}
                sx={{ borderRadius: "6px", pl: 2, pb: 2, pr: 2, mt: 2 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TypographyNormal sx={{ fontWeight: 600 }}>
                      Username
                    </TypographyNormal>
                  </Grid>
                  <Grid item xs={6}>
                    <TypographyNormal sx={{ fontWeight: 300 }}>
                      {decryptedLoggedUser.name}
                    </TypographyNormal>
                  </Grid>

                  <Grid item xs={6}>
                    <TypographyNormal sx={{ fontWeight: 600 }}>
                      Email
                    </TypographyNormal>
                  </Grid>
                  <Grid item xs={6}>
                    <TypographyNormal sx={{ fontWeight: 300 }}>
                      {decryptedLoggedUser.email}
                    </TypographyNormal>
                  </Grid>

                  <Grid item xs={6}>
                    <TypographyNormal sx={{ fontWeight: 600 }}>
                      Phone
                    </TypographyNormal>
                  </Grid>
                  <Grid item xs={6}>
                    <TypographyNormal sx={{ fontWeight: 300 }}>
                      {decryptedLoggedUser.phone}
                    </TypographyNormal>
                  </Grid>

                  <Grid item xs={6}>
                    <TypographyNormal sx={{ fontWeight: 600 }}>
                      Organization
                    </TypographyNormal>
                  </Grid>
                  <Grid item xs={6}>
                    <TypographyNormal sx={{ fontWeight: 300 }}>
                      {decryptedLoggedUser.organization.name}
                    </TypographyNormal>
                  </Grid>

                  <Grid item xs={6}>
                    <TypographyNormal sx={{ fontWeight: 600 }}>
                      Company
                    </TypographyNormal>
                  </Grid>
                  <Grid item xs={6}>
                    <TypographyNormal sx={{ fontWeight: 300 }}>
                      {decryptedLoggedUser.organization.company.name}
                    </TypographyNormal>
                  </Grid>
                </Grid>
              </Paper>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <EditPassword />
            </TabPanel>
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default MyAccount;
