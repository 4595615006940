import React, { useState } from "react";

import { IconButton, Menu, MenuItem, TableCell } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { red } from "@mui/material/colors";

import { StyledTableRow } from "../../customComponent";
import EditUserGroup from "./EditUserGroup";
import DeleteUserGroup from "./DeleteUserGroup";

const UserGroupItem = ({ group }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [openEdit, setOpenEdit] = useState(false);

  const handleClickOpenEdit = () => setOpenEdit(true);

  const handleClickCloseEdit = () => setOpenEdit(false);

  const [openDelete, setOpenDelete] = useState(false);

  const handleClickOpenDelete = () => setOpenDelete(true);

  const handleClickCloseDelete = () => setOpenDelete(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <StyledTableRow>
      <TableCell>{group.name}</TableCell>
      <TableCell align="right">
        <IconButton
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          sx={{ color: "#111827" }}
        >
          <MoreHorizIcon />
        </IconButton>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleClickOpenEdit} dense>
            <EditOutlinedIcon fontSize="small" sx={{ mr: "5px" }} />
            Edit group
          </MenuItem>
          <EditUserGroup
            open={openEdit}
            onClose={handleClickCloseEdit}
            group={group}
          />

          <MenuItem
            sx={{ color: red[600] }}
            onClick={handleClickOpenDelete}
            dense
          >
            <DeleteOutlinedIcon fontSize="small" sx={{ mr: "5px" }} />
            Delete group
          </MenuItem>
          <DeleteUserGroup
            open={openDelete}
            onClose={handleClickCloseDelete}
            group={group}
          />
        </Menu>
      </TableCell>
    </StyledTableRow>
  );
};

export default UserGroupItem;
