import React from "react";
import { Box, Button, Container, CssBaseline, Toolbar } from "@mui/material";
import ContentTemplateSuccessPrompt from "./ContentTemplateSuccessPrompt";
import ContentTemplateErrorPrompt from "./ContentTemplateErrorPrompt";
import { TypographyNormal, TypographyXLarge } from "../../customComponent";
import Sidebar from "../../Sidebar";
import ContentTemplateList from "./ContentTemplateList";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";

const ContentTemplate = () => {
  const navigate = useNavigate();

  return (
    <div style={{ display: "flex" }}>
      <Sidebar />

      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Toolbar />
        <CssBaseline />

        <Container maxWidth="xl">
          <Box sx={{ mb: 1 }}>
            <TypographyXLarge sx={{ fontWeight: 700 }}>
              CMS Content Template
            </TypographyXLarge>
            <TypographyNormal sx={{ fontWeight: 400 }}>
              Manage your digital signage content template
            </TypographyNormal>
          </Box>

          <ContentTemplateSuccessPrompt />
          <ContentTemplateErrorPrompt />

          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              sx={{ boxShadow: 3 }}
              onClick={() => navigate("/cms/content-template/create")}
            >
              Create Content
            </Button>
          </Box>
          <Box sx={{ my: 2 }}>
            {/* TODO add content filter */}
            {/* <Paper
              component="section"
              elevation={2}
              sx={{ p: 2, my: 2, borderRadius: "6px" }}
            >
              <Box>Filter</Box>
            </Paper> */}
            <ContentTemplateList />
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default ContentTemplate;
