export const SET_HOME_LOADING_VIEWER = "SET_HOME_LOADING_VIEWER";
export const SET_HOME_VIEWER = "SET_HOME_VIEWER";
export const SET_HOME_ACTIVE_VIEWER = "SET_HOME_ACTIVE_VIEWER";
export const SET_HOME_LOADING_GROUP = "SET_HOME_LOADING_GROUP";
export const SET_HOME_GROUP = "SET_HOME_GROUP";
export const SET_HOME_LOADING_SCHEDULE = "SET_HOME_LOADING_SCHEDULE";
export const SET_HOME_ACTIVE_SCHEDULE = "SET_HOME_ACTIVE_SCHEDULE";
export const SET_HOME_LOADING_LAST_VIEWER_ACTIVE =
  "SET_HOME_LOADING_LAST_VIEWER_ACTIVE";
export const SET_HOME_LAST_VIEWER_ACTIVE = "SET_HOME_LAST_VIEWER_ACTIVE";
export const SET_HOME_SCHEDULE_PENDING_ACTIVE_STATUS =
  "SET_HOME_SCHEDULE_PENDING_ACTIVE_STATUS";
export const SET_HOME_LOADING_SCHEDULE_PENDING_ACTIVE_STATUS =
  "SET_HOME_LOADING_SCHEDULE_PENDING_ACTIVE_STATUS";
export const SET_HOME_ACTIVE_ANNOUNCEMENT = "SET_HOME_ACTIVE_ANNOUNCEMENT";
export const SET_HOME_LOADING_ACTIVE_ANNOUNCEMENT =
  "SET_HOME_LOADING_ACTIVE_ANNOUNCEMENT";
