import { userReducer } from "./userReducer";
import { announcementReducer } from "./announcementReducer";
import { globalReducer } from "./globalReducer";
import { groupReducer } from "./groupReducer";
import { homeReducer } from "./homeReducer";
import { scheduleReducer } from "./scheduleReducer";
import { viewerReducer } from "./viewerReducer";
import { organizationReducer } from "./organizationReducer";
import { jobHistoryReducer } from "./jobHistoryReducer";
import { disruptionReducer } from "./trainDisruptionReducer";
import { contentUploadReducer } from "./contentUploadReducer";

// Start BPLRT SMP #RefBPLRT
import { smpReducer } from "./smpReducer";
import { equipmentReducer } from "./equipmentBreakdownReducer";
import { cmsReducer } from "./cmsReducer";
// End BPLRT SMP

const rootReducers = {
  user: userReducer,
  global: globalReducer,
  home: homeReducer,
  schedule: scheduleReducer,
  group: groupReducer,
  viewer: viewerReducer,
  announcement: announcementReducer,
  organization: organizationReducer,
  jobHistory: jobHistoryReducer,
  disruption: disruptionReducer,
  contentUpload: contentUploadReducer,
  cms: cmsReducer,
  // Start BPLRT SMP #RefBPLRT
  smp: smpReducer,
  equipmentBreakdown: equipmentReducer,
  // End BPLRT SMP
};

export default rootReducers;
