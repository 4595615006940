import { setPriorityName } from "../helpers/scheduleHelper";

export default class Schedule {
  constructor(payload) {
    this.id = payload.id;
    this.isActive = payload.isActive;
    this.isApproved = payload.isApproved;
    this.name = payload.name;
    this.type = payload.type;
    this.command = payload.command;
    this.startAt = payload.startAt;
    this.endAt = payload.endAt;
    this.frequency = payload.repetition;
    this.cron = payload.cron;
    this.duration = payload.duration;
    this.priority = {
      value: payload.priority,
      name: setPriorityName(+payload.priority),
    };
    this.content = payload.content;
    this.contentId = payload.contentId;
    this.screenGroups = payload.screenGroups;
    this.screens = payload.screens;
    this.organizationId = payload.organizationId;
  }
}
