import {
  SET_DISRUPTION,
  SET_LOADING_DISRUPTION,
  SET_NEW_DISRUPTION,
  SET_DELETE_DISRUPTION,
  SET_DELETE_ALL_DISRUPTION,
  SET_ERROR_MESSAGE_DISRUPTION,
  SET_ERROR_PROMPT_DISRUPTION,
  SET_SUCCESS_MESSAGE_DISRUPTION,
  SET_SUCCESS_PROMPT_DISRUPTION,
  SET_DISRUPTION_ANNOUNCEMENT,
} from "../actionTypes/disruptionActionType";

const initialState = {
  disruptions: [],
  disruptionAnnouncement: {},
  loadingDisruption: false,
  isSuccessPromptDisruptionPage: false,
  isErrorPromptDisruptionPage: false,
  successMessageDisruptionPage: "",
  errorMessageDisruptionPage: "",
};

export const disruptionReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_DISRUPTION:
      return { ...state, disruptions: payload };

    case SET_LOADING_DISRUPTION:
      return { ...state, loadingDisruption: payload };

    case SET_NEW_DISRUPTION:
      const listOfDisruption = state.disruptions;
      return { ...state, disruptions: [payload, ...listOfDisruption] };

    case SET_DELETE_DISRUPTION:
      const disruptionsState = state.disruptions;
      const filtered = disruptionsState.filter(
        (disruption) => disruption.id.toString() !== payload.toString()
      );
      return { ...state, disruptions: filtered };

    case SET_DELETE_ALL_DISRUPTION:
      return { ...state, disruptions: [] };

    case SET_SUCCESS_PROMPT_DISRUPTION:
      return { ...state, isSuccessPromptDisruptionPage: payload };

    case SET_SUCCESS_MESSAGE_DISRUPTION:
      return { ...state, successMessageDisruptionPage: payload };

    case SET_ERROR_PROMPT_DISRUPTION:
      return { ...state, isErrorPromptDisruptionPage: payload };

    case SET_ERROR_MESSAGE_DISRUPTION:
      return { ...state, errorMessageDisruptionPage: payload };

    case SET_DISRUPTION_ANNOUNCEMENT:
      return { ...state, disruptionAnnouncement: payload };

    default:
      return state;
  }
};
