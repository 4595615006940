import { useSelector } from "react-redux";

import { Box, CssBaseline, Toolbar, Container, Paper } from "@mui/material";

import { Sidebar } from "..";
import GroupSuccessPrompt from "./GroupSuccessPrompt";
import GroupErrorPrompt from "./GroupErrorPrompt";
import CreateGroup from "./CreateGroup";
import FilterGroup from "./FilterGroup";
import GroupList from "./GroupList";

import { TypographyNormal, TypographyXLarge } from "../customComponent";
import { decrypt } from "../../helpers/crypto";
import Role from "../../helpers/roles";

const Group = () => {
  const { loggedUser } = useSelector((state) => state.auth);
  const decryptedLoggedUser = decrypt(loggedUser);
  const userRole = decryptedLoggedUser.type;

  return (
    <div style={{ display: "flex" }}>
      <Sidebar />

      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Toolbar />
        <CssBaseline />

        <Container maxWidth="xl">
          <Box sx={{ mb: 1 }}>
            <TypographyXLarge sx={{ fontWeight: 700 }}>Group</TypographyXLarge>
            <TypographyNormal sx={{ fontWeight: 400 }}>
              Manage your digital signage groups
            </TypographyNormal>
          </Box>

          <GroupSuccessPrompt />
          <GroupErrorPrompt />

          {userRole === Role.Admin && (
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <CreateGroup />
            </Box>
          )}

          <Paper
            component="section"
            elevation={2}
            sx={{ p: 2, my: 2, borderRadius: "6px" }}
          >
            <FilterGroup />
          </Paper>

          <Paper
            component="section"
            elevation={2}
            sx={{ borderRadius: "6px", my: 2, p: 2 }}
          >
            <GroupList />
          </Paper>
        </Container>
      </Box>
    </div>
  );
};

export default Group;
