import React, { useState } from "react";

import {
  IconButton,
  TableCell,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tooltip,
  TableHead,
  Table,
  TableBody,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import { formattedDate } from "../../helpers/dateFormat";
// import { blue } from "@mui/material/colors";
import { useDispatch } from "react-redux";
import { deleteDisruption } from "../../store/actions/disruptionAction";

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f3f8fe",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const TrainDisruptionItem = ({ disruption }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const deleteEvent = async (eventId) => {
    dispatch(deleteDisruption(eventId));
  };

  return (
    <StyledTableRow>
      <TableCell>
        <IconButton
          color="error"
          onClick={() => {
            setOpen(true);
          }}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>

        <Dialog maxWidth="sm" open={open} onClose={handleClose}>
          <DialogTitle>Delete Confirmation</DialogTitle>

          <DialogContent>
            <DialogContentText>
              Are you sure want to delete all this records?
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button
              fullWidth
              onClick={handleClose}
              variant="outlined"
              color="error"
            >
              Cancel
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="error"
              onClick={() => {
                deleteEvent(disruption.id);
              }}
            >
              Yes, Delete It!
            </Button>
          </DialogActions>
        </Dialog>
      </TableCell>
      <TableCell sx={{ textTransform: "uppercase" }}>
        {disruption.line.code}
      </TableCell>
      <TableCell sx={{ textTransform: "uppercase" }}>
        {disruption.direction.station}
      </TableCell>
      {/* <TableCell>{disruption.lineStatus}</TableCell> */}
      <TableCell sx={{ textTransform: "uppercase" }}>
        {disruption.betweenFrom.abbr}
      </TableCell>
      <TableCell sx={{ textTransform: "uppercase" }}>
        {disruption.betweenTo.abbr}
      </TableCell>
      <TableCell>
        <AffectedStationList data={disruption.affectedStations} />
      </TableCell>
      <TableCell sx={{ textTransform: "capitalize" }}>
        {disruption.type.toLowerCase()}
      </TableCell>
      <TableCell>
        {disruption.type === "DELAY"
          ? disruption.delayTime + " minutes"
          : formattedDate(disruption.resumeTime)}
      </TableCell>
      <TableCell>{formattedDate(disruption.date)}</TableCell>
    </StyledTableRow>
  );
};

const AffectedStationList = ({ data }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      {data?.length}{" "}
      {data?.length !== 0 && (
        <>
          <Tooltip title="View Affected Stations">
            <IconButton
              size="small"
              onClick={() => {
                setOpen(true);
              }}
            >
              <OpenInNewIcon fontSize="inherit" color="primary" />
            </IconButton>
          </Tooltip>

          <Dialog
            open={open}
            onClick={() => {
              setOpen(false);
            }}
            fullWidth
            maxWidth="xs"
          >
            <DialogContent>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Code</TableCell>
                    <TableCell>Name</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.map((item) => (
                    <StyledTableRow key={item.code}>
                      <TableCell>{item.code}</TableCell>
                      <TableCell>
                        {item.name + " (" + item.abbr + ")"}
                      </TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </DialogContent>
            <DialogActions>
              <Button
                fullWidth
                onClick={() => setOpen(false)}
                variant="contained"
                color="primary"
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
};

export default TrainDisruptionItem;
