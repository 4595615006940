import {
  SET_DELETE_VIEWER,
  SET_LOADING_VIEWER,
  SET_LOADING_VIEWER_DETAIL,
  SET_VIEWER,
  SET_VIEWER_DETAIL,
  SET_SUCCESS_MESSAGE_VIEWER_PAGE,
  SET_SUCCESS_PROMPT_VIEWER_PAGE,
  SET_ERROR_PROMPT_VIEWER_PAGE,
  SET_ERROR_MESSAGE_VIEWER_PAGE,
  SET_CREATE_VIEWER,
} from "../actionTypes/viewerActionTypes";

const initialState = {
  viewers: [],
  loadingViewer: false,
  viewer: {},
  loadingViewerDetail: false,
  isSuccessPromptViewerPage: false,
  isErrorPromptViewerPage: false,
  successMessageViewerPage: "",
  errorMessageViewerPage: "",
};
export const viewerReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_VIEWER:
      const sortedViewer = payload.sort(function compare(a, b) {
        if (a.status.connectionStatus > b.status.connectionStatus) {
          return -1;
        }
        if (a.status.connectionStatus < b.status.connectionStatus) {
          return 1;
        }
        return 0;
      });
      return {
        ...state,
        viewers: sortedViewer,
      };

    case SET_CREATE_VIEWER:
      const listViewer = state.viewers;
      return { ...state, viewers: [payload, ...listViewer] };

    case SET_LOADING_VIEWER:
      return { ...state, loadingViewer: payload };

    case SET_VIEWER_DETAIL:
      return { ...state, viewer: payload };

    case SET_LOADING_VIEWER_DETAIL:
      return { ...state, loadingViewerDetail: payload };

    case SET_DELETE_VIEWER:
      return {
        ...state,
        viewers: state.viewers.filter(
          (viewer) => viewer.id.toString() !== payload.toString()
        ),
      };

    case SET_SUCCESS_PROMPT_VIEWER_PAGE:
      return { ...state, isSuccessPromptViewerPage: payload };

    case SET_SUCCESS_MESSAGE_VIEWER_PAGE:
      return { ...state, successMessageViewerPage: payload };

    case SET_ERROR_PROMPT_VIEWER_PAGE:
      return { ...state, isErrorPromptViewerPage: payload };

    case SET_ERROR_MESSAGE_VIEWER_PAGE:
      return { ...state, errorMessageViewerPage: payload };

    default:
      return state;
  }
};
