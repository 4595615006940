import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
} from "@mui/material";
import ContentTemplateItem from "./ContentTemplateItem";
import ContentDetails from "./detail/ContentDetails";
import { TypographyNormal, TypographyLarge } from "../../customComponent";
import { getAllContents } from "../../../store/actions/cmsAction";

const tableStyling = {
  padding: "12px 12px"
};

const ContentTemplateList = () => {
  const dispatch = useDispatch();
  const { cmsData, loadingCMS } = useSelector((state) => state.cms);

  const DATA_PER_PAGE = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [contents, setContents] = useState([]);

  const [openContentDetails, setOpenContentDetails] = useState(false);
  const [dataDetails, setDataDetails] = useState({});

  useEffect(() => {
    // First load
    const { payload } = parseGetContentsPayload(0);
    dispatch(getAllContents(payload));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenContentDetails = (data) => {
    setDataDetails(data);
    setOpenContentDetails(true);
  };

  const handleCloseContentDetails = () => {
    setOpenContentDetails(false);
  };

  const parseGetContentsPayload = useCallback(
    (page) => {
      let safePage = Math.min(page ?? currentPage, pageCount);
      safePage = Math.max(safePage, 1);
      const newOffset = (safePage - 1) * DATA_PER_PAGE;
  
      const getPayload = {
        limit: DATA_PER_PAGE,
        offset: newOffset,
      };
  
      return { payload: getPayload, page: safePage };
    },
    [currentPage, pageCount],
  );

  const handleChangePagination = (e, page) => {
    if (page === currentPage) {
      return;
    }

    const { payload, page: pageValue } = parseGetContentsPayload(page);
    dispatch(getAllContents(payload));
    setCurrentPage(pageValue);
  };

  useEffect(() => {
    if (cmsData?.items?.length > 0) {
      setContents(cmsData.items);

      const count = Math.ceil((cmsData.totalItem ?? cmsData.items.length) / DATA_PER_PAGE);
      setPageCount(count);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmsData]);

  if (contents.length === 0 || loadingCMS) {
    return (
      <Box
        sx={{ height: "20vh" }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <CircularProgress size={20} thickness={3} sx={{ color: "#1D4ED8" }} />
        <TypographyNormal sx={{ color: "#1D4ED8", mt: 2, fontWeight: 400 }}>
          Loading Data...
        </TypographyNormal>
      </Box>
    );
  }

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ overflowY: "auto", maxHeight: 500, boxShadow: 3 }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ ...tableStyling, paddingLeft: 4 }}>
                Content Name
              </TableCell>
              <TableCell align="center" sx={{ ...tableStyling, width: 222 }}>
                Modified Date
                <TypographyNormal>
                  Local Time [Month/Day]
                </TypographyNormal>
              </TableCell>
              <TableCell align="center" sx={{ ...tableStyling, width: 222 }}>
                Latest Edited by
              </TableCell>
              <TableCell align="right" sx={{ ...tableStyling, width: 222, paddingRight: 7 }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contents.length !== 0 && contents.map((data) => (
              <ContentTemplateItem
                key={data.id}
                data={data}
                openContentDetails={handleOpenContentDetails}
                currentPayload={parseGetContentsPayload}
              />
            ))}
          </TableBody>
        </Table>
        {contents.length === 0 && (
          <Box
            alignItems={"center"}
            bgcolor={"#f9f9f9"}
            sx={{ p: 5 }}
          >
            <TypographyLarge textAlign={"center"}>
              No Content Found
            </TypographyLarge>
          </Box>
        )}
      </TableContainer>

      <ContentDetails
        open={openContentDetails}
        handleClose={handleCloseContentDetails}
        data={dataDetails}
      />

      {!loadingCMS && contents.length ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            pt: 4,
            pb: 2,
            pr: 2,
          }}
        >
          <Pagination
            count={pageCount}
            page={currentPage}
            shape="rounded"
            color="primary"
            boundaryCount={1}
            onChange={handleChangePagination}
          />
        </Box>
      ) : null}
    </>
  );
};

export default ContentTemplateList;
