import React, { useState } from "react";
import { useDispatch } from "react-redux";

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Button,
  IconButton,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";

import {
  GlobePurpleIcon,
  // OfflineRedIcon,
  PCOrangeIcon,
  TvGreenIcon,
} from "../../../icons";
import { CustomTextField } from "../../customComponent";
import { createImmediateJobForGroup } from "../../../store/actions/groupAction";

const Confirmation = ({
  openConfirmation,
  closeConfirmation,
  closeForm,
  group,
  type,
  command,
}) => {
  const dispatch = useDispatch();

  const handleClickClose = () => {
    closeConfirmation(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      id: group.id,
      schedule: {
        type: command === "RELOAD" ? "PC" : type,
        command: command,
        args: [],
      },
    };

    dispatch(createImmediateJobForGroup(payload));

    closeConfirmation(false);
    closeForm();
  };

  return (
    <>
      <Dialog maxWidth="sm" open={openConfirmation} onClose={handleClickClose}>
        <DialogContent>
          <DialogContentText sx={{ textAlign: "center" }}>
            Are you sure want to create an immediate job for{" "}
            <strong>{group.name}</strong> ?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            startIcon={<ClearIcon />}
            fullWidth
            variant="outlined"
            onClick={handleClickClose}
          >
            No
          </Button>

          <Button
            startIcon={<DoneIcon />}
            fullWidth
            variant="contained"
            onClick={handleSubmit}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const CreateImmediateJobGroup = ({ group }) => {
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("");
  const [command, setCommand] = useState("");

  const [openConfirmation, setOpenConfirmation] = useState(false);

  const handleOpenConfirmation = () => {
    setOpenConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
    setType("");
    setCommand("");
  };

  return (
    <>
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={handleClickOpen}
        sx={{ boxShadow: 3 }}
      >
        New Immediate Job
      </Button>

      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        keepMounted
        onClose={handleClickClose}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          New Immediate Job
          <IconButton onClick={handleClickClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <Divider />

        <Box component="form">
          <DialogContent>
            <Box>
              <FormControl
                required
                component="fieldset"
                fullWidth
                margin="dense"
              >
                <FormLabel>Type</FormLabel>
                <RadioGroup
                  row
                  aria-label="type"
                  onChange={(e) => setType(e.target.value)}
                  value={type}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    style={{ width: "100%" }}
                  >
                    <Box
                      sx={{ border: "1px solid #DCDEE3", borderRadius: "6px" }}
                      p={1}
                      mt={1}
                      display="flex"
                      alignItems="center"
                    >
                      <FormControlLabel
                        label="Browser"
                        value="BROWSER"
                        control={
                          <Radio
                            sx={{
                              "&.Mui-checked": {
                                color: "#1C4ED8",
                              },
                            }}
                          />
                        }
                      />
                      <GlobePurpleIcon />
                    </Box>

                    <Box
                      sx={{ border: "1px solid #DCDEE3", borderRadius: "6px" }}
                      p={1}
                      mt={1}
                      mr={1}
                      display="flex"
                      alignItems="center"
                    >
                      <FormControlLabel
                        label="TV"
                        value="TV"
                        control={
                          <Radio
                            sx={{
                              "&.Mui-checked": {
                                color: "#1C4ED8",
                              },
                            }}
                          />
                        }
                      />
                      <TvGreenIcon />
                    </Box>

                    <Box
                      sx={{ border: "1px solid #DCDEE3", borderRadius: "6px" }}
                      p={1}
                      mt={1}
                      display="flex"
                      alignItems="center"
                    >
                      <FormControlLabel
                        label="PC"
                        value="PC"
                        control={
                          <Radio
                            sx={{
                              "&.Mui-checked": {
                                color: "#1C4ED8",
                              },
                            }}
                          />
                        }
                      />
                      <PCOrangeIcon />
                    </Box>
                  </Box>
                </RadioGroup>
              </FormControl>

              <CustomTextField
                select
                fullWidth
                variant="outlined"
                SelectProps={{
                  native: true,
                }}
                InputLabelProps={{ shrink: true }}
                label="Action"
                required
                onChange={(e) => setCommand(e.target.value)}
                value={command}
                helperText="*Fill the type field first"
                style={{ marginTop: "15px" }}
              >
                <option value="" disabled>
                  Select Action
                </option>
                {/* {type === "BROWSER" ? (
                <option value="OPEN">Open Browser</option>
              ) : null}
              {type === "BROWSER" ? (
                <option value="CLOSE">Close Browser</option>
              ) : null} */}

                {/* {type === "PC" ? <option value="STARTUP">Start Up</option> : null} */}
                {type === "BROWSER" ? (
                  <option value="RELOAD">Reload Browser</option>
                ) : null}
                {/* {type === "OFFLINE" ? (
                <option value="ENABLE_OFFLINE_MODE">Enable Offline Mode</option>
              ) : null}
              {type === "OFFLINE" ? (
                <option value="DISABLE_OFFLINE_MODE">
                  Disable Offline Mode
                </option>
              ) : null} */}
                {type === "PC" ? (
                  <option value="SHUTDOWN">Shut Down</option>
                ) : null}
                {type === "PC" ? <option value="REBOOT">Reboot</option> : null}
                {/* {type === "PC" ? (
                <option value="DOWNLOAD_CONTENT">Download Content</option>
              ) : null} */}

                {type === "TV" ? <option value="ON">Turn On</option> : null}
                {type === "TV" ? <option value="OFF">Turn Off</option> : null}
                {/* {type === "TV" ? <option value="STATUS">Status</option> : null} */}
                {type === "TV" ? <option value="HDMI1">HDMI 1</option> : null}
                {type === "TV" ? <option value="HDMI2">HDMI 2</option> : null}
              </CustomTextField>
            </Box>
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" fullWidth onClick={handleClickClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              fullWidth
              onClick={handleOpenConfirmation}
            >
              Confirm
            </Button>
            <Confirmation
              openConfirmation={openConfirmation}
              closeForm={() => handleClickClose()}
              closeConfirmation={handleCloseConfirmation}
              group={group}
              type={type}
              command={command}
            />
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default CreateImmediateJobGroup;
