export const SET_USER = "SET_USER";
export const SET_USER_HISTORY = "SET_USER_HISTORY";
export const SET_USER_GROUP = "SET_USER_GROUP";
export const SET_NEW_USER = "SET_NEW_USER";
export const SET_NEW_USER_GROUP = "SET_NEW_USER_GROUP";
export const SET_DELETE_USER = "SET_DELETE_USER";
export const SET_DELETE_USER_GROUP = "SET_DELETE_USER_GROUP";
export const SET_EDIT_USER = "SET_EDIT_USER";
export const SET_EDIT_USER_GROUP = "SET_EDIT_USER_GROUP";
export const SET_LOADING_USER = "SET_LOADING_USER";
export const SET_ERROR_PROMPT_USER_PAGE = "SET_ERROR_PROMPT_USER_PAGE";
export const SET_ERROR_MESSAGE_USER_PAGE = "SET_ERROR_MESSAGE_USER_PAGE";
export const SET_SUCCESS_PROMPT_USER_PAGE = "SET_SUCCESS_PROMPT_USER_PAGE";
export const SET_SUCCESS_MESSAGE_USER_PAGE = "SET_SUCCESS_MESSAGE_USER_PAGE";
export const SET_ERROR_PROMPT_USER_PERMISSION =
  "SET_ERROR_PROMPT_USER_PERMISSION";
export const SET_ERROR_MESSAGE_USER_PERMISSION =
  "SET_ERROR_MESSAGE_USER_PERMISSION";
export const SET_SUCCESS_PROMPT_USER_PERMISSION =
  "SET_SUCCESS_PROMPT_USER_PERMISSION";
export const SET_SUCCESS_MESSAGE_USER_PERMISSION =
  "SET_SUCCESS_MESSAGE_USER_PERMISSION";
export const SET_AUTH_USER = "SET_AUTH_USER";
export const SET_USER_PAGE = "SET_USER_PAGE";
