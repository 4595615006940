import {
  // DoDisturbOutlined as RemoveIcon,
  LinkOutlined as LinkIcon,
  MoreVert as MenuIcon,
  Clear as ClearIcon,
  Done as DoneIcon,
} from "@mui/icons-material";
import {
  alpha,
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  styled,
  Switch,
  CircularProgress,
  IconButton,
  Menu,
  DialogContentText,
} from "@mui/material";

import { red } from "@mui/material/colors";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  activateAllSMPAnnouncement,
  activateSMPAnnouncement,
} from "../../../../store/actions/smpAction";
import { CustomMenuItem } from "../../../customComponent";
import DetailLinkAnnouncement from "./DetailLinkAnnouncement";
import LinkAnnouncementErrorPrompt from "./LinkAnnouncementErrorPrompt";
import Role from "../../../../helpers/roles";
import { decrypt } from "../../../../helpers/crypto";

const DangerSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: red[600],
    "&:hover": {
      backgroundColor: alpha(red[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: red[600],
  },
}));

const DisruptionActivation = () => {
  const { loggedUser } = useSelector((state) => state.auth);
  const decryptedLoggedUser = decrypt(loggedUser);
  const userRole = decryptedLoggedUser.type;

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openActivateAll, setOpenActivateAll] = useState(false);
  const [openDeactivateAll, setOpenDeactivateAll] = useState(false);

  const { stationDisruptions, loadingAnnouncementSMPDisruption } = useSelector(
    (state) => state.smp
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenActivateAll = () => {
    setOpenActivateAll(true);
  };

  const handleCloseActivateAll = () => {
    setOpenActivateAll(false);
  };

  const handleOpenDeactivateAll = () => {
    setOpenDeactivateAll(true);
  };

  const handleCloseDeactivateAll = () => {
    setOpenDeactivateAll(false);
  };

  const handleActivateAll = async () => {
    handleCloseActivateAll();
    const payload = [];
    stationDisruptions.forEach((item) => {
      if (
        item.disruptionAnnouncementId !== null &&
        item.disruptionAnnouncement?.status === "ANNOUNCEMENT_OFF"
      ) {
        payload.push({
          id: item.id,
          announcement: { disruptionAnnouncement: { status: "ON" } },
        });
      }
    });
    if (payload.length !== 0) {
      dispatch(activateAllSMPAnnouncement(payload));
    }
  };

  const handleDeactivateAll = async () => {
    handleCloseDeactivateAll();
    const payload = [];
    stationDisruptions.forEach((item) => {
      if (
        item.disruptionAnnouncementId !== null &&
        item.disruptionAnnouncement?.status === "ANNOUNCEMENT_ON"
      ) {
        payload.push({
          id: item.id,
          announcement: { disruptionAnnouncement: { status: "OFF" } },
        });
      }
    });
    if (payload.length !== 0) {
      dispatch(activateAllSMPAnnouncement(payload));
    }
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={() => {
          setOpen(true);
        }}
        fullWidth
        sx={{ boxShadow: 3 }}
        color="error"
      >
        Disruption
      </Button>

      <Dialog open={open} maxWidth="md" fullWidth onClose={handleClose}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Disruption
          <Box>
            <Button
              variant="contained"
              sx={{ boxShadow: 3 }}
              onClick={() => {
                handleOpenActivateAll();
              }}
            >
              Activate All
            </Button>
            <Dialog
              maxWidth="sm"
              open={openActivateAll}
              onClose={handleCloseActivateAll}
            >
              <DialogContent>
                <DialogContentText
                  sx={{
                    textAlign: "center",
                    mb: 2,
                  }}
                >
                  Are you sure want to{" "}
                  <strong>activate all disruption announcement</strong>?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  color="error"
                  variant="outlined"
                  fullWidth
                  startIcon={<ClearIcon />}
                  onClick={handleCloseActivateAll}
                >
                  Cancel
                </Button>

                <Button
                  color="error"
                  variant="contained"
                  fullWidth
                  startIcon={<DoneIcon />}
                  onClick={handleActivateAll}
                >
                  Yes, Activate All!
                </Button>
              </DialogActions>
            </Dialog>

            <Button
              variant="contained"
              sx={{ boxShadow: 3, ml: "8px" }}
              onClick={() => {
                handleOpenDeactivateAll();
              }}
            >
              Deactivate All
            </Button>
            <Dialog
              maxWidth="sm"
              open={openDeactivateAll}
              onClose={handleCloseDeactivateAll}
            >
              <DialogContent>
                <DialogContentText
                  sx={{
                    textAlign: "center",
                    mb: 2,
                  }}
                >
                  Are you sure want to{" "}
                  <strong>deactivate all disruption announcement</strong>?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  color="error"
                  variant="outlined"
                  fullWidth
                  startIcon={<ClearIcon />}
                  onClick={handleCloseDeactivateAll}
                >
                  Cancel
                </Button>

                <Button
                  color="error"
                  variant="contained"
                  fullWidth
                  startIcon={<DoneIcon />}
                  onClick={handleDeactivateAll}
                >
                  Yes, Deactivate All!
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </DialogTitle>
        <DialogContent>
          <LinkAnnouncementErrorPrompt />
          {loadingAnnouncementSMPDisruption ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress size={24} sx={{ color: "#1C4ED8" }} />
            </Box>
          ) : (
            <Grid container>
              {stationDisruptions.map((item) => (
                <Grid item xs={6} key={item.id}>
                  <SwitchItem data={item} role={userRole} />
                </Grid>
              ))}
            </Grid>
          )}
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button variant="outlined" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const SwitchItem = ({ data, role }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDetail, setOpenDetail] = useState(false);
  const [switchCheck, setSwitchCheck] = useState(false);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (data.disruptionAnnouncementId !== null) {
      if (data.disruptionAnnouncement?.status === "ANNOUNCEMENT_ON") {
        setSwitchCheck(true);
      }
    }
  }, [dispatch, data]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDetail = () => {
    setOpenDetail(false);
    setAnchorEl(null);
  };

  const handleSwitch = (event) => {
    const payload = { id: data.id, announcement: {} };
    switchCheck
      ? (payload.announcement["disruptionAnnouncement"] = { status: "OFF" })
      : (payload.announcement["disruptionAnnouncement"] = { status: "ON" });

    dispatch(activateSMPAnnouncement(payload));
  };

  const handleOpenDetail = () => {
    setOpenDetail(true);
  };

  return (
    <>
      <FormControlLabel
        value={switchCheck}
        control={<DangerSwitch />}
        checked={switchCheck}
        label={data.code + " Announcement"}
        onChange={handleSwitch}
        disabled={
          data.disruptionAnnouncementId === null ||
          data.disruptionAnnouncementId === ""
            ? true
            : false
        }
      />

      {role === Role.User ? null : (
        <>
          <IconButton
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            color="inherit"
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <CustomMenuItem dense onClick={() => handleOpenDetail()}>
              <LinkIcon
                sx={{
                  width: 20,
                  height: 20,
                  marginRight: "10px",
                  color: "#111827",
                }}
              />
              Link Announcement
            </CustomMenuItem>
            <DetailLinkAnnouncement
              type="Disruption"
              open={openDetail}
              onClose={handleCloseDetail}
              data={data}
            />
          </Menu>
        </>
      )}
    </>
  );
};

export default DisruptionActivation;
