import { initiateAXIOS } from "../../config/axios";
import { handleError } from "../handleError";
import JobHistory from "../../models/JobHistoryModel";
import { JOB_HISTORY_API_LIMIT, initiateJobHistoryAPI } from "../../api";
import {
  SET_ERROR_MESSAGE_JOB_HISTORY_PAGE,
  SET_ERROR_PROMPT_JOB_HISTORY_PAGE,
  SET_JOB_HISTORY,
  SET_LOADING_JOB_HISTORY,
} from "../actionTypes/jobHistoryActionType";

export const getJobHistories = () => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_JOB_HISTORY, payload: true });

    try {
      const { data } = await initiateAXIOS.get(
        initiateJobHistoryAPI + `?limit=${JOB_HISTORY_API_LIMIT}&sortBy=id-`,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      let jobHistories = [];
      const jobHistoryList = data.items;
      jobHistoryList.map((item) => jobHistories.push(new JobHistory(item)));
      dispatch({ type: SET_JOB_HISTORY, payload: jobHistories });
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_JOB_HISTORY_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_JOB_HISTORY_PAGE, payload: message });
    } finally {
      dispatch({ type: SET_LOADING_JOB_HISTORY, payload: false });
    }
  };
};

export const filterJobHistory = (query) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_JOB_HISTORY, payload: true });
    try {
      const { data } = await initiateAXIOS.get(
        initiateJobHistoryAPI +
          `?limit=${JOB_HISTORY_API_LIMIT}&sortBy=id-&${query}`,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      let jobHistories = [];
      const jobHistoryList = data.items;
      jobHistoryList.map((item) => jobHistories.push(new JobHistory(item)));
      dispatch({ type: SET_JOB_HISTORY, payload: jobHistories });
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_JOB_HISTORY_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_JOB_HISTORY_PAGE, payload: message });
    } finally {
      dispatch({ type: SET_LOADING_JOB_HISTORY, payload: false });
    }
  };
};
