import {
  SET_DELETE_USER,
  SET_DELETE_USER_GROUP,
  SET_EDIT_USER,
  SET_EDIT_USER_GROUP,
  SET_ERROR_MESSAGE_USER_PAGE,
  SET_ERROR_MESSAGE_USER_PERMISSION,
  SET_ERROR_PROMPT_USER_PAGE,
  SET_ERROR_PROMPT_USER_PERMISSION,
  SET_LOADING_USER,
  SET_NEW_USER,
  SET_NEW_USER_GROUP,
  SET_SUCCESS_MESSAGE_USER_PAGE,
  SET_SUCCESS_MESSAGE_USER_PERMISSION,
  SET_SUCCESS_PROMPT_USER_PAGE,
  SET_SUCCESS_PROMPT_USER_PERMISSION,
  SET_USER,
  SET_USER_GROUP,
  SET_USER_HISTORY,
  SET_USER_PAGE,
} from "../actionTypes/userActionType";

const initialState = {
  users: [],
  userHistory: [],
  userGroups: [],
  loadingUser: false,
  isUserPageError: false,
  errorMessageUserPage: "",
  isUserPageSuccess: false,
  successMessageUserPage: "",
  isUserPermissionError: false,
  errorMessageUserPermission: "",
  isUserPermissionSuccess: false,
  successMessageUserPermission: "",
  userPage: 0,
};

export const userReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_USER:
      return { ...state, users: payload };

    case SET_USER_HISTORY:
      return { ...state, userHistory: payload };

    case SET_USER_GROUP:
      return { ...state, userGroups: payload };

    case SET_NEW_USER:
      const listAdmin = state.users;
      return { ...state, users: [payload, ...listAdmin] };

    case SET_NEW_USER_GROUP:
      const listUserGroup = state.userGroups;
      return { ...state, userGroups: [payload, ...listUserGroup] };

    case SET_DELETE_USER:
      return {
        ...state,
        users: state.users.filter(
          (admin) => admin.id.toString() !== payload.toString()
        ),
      };
    case SET_DELETE_USER_GROUP:
      return {
        ...state,
        userGroups: state.userGroups.filter(
          (group) => group.id.toString() !== payload.toString()
        ),
      };

    case SET_EDIT_USER:
      const data = state.users;
      const objIndex = data.findIndex((obj) => obj.id === payload.id);
      data[objIndex] = payload;
      return { ...state, users: [...data] };

    case SET_EDIT_USER_GROUP:
      const dataGroup = state.userGroups;
      const objIndexGroup = dataGroup.findIndex((obj) => obj.id === payload.id);
      dataGroup[objIndexGroup] = payload;
      return { ...state, userGroups: [...dataGroup] };

    case SET_LOADING_USER:
      return { ...state, loadingUser: payload };

    case SET_ERROR_PROMPT_USER_PAGE:
      return { ...state, isUserPageError: payload };

    case SET_ERROR_MESSAGE_USER_PAGE:
      return { ...state, errorMessageUserPage: payload };

    case SET_SUCCESS_PROMPT_USER_PAGE:
      return { ...state, isUserPageSuccess: payload };

    case SET_SUCCESS_MESSAGE_USER_PAGE:
      return { ...state, successMessageUserPage: payload };

    case SET_ERROR_PROMPT_USER_PERMISSION:
      return { ...state, isUserPermissionError: payload };

    case SET_ERROR_MESSAGE_USER_PERMISSION:
      return { ...state, errorMessageUserPermission: payload };

    case SET_SUCCESS_PROMPT_USER_PERMISSION:
      return { ...state, isUserPermissionSuccess: payload };

    case SET_SUCCESS_MESSAGE_USER_PERMISSION:
      return { ...state, successMessageUserPermission: payload };

    case SET_USER_PAGE:
      return { ...state, userPage: payload };

    default:
      return state;
  }
};
