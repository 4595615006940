import React from "react";
import { StyledTableRow } from "../../customComponent";
import { TableCell, Tooltip } from "@mui/material";
import { formattedDate } from "../../../helpers/dateFormat";

const ContentHistoryItem = ({ historyData }) => {
  return (
    <StyledTableRow>
      <TableCell>{historyData.id}</TableCell>
      <Tooltip title={historyData.fileName}>
        <TableCell>{historyData.name}</TableCell>
      </Tooltip>
      <TableCell>{historyData.type}</TableCell>
      <TableCell>{historyData.status}</TableCell>
      <TableCell>{historyData.viewerName}</TableCell>
      <TableCell>{historyData.viewerGroupName}</TableCell>
      <TableCell>{formattedDate(historyData.recordedAt)}</TableCell>
    </StyledTableRow>
  );
};

export default ContentHistoryItem;
