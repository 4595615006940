import {
  // DoDisturbOutlined as RemoveIcon,
  LinkOutlined as LinkIcon,
  MoreVert as MenuIcon,
  Clear as ClearIcon,
} from "@mui/icons-material";
import {
  alpha,
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  styled,
  Switch,
  CircularProgress,
  IconButton,
  Menu,
  DialogContentText,
} from "@mui/material";

import { red } from "@mui/material/colors";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  activateAllSMPAnnouncement,
  activateSMPAnnouncement,
} from "../../../../store/actions/smpAction";
import { CustomMenuItem } from "../../../customComponent";
import DetailLinkAnnouncement from "./DetailLinkAnnouncement";
import LinkAnnouncementErrorPrompt from "./LinkAnnouncementErrorPrompt";
import Role from "../../../../helpers/roles";
import { decrypt } from "../../../../helpers/crypto";

const DangerSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: red[600],
    "&:hover": {
      backgroundColor: alpha(red[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: red[600],
  },
}));

const DelayActivation = () => {
  const { loggedUser } = useSelector((state) => state.auth);
  const decryptedLoggedUser = decrypt(loggedUser);
  const userRole = decryptedLoggedUser.type;

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openActivateAll, setOpenActivateAll] = useState(false);
  const [openDeactivateAll, setOpenDeactivateAll] = useState(false);

  const { stationDisruptions, loadingAnnouncementSMPDisruption } = useSelector(
    (state) => state.smp
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenActivateAll = () => {
    setOpenActivateAll(true);
  };

  const handleCloseActivateAll = () => {
    setOpenActivateAll(false);
  };

  const handleOpenDeactivateAll = () => {
    setOpenDeactivateAll(true);
  };

  const handleCloseDeactivateAll = () => {
    setOpenDeactivateAll(false);
  };

  const handleActivateAll = async (type) => {
    handleCloseActivateAll();
    const payload = [];
    if (type === "delay") {
      stationDisruptions.forEach((item) => {
        if (
          item.delayAnnouncementId !== null &&
          item.delayAnnouncement?.status === "ANNOUNCEMENT_OFF"
        ) {
          payload.push({
            id: item.id,
            announcement: { delayAnnouncement: { status: "ON" } },
          });
        }
      });
    } else if (type === "freeBus") {
      stationDisruptions.forEach((item) => {
        if (
          item.freeBusAnnouncementId !== null &&
          item.freeBusAnnouncement?.status === "ANNOUNCEMENT_OFF"
        ) {
          payload.push({
            id: item.id,
            announcement: { freeBusAnnouncement: { status: "ON" } },
          });
        }
      });
    } else {
      return;
    }
    dispatch(activateAllSMPAnnouncement(payload));
  };

  const handleDeactivateAll = async (type) => {
    handleCloseDeactivateAll();
    const payload = [];
    if (type === "delay") {
      stationDisruptions.forEach((item) => {
        if (
          item.delayAnnouncementId !== null &&
          item.delayAnnouncement?.status === "ANNOUNCEMENT_ON"
        ) {
          payload.push({
            id: item.id,
            announcement: { delayAnnouncement: { status: "OFF" } },
          });
        }
      });
    } else if (type === "freeBus") {
      stationDisruptions.forEach((item) => {
        if (
          item.freeBusAnnouncementId !== null &&
          item.freeBusAnnouncement?.status === "ANNOUNCEMENT_ON"
        ) {
          payload.push({
            id: item.id,
            announcement: { freeBusAnnouncement: { status: "OFF" } },
          });
        }
      });
    } else {
      return;
    }
    dispatch(activateAllSMPAnnouncement(payload));
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={() => {
          setOpen(true);
        }}
        fullWidth
        sx={{ boxShadow: 3 }}
        color="warning"
      >
        Delay
      </Button>

      <Dialog open={open} maxWidth="md" fullWidth onClose={handleClose}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Delay
          <Box>
            <Button
              variant="contained"
              sx={{ boxShadow: 3 }}
              onClick={() => {
                handleOpenActivateAll();
              }}
            >
              Activate All
            </Button>
            <Dialog
              fullWidth
              maxWidth="md"
              open={openActivateAll}
              onClose={handleCloseActivateAll}
            >
              <DialogContent>
                <DialogContentText
                  sx={{
                    textAlign: "center",
                    mb: 2,
                  }}
                >
                  Are you sure want to{" "}
                  <strong>activate all delay announcement</strong>?
                </DialogContentText>
                <DialogContentText
                  sx={{
                    textAlign: "center",
                    mb: 2,
                  }}
                >
                  <strong>Choose which delay you want to activate</strong>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  color="error"
                  variant="outlined"
                  fullWidth
                  startIcon={<ClearIcon />}
                  onClick={handleCloseActivateAll}
                >
                  Cancel
                </Button>

                <Button
                  color="error"
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    handleActivateAll("delay");
                  }}
                >
                  Activate Delay A!
                </Button>

                <Button
                  color="error"
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    handleActivateAll("freeBus");
                  }}
                >
                  Activate Free Bus(Delay B)!
                </Button>
              </DialogActions>
            </Dialog>

            <Button
              variant="contained"
              sx={{ boxShadow: 3, ml: "8px" }}
              onClick={() => {
                handleOpenDeactivateAll();
              }}
            >
              Deactivate All
            </Button>
            <Dialog
              fullWidth
              maxWidth="md"
              open={openDeactivateAll}
              onClose={handleCloseDeactivateAll}
            >
              <DialogContent>
                <DialogContentText
                  sx={{
                    textAlign: "center",
                    mb: 2,
                  }}
                >
                  Are you sure want to{" "}
                  <strong>deactivate all delay announcement</strong>?
                </DialogContentText>
                <DialogContentText
                  sx={{
                    textAlign: "center",
                    mb: 2,
                  }}
                >
                  <strong>Choose which delay you want to deactivate</strong>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  color="error"
                  variant="outlined"
                  fullWidth
                  startIcon={<ClearIcon />}
                  onClick={handleCloseDeactivateAll}
                >
                  Cancel
                </Button>

                <Button
                  color="error"
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    handleDeactivateAll("delay");
                  }}
                >
                  Deactivate Delay A!
                </Button>

                <Button
                  color="error"
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    handleDeactivateAll("freeBus");
                  }}
                >
                  Deactivate Free Bus(Delay B)!
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </DialogTitle>
        <DialogContent>
          <LinkAnnouncementErrorPrompt />
          {loadingAnnouncementSMPDisruption ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress size={24} sx={{ color: "#1C4ED8" }} />
            </Box>
          ) : (
            <Grid container>
              {stationDisruptions.map((item) => (
                <Grid item xs={6} key={item.id}>
                  <SwitchItem data={item} role={userRole} />
                </Grid>
              ))}
            </Grid>
          )}
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button variant="outlined" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const SwitchItem = ({ data, role }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDetail, setOpenDetail] = useState(false);
  const [switchCheck, setSwitchCheck] = useState(false);
  const [freeBusCheck, setFreeBusCheck] = useState(false);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (data.freeBusAnnouncementId !== null) {
      if (data.freeBusAnnouncement?.status === "ANNOUNCEMENT_ON")
        setFreeBusCheck(true);
    }
    if (data.delayAnnouncementId !== null) {
      if (data.delayAnnouncement?.status === "ANNOUNCEMENT_ON")
        setSwitchCheck(true);
    }
  }, [dispatch, data]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDetail = () => {
    setOpenDetail(false);
    setAnchorEl(null);
  };

  const handleSwitch = (type) => {
    const payload = { id: data.id, announcement: {} };
    if (type === "delay") {
      switchCheck
        ? (payload.announcement["delayAnnouncement"] = { status: "OFF" })
        : (payload.announcement["delayAnnouncement"] = { status: "ON" });
    }

    if (type === "freeBus") {
      freeBusCheck
        ? (payload.announcement["freeBusAnnouncement"] = { status: "OFF" })
        : (payload.announcement["freeBusAnnouncement"] = { status: "ON" });
    }

    dispatch(activateSMPAnnouncement(payload));
  };

  const handleOpenDetail = () => {
    setOpenDetail(true);
  };

  return (
    <Box
      sx={{
        border: 1,
        borderRadius: "4px",
        borderColor: "#9e9e9e",
        p: "8px",
        m: "4px",
      }}
    >
      <Grid container direction="column">
        <Grid item>
          <FormControlLabel
            value={switchCheck}
            control={<DangerSwitch />}
            checked={switchCheck}
            label={data.code + " Delay A"}
            onChange={() => {
              handleSwitch("delay");
            }}
            disabled={
              data.delayAnnouncementId === null ||
              data.delayAnnouncementId === ""
                ? true
                : false
            }
          />
          {role === Role.User ? null : (
            <>
              <IconButton
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                color="inherit"
                onClick={handleClick}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <CustomMenuItem dense onClick={() => handleOpenDetail()}>
                  <LinkIcon
                    sx={{
                      width: 20,
                      height: 20,
                      marginRight: "10px",
                      color: "#111827",
                    }}
                  />
                  Link Announcement
                </CustomMenuItem>
                <DetailLinkAnnouncement
                  type="Delay"
                  open={openDetail}
                  onClose={handleCloseDetail}
                  data={data}
                />
              </Menu>
            </>
          )}
        </Grid>
        <Grid item>
          {data.freeBusAnnouncementId !== null &&
            data.freeBusAnnouncementId !== "" && (
              <FormControlLabel
                value={freeBusCheck}
                control={<DangerSwitch />}
                checked={freeBusCheck}
                label={data.code + " Free Bus (Delay B)"}
                onChange={() => {
                  handleSwitch("freeBus");
                }}
              />
            )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default DelayActivation;
