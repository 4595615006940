import React, { useState } from "react";
import { IconButton, Menu, TableCell } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { switchColorOnJobHistoryStatus } from "../../helpers/switchColor";
import { formattedDate } from "../../helpers/dateFormat";
import { MoreHoriz } from "@mui/icons-material";
import { CustomMenuItem, StyledTableRow } from "../customComponent";
import JobHistoryDetail from "./JobHistoryDetail";

const JobHistoryItem = ({ job }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openDetail, setOpenDetail] = useState(false);
  const handleCloseDetail = () => setOpenDetail(false);

  const setHistoryType = () => {
    if (
      job.type === "VIEWER" &&
      (job.command !== "BLOCK" || job.command !== "UNBLOCK")
    ) {
      return job.command;
    }

    if (job.command === "DELETE") {
      return job.command;
    }

    return job.type;
  };

  const setHistoryScheduleField = () => {
    if (job?.scheduleName?.length > 40 && job.type !== "CONTENT") {
      return `${job.scheduleName.substr(0, 30)}...`;
    }

    if (job.command === "DELETE") {
      return `[${job.type}${
        job.type === "CONTENT" ? ` - ` + job.args[2] : ""
      }] ${job.args[1]}`;
    }
    if (job.type === "CONTENT") {
      return `(Upload ${
        job.contentType[0] + job.contentType.slice(1).toLowerCase()
      }) ${job.contentName}`;
    }

    if (job.scheduleName === null) {
      return "-";
    }

    return job.scheduleName;
  };

  return (
    <StyledTableRow>
      <TableCell>{job.id}</TableCell>
      <TableCell>{setHistoryType()}</TableCell>
      <TableCell sx={{ width: 200 }}>{setHistoryScheduleField()}</TableCell>
      <TableCell>
        {job.type === "VIEWER" &&
        (job.command !== "BLOCK" || job.command !== "UNBLOCK")
          ? job?.url
          : job.viewerName === null
          ? "-"
          : job.viewerName}
      </TableCell>
      <TableCell
        sx={{
          color: switchColorOnJobHistoryStatus(job.status),
          fontWeight: 600,
          textTransform: "lowercase",
        }}
      >
        {!job.completedAt &&
        job.status === "COMPLETED" &&
        job.type !== "CONTENT"
          ? "SKIPPED"
          : job.status}
      </TableCell>
      <TableCell>
        {!job.completedAt ? "-" : formattedDate(job.completedAt)}
      </TableCell>
      <TableCell>
        {job.createdByName === null ? "-" : job.createdByName}
      </TableCell>
      <TableCell>
        <IconButton
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          color="inherit"
          onClick={handleClick}
        >
          <MoreHoriz />
        </IconButton>

        <Menu
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <CustomMenuItem dense onClick={() => setOpenDetail(true)}>
            <InfoOutlinedIcon
              sx={{
                width: 20,
                height: 20,
                marginRight: "10px",
                color: "#111827",
              }}
            />
            See Details
          </CustomMenuItem>
          <JobHistoryDetail
            open={openDetail}
            onClose={handleCloseDetail}
            job={job}
          />
        </Menu>
      </TableCell>
    </StyledTableRow>
  );
};

export default JobHistoryItem;
