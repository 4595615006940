import React from "react";
import Sidebar from "../../Sidebar";
import { Box, Container, CssBaseline, Paper, Toolbar } from "@mui/material";
import { TypographyLarge, TypographyXLarge } from "../../customComponent";
import UserErrorPrompt from "../UserErrorPrompt";
import UserSuccessPrompt from "../UserSuccessPrompt";
import UserHistoryList from "./UserHistoryList";
import UserHistoryFilter from "./UserHistoryFilter";
import ExportUserHistory from "./ExportUserHistory";
import { decrypt } from "../../../helpers/crypto";
import { useSelector } from "react-redux";

const UserHistory = () => {
  const { loggedUser } = useSelector((state) => state.auth);
  const decryptedLoggedUser = decrypt(loggedUser);
  const userRole = decryptedLoggedUser.type;

  return (
    <div style={{ display: "flex" }}>
      <Sidebar />

      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Toolbar />
        <CssBaseline />

        <Container maxWidth="xl">
          <Box sx={{ mb: 1 }}>
            <TypographyXLarge sx={{ fontWeight: 700 }}>
              User Action History
            </TypographyXLarge>
            <TypographyLarge sx={{ fontWeight: 400 }}>
              List of user action
            </TypographyLarge>
          </Box>

          <UserSuccessPrompt />
          <UserErrorPrompt />

          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <ExportUserHistory />
          </Box>
          <Box sx={{ my: 2 }}>
            <Paper
              component="section"
              elevation={2}
              sx={{ p: 2, my: 2, borderRadius: "6px" }}
            >
              <UserHistoryFilter role={userRole} />
            </Paper>
            <UserHistoryList role={userRole} />
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default UserHistory;
