import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";
import { uploadMedia } from "../../../store/actions/cmsAction";
import { createVideoThumbnail } from "../../../helpers/fileProcessing";

const dropzoneStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  borderWidth: "2px",
  borderRadius: "2px",
  borderColor: "rgba(28, 78, 216, 0.8)",
  borderStyle: "dashed",
  backgroundColor: "rgba(28, 78, 216, 0.1)",
  height: "100%",
  ":hover": {
    backgroundColor: "rgba(28, 78, 216, 0.2)",
    borderColor: "rgba(28, 78, 216, 1)",
  },
};

const UploadField = ({ closeUploadUI }) => {
  const dispatch = useDispatch();

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
      "video/*": [],
    },
  });

  const uploadFiles = async (files) => {
    const payload = [];
    const getImageSource = (file) => {
      let fileURL;
      let videoURL;

      return new Promise(async (resolve, reject) => {
        const fileType = file.type;
        if (!file || !fileType || fileType?.length === 0) {
          reject({ file, message: "File empty or type unknown" });
        }

        const type = fileType.substring(0, fileType.indexOf("/"));
        switch (type) {
          case "image":
            fileURL = URL.createObjectURL(file);
            break;
  
          case "video":
            videoURL = URL.createObjectURL(file);
            fileURL = await createVideoThumbnail(videoURL);
            break;
  
          default:
            break;
        }

        const imagee = new Image();
        imagee.src = fileURL;
        let breaker = 0;

        let imageDim = null;
        const loadImgInterval = setInterval(() => {
          const imgWidth = imagee.width;
          const imgHeight = imagee.height;
          if (imgWidth && imgHeight) {
            clearInterval(loadImgInterval);
            imageDim = {
              width: imgWidth,
              height: imgHeight,
            };

            resolve({
              url: fileURL,
              dimension: imageDim,
              videoURL,
            });
          } else {
            breaker++;
            if (breaker === 50) {
              clearInterval(loadImgInterval);
            }
          }
        }, 100);
      });
    };

    let errFiles = [];
    let fileProcessedCount = 0;
    files.forEach((file, i) => {
      getImageSource(file).then(({ url, dimension, videoURL }) => {
        payload.push({
          file,
          fileSrc: url,
          videoSrc: videoURL,
          type: file.type,
          name: file.name,
          dimension: dimension,
        });

        fileProcessedCount += 1;
      }, ({ file, message }) => {
        // WIP
        errFiles.push(file);
      });
    });

    let breaker = 0;
    const waitForAll = setInterval(function() {
      if (fileProcessedCount === files.length) {
        clearInterval(waitForAll);
        dispatch(uploadMedia(payload));
        closeUploadUI();
      } else {
        breaker++;
        if (breaker === 50) {
          clearInterval(waitForAll);
        }
      }
    }, 100);
  };

  useEffect(() => {
    if (acceptedFiles.length > 0) uploadFiles(acceptedFiles);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);

  return (
    <Box>
      <Box sx={{ ...dropzoneStyle, minHeight: "500px" }} {...getRootProps()}>
        <input {...getInputProps()} />
        <img
          style={{ width: "200px", height: "200px" }}
          src="/assets/multimedia-icon.png"
          alt="Upload Media"
        />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </Box>
    </Box>
  );
};

export default UploadField;
