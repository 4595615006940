import React from "react";

import { Box, CircularProgress, Grid } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import { ViewerBlueIcon, DisplayIcon, ScheduleGreyIcon } from "../../../icons";
import {
  TypographyLarge,
  TypographyNormal,
  TypographySmall,
  TypographyXLarge,
} from "../../customComponent";
import Role from "../../../helpers/roles";
import { formattedDate } from "../../../helpers/dateFormat";
import { colorStyling } from "../../../helpers/color";

const ViewerInfo = ({ viewer, loading, userRole }) => {
  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <CircularProgress
          size={20}
          thickness={3}
          sx={{ color: colorStyling.primary }}
        />
        <TypographyNormal
          sx={{ color: colorStyling.primary, mt: 2, fontWeight: 300 }}
        >
          Loading Data...
        </TypographyNormal>
      </Box>
    );

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ViewerBlueIcon />
          <TypographyXLarge sx={{ ml: 2, fontWeight: 700 }}>
            {viewer.name}
          </TypographyXLarge>
        </Box>

        <Box sx={{ display: "flex", mt: 1 }}>
          <TypographySmall
            sx={{
              display: "flex",
              alignItems: "center",
              borderRadius: "15px",
              color:
                viewer.status?.connectionStatus === "ONLINE"
                  ? colorStyling.green
                  : colorStyling.black,
              backgroundColor:
                viewer.status?.connectionStatus === "ONLINE"
                  ? colorStyling.white.active
                  : colorStyling.white.inactive,
              p: 1,
              fontWeight: 400,
              mr: 2,
            }}
          >
            <FiberManualRecordIcon
              sx={{
                color:
                  viewer.status?.connectionStatus === "ONLINE"
                    ? colorStyling.green
                    : colorStyling.gray,
                fontSize: 12,
                mr: 1,
              }}
            />
            {viewer.status?.connectionStatus === "ONLINE"
              ? "Online"
              : "Offline"}
          </TypographySmall>

          <TypographySmall
            sx={{
              display: "flex",
              alignItems: "center",
              borderRadius: "15px",
              color:
                viewer.status?.displayStatus === "ON"
                  ? colorStyling.green
                  : colorStyling.black,
              backgroundColor:
                viewer.status?.displayStatus === "ON"
                  ? colorStyling.white.active
                  : colorStyling.white.inactive,
              p: 1,
              fontWeight: 400,
            }}
          >
            <FiberManualRecordIcon
              sx={{
                color:
                  viewer.status?.displayStatus === "ON"
                    ? colorStyling.green
                    : colorStyling.gray,
                fontSize: 12,
                mr: 1,
              }}
            />
            Display :{" "}
            {viewer.status?.displayStatus === "ON"
              ? "On"
              : viewer.status?.displayStatus === "OFF"
              ? "Off"
              : "No Data"}
          </TypographySmall>
        </Box>
      </Box>

      <Grid container spacing={2} sx={{ my: 2 }}>
        {userRole === Role.Admin && (
          <Grid item xs={4}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <DisplayIcon />

              <Box sx={{ ml: 2 }}>
                <TypographyNormal sx={{ fontWeight: 300 }}>ID</TypographyNormal>
                <TypographyLarge sx={{ fontWeight: 600 }}>
                  {viewer.id}
                </TypographyLarge>
              </Box>
            </Box>
          </Grid>
        )}

        {userRole === Role.Admin && (
          <Grid item xs={4}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <DisplayIcon />

              <Box sx={{ ml: 2 }}>
                <TypographyNormal sx={{ fontWeight: 300 }}>
                  IP Address
                </TypographyNormal>
                <TypographyLarge sx={{ fontWeight: 600 }}>
                  {viewer.status?.ipAddress ?? "-"}
                </TypographyLarge>
              </Box>
            </Box>
          </Grid>
        )}

        <Grid item xs={4}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ScheduleGreyIcon />

            <Box sx={{ ml: 2 }}>
              <TypographyNormal sx={{ fontWeight: 300 }}>
                Last Active Time
              </TypographyNormal>
              <TypographyLarge sx={{ fontWeight: 600 }}>
                {viewer.activeAt ? formattedDate(viewer.activeAt) : "-"}
              </TypographyLarge>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ViewerInfo;
