import React, { useEffect, useRef, useState } from "react";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { formattedDate } from "../../../helpers/dateFormat";
import { CSVLink } from "react-csv";

const ExportUserHistory = () => {
  const { userHistory } = useSelector((state) => state.user);
  const csvRef = useRef();
  const [csvData, setCsvData] = useState([]);
  const headers = [
    { label: "ID", key: "id" },
    { label: "User Name", key: "userName" },
    { label: "Role", key: "userRole" },
    { label: "Company", key: "company" },
    { label: "Organization", key: "organization" },
    { label: "Time", key: "recordedAt" },
    { label: "Action", key: "action" },
    { label: "Status", key: "statusCode" },
  ];

  useEffect(() => {
    if (csvData.length !== 0) csvRef.current.link.click();
  }, [csvData]);

  const setStatus = (data) => {
    if (data === null) {
      return "-";
    }
    if (String(data)[0] === "2") return "Success";
    else return "Failed";
  };

  const getData = () => {
    setCsvData(
      userHistory.map(
        ({
          id,
          userName,
          userRole,
          organization,
          recordedAt,
          action,
          statusCode,
        }) => ({
          id,
          userName,
          userRole,
          company: organization.company.name ?? "-",
          organization: organization.name ?? "-",
          recordedAt: formattedDate(recordedAt),
          action,
          statusCode: setStatus(statusCode),
        })
      )
    );
  };

  return (
    <>
      <Button variant="contained" onClick={() => getData()}>
        Export CSV
      </Button>
      <CSVLink
        data={csvData}
        headers={headers}
        filename="user_action_history.csv"
        ref={csvRef}
      ></CSVLink>
    </>
  );
};

export default ExportUserHistory;
