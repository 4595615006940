import Sidebar from "../Sidebar";
import JobHistoryErrorPrompt from "./JobHistoryErrorPrompt";
import JobHistoryList from "./JobHistoryList";
import JobHistoryFilter from "./JobHistoryFilter";
import { Box, Container, CssBaseline, Paper, Toolbar } from "@mui/material";
import { TypographyLarge, TypographyXLarge } from "../customComponent";
import JobHistoryCSV from "./JobHistoryCSV";

const JobHistory = () => {
  return (
    <div style={{ display: "flex" }}>
      <Sidebar />

      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Toolbar />
        <CssBaseline />

        <Container maxWidth="xl">
          <Box sx={{ mb: 1 }}>
            <TypographyXLarge sx={{ fontWeight: 700 }}>
              Job History
            </TypographyXLarge>
            <TypographyLarge sx={{ fontWeight: 400 }}>
              List of job history of viewers
            </TypographyLarge>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <JobHistoryCSV />
          </Box>

          <JobHistoryErrorPrompt />

          <Paper
            component="section"
            elevation={2}
            sx={{ p: 2, my: 2, borderRadius: "6px" }}
          >
            <JobHistoryFilter />
          </Paper>

          <Paper
            component="section"
            elevation={2}
            sx={{ p: 2, borderRadius: "6px" }}
          >
            <JobHistoryList />
          </Paper>
        </Container>
      </Box>
    </div>
  );
};

export default JobHistory;
