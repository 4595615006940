import React from "react";
import { useNavigate } from "react-router-dom";

import { Breadcrumbs, Link } from "@mui/material";
import { NavigateNext } from "@mui/icons-material";

import { TypographyLarge } from "../../customComponent";

const GroupBreadcrumb = ({ group }) => {
  const navigate = useNavigate();

  const redirectPage = (groupId) => {
    navigate("/group/detail/" + groupId);
  };

  return (
    <>
      {!group.parentId ? (
        <Breadcrumbs
          separator={<NavigateNext sx={{ color: "#1C4ED8" }} fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            sx={{ display: "flex", alignItems: "center" }}
            underline="hover"
            onClick={() =>
              navigate("/groups?root=true&sortBy=name&sortOrder=asc&limit=100")
            }
          >
            <TypographyLarge
              sx={{ color: "#1C4ED8", cursor: "pointer", fontWeight: 400, ml: 1 }}
            >
              All Groups
            </TypographyLarge>
          </Link>
          <Link underline="always">
            <TypographyLarge sx={{ color: "#1C4ED8", cursor: "pointer" }}>
              {group.name}
            </TypographyLarge>
          </Link>
        </Breadcrumbs>
      ) : (
        <Breadcrumbs
          separator={<NavigateNext sx={{ color: "#1C4ED8" }} fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            underline="hover"
            sx={{ color: "#1C4ED8" }}
            onClick={() =>
              navigate("/groups?root=true&sortBy=name&sortOrder=asc&limit=100")
            }
          >
            <TypographyLarge
              sx={{ color: "#1C4ED8", cursor: "pointer", fontWeight: 400 }}
            >
              All Groups
            </TypographyLarge>
          </Link>

          <Link
            underline="hover"
            sx={{ color: "#1C4ED8" }}
            onClick={() => redirectPage(group.parentId)}
          >
            <TypographyLarge
              sx={{ color: "#1C4ED8", cursor: "pointer", fontWeight: 400 }}
            >
              {group.parent.name}
            </TypographyLarge>
          </Link>

          <Link underline="always">
            <TypographyLarge
              sx={{ color: "#1C4ED8", cursor: "pointer", fontWeight: 400 }}
            >
              {group.name}
            </TypographyLarge>
          </Link>
        </Breadcrumbs>
      )}
    </>
  );
};

export default GroupBreadcrumb;
