export const API_LIMIT = +process.env.REACT_APP_API_LIMIT;
export const JOB_HISTORY_API_LIMIT =
  +process.env.REACT_APP_JOB_HISTORY_API_LIMIT;

export const instanceDisruptionEventAPI = "/api/event/";
export const instanceDisruptionLineAPI = "/api/line/";
export const instanceDisruptionStationAPI = "/api/station/";
export const instanceDisruptionAnnouncementAPI = "/api/organization/";
export const instanceDisruptionUserAPI = "/api/user/";

export const instanceBPLRTUserAPI = "/api/user/";
export const instanceSMPAPI = "/api/smp/";
export const instanceEquipmentBreakdownAPI = "/api/eb/";

export const assetCMSAPILimit = `/api/asset?limit=${API_LIMIT}`;
export const instanceCMSAssetAPI = "/api/asset";

export const contentCMSAPILimit = `/api/content/?limit=${API_LIMIT}`;
export const instanceCMSContentAPI = "/api/content/";
export const instanceCMSViewerAPI = `/api/screen/?limit=${API_LIMIT}`;
export const instanceCMSViewerGroupAPI = `/api/screen-group/?limit=${API_LIMIT}`;
export const instanceCMSScheduleAPI = "/api/schedule/";

export const initiateGroupAPI = "/api/screen-group/";
export const groupAPILimit = `/api/screen-group/?limit=${API_LIMIT}`;

export const initiateViewerAPI = "/api/screen/";
export const viewerAPILimit = `/api/screen/?limit=${API_LIMIT}`;

export const initiateScheduleAPI = "/api/schedule/";
export const scheduleAPILimit = `/api/schedule/?limit=${API_LIMIT}`;

export const initiateAnnouncementAPI = "/api/schedule/?type=ANNOUNCEMENT";
export const announcementAPILimit = `/api/schedule/?limit=${API_LIMIT}&type=ANNOUNCEMENT`;

export const initiateJobHistoryAPI = "/api/history/job/";
export const instanceContentHistoryAPI = `/api/history/content/?limit=${JOB_HISTORY_API_LIMIT}`;
export const instanceUserHistoryAPI = `/api/history/user-action/?limit=${JOB_HISTORY_API_LIMIT}`;

export const initiateUserAPI = "/api/user/";
export const initiateUserGroupAPI = "/api/user-group/";

export const initiateOrganizationAPI = "/api/organization/";
