import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SortIcon from "@mui/icons-material/Sort";
import ClearIcon from "@mui/icons-material/ClearOutlined";
import { ascending, descending } from "../../helpers/sorting";
import {
  filterOrganizations,
  getOrganizations,
} from "../../store/actions/organizationAction";
import { SET_ORGANIZATION } from "../../store/actionTypes/organizationActionType";

const OrganizationFilter = () => {
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const { organizations } = useSelector((state) => state.organization);

  const [anchorEl, setAnchorEl] = useState(null);

  const openSortButton = Boolean(anchorEl);

  const [sortBy, setSortBy] = useState("A-Z");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeSortBy = (event) => {
    const value = event.target.value;
    setSortBy(value);

    let sorted;

    switch (value) {
      case "Oldest":
        sorted = ascending(organizations, "id");
        break;

      case "Newest":
        sorted = descending(organizations, "id");
        break;

      case "A-Z":
        sorted = ascending(organizations, "name");
        break;

      case "Z-A":
        sorted = descending(organizations, "name");
        break;

      default:
        break;
    }

    dispatch({ type: SET_ORGANIZATION, payload: sorted });
  };

  const handleResetState = () => {
    setName("");
    setSortBy("A-Z");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const query = [];

    if (name) {
      query.push(`nameLike=${name}`);
    }

    const payload = `&${query.join("&")}`;

    dispatch(filterOrganizations(payload));
  };

  const handleRefresh = () => {
    dispatch(getOrganizations());
    handleResetState();
  };

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
      onSubmit={handleSubmit}
    >
      <TextField
        fullWidth
        size="small"
        sx={{ mr: 2 }}
        placeholder="Search organization..."
        type="search"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        value={name}
        onChange={(e) => setName(e.target.value)}
      />

      <Button type="submit" variant="contained">
        Search
      </Button>

      <IconButton sx={{ color: "#111827", mx: 2 }} onClick={handleClick}>
        <Tooltip title="Sort">
          <SortIcon fontSize="small" />
        </Tooltip>
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openSortButton}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <RadioGroup value={sortBy} onChange={handleChangeSortBy}>
          <MenuItem>
            <FormControlLabel
              disableTypography
              value="Oldest"
              control={<Radio size="small" />}
              label="Oldest"
              sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
            />
          </MenuItem>
          <MenuItem>
            <FormControlLabel
              disableTypography
              value="Newest"
              control={<Radio size="small" />}
              label="Newest"
              sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
            />
          </MenuItem>
          <MenuItem>
            <FormControlLabel
              disableTypography
              value="A-Z"
              control={<Radio size="small" />}
              label="A-Z"
              sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
            />
          </MenuItem>
          <MenuItem>
            <FormControlLabel
              disableTypography
              value="Z-A"
              control={<Radio size="small" />}
              label="Z-A"
              sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
            />
          </MenuItem>
        </RadioGroup>
      </Menu>

      <IconButton onClick={handleRefresh} sx={{ color: "#111827" }}>
        <Tooltip title="Clear">
          <ClearIcon fontSize="small" />
        </Tooltip>
      </IconButton>
    </Box>
  );
};

export default OrganizationFilter;
