import React, { useState, useEffect, useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  InputLabel,
  Button,
} from "@mui/material";
import PreviewItem from "./PreviewItem";
import { ResolutionContext } from "../../../../helpers/resolution";
import {
  setContentLayers,
  // setLayerMedias,
  // getAllAssets,
  // downloadAsset,
  submitContent,
} from "../../../../store/actions/cmsAction";

import { useDrop } from 'react-dnd';
import update from 'immutability-helper';

const PreviewBox = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { layerMedias, contentLayers, canvasRes, contentName } = useSelector((state) => state.cms);

  const resolution = useContext(ResolutionContext);
  const [layers, setLayers] = useState({});
  
  // for debugging purposes
  // eslint-disable-next-line no-unused-vars
  const consoleLayers = useCallback( // FOR DEV PURPOSES
    async () => {
      // console.log(`resolution AGAIN:`, resolution);
      // console.log(`canvasRes AGAIN:`, canvasRes);
      // console.log(`layers AGAIN:`, layers);
      // console.log(`layerContext AGAIN:`, layerContext.layers);
      // console.log(`test:`, dispatch(downloadAsset({ id: 8 })));

      // const testDown = await downloadAsset({ id: 8 });
      // const layerID = Object.keys(contentLayers)[0];
      // let updatedMedias = []; // [...layerMedias[layerID]];
      // const contoh = {
      //   "type": "image",
      //   "name": "Teheeee",
      //   "src": testDown, // "https://placehold.co/150x150",
      //   "interval": 10,
      //   "id": "tehee"
      // };
      // updatedMedias.push(contoh);
      // dispatch(setLayerMedias({ layerID, layerMedias: updatedMedias }));
      console.log(`layerMedias:`, layerMedias);
      console.log(`contentLayers:`, contentLayers);
      console.log(`contentName:`, contentName);
      
      // const getPayload = {
      //   limit: 5,
      //   offset: 0,
      //   // type: mediaType,
      //   searchIDs: [4, 5, 6], // [7, 8, 9],
      //   // searchNames: ["4.png"],
      //   nameLike: "test",
      // };

      // console.log(`test-getPayload:`, getPayload);
      // dispatch(getAllAssets(getPayload));
      // const submitPayload = {
      //   name: contentName?.length > 0 ? contentName : "test",
      //   width: resolution.width,
      //   height: resolution.height,
      //   organizationId: 1, // payload.organizationId,
      //   contentLayers,
      //   layerMedias,
      //   // layers: parsedLayers,
      // };
      // dispatch(submitContent(submitPayload));
    },
    [layerMedias, contentLayers, contentName],
  );

  const submitNewContent = useCallback(
    async () => {
      const ratiodWidth = Math.round(resolution.width / canvasRes.width);
      const ratiodHeight = Math.round(resolution.height / canvasRes.height);
      const submitPayload = {
        name: contentName,
        width: resolution.width,
        height: resolution.height,
        contentLayers,
        layerMedias,
        ratiodWidth,
        ratiodHeight,
      };
      dispatch(submitContent(submitPayload, () => {
        navigate("/cms/content-template");
      }));
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [layerMedias, contentLayers, contentName, resolution],
  );

  useEffect(() => {
    const layerKeys = Object.keys(contentLayers);
    const updatedLayers = {};
    for (let i=0; i<layerKeys.length; i++) {
      const layer = Object.assign({}, contentLayers[layerKeys[i]]);
      const layerDim = layer.dimensions;

      const ratiodWidth = Math.round(layerDim.width / (resolution.width / canvasRes.width));
      const ratiodHeight = Math.round(layerDim.height / (resolution.height / canvasRes.height));
      layerDim.ratiodWidth = ratiodWidth;
      layerDim.ratiodHeight = ratiodHeight;

      updatedLayers[layer.id] = layerDim;
      updatedLayers[layer.id]["active"] = layer.active;
      updatedLayers[layer.id]["indexLayer"] = layer.indexLayer;
    }

    setLayers(update(layers, { $set: updatedLayers }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentLayers, resolution, canvasRes]);

  const moveBox = useCallback(
    (id, left, top) => {
      let mergedUpdates = { ...contentLayers };
      const layerKeys = Object.keys(layers);
      for (let i=0; i<layerKeys.length; i++) {
        const layerID = layerKeys[i];
        if (layerID === id) {
          mergedUpdates = update(mergedUpdates, {
            [layerID]: { $merge: { active: true } }
          });
          mergedUpdates = update(mergedUpdates, {
            [layerID]: { dimensions: { $merge: { left, top } } }
          });
        } else {
          mergedUpdates = update(mergedUpdates, {
            [layerID]: { $merge: { active: false } }
          });
        }
      }

      const finalUpdates = update(contentLayers, { $merge: mergedUpdates });
      dispatch(setContentLayers({ ...finalUpdates }));
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [layers, setLayers],
  );

  // eslint-disable-next-line no-unused-vars
  const [{ isDropping }, dropDiv] = useDrop(
    () => ({
      accept: 'box',  // string eq drag elements-type
      drop(item, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset();
        
        let left = Math.round(item.left + delta.x);
        let top = Math.round(item.top + delta.y);
        if (left < 0) left = 0;
        if (top < 0) top = 0;

        const right = left + item.ratiodWidth;
        const bottom = top + item.ratiodHeight;
        if (right > canvasRes.width) left = left - (right - canvasRes.width);
        if (bottom > canvasRes.height) top = top - (bottom - canvasRes.height);
        
        moveBox(item.id, left, top);
        return undefined;
      },
    }),
    [moveBox, resolution, canvasRes],
  );

  let previewResLabel;
  if (resolution.width > 0 && resolution.height > 0) {
    previewResLabel = <InputLabel>({ resolution.width } x { resolution.height })</InputLabel>;
  }

  return (
    <Box sx={{ width: "50%" }}>
      <Box display={"flex"} gap={3} sx={{ px: 2, py: 2 }}>
        <Box display={"flex"} flexDirection={"column"} sx={{ py: "4px" }}>
          <InputLabel>Preview</InputLabel>
          {previewResLabel}
        </Box>
        {/* // For Development */}
        {/* <Button
          variant="contained"
          sx={{ boxShadow: 3 }}
          onClick={() => consoleLayers()}
        >
          Debug Info
        </Button> */}
      </Box>
      <Box sx={{ height: "650px" }}>
        <Box
          ref={dropDiv}
          sx={{
            ml: 1,
            mb: 2,
            width: canvasRes.width,
            height: canvasRes.height,
            position: "relative",
            backgroundColor: "white",
            border: "1px solid rgba(28, 78, 216, 0.5)",
          }}
        >
          {Object.keys(layers).map((key) => {
            return (
              <PreviewItem id={key} key={key} layer={layers[key]} />
            )
          })}
        </Box>
      </Box>
      <Box textAlign={"right"} width={"400px"} sx={{ pt: 1 }}>
        {/* // For Development */}
        <Button
          variant="contained"
          sx={{ boxShadow: 3 }}
          onClick={() => submitNewContent()}
        >
          Submit Content
        </Button>
      </Box>
    </Box>
  );
};

export default PreviewBox;
