import React, { useState } from "react";
import { useDispatch } from "react-redux";

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { editUserGroup } from "../../../store/actions/userAction";
import { TypographyLarge } from "../../customComponent";

const EditUserGroup = ({ group, open, onClose }) => {
  const dispatch = useDispatch();

  const [groupName, setGroupName] = useState(group.name);
  const [permission, setPermission] = useState(group.permission);

  const handleClickClose = () => {
    onClose(false);
    setGroupName(group.name);
    handleChangeAll(false);
  };

  const handleGroupName = (e) => {
    setGroupName(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      permission,
    };

    if (groupName !== group.name) payload["name"] = groupName;

    dispatch(editUserGroup({ id: group.id, payload }));

    handleClickClose();
  };

  const handleChangePermission = (e, menu, perm) => {
    setPermission({
      ...permission,
      [menu]: { ...permission[menu], [perm]: e.target.checked },
    });
  };

  const handleChangeAll = (val) => {
    let newObj = {};
    Object.keys(permission).forEach((m) => {
      Object.keys(permission[m]).forEach((p) => {
        newObj = { ...newObj, [m]: { ...newObj[m], [p]: val } };
      });
    });
    setPermission(newObj);
  };

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={handleClickClose}>
      <Box component="form" onSubmit={handleSubmit}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Edit User Group
          <IconButton onClick={handleClickClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Box sx={{ mb: 2 }}>
            <InputLabel>Group Name</InputLabel>
            <TextField
              fullWidth
              size="small"
              margin="dense"
              name="groupName"
              value={groupName}
              onChange={handleGroupName}
              onKeyDown={(e) => e.stopPropagation()}
              placeholder="Enter group name"
              required
            />
          </Box>

          <Box sx={{ mt: 2 }}>
            <Box
              sx={{ mb: 1 }}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <InputLabel>Permissions</InputLabel>
              <Box>
                <Button
                  sx={{ mr: "4px" }}
                  onClick={() => handleChangeAll(true)}
                  variant="contained"
                  size="small"
                >
                  Check All
                </Button>
                <Button
                  onClick={() => handleChangeAll(false)}
                  variant="contained"
                  size="small"
                >
                  Uncheck All
                </Button>
              </Box>
            </Box>
            <Grid container>
              {Object.keys(permission).map((key) => (
                <Grid item md={3} sx={{ mb: 1 }} key={key}>
                  <TypographyLarge
                    sx={{
                      fontWeight: 600,
                      color: "#3e3e3e",
                      textTransform: "capitalize",
                    }}
                  >
                    {key.replace(/([A-Z])/g, " $1").trim()}
                  </TypographyLarge>
                  <Box display={"flex"} flexDirection={"column"}>
                    {Object.keys(permission[key]).map((perm) => (
                      <FormControlLabel
                        key={key + perm}
                        control={<Checkbox size="small" />}
                        checked={permission[key][perm]}
                        onChange={(e) => {
                          handleChangePermission(e, key, perm);
                        }}
                        label={
                          perm === "read"
                            ? "view"
                            : perm === "update"
                            ? "edit"
                            : perm
                        }
                      />
                    ))}
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClickClose} variant="outlined" fullWidth>
            Cancel
          </Button>
          <Button type="submit" variant="contained" fullWidth>
            Save
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default EditUserGroup;
