import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";

import { DangerButton, SecondaryDangerButton } from "../../customComponent";
import { deleteAnnouncement } from "../../../store/actions/announcementAction";

const DeleteAnnouncement = ({ announcement }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleDelete = (id) => {
    dispatch(deleteAnnouncement(id));

    setOpen(false);
    navigate("/announcements");
  };

  return (
    <>
      <Button
        variant="contained"
        color="error"
        startIcon={<DeleteIcon fontSize="small" />}
        sx={{ boxShadow: 3 }}
        onClick={() => setOpen(true)}
      >
        Delete Announcement
      </Button>

      <Dialog maxWidth="sm" open={open} onClose={handleClickClose}>
        <DialogContent>
          <DialogContentText
            sx={{
              textAlign: "center",
              mb: 2,
            }}
          >
            Are you sure want to delete the{" "}
            <strong>{announcement.name}?</strong>
          </DialogContentText>

          <Box>
            <Alert severity="warning" variant="outlined">
              <strong>You won't be able to revert this!</strong>
            </Alert>
          </Box>
        </DialogContent>

        <DialogActions>
          <SecondaryDangerButton
            fullWidth
            startIcon={<ClearIcon />}
            onClick={handleClickClose}
          >
            Cancel
          </SecondaryDangerButton>

          <DangerButton
            fullWidth
            startIcon={<DoneIcon />}
            onClick={() => handleDelete(announcement.id)}
          >
            Yes, Delete It!
          </DangerButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteAnnouncement;
