import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Alert, Box, CircularProgress, Pagination } from "@mui/material";
import { TypographyNormal } from "../customComponent";

import GroupItem from "./GroupItem";
import { getGroups } from "../../store/actions/groupAction";
import { usePagination } from "../hooks";
import { API_LIMIT } from "../../api";

const GroupList = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGroups("?limit=" + API_LIMIT));
  }, [dispatch]);

  const { groups, loadingGroup } = useSelector((state) => state.group);

  const [page, setPage] = useState(1);

  const DATA_PER_PAGE = 5;

  const count = Math.ceil(groups.length / DATA_PER_PAGE);

  const _DATA = usePagination(groups, DATA_PER_PAGE);

  const handleChangePagination = (e, page) => {
    setPage(page);
    _DATA.jump(page);
  };

  useEffect(() => {
    setPage(1);
    _DATA.jump(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups]);

  if (loadingGroup)
    return (
      <Box
        sx={{ height: "20vh" }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <CircularProgress size={20} thickness={3} sx={{ color: "#1D4ED8" }} />
        <TypographyNormal sx={{ color: "#1D4ED8", mt: 2, fontWeight: 300 }}>
          Loading Data...
        </TypographyNormal>
      </Box>
    );

  return (
    <>
      <Box>
        {!groups.length || groups === undefined ? (
          <Alert severity="info" variant="outlined">
            <strong>No results found!</strong> There are no available groups at
            the moment We couldn't find what you're looking for
          </Alert>
        ) : (
          groups &&
          _DATA
            .currentData()
            .map((group) => <GroupItem key={group.id} group={group} />)
        )}
      </Box>

      {!loadingGroup && groups.length ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            pt: 4,
            pb: 2,
            pr: 2,
          }}
        >
          <Pagination
            count={count}
            page={page}
            shape="rounded"
            color="primary"
            onChange={handleChangePagination}
          />
        </Box>
      ) : null}
    </>
  );
};

export default GroupList;
