import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Container,
  CssBaseline,
  Grid,
  Paper,
  Toolbar,
} from "@mui/material";

import { getViewer } from "../../../store/actions/viewerAction";
import Sidebar from "../../Sidebar";
import ViewerBreadCrumb from "./ViewerBreadCrumb";
import ViewerSuccessPrompt from "../ViewerSuccessPrompt";
import ViewerErrorPrompt from "../ViewerErrorPrompt";
// TODO: When working on the feature
// import ViewerCreateImmediateJob from "./ViewerCreateImmediateJob";
import ViewerInfo from "./ViewerInfo";
import DeleteViewer from "./DeleteViewer";
import { decrypt } from "../../../helpers/crypto";
import Role from "../../../helpers/roles";
import AssignedComponentList from "../../assign/AssignedComponentList";
import AssignComponent from "../../assign/AssignComponent";
import ViewerAssignGroup from "./group/ViewerAssignGroup";
import ViewerGroup from "./group/ViewerGroup";
import EditViewer from "./EditViewer";
// TODO: When working on the feature
// import ViewerScreenshot from "./ViewerScreenshot";
// import BlockViewer from "./BlockViewer";

const ViewerDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getViewer(id));
  }, [dispatch, id]);

  const { viewer, loadingViewerDetail: loading } = useSelector(
    (state) => state.viewer
  );

  const { loggedUser } = useSelector((state) => state.auth);
  const decryptedLoggedUser = decrypt(loggedUser);
  const userRole = decryptedLoggedUser.type;

  return (
    <div style={{ display: "flex" }}>
      <Sidebar />

      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Toolbar />
        <CssBaseline />

        <Container maxWidth="xl">
          <Box sx={{ mb: 2 }}>
            <ViewerBreadCrumb viewer={viewer} />
          </Box>

          <ViewerSuccessPrompt />
          <ViewerErrorPrompt />

          {!loading && (
            <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
              <EditViewer viewer={viewer} />
              {userRole === Role.Admin ? (
                <>
                  {/* TODO: When working on the feature */}
                  {/* <ViewerScreenshot viewer={viewer} size="md" /> */}
                  {/* <ViewerCreateImmediateJob viewer={viewer} /> */}
                  {/* <BlockViewer viewer={viewer} size="md" /> */}
                  <DeleteViewer viewer={viewer} />
                </>
              ) : (
                <>
                  {/* TODO: When working on the feature */}
                  {/* <ViewerScreenshot viewer={viewer} size="md" /> */}
                  {/* {decryptedLoggedUser.isAllowedToCreateImmediateJob && (
                  <ViewerCreateImmediateJob viewer={viewer} />
                )} */}
                </>
              )}
            </Box>
          )}

          <Grid container spacing={2} direction="column">
            <Grid item xs={4}>
              <Paper elevation={2} sx={{ p: 2, borderRadius: "6px" }}>
                <ViewerInfo
                  viewer={viewer}
                  loading={loading}
                  userRole={userRole}
                />
              </Paper>
            </Grid>

            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
              {userRole === Role.Admin && (
                <>
                  <ViewerAssignGroup viewer={viewer} />

                  {/* Assign Schedule */}
                  <AssignComponent
                    getName={"schedule"}
                    typeName="viewer"
                    typeData={viewer}
                  />
                </>
              )}
            </Box>

            <Grid item xs={4}>
              <Paper elevation={2} sx={{ p: 2, borderRadius: "6px" }}>
                <ViewerGroup viewer={viewer} loading={loading} />
              </Paper>
            </Grid>

            {/* Schedule List */}
            <Grid item xs={4}>
              <Paper elevation={2} sx={{ borderRadius: "6px" }}>
                <AssignedComponentList
                  key={"schedule"}
                  type={"schedule"}
                  listData={viewer.schedules}
                  sourceData={{
                    id: viewer.id,
                    name: viewer.name,
                    type: "viewer",
                  }}
                  loading={loading}
                />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default ViewerDetail;
