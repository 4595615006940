import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  IconButton,
  TextField,
  InputLabel,
  Alert,
  AlertTitle,
  CircularProgress,
} from "@mui/material";
import EditIcon from "@mui/icons-material/ModeEdit";
import CloseIcon from "@mui/icons-material/Close";

import { editAnnouncement } from "../../../store/actions/announcementAction";
import { CustomTextField } from "../../customComponent";
import { initiateAXIOS } from "../../../config/axios";
import { contentCMSAPILimit } from "../../../api";

const EditAnnouncement = ({ announcement }) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const handleClickClose = () => {
    setOpen(false);
  };

  const [name, setName] = useState("");
  const [loadingContentList, setLoadingContentList] = useState(false);
  const [customContentList, setCustomContentList] = useState([]);
  const [selectCustomContent, setSelectCustomContent] = useState("");
  const [selectPriority, setSelectPriority] = useState(2);

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleCloseErr = () => {
    setIsError(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let payload = {
      id: announcement.id,
      announcement: {},
    };

    if (name !== announcement.name) {
      payload["announcement"]["name"] = name;
    }

    if (announcement.contentId !== +selectCustomContent) {
      payload["announcement"]["contentId"] = +selectCustomContent;
    }

    if (announcement.priority.value !== +selectPriority)
      payload["announcement"]["priority"] = selectPriority;

    dispatch(editAnnouncement(payload));
    handleClickClose();
  };

  const getCMSContent = async () => {
    const access_token = sessionStorage.getItem("access_token");
    setLoadingContentList(true);
    try {
      const { data } = await initiateAXIOS.get(contentCMSAPILimit, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      setCustomContentList(data.items);
    } catch (error) {
      setIsError(true);
      setErrorMessage("Cannot retrieve content list, please try again later");
      setTimeout(handleCloseErr, 5000);
    } finally {
      setLoadingContentList(false);
    }
  };

  useEffect(() => {
    if (open) {
      setName(announcement.name);
      getCMSContent();
      setSelectCustomContent(announcement.contentId);
      setSelectPriority(announcement.priority.value);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        variant="contained"
        startIcon={<EditIcon fontSize="small" />}
        sx={{ boxShadow: 3 }}
      >
        Edit Announcement
      </Button>

      <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClickClose}>
        <Box component="form" onSubmit={handleSubmit}>
          <DialogTitle
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            Edit Announcement
            <IconButton onClick={handleClickClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            {isError && (
              <Alert
                sx={{ my: 2 }}
                severity="error"
                variant="filled"
                onClose={handleCloseErr}
              >
                <AlertTitle>Something went wrong!</AlertTitle>
                {errorMessage}
              </Alert>
            )}

            <InputLabel>Announcement Name</InputLabel>

            <TextField
              fullWidth
              margin="dense"
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />

            {loadingContentList ? (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ width: "100%", height: 150 }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Box>
                <InputLabel>Select Content</InputLabel>
                <CustomTextField
                  select
                  required
                  SelectProps={{ native: true }}
                  fullWidth
                  margin="dense"
                  name="contentSelect"
                  value={selectCustomContent}
                  onChange={(e) => {
                    setSelectCustomContent(e.target.value);
                  }}
                >
                  <option value="" disabled>
                    Choose Content
                  </option>
                  {customContentList.map((content) => (
                    <option value={content.id} key={content.id}>
                      {content.name}
                    </option>
                  ))}
                </CustomTextField>
              </Box>
            )}

            <Box>
              <InputLabel>Select Priority</InputLabel>
              <CustomTextField
                select
                required
                SelectProps={{ native: true }}
                fullWidth
                margin="dense"
                name="contentSelect"
                value={selectPriority}
                onChange={(e) => {
                  setSelectPriority(+e.target.value);
                }}
              >
                <option value="0">Highest</option>
                <option value="1">High</option>
                <option value="2">Normal</option>
                <option value="3">Low</option>
                <option value="4">Lowest</option>
              </CustomTextField>
            </Box>
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" fullWidth onClick={handleClickClose}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" fullWidth>
              Save
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default EditAnnouncement;
