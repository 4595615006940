export const handleError = (e) => {
  // console.log(e);
  const status = e.response?.status;
  const detailKeyword = e.response?.data?.detail?.keyword;
  let errorMessage = e.response?.data.message;

  if (detailKeyword === "expiredPassword") {
    window.localStorage.clear();
    window.location.replace("/expired-password");

    return errorMessage;
  } else if (detailKeyword === "expiredSession") {
    window.localStorage.clear();
    window.sessionStorage.clear();

    // setTimeout(() => {
    window.location.replace("/login");
    // }, 6000);

    return errorMessage;
  } else {
    switch (status) {
      case 401:
        errorMessage = e.response.data.message;
        window.localStorage.clear();
        window.sessionStorage.clear();

        // setTimeout(() => {
        window.location.replace("/login");
        // }, 5000);
        break;

      case 500:
        errorMessage = "Problem connecting. Check your internet and try again.";
        break;

      case undefined:
        errorMessage =
          "Your server is disconnected. Check your internet connection.";
        break;

      default:
        errorMessage = e.response.data.message;
        break;
    }
    return errorMessage;
  }
};
