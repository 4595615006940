import React, { useState } from "react";
import { useDispatch } from "react-redux";

import {
  Dialog,
  DialogContent,
  Box,
  DialogActions,
  Button,
  DialogContentText,
} from "@mui/material";
import UpdateIcon from "@mui/icons-material/Update";
import DoneIcon from "@mui/icons-material/Done";
import CancelIcon from "@mui/icons-material/Close";

import { editAnnouncementStatus } from "../../../store/actions/announcementAction";

const AnnouncementUpdateStatus = ({ announcement }) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let status;

    announcement.isActive ? (status = false) : (status = true);

    const payload = {
      id: announcement.id,
      payload: { isActive: status },
    };

    dispatch(editAnnouncementStatus(payload));
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="contained"
        sx={{ boxShadow: 3, mx: 2 }}
        startIcon={<UpdateIcon fontSize="small" />}
        onClick={() => setOpen(true)}
      >
        {announcement.isActive
          ? "Turn Off Announcement"
          : "Turn On Announcement"}
      </Button>

      <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClickClose}>
        <Box component="form" onSubmit={handleSubmit}>
          <DialogContent
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <DialogContentText
              sx={{
                textAlign: "center",
              }}
            >
              Are you sure want to{" "}
              <strong>{announcement.isActive ? "TURN OFF" : "TURN ON"}</strong>{" "}
              the <strong>{announcement.name}?</strong>
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button
              variant="outlined"
              fullWidth
              onClick={handleClickClose}
              startIcon={<CancelIcon />}
            >
              No
            </Button>

            <Button
              variant="contained"
              type="submit"
              fullWidth
              startIcon={<DoneIcon />}
            >
              Yes, Turn It
              {announcement.isActive ? " Off" : " On"}!
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default AnnouncementUpdateStatus;
