import {
  SET_DELETE_CONTENT_UPLOAD,
  SET_LOADING_CONTENT_UPLOAD,
  SET_CONTENT_UPLOAD,
  SET_EDIT_CONTENT_UPLOAD,
  SET_SUCCESS_MESSAGE_CONTENT_UPLOAD_PAGE,
  SET_SUCCESS_PROMPT_CONTENT_UPLOAD_PAGE,
  SET_ERROR_PROMPT_CONTENT_UPLOAD_PAGE,
  SET_ERROR_MESSAGE_CONTENT_UPLOAD_PAGE,
  SET_NEW_CONTENT_UPLOAD,
  SET_OPEN_UPLOAD_WINDOW,
  SET_UPLOAD_PROGRESS,
  SET_UPLOAD_FILENAME,
  SET_UPLOAD_STATUS,
  SET_CONTENT_HISTORY,
} from "../actionTypes/contentUploadActionType";

const initialState = {
  contentUploads: [],
  contentHistory: [],
  loadingContentUploads: false,
  isSuccessPromptContentUploadPage: false,
  isErrorPromptContentUploadPage: false,
  successMessageContentUploadPage: "",
  errorMessageContentUploadPage: "",
  openUploadWindow: false,
  uploadProgress: "0",
  uploadFilename: "",
  uploadStatus: "",
};
export const contentUploadReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_CONTENT_UPLOAD:
      return { ...state, contentUploads: payload };

    case SET_CONTENT_HISTORY:
      return { ...state, contentHistory: payload };

    case SET_NEW_CONTENT_UPLOAD:
      const listOfContents = state.contentUploads;
      return { ...state, contentUploads: [payload, ...listOfContents] };

    case SET_EDIT_CONTENT_UPLOAD:
      const data = state.contentUploads;
      const objIndex = data.findIndex((obj) => obj.id === payload.id);
      data[objIndex] = { ...payload, viewerName: data[objIndex].viewerName };
      return { ...state, contentUploads: data };

    case SET_LOADING_CONTENT_UPLOAD:
      return { ...state, loadingContentUploads: payload };

    case SET_DELETE_CONTENT_UPLOAD:
      return {
        ...state,
        contentUploads: state.contentUploads.filter(
          (content) => content.id.toString() !== payload.toString()
        ),
      };

    case SET_SUCCESS_PROMPT_CONTENT_UPLOAD_PAGE:
      return { ...state, isSuccessPromptContentUploadPage: payload };

    case SET_SUCCESS_MESSAGE_CONTENT_UPLOAD_PAGE:
      return { ...state, successMessageContentUploadPage: payload };

    case SET_ERROR_PROMPT_CONTENT_UPLOAD_PAGE:
      return { ...state, isErrorPromptContentUploadPage: payload };

    case SET_ERROR_MESSAGE_CONTENT_UPLOAD_PAGE:
      return { ...state, errorMessageContentUploadPage: payload };

    case SET_OPEN_UPLOAD_WINDOW:
      return { ...state, openUploadWindow: payload };

    case SET_UPLOAD_PROGRESS:
      return { ...state, uploadProgress: payload };

    case SET_UPLOAD_FILENAME:
      return { ...state, uploadFilename: payload };

    case SET_UPLOAD_STATUS:
      return { ...state, uploadStatus: payload };

    default:
      return state;
  }
};
