import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Box,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import SortIcon from "@mui/icons-material/Sort";

import { filterViewer, getViewers } from "../../store/actions/viewerAction";
import { SET_VIEWER } from "../../store/actionTypes/viewerActionTypes";
import { ascending, descending } from "../../helpers/sorting";

const FilterViewer = () => {
  const dispatch = useDispatch();

  const [viewerName, setViewerName] = useState("");

  const [status, setStatus] = useState("");

  const [displayStatus, setDisplayStatus] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);

  const openSortButton = Boolean(anchorEl);

  const [sortBy, setSortBy] = useState("Oldest");

  const { viewers } = useSelector((state) => state.viewer);

  const handleOpenSort = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseSort = () => {
    setAnchorEl(null);
  };

  const handleChangeSortBy = (event) => {
    const value = event.target.value;
    setSortBy(value);

    let sorted;

    switch (value) {
      case "Oldest":
        sorted = ascending(viewers, "id");
        break;

      case "Newest":
        sorted = descending(viewers, "id");
        break;

      case "A-Z":
        sorted = ascending(viewers, "name");
        break;

      case "Z-A":
        sorted = descending(viewers, "name");
        break;

      default:
        break;
    }

    dispatch({ type: SET_VIEWER, payload: sorted });
  };

  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };

  const handleChangeDisplayStatus = (event) => {
    setDisplayStatus(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const query = [];

    if (viewerName) query.push(`nameLike=${viewerName}`);
    if (status) query.push(`connectionStatus=${status}`);
    if (displayStatus) query.push(`displayStatus=${displayStatus}`);

    const payload = `&${query.join("&")}`;

    dispatch(filterViewer(payload));
  };

  const handleRefresh = () => {
    dispatch(getViewers());

    setViewerName("");
    setStatus("");
    setDisplayStatus("");
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Box
          component="form"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
          onSubmit={handleSubmit}
        >
          <TextField
            fullWidth
            type="search"
            size="small"
            sx={{ mr: 2 }}
            value={viewerName}
            onChange={(event) => setViewerName(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            placeholder="Search viewer..."
          />

          <TextField
            fullWidth
            select
            SelectProps={{
              native: true,
            }}
            sx={{ mr: 2 }}
            InputLabelProps={{ shrink: true }}
            size="small"
            value={status}
            onChange={handleChangeStatus}
          >
            <option value="">Any Status</option>
            <option value="ONLINE">Online</option>
            <option value="OFFLINE">Offline</option>
          </TextField>

          <TextField
            fullWidth
            select
            SelectProps={{
              native: true,
            }}
            InputLabelProps={{ shrink: true }}
            size="small"
            value={displayStatus}
            onChange={handleChangeDisplayStatus}
          >
            <option value="">Any Display Status</option>
            <option value="ON">Display ON</option>
            <option value="OFF">Display OFF</option>
            <option value="NO_DATA">No Data</option>
          </TextField>

          <Button sx={{ ml: 2 }} variant="contained" type="submit">
            Search
          </Button>
        </Box>

        <Box sx={{ display: "flex", ml: 2 }}>
          <IconButton
            sx={{ color: "#111827" }}
            onClick={handleOpenSort}
            aria-controls={openSortButton ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openSortButton ? "true" : undefined}
          >
            <Tooltip title="Sort">
              <SortIcon fontSize="small" />
            </Tooltip>
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openSortButton}
            onClose={handleCloseSort}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <RadioGroup value={sortBy} onChange={handleChangeSortBy}>
              <MenuItem>
                <FormControlLabel
                  disableTypography
                  value="Oldest"
                  control={<Radio size="small" />}
                  label="Oldest"
                  sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
                />
              </MenuItem>
              <MenuItem>
                <FormControlLabel
                  disableTypography
                  value="Newest"
                  control={<Radio size="small" />}
                  label="Newest"
                  sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
                />
              </MenuItem>
              <MenuItem>
                <FormControlLabel
                  disableTypography
                  value="A-Z"
                  control={<Radio size="small" />}
                  label="A-Z"
                  sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
                />
              </MenuItem>
              <MenuItem>
                <FormControlLabel
                  disableTypography
                  value="Z-A"
                  control={<Radio size="small" />}
                  label="Z-A"
                  sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
                />
              </MenuItem>
            </RadioGroup>
          </Menu>

          <IconButton
            sx={{ ml: 2, color: "#111827" }}
            color="primary"
            onClick={handleRefresh}
          >
            <Tooltip title="Clear">
              <ClearOutlinedIcon fontSize="small" />
            </Tooltip>
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export default FilterViewer;
