import { useSelector } from "react-redux";

import { Box, Container, CssBaseline, Paper, Toolbar } from "@mui/material";
import { TypographyNormal, TypographyXLarge } from "../customComponent";

import ScheduleSuccessPrompt from "./ScheduleSuccessPrompt";
import ScheduleErrorPrompt from "./ScheduleErrorPrompt";
import ScheduleList from "./ScheduleList";
import CreateSchedule from "./CreateSchedule";
import ScheduleFilter from "./ScheduleFilter";
import Sidebar from "../Sidebar";
import { decrypt } from "../../helpers/crypto";
import Role from "../../helpers/roles";

const Schedule = () => {
  const { loggedUser } = useSelector((state) => state.auth);
  const decryptedLoggedUser = decrypt(loggedUser);
  const userRole = decryptedLoggedUser.type;

  return (
    <div style={{ display: "flex" }}>
      <Sidebar />

      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Toolbar />
        <CssBaseline />

        <Container maxWidth="xl">
          <Box sx={{ mb: 1 }}>
            <TypographyXLarge sx={{ fontWeight: 700 }}>
              Schedule
            </TypographyXLarge>
            <TypographyNormal sx={{ fontWeight: 400 }}>
              Manage your digital signage schedules
            </TypographyNormal>
          </Box>

          <ScheduleSuccessPrompt />
          <ScheduleErrorPrompt />

          {userRole === Role.Admin && (
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <CreateSchedule />
            </Box>
          )}

          <Paper
            elevation={2}
            component="section"
            sx={{ borderRadius: "6px", my: 2, p: 2 }}
          >
            <ScheduleFilter />
          </Paper>

          <Paper
            component="section"
            elevation={2}
            sx={{ borderRadius: "6px", my: 2, p: 2 }}
          >
            <ScheduleList />
          </Paper>
        </Container>
      </Box>
    </div>
  );
};

export default Schedule;
