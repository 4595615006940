import { initiateAXIOS } from "../../config/axios";
import Schedule from "../../models/ScheduleModel";
import { handleError } from "../handleError";
import {
  API_LIMIT,
  initiateScheduleAPI,
  // instanceCMSScheduleAPI,
} from "../../api";
import {
  SET_DELETE_SCHEDULE,
  SET_EDIT_SCHEDULE,
  SET_ERROR_MESSAGE_SCHEDULE_PAGE,
  SET_ERROR_PROMPT_SCHEDULE_PAGE,
  SET_LOADING_SCHEDULE,
  SET_LOADING_SCHEDULE_DETAIL,
  SET_NEW_SCHEDULE,
  SET_SCHEDULE,
  SET_SCHEDULE_DETAIL,
  SET_SUCCESS_MESSAGE_SCHEDULE_PAGE,
  SET_SUCCESS_PROMPT_SCHEDULE_PAGE,
} from "../actionTypes/scheduleActionType";
import Viewer from "../../models/ViewerModel";
import Group from "../../models/GroupModel";

const handleTimeoutSuccessPrompt = (dispatch) => {
  setTimeout(() => {
    dispatch({ type: SET_SUCCESS_PROMPT_SCHEDULE_PAGE, payload: false });
  }, 3000);
};

const handleTimeoutErrorPrompt = (dispatch) => {
  setTimeout(() => {
    dispatch({ type: SET_ERROR_PROMPT_SCHEDULE_PAGE, payload: false });
  }, 5000);
};

export const fetchScheduleById = async (id, access_token) => {
  const { data } = await initiateAXIOS.get(initiateScheduleAPI + id, {
    headers: { authorization: `Bearer ${access_token}` },
  });

  let screens = [];
  data.screens.map((item) => screens.push(new Viewer(item)));
  let screenGroups = [];
  data.screenGroups.map((item) => screenGroups.push(new Group(item)));

  return { ...data, screens, screenGroups };
};

export const getSchedules = () => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_SCHEDULE, payload: true });
    try {
      const { data } = await initiateAXIOS.get(
        initiateScheduleAPI + `?limit=${API_LIMIT}&type=SCHEDULE&type=INSTANT`,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      let schedules = [];
      const dataSchedule = data.items;
      dataSchedule.map((item) => schedules.push(new Schedule(item)));
      dispatch({ type: SET_SCHEDULE, payload: schedules });
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_SCHEDULE_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_SCHEDULE, payload: false });
    }
  };
};

export const filterSchedule = (query) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_SCHEDULE, payload: true });

    try {
      const { data } = await initiateAXIOS.get(
        initiateScheduleAPI + `?limit=${API_LIMIT}` + query,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      let schedules = [];
      const dataSchedule = data.items;
      dataSchedule.map((item) => schedules.push(new Schedule(item)));
      dispatch({ type: SET_SCHEDULE, payload: schedules });
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_SCHEDULE_PAGE, payload: message });
    } finally {
      dispatch({ type: SET_LOADING_SCHEDULE, payload: false });
    }
  };
};

export const createSchedule = (payload) => {
  const access_token = sessionStorage.getItem("access_token");
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_SCHEDULE, payload: true });

    try {
      const { data } = await initiateAXIOS.post(initiateScheduleAPI, payload, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      const schedule = data;
      const OOP = new Schedule(schedule);
      dispatch({ type: SET_NEW_SCHEDULE, payload: OOP });
      dispatch({ type: SET_SCHEDULE_DETAIL, payload: {} });
      dispatch({ type: SET_SUCCESS_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_SCHEDULE_PAGE,
        payload: "A new schedule has been created successfully.",
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_SCHEDULE_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_SCHEDULE, payload: false });
    }
  };
};

export const getSchedule = (id) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_SCHEDULE_DETAIL, payload: true });
    try {
      const { data } = await initiateAXIOS.get(initiateScheduleAPI + id, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      const schedule = data;
      const OOP = new Schedule(schedule);
      dispatch({ type: SET_SCHEDULE_DETAIL, payload: OOP });
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_SCHEDULE_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_SCHEDULE_DETAIL, payload: false });
    }
  };
};

export const deleteSchedule = (id) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_SCHEDULE, payload: true });

    try {
      await initiateAXIOS.delete(initiateScheduleAPI + id, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      dispatch({ type: SET_DELETE_SCHEDULE, payload: id });
      dispatch({ type: SET_SUCCESS_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_SCHEDULE_PAGE,
        payload: "The schedule is deleted.",
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_SCHEDULE_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_SCHEDULE, payload: false });
    }
  };
};

export const assignScheduleToViewer = ({ id, assignData }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_SCHEDULE_DETAIL, payload: true });

    try {
      await initiateAXIOS.patch(
        initiateScheduleAPI + id + "/screen",
        {
          add: assignData,
        },
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({
        type: SET_SCHEDULE_DETAIL,
        payload: new Schedule(await fetchScheduleById(id, access_token)),
      });
      dispatch({ type: SET_SUCCESS_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_SCHEDULE_PAGE,
        payload: "Viewers have been assigned to the schedule.",
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_SCHEDULE_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_SCHEDULE_DETAIL, payload: false });
    }
  };
};

export const removeViewerFromSchedule = ({ id, remove }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_SCHEDULE_DETAIL, payload: true });

    try {
      await initiateAXIOS.patch(
        initiateScheduleAPI + id + "/screen",
        { remove },
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({
        type: SET_SCHEDULE_DETAIL,
        payload: new Schedule(await fetchScheduleById(id, access_token)),
      });
      dispatch({ type: SET_SUCCESS_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_SCHEDULE_PAGE,
        payload: "The viewer has been removed from the schedule.",
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_SCHEDULE_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_SCHEDULE_DETAIL, payload: false });
    }
  };
};

export const assignScheduleToGroup = ({ id, assignData }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_SCHEDULE_DETAIL, payload: true });

    try {
      await initiateAXIOS.patch(
        initiateScheduleAPI + id + "/screen-group",
        { add: assignData },
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({
        type: SET_SCHEDULE_DETAIL,
        payload: new Schedule(await fetchScheduleById(id, access_token)),
      });
      dispatch({ type: SET_SUCCESS_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_SCHEDULE_PAGE,
        payload: "Groups have been assigned to the schedule.",
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_SCHEDULE_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_SCHEDULE_DETAIL, payload: false });
    }
  };
};

export const removeGroupFromSchedule = ({ id, remove }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_SCHEDULE_DETAIL, payload: true });

    try {
      await initiateAXIOS.patch(
        initiateScheduleAPI + id + "/screen-group",
        { remove },
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({
        type: SET_SCHEDULE_DETAIL,
        payload: new Schedule(await fetchScheduleById(id, access_token)),
      });
      dispatch({ type: SET_SUCCESS_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_SCHEDULE_PAGE,
        payload: "The group has been removed from the schedule.",
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_SCHEDULE_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_SCHEDULE_DETAIL, payload: false });
    }
  };
};
// TODO Check remove or keep
// export const updateScheduleCMSAction = ({ id, payload }) => {
//   const access_token = sessionStorage.getItem("access_token");

//   return async (dispatch) => {
//     dispatch({ type: SET_LOADING_SCHEDULE_DETAIL, payload: true });

//     try {
//       await initiateAXIOS.patch(instanceCMSScheduleAPI + id, payload, {
//         headers: { authorization: `Bearer ${access_token}` },
//       });
//       const { data } = await initiateAXIOS.get(initiateScheduleAPI + id, {
//         headers: { authorization: `Bearer ${access_token}` },
//       });

//       const schedule = data;
//       const OOP = new Schedule(schedule);
//       dispatch({ type: SET_SCHEDULE_DETAIL, payload: OOP });
//       dispatch({ type: SET_SUCCESS_PROMPT_SCHEDULE_PAGE, payload: true });
//       dispatch({
//         type: SET_SUCCESS_MESSAGE_SCHEDULE_PAGE,
//         payload: "Schedule successfully updated.",
//       });

//       handleTimeoutSuccessPrompt(dispatch);
//     } catch (e) {
//       const message = handleError(e);
//       dispatch({ type: SET_ERROR_PROMPT_SCHEDULE_PAGE, payload: true });
//       dispatch({ type: SET_ERROR_MESSAGE_SCHEDULE_PAGE, payload: message });

//       handleTimeoutErrorPrompt(dispatch);
//     } finally {
//       dispatch({ type: SET_LOADING_SCHEDULE_DETAIL, payload: false });
//     }
//   };
// };

export const editSchedule = ({ id, schedule }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_SCHEDULE, payload: true });
    dispatch({ type: SET_LOADING_SCHEDULE_DETAIL, payload: true });

    try {
      const { data } = await initiateAXIOS({
        method: "PATCH",
        url: initiateScheduleAPI + id,
        headers: { authorization: `Bearer ${access_token}` },
        data: schedule,
      });

      const OOP = new Schedule(data);
      dispatch({ type: SET_SCHEDULE_DETAIL, payload: OOP });
      dispatch({ type: SET_EDIT_SCHEDULE, payload: OOP });
      dispatch({ type: SET_SUCCESS_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_SCHEDULE_PAGE,
        payload: "The schedule is updated.",
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_SCHEDULE_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_SCHEDULE, payload: false });
      dispatch({ type: SET_LOADING_SCHEDULE_DETAIL, payload: false });
    }
  };
};

export const resendSchedule = ({ id, loading }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: loading, payload: true });

    try {
      await initiateAXIOS.post(
        initiateScheduleAPI + id + "/resend",
        {},
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({ type: SET_SUCCESS_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_SCHEDULE_PAGE,
        payload: "The schedule is resend.",
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_SCHEDULE_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({ type: loading, payload: false });
    }
  };
};
