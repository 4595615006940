import React from "react";
import { useDispatch } from "react-redux";

import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Alert,
  Button,
  Box,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";

import { deleteOrganization } from "../../store/actions/organizationAction";

const DeleteOrganization = ({ open, onClose, organization }) => {
  const dispatch = useDispatch();

  const handleClickClose = () => {
    onClose(false);
  };

  const handleDelete = (id) => {
    dispatch(deleteOrganization(id));

    handleClickClose();
  };

  return (
    <>
      <Dialog maxWidth="sm" onClose={handleClickClose} open={open}>
        <DialogContent>
          <DialogContentText
            sx={{
              textAlign: "center",
              mb: 2,
            }}
          >
            Are you sure want to delete the <strong>{organization.name}</strong>{" "}
            organization?
          </DialogContentText>

          <Box
            sx={{
              fontFamily: "sans-serif",
              color: "#4E5D78",
              textAlign: "center",
              fontSize: 14,
            }}
            id="alert-dialog-slide-description"
          >
            <Alert severity="warning" variant="outlined">
              <strong>You won't be able to revert this!</strong>
            </Alert>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            color="error"
            fullWidth
            startIcon={<ClearIcon />}
            onClick={handleClickClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleDelete(organization.id)}
            fullWidth
            startIcon={<DoneIcon />}
          >
            Yes, Delete It!
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteOrganization;
