import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Alert, AlertTitle } from "@mui/material";

import { SET_ERROR_PROMPT_GROUP_PAGE } from "../../store/actionTypes/groupActionType";

const GroupErrorPrompt = () => {
  const dispatch = useDispatch();

  const { isErrorPromptGroupPage: isError, errorMessageGroupPage: errorMessage } =
    useSelector((state) => state.group);

  const handleClose = () => {
    dispatch({ type: SET_ERROR_PROMPT_GROUP_PAGE, payload: false });
  };

  return (
    isError && (
      <Alert sx={{ my: 2 }} severity="error" variant="filled" onClose={handleClose}>
        <AlertTitle>Something went wrong!</AlertTitle>
        {errorMessage}
      </Alert>
    )
  );
};

export default GroupErrorPrompt;
