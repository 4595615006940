import React from "react";
import { Box } from "@mui/material";
import {
  AnnouncementPurpleIcon,
  ClockIcon,
  GroupBlueIcon,
  ScheduleYellowIcon,
  ViewerBlueIcon,
} from "../../icons";
import { TypographyNormal, TypographySmall } from "../customComponent";
import { colorStyling } from "../../helpers/color";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import AssignedComponentActionMenu from "./AssignedComponentActionMenu";
import { purple } from "@mui/material/colors";
// import ViewerScreenshot from "../viewer/id/ViewerScreenshot";

const AssignedComponentItem = ({ type, item, sourceData }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mb: 2,
        ":last-child": {
          mb: 0,
        },
      }}
    >
      {type === "viewer" && <ViewerItem item={item} />}
      {type === "schedule" && <ScheduleItem item={item} />}
      {type === "group" && <GroupItem item={item} />}

      <Box sx={{ display: "flex", flexDirection: "row" }}>
        {/* TODO when implementing screenshot */}
        {/* {type === "viewer" && <ViewerScreenshot viewer={item} size="sm" />} */}
        <AssignedComponentActionMenu
          type={type}
          item={item}
          sourceData={sourceData}
        />
      </Box>
    </Box>
  );
};

export default AssignedComponentItem;

const ViewerItem = ({ item }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <ViewerBlueIcon />

      <Box sx={{ ml: 1 }}>
        <TypographyNormal sx={{ fontWeight: 600, mb: 1 }}>
          {item.name}
        </TypographyNormal>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TypographySmall
            sx={{
              display: "flex",
              alignItems: "center",
              borderRadius: "15px",
              color:
                item.status?.connectionStatus === "ONLINE"
                  ? colorStyling.green
                  : colorStyling.black,
              backgroundColor:
                item.status?.connectionStatus === "ONLINE"
                  ? colorStyling.white.active
                  : colorStyling.white.inactive,
              p: 1,
              mr: 1,
            }}
          >
            <FiberManualRecordIcon
              sx={{
                color:
                  item.status?.connectionStatus === "ONLINE"
                    ? colorStyling.green
                    : colorStyling.gray,
                fontSize: 8,
                mr: 1,
              }}
            />
            {item?.status?.connectionStatus === "ONLINE" ? "Online" : "Offline"}
          </TypographySmall>

          <TypographySmall
            sx={{
              display: "flex",
              alignItems: "center",
              borderRadius: "15px",
              color:
                item.status?.diplayStatus === "ON"
                  ? colorStyling.green
                  : colorStyling.black,
              backgroundColor:
                item.status?.diplayStatus === "ON"
                  ? colorStyling.white.active
                  : colorStyling.white.inactive,
              p: 1,
              mr: 2,
              fontWeight: 400,
            }}
          >
            <FiberManualRecordIcon
              sx={{
                color:
                  item?.status?.diplayStatus === "ON"
                    ? colorStyling.green
                    : colorStyling.gray,
                fontSize: 12,
                mr: 1,
              }}
            />
            Display : {item?.status?.diplayStatus === "ON" ? "On" : "Off"}
          </TypographySmall>

          {item?.status?.ipAddress && (
            <TypographyNormal sx={{ fontWeight: 300, mr: 2 }}>
              IP Address {item?.status?.ipAddress}
            </TypographyNormal>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const ScheduleItem = ({ item }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {item.type === "ANNOUNCEMENT" ? (
        <AnnouncementPurpleIcon />
      ) : (
        <ScheduleYellowIcon />
      )}

      <Box sx={{ ml: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TypographyNormal
            sx={{
              fontWeight: 600,
            }}
          >
            {item.name}
          </TypographyNormal>
        </Box>

        {item.type === "SCHEDULE" && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <TypographySmall
              sx={{
                fontWeight: 600,
                color: purple[600],
                textTransform: "capitalize",
                mr: 1,
              }}
            >
              {item.command.replace(/_/g, " ").toLowerCase()}
            </TypographySmall>
            <ClockIcon />
            <TypographySmall sx={{ ml: 0.5, fontWeight: 600 }}>
              {item.frequency === "OFF" ? "ONCE" : item.frequency}
            </TypographySmall>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const GroupItem = ({ item }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <GroupBlueIcon />

      <TypographyNormal sx={{ fontWeight: 600, ml: 2 }}>
        {item.name}
      </TypographyNormal>
    </Box>
  );
};
