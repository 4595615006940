import React from "react";

import { Box, CircularProgress, Grid } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  Sort as PriorityIcon,
  // TODO : will come back when finishing schedule features
  // Timelapse as DurationIcon,
} from "@mui/icons-material";
import {
  ScheduleYellowIcon,
  GroupGreyIcon,
  DotIcon,
  ViewerGreyIcon,
  TerminalIcon,
  TableIcon,
  GlobeIcon,
  ScheduleGreyIcon,
} from "../../../icons";
import {
  TypographyLarge,
  TypographyNormal,
  TypographySmall,
  TypographyXLarge,
} from "../../customComponent";
import {
  switchBackgroundColorBasedOnStatus,
  switchColorOnScheduleStatus,
} from "../../../helpers/switchColor";
import { formattedDate } from "../../../helpers/dateFormat";

const ScheduleInfo = ({ schedule, loading }) => {
  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <CircularProgress size={20} thickness={3} sx={{ color: "#1D4ED8" }} />
        <TypographyNormal sx={{ color: "#1D4ED8", mt: 2, fontWeight: 300 }}>
          Loading Data...
        </TypographyNormal>
      </Box>
    );

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mt: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ScheduleYellowIcon />
          <Box display={"flex"} flexDirection={"column"} sx={{ ml: 2 }}>
            <Box sx={{ width: "fit-content" }}>
              <TypographySmall
                sx={{
                  borderRadius: "15px",
                  color: switchColorOnScheduleStatus(
                    schedule.isApproved ? "APPROVED" : "NOT_APPROVED"
                  ),
                  backgroundColor: switchBackgroundColorBasedOnStatus(
                    schedule.isApproved ? "APPROVED" : "NOT_APPROVED"
                  ),
                  fontWeight: 600,
                  px: 1,
                  py: 0.2,
                }}
              >
                {schedule.isApproved ? "Approved" : "Not Approved"}
              </TypographySmall>
            </Box>
            <TypographyXLarge sx={{ fontWeight: 700 }}>
              {schedule.name}
            </TypographyXLarge>
          </Box>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TypographySmall
            sx={{
              borderRadius: "15px",
              p: 1,
              color: switchColorOnScheduleStatus(
                schedule.isActive ? "ACTIVE" : "PENDING"
              ),
              backgroundColor: switchBackgroundColorBasedOnStatus(
                schedule.isActive ? "ACTIVE" : "PENDING"
              ),
              fontWeight: 600,
              mr: 4,
            }}
          >
            <FiberManualRecordIcon
              sx={{
                color: switchColorOnScheduleStatus(
                  schedule.isActive ? "ACTIVE" : "PENDING"
                ),
                fontSize: 8,
                mr: "5px",
              }}
            />
            {schedule.isActive ? "Active" : "Inactive"}
          </TypographySmall>

          <Box display="flex" alignItems="center" sx={{ mr: 2 }}>
            <GroupGreyIcon />
            <TypographyNormal sx={{ fontWeight: 400 }}>
              {schedule.screenGroups === undefined
                ? 0
                : schedule.screenGroups.length}{" "}
              groups
            </TypographyNormal>
          </Box>

          <DotIcon />

          <Box display="flex" alignItems="center" sx={{ ml: 2 }}>
            <ViewerGreyIcon />
            <TypographyNormal>
              {schedule.screens === undefined ? 0 : schedule.screens.length}{" "}
              viewers
            </TypographyNormal>
          </Box>
        </Box>
      </Box>

      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={4}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TableIcon />

            <Box sx={{ ml: 2 }}>
              <TypographyNormal sx={{ fontWeight: 300 }}>
                Frequency
              </TypographyNormal>
              <TypographyLarge sx={{ fontWeight: 600 }}>
                {schedule.frequency === "OFF" ? "ONCE" : schedule.frequency}
              </TypographyLarge>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={4}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TerminalIcon />

            <Box sx={{ ml: 2 }}>
              <TypographyNormal sx={{ fontWeight: 300 }}>
                Command
              </TypographyNormal>
              <TypographyLarge sx={{ fontWeight: 600 }}>
                {schedule.command}
              </TypographyLarge>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={4}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <PriorityIcon sx={{ color: "#9ca3af" }} />

            <Box sx={{ ml: 2 }}>
              <TypographyNormal sx={{ fontWeight: 300 }}>
                Priority
              </TypographyNormal>
              <TypographyLarge sx={{ fontWeight: 600 }}>
                {schedule.priority?.name}
              </TypographyLarge>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={4}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ScheduleGreyIcon />

            <Box sx={{ ml: 2 }}>
              <TypographyNormal sx={{ fontWeight: 300 }}>
                Start Time
              </TypographyNormal>
              <TypographyLarge sx={{ fontWeight: 600 }}>
                {formattedDate(schedule.startAt)}
              </TypographyLarge>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={4}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ScheduleGreyIcon />

            <Box sx={{ ml: 2 }}>
              <TypographyNormal sx={{ fontWeight: 300 }}>
                End Time
              </TypographyNormal>
              <TypographyLarge sx={{ fontWeight: 600 }}>
                {schedule.endAt === schedule.startAt
                  ? "-"
                  : formattedDate(schedule.endAt)}
              </TypographyLarge>
            </Box>
          </Box>
        </Grid>
        {/* 
          TODO : will come back when finishing schedule features 
        */}
        {/* <Grid item xs={4}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <DurationIcon sx={{ color: "#9ca3af" }} />

            <Box sx={{ ml: 2 }}>
              <TypographyNormal sx={{ fontWeight: 300 }}>
                Duration
              </TypographyNormal>
              <TypographyLarge sx={{ fontWeight: 600 }}>
                {schedule.duration ?? "Indefinitely"}
              </TypographyLarge>
            </Box>
          </Box>
        </Grid> */}

        {schedule.command === "PLAY_CONTENT" && (
          <Grid item xs={12}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <GlobeIcon />

              <Box sx={{ ml: 2 }}>
                <TypographyNormal sx={{ fontWeight: 300 }}>
                  Content ID
                </TypographyNormal>
                <TypographyLarge sx={{ fontWeight: 600 }}>
                  {schedule.contentId}
                </TypographyLarge>
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default ScheduleInfo;
