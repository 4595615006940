import React, { useState } from "react";
import { TypographyNormal } from "../../customComponent";
import {
  Box,
  Collapse,
  Grid,
  IconButton,
  TableCell,
  TableRow,
} from "@mui/material";
import { formattedDate } from "../../../helpers/dateFormat";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Role from "../../../helpers/roles";

const UserHistoryItem = ({ data, index, role }) => {
  const [expanded, setExpanded] = useState(false);

  const setStatus = (data) => {
    if (data === null) {
      return "-";
    }
    if (String(data)[0] === "2") return "Success";
    else return "Failed";
  };
  return (
    <>
      <TableRow
        sx={{
          ...((index + 1) % 2 !== 0
            ? {
                backgroundColor: "#eaf2fa",
              }
            : {
                backgroundColor: "#fafdff",
              }),
        }}
        onClick={() => {
          if (role === Role.Eins) setExpanded(!expanded);
        }}
      >
        {role === Role.Eins && (
          <TableCell sx={{ py: 1, width: "50px" }}>
            <IconButton>
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </TableCell>
        )}
        <TableCell>{data.id}</TableCell>
        <TableCell>{data.userName}</TableCell>
        <TableCell>{data.userRole}</TableCell>
        <TableCell>{data.action}</TableCell>
        <TableCell>{setStatus(data.statusCode)}</TableCell>
        <TableCell>{data?.organization?.name}</TableCell>
        <TableCell>{formattedDate(data.recordedAt)}</TableCell>
      </TableRow>
      {role === Role.Eins && (
        <TableRow>
          <TableCell sx={{ p: 0 }} colSpan={8}>
            <Collapse in={expanded} sx={{ ml: 8, mr: 4 }}>
              <Grid container sx={{ my: 2 }} justifyContent={"flex-start"}>
                <Grid
                  item
                  xs={4}
                  sx={{ display: "flex", flexDirection: "column", mb: 2 }}
                >
                  <TypographyNormal sx={{ fontWeight: 600 }}>
                    Request ID :
                  </TypographyNormal>
                  <TypographyNormal>{data.requestId}</TypographyNormal>
                </Grid>

                <Grid
                  item
                  xs={8}
                  sx={{ display: "flex", flexDirection: "column", mb: 2 }}
                >
                  <TypographyNormal sx={{ fontWeight: 600 }}>
                    Recorded Time :
                  </TypographyNormal>
                  <TypographyNormal>
                    {formattedDate(data.recordedAt)}
                  </TypographyNormal>
                </Grid>

                <Grid
                  item
                  xs={4}
                  sx={{ display: "flex", flexDirection: "column", mb: 2 }}
                >
                  <TypographyNormal sx={{ fontWeight: 600 }}>
                    Action :
                  </TypographyNormal>
                  <TypographyNormal>{data.action}</TypographyNormal>
                </Grid>

                <Grid
                  item
                  xs={8}
                  sx={{ display: "flex", flexDirection: "column", mb: 2 }}
                >
                  <TypographyNormal sx={{ fontWeight: 600 }}>
                    StatusCode :
                  </TypographyNormal>
                  <TypographyNormal>{data.statusCode}</TypographyNormal>
                </Grid>

                {data.requestParam && (
                  <Grid
                    item
                    xs={4}
                    sx={{ display: "flex", flexDirection: "column", mb: 2 }}
                  >
                    <TypographyNormal sx={{ fontWeight: 600 }}>
                      Request Parameter :
                    </TypographyNormal>
                    <TypographyNormal>{data.requestParam}</TypographyNormal>
                  </Grid>
                )}

                {data.requestQuery && (
                  <Grid
                    item
                    xs={4}
                    sx={{ display: "flex", flexDirection: "column", mb: 2 }}
                  >
                    <TypographyNormal sx={{ fontWeight: 600 }}>
                      Request Query :
                    </TypographyNormal>
                    <TypographyNormal>{data.requestQuery}</TypographyNormal>
                  </Grid>
                )}

                {data.requestBody && (
                  <Grid
                    item
                    xs={4}
                    sx={{ display: "flex", flexDirection: "column", mb: 2 }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", mb: 2 }}
                    >
                      <TypographyNormal sx={{ fontWeight: 600 }}>
                        Request Body :
                      </TypographyNormal>
                      <TypographyNormal>{data.requestBody}</TypographyNormal>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default UserHistoryItem;
