import React from "react";

import { Box, IconButton } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { makeStyles } from "@mui/styles";
import {
  switchBackgroundColorBasedOnStatus,
  switchColorOnScheduleStatus,
} from "../../helpers/switchColor";
import { TypographyNormal, TypographySmall } from "../customComponent";
import { ScheduleYellowIcon } from "../../icons";
import { blue, purple } from "@mui/material/colors";
import { useNavigate } from "react-router";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  hover: { "&:hover": { backgroundColor: "#F3F8FD", cursor: "pointer" } },
}));

const ScheduleItem = ({ schedule }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <>
      <Box
        onClick={() => navigate("/schedule/detail/" + schedule.id)}
        className={classes.hover}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid lightgrey",
          p: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", minWidth: "300px" }}>
          <ScheduleYellowIcon />
          <Box display={"flex"} alignItems={"center"} sx={{ ml: 2 }}>
            {schedule.isApproved ? (
              <Tooltip title={"Schedule is approved"} placement="top">
                <CheckCircleOutlineIcon
                  fontSize="small"
                  sx={{
                    mr: 0.5,
                    color: switchBackgroundColorBasedOnStatus("APPROVED"),
                    borderRadius: "16px",
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip title={"Schedule is not approved"} placement="top">
                <ErrorOutlineIcon
                  fontSize="small"
                  sx={{
                    mr: 0.5,
                    color: switchBackgroundColorBasedOnStatus("NOT_APPROVED"),
                    borderRadius: "16px",
                  }}
                />
              </Tooltip>
            )}
            {schedule.name.length >= 30 ? (
              <Tooltip title={schedule.name} placement="bottom-start">
                <TypographyNormal sx={{ fontWeight: 600 }}>
                  {schedule.name.substring(0, 30) + "..."}
                </TypographyNormal>
              </Tooltip>
            ) : (
              <TypographyNormal sx={{ fontWeight: 600 }}>
                {schedule.name}
              </TypographyNormal>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            maxWidth: 700,
            minWidth: 400,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <TypographyNormal
            sx={{
              width: 100,
              textAlign: "center",
              fontWeight: 600,
              color: blue[600],
            }}
          >
            {schedule.frequency === "OFF" ? "ONCE" : schedule.frequency}
          </TypographyNormal>

          <TypographySmall
            sx={{
              color: switchColorOnScheduleStatus(
                schedule.isActive ? "ACTIVE" : "PENDING"
              ),
              backgroundColor: switchBackgroundColorBasedOnStatus(
                schedule.isActive ? "ACTIVE" : "PENDING"
              ),
              borderRadius: "15px",
              p: 1,
              fontWeight: 600,
              minWidth: "80px",
            }}
          >
            <FiberManualRecordIcon
              sx={{
                color: switchColorOnScheduleStatus(
                  schedule.isActive ? "ACTIVE" : "PENDING"
                ),
                fontSize: 8,
                mr: "5px",
              }}
            />
            {schedule.isActive ? "Active" : "Inactive"}
          </TypographySmall>

          <TypographyNormal
            sx={{
              width: 100,
              textAlign: "center",
              fontWeight: 600,
              color: purple[600],
              textTransform: "capitalize",
            }}
          >
            {schedule.command.replace(/_/g, " ").toLowerCase()}
          </TypographyNormal>

          <IconButton sx={{ color: "#111827" }}>
            <NavigateNextIcon />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export default ScheduleItem;
