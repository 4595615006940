import React from "react";

const UserManualIcon = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1219_78284)">
          <path
            d="M4.27321 17.308V0H3.12055C1.42237 0 0.0407715 1.38159 0.0407715 3.07978V18.2859C0.746287 17.6773 1.66377 17.3079 2.66647 17.3079H4.27321V17.308Z"
            fill="#00D4FF"
          />
          <path
            d="M18.6551 11.9508C18.8064 11.9508 18.9565 11.9563 19.1054 11.9662V0H5.67676V17.308H12.0867C12.712 14.2546 15.4196 11.9508 18.6551 11.9508ZM9.93676 4.13302H14.6346V5.53669H9.93676V4.13302ZM8.3499 7.18036H16.2214V8.58403H8.3499V7.18036Z"
            fill="#FFBD80"
          />
          <path
            d="M12.8675 22.0355H3.5977V20.6318H12.2483C12.0562 20.0104 11.9521 19.3507 11.9509 18.6674H2.66634C1.19611 18.6674 0 19.8635 0 21.3337C0 22.8039 1.19611 24 2.66634 24H14.6128C13.9101 23.4673 13.3154 22.7995 12.8675 22.0355Z"
            fill="#F55858"
          />
          <path
            d="M18.655 13.3102C15.7078 13.3102 13.3101 15.7079 13.3101 18.6551C13.3101 21.6023 15.7078 24 18.655 24C21.6022 24 23.9999 21.6023 23.9999 18.6551C23.9999 15.7079 21.6022 13.3102 18.655 13.3102ZM18.6545 15.4553C19.0487 15.4553 19.3406 15.7774 19.3576 16.1584C19.3747 16.5381 19.0223 16.8615 18.6545 16.8615C18.2603 16.8615 17.9684 16.5394 17.9514 16.1584C17.9344 15.7787 18.2867 15.4553 18.6545 15.4553ZM19.3592 21.3954H17.953V17.7955H19.3592V21.3954Z"
            fill="#FBE4D7"
          />
        </g>
        <defs>
          <clipPath id="clip0_1219_78284">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default UserManualIcon;
