import React from "react";

export const ResolutionType = {
  // "": {
  //   label: "Select Resolution",
  //   width: null,
  //   height: null,
  // },
  "4k": {
    labelL: "3840 x 2160",
    labelP: "2160 x 3840",
    height: 3840,
    width: 2160,
    custom: false,
  },
  "1080p": {
    labelL: "1920 x 1080",
    labelP: "1080 x 1920",
    height: 1920,
    width: 1080,
    custom: false,
  },
  "720p": {
    labelL: "1280 x 720",
    labelP: "720 x 1280",
    height: 1280,
    width: 720,
    custom: false,
  },
  "custom(16:9)": {
    labelL: "Custom (16 : 9)",
    labelP: "Custom (9 : 16)",
    height: 1600,
    width: 900,
    custom: true,
  },
  "custom(4:3)": {
    labelL: "Custom (4 : 3)",
    labelP: "Custom (3 : 4)",
    height: 1600,
    width: 1200,
    custom: true,
  },
};

// Context for Device Resolution
// Can be used independently of React state or reducers.
// ex. .\components\cms\contentTemplate\create\index.jsx
export const ResolutionContext = React.createContext({
  width: null,
  height: null,
  custom: null,
  orientation: null,
  setResolution: () => {},
});
