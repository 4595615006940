import React from "react";
import { Alert, AlertTitle } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { SET_ERROR_PROMPT_VIEWER_PAGE } from "../../store/actionTypes/viewerActionTypes";

const ViewerErrorPrompt = () => {
  const dispatch = useDispatch();

  const { isErrorPromptViewerPage: isError, errorMessageViewerPage: errorMessage } =
    useSelector((state) => state.viewer);

  const handleClose = (event, reason) => {
    dispatch({ type: SET_ERROR_PROMPT_VIEWER_PAGE, payload: false });
  };

  return (
    isError && (
      <Alert sx={{ my: 2 }} severity="error" variant="filled" onClose={handleClose}>
        <AlertTitle>Something went wrong!</AlertTitle>
        {errorMessage}
      </Alert>
    )
  );
};

export default ViewerErrorPrompt;
