import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Alert, AlertTitle } from "@mui/material";

import { SET_SUCCESS_PROMPT_ORGANIZATION_PAGE } from "../../store/actionTypes/organizationActionType";

const OrganizationSuccessPrompt = () => {
  const dispatch = useDispatch();

  const {
    isSuccessPromptOrganizationPage: isSuccess,
    successMessageOrganizationPage: successMessage,
  } = useSelector((state) => state.organization);

  const handleClose = (event, reason) => {
    dispatch({
      type: SET_SUCCESS_PROMPT_ORGANIZATION_PAGE,
      payload: false,
    });
  };

  return (
    isSuccess && (
      <Alert
        sx={{ my: 2 }}
        severity="success"
        variant="filled"
        onClose={handleClose}
      >
        <AlertTitle>Success!</AlertTitle>
        {successMessage}
      </Alert>
    )
  );
};

export default OrganizationSuccessPrompt;
