import React from "react";
import { Alert, AlertTitle } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { SET_ERROR_PROMPT_CONTENT_UPLOAD_PAGE } from "../../store/actionTypes/contentUploadActionType";

const ContentUploadErrorPrompt = () => {
  const dispatch = useDispatch();

  const {
    isErrorPromptContentUploadPage: isError,
    errorMessageContentUploadPage: errorMessage,
  } = useSelector((state) => state.contentUpload);
  const handleClose = (event, reason) => {
    dispatch({ type: SET_ERROR_PROMPT_CONTENT_UPLOAD_PAGE, payload: false });
  };

  return (
    isError && (
      <Alert
        sx={{ my: 2 }}
        severity="error"
        variant="filled"
        onClose={handleClose}
      >
        <AlertTitle>Something went wrong!</AlertTitle>
        {errorMessage}
      </Alert>
    )
  );
};

export default ContentUploadErrorPrompt;
