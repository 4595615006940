import React, { useState, useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, ButtonBase, InputLabel, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { default as LandscapeIcon } from "@mui/icons-material/NoteOutlined";
import { default as PotraitIcon } from "@mui/icons-material/InsertDriveFileOutlined";
import { TypographyLarge } from "../../../customComponent";
import { ResolutionType, ResolutionContext } from "../../../../helpers/resolution";
import { setCanvasRes, setContentName, setErrorHandler } from "../../../../store/actions/cmsAction";
import update from 'immutability-helper';

const StyledBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  ":last-child": {
    marginBottom: 0,
  },
}));

const Form = ({ resType, setResType }) => {
  const dispatch = useDispatch();
  const { canvasRes, errorHandler } = useSelector((state) => state.cms);

  const resolution = useContext(ResolutionContext);
  const [widthInput, setWidthInput] = useState(360);
  const [heightInput, setHeightInput] = useState(640);
  const [orientation, setOrientation] = useState("P");
  
  const handleSetOrientation = useCallback(
    (value, selectedRes) => {
      setOrientation(value);
      if (canvasRes.orientation !== value) {
        dispatch(setCanvasRes({
          ...update(canvasRes, { $merge: { // fixed preview res
            width: value === "P" ? 360 : 640,
            height: value === "P" ? 640 : 360,
            orientation: value,
          }})
        }));
      }

      if (!(selectedRes ?? resType)) {
        resolution.setResolution(update(resolution, { $merge: { orientation: value } }));
        return;
      }

      const { width, height, custom } = ResolutionType[selectedRes ?? resType];
      let updatedWidth = width;
      let updatedHeight = height;
      if (value === "L") {
        updatedWidth = height;
        updatedHeight = width;
      }

      resolution.setResolution(update(resolution, { $merge: { width: updatedWidth, height: updatedHeight, custom, orientation: value } }));
      setWidthInput(updatedWidth);
      setHeightInput(updatedHeight);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resType, resolution, canvasRes],
  );

  const handleSelectedResType = useCallback(
    (e) => {
      const selectedRes = e.target.value;
      setResType(selectedRes);
      if (selectedRes?.length === 0) {
        return;
      }
  
      const { width, height, custom } = ResolutionType[selectedRes];
      resolution.setResolution(update(resolution, { $merge: { width, height, custom } }));
      handleSetOrientation(orientation, selectedRes);
      dispatch(setErrorHandler({ resolution: false }));
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orientation, handleSetOrientation],
  );

  const handleChangeReso = useCallback(
    (which, event) => {
      const value = parseInt(event.target.value);
      switch(which) {
        case "width":
          setWidthInput(value);
          resolution.setResolution(update(resolution, { $merge: { width: value } }));
          break;
          
        case "height":
          setHeightInput(value);
          resolution.setResolution(update(resolution, { $merge: { height: value } }));
          break;
  
        default:
          return;
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resolution, widthInput, heightInput],
  );

  const handleChangeName = (event) => {
    const value = event.target.value;
    dispatch(setContentName(value));
    dispatch(setErrorHandler({ contentName: false }));
  };

  return (
    <>
      <StyledBox>
        <InputLabel>Content Name</InputLabel>

        <TextField
          margin="dense"
          fullWidth
          variant="outlined"
          placeholder="Enter content name"
          size="small"
          error={errorHandler.contentName}
          onChange={(e) => handleChangeName(e)}
        />
      </StyledBox>

      <StyledBox>
        <InputLabel>Orientation</InputLabel>

        <Box display={"flex"} flexDirection={"row"} gap={3} sx={{ mt: "8px" }}>
          <ButtonBase
            sx={{
              border:
                orientation === "P"
                  ? "2px solid rgba(28, 78, 216, 0.8)"
                  : "2px solid rgba(28, 78, 216, 0.5)",
              backgroundColor:
                orientation === "P"
                  ? "rgba(28, 78, 216, 0.1)"
                  : "transparent",
              borderRadius: "8px",
              width: "112px",
              p: 2,
              ":hover": {
                border: "2px solid rgba(28, 78, 216, 1)",
                backgroundColor: "rgba(28, 78, 216, 0.2)",
                ".potraitIcon": {
                  color: "#1C4ED8",
                },
              },
            }}
            onClick={() => {
              handleSetOrientation("P");
            }}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <PotraitIcon
                className="potraitIcon"
                fontSize="inherit"
                color="inherit"
                sx={{ fontSize: "64px", transform: "scaleX(-1)" }}
              />
              <TypographyLarge>Potrait</TypographyLarge>
            </Box>
          </ButtonBase>

          <ButtonBase
            sx={{
              border:
                orientation === "L"
                  ? "2px solid rgba(28, 78, 216, 0.8)"
                  : "2px solid rgba(28, 78, 216, 0.5)",
              backgroundColor:
                orientation === "L"
                  ? "rgba(28, 78, 216, 0.1)"
                  : "transparent",
              borderRadius: "8px",
              width: "112px",
              p: 2,
              ":hover": {
                border: "2px solid rgba(28, 78, 216, 1)",
                backgroundColor: "rgba(28, 78, 216, 0.1)",
                ".landscapeIcon": {
                  color: "#1C4ED8",
                },
              },
            }}
            onClick={() => {
              handleSetOrientation("L");
            }}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <LandscapeIcon
                className="landscapeIcon"
                fontSize="inherit"
                color="inherit"
                sx={{ fontSize: "64px" }}
              />
              <TypographyLarge>Landscape</TypographyLarge>
            </Box>
          </ButtonBase>
        </Box>
      </StyledBox>

      <StyledBox>
        <InputLabel>Resolution</InputLabel>

        <TextField
          margin="dense"
          fullWidth
          variant="outlined"
          placeholder="Select Resolution"
          size="small"
          select
          SelectProps={{
            native: true,
          }}
          value={resType}
          error={errorHandler.resolution}
          onChange={(e) => {
            handleSelectedResType(e);
          }}
        >
          {resType === "" && (<option value="">Select Resolution</option>)}
          {Object.keys(ResolutionType).map((res) => {
            const selectedRes = ResolutionType[res];
            return (
              <option value={res} key={res}>
                {selectedRes[`label${orientation}`]}
              </option>
            )
          })}
        </TextField>

        {resType !== "" && (
          <Box display={"flex"} gap={2} sx={{ mt: "4px" }}>
            <Box>
              <InputLabel>Width</InputLabel>

              <TextField
                sx={{ width: "70px" }}
                margin="dense"
                variant="outlined"
                placeholder="0"
                size="small"
                onChange={(e) => handleChangeReso("width", e)}
                value={widthInput}
                InputProps={{ readOnly: !resolution.custom }}
              />
            </Box>
            <Box>
              <InputLabel>Height</InputLabel>

              <TextField
                sx={{ width: "70px" }}
                margin="dense"
                variant="outlined"
                placeholder="0"
                size="small"
                onChange={(e) => handleChangeReso("height", e)}
                value={heightInput}
                InputProps={{ readOnly: !resolution.custom }}
              />
            </Box>
          </Box>
        )}
      </StyledBox>
    </>
  );
};

export default Form;
