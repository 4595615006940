import React from "react";

const GroupBlueIcon = () => {
  return (
    <>
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="24" cy="24" r="24" fill="#EFF6FF" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.6088 21.8261V23.5652H21.3914C20.7579 23.5652 20.1639 23.3958 19.6523 23.0999V27.9131C19.6523 28.8736 20.4309 29.6522 21.3914 29.6522H26.6088V31.3913H21.3914C19.4704 31.3913 17.9132 29.8341 17.9132 27.9131V20.087V19.6522V19.2174H19.6523V19.6522V20.087C19.6523 21.0475 20.4309 21.8261 21.3914 21.8261H26.6088Z"
          fill="#FFBD80"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.3042 17.4783C15.3042 19.3993 16.8615 20.9565 18.7825 20.9565C20.7035 20.9565 22.2607 19.3993 22.2607 17.4783C22.2607 15.5573 20.7035 14 18.7825 14C16.8615 14 15.3042 15.5573 15.3042 17.4783ZM20.5216 17.4782C20.5216 18.4387 19.743 19.2174 18.7825 19.2174C17.822 19.2174 17.0434 18.4387 17.0434 17.4782C17.0434 16.5177 17.822 15.7391 18.7825 15.7391C19.743 15.7391 20.5216 16.5177 20.5216 17.4782Z"
          fill="#F55858"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.739 30.5217C25.739 32.4427 27.2963 34 29.2173 34C31.1383 34 32.6955 32.4427 32.6955 30.5217C32.6955 28.6007 31.1383 27.0435 29.2173 27.0435C27.2963 27.0435 25.739 28.6007 25.739 30.5217ZM30.9564 30.5217C30.9564 31.4822 30.1778 32.2609 29.2173 32.2609C28.2568 32.2609 27.4782 31.4822 27.4782 30.5217C27.4782 29.5612 28.2568 28.7826 29.2173 28.7826C30.1778 28.7826 30.9564 29.5612 30.9564 30.5217Z"
          fill="#FFBD80"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.739 22.6957C25.739 24.6167 27.2963 26.1739 29.2173 26.1739C31.1383 26.1739 32.6955 24.6167 32.6955 22.6957C32.6955 20.7747 31.1383 19.2174 29.2173 19.2174C27.2963 19.2174 25.739 20.7747 25.739 22.6957ZM30.9564 22.6956C30.9564 23.6561 30.1778 24.4348 29.2173 24.4348C28.2568 24.4348 27.4782 23.6561 27.4782 22.6956C27.4782 21.7351 28.2568 20.9565 29.2173 20.9565C30.1778 20.9565 30.9564 21.7351 30.9564 22.6956Z"
          fill="#00D4FF"
        />
      </svg>
    </>
  );
};

export default GroupBlueIcon;
