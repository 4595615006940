import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  editPasswordUserSchema,
  editPasswordAdminSchema,
} from "../../schemas/editPasswordSchema";
import {
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  Paper,
  Button,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { PrimaryButton, SecondaryButton } from "../customComponent";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Role from "../../helpers/roles";
import { decrypt } from "../../helpers/crypto";
import { changeSelfPassword } from "../../store/actions/userAction";

const EditPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loadingUser } = useSelector((state) => state.user);

  const { loggedUser } = useSelector((state) => state.auth);
  const decryptedLoggedUser = decrypt(loggedUser);
  const userRole = decryptedLoggedUser.type;

  const [form, setForm] = useState({
    showOldPassword: false,
    showNewPassword: false,
  });

  const handleClickShowOldPassword = () => {
    setForm({
      ...form,
      showOldPassword: !form.showOldPassword,
    });
  };

  const handleClickShowNewPassword = () => {
    setForm({
      ...form,
      showNewPassword: !form.showNewPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  let resolver;

  if (userRole === Role.Admin) {
    resolver = editPasswordAdminSchema;
  } else {
    resolver = editPasswordUserSchema;
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(resolver),
  });

  const submitEditPasswordForm = async (data) => {
    const { oldPassword, confirmPassword } = data;
    const payload = {
      currentPassword: oldPassword,
      newPassword: confirmPassword,
    };

    dispatch(changeSelfPassword(payload)).then(() =>
      reset({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      })
    );
  };

  return (
    <>
      <Paper elevation={2} sx={{ p: 2 }}>
        <Box component="form">
          <FormControl variant="outlined" fullWidth margin="normal">
            <InputLabel htmlFor="outlined-adornment-password">
              Current password
            </InputLabel>
            <OutlinedInput
              {...register("oldPassword")}
              label="Current password"
              type={form.showOldPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowOldPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {form.showOldPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText sx={{ color: "red" }}>
              {errors.oldPassword && "Current password is a required field"}
            </FormHelperText>
          </FormControl>

          <FormControl variant="outlined" fullWidth margin="normal">
            <InputLabel htmlFor="outlined-adornment-password">
              New password
            </InputLabel>
            <OutlinedInput
              {...register("newPassword")}
              label="New password"
              type={form.showNewPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowNewPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {form.showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText sx={{ color: "red" }}>
              {errors.newPassword?.message}
            </FormHelperText>
          </FormControl>

          <FormControl variant="outlined" fullWidth margin="normal">
            <InputLabel htmlFor="outlined-adornment-password">
              Confirm password
            </InputLabel>
            <OutlinedInput
              {...register("confirmPassword")}
              label="Confirm password"
              type={form.showNewPassword ? "text" : "password"}
            />
            <FormHelperText sx={{ color: "red" }}>
              {errors.confirmPassword &&
                "Confirm password must be matched with new password"}
            </FormHelperText>
          </FormControl>

          {loadingUser ? (
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <SecondaryButton onClick={() => navigate("/")}>
                Cancel
              </SecondaryButton>

              <PrimaryButton sx={{ ml: 1 }} size="large" disabled>
                <CircularProgress size={20} sx={{ color: "#ffff", mr: 1 }} />{" "}
                Saving Password
              </PrimaryButton>
            </Box>
          ) : (
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button variant="outlined" onClick={() => navigate("/")}>
                Cancel
              </Button>

              <Button
                variant="contained"
                sx={{ ml: 2 }}
                onClick={handleSubmit(submitEditPasswordForm)}
              >
                Save Password
              </Button>
            </Box>
          )}
        </Box>
      </Paper>
    </>
  );
};

export default EditPassword;
