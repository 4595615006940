import { initiateAXIOS } from "../../config/axios";
import { handleError } from "../handleError";
import Viewer from "../../models/ViewerModel";
import {
  SET_DELETE_VIEWER,
  SET_ERROR_MESSAGE_VIEWER_PAGE,
  SET_ERROR_PROMPT_VIEWER_PAGE,
  SET_LOADING_VIEWER,
  SET_LOADING_VIEWER_DETAIL,
  SET_SUCCESS_MESSAGE_VIEWER_PAGE,
  SET_SUCCESS_PROMPT_VIEWER_PAGE,
  SET_VIEWER,
  SET_VIEWER_DETAIL,
  SET_CREATE_VIEWER,
} from "../actionTypes/viewerActionTypes";
import { initiateViewerAPI, viewerAPILimit } from "../../api";
import Schedule from "../../models/ScheduleModel";
import Group from "../../models/GroupModel";

const handleSuccessPromptTimeout = (dispatch) => {
  setTimeout(() => {
    dispatch({ type: SET_SUCCESS_PROMPT_VIEWER_PAGE, payload: false });
  }, 3000);
};

const handleErrorPromptTimeout = (dispatch) => {
  setTimeout(() => {
    dispatch({ type: SET_ERROR_PROMPT_VIEWER_PAGE, payload: false });
  }, 5000);
};

const handleInsertScheduleModel = (viewer) => {
  let newSchedules = [];
  viewer?.schedules.map((item) => newSchedules.push(new Schedule(item)));
  const newViewer = { ...viewer, schedules: newSchedules };
  return newViewer;
};

export const fetchViewerById = async (id, access_token) => {
  const { data } = await initiateAXIOS.get(initiateViewerAPI + id, {
    headers: { authorization: `Bearer ${access_token}` },
  });
  let schedules = [];
  data.schedules.map((item) => schedules.push(new Schedule(item)));

  return {
    ...data,
    schedules,
    screenGroup: data.screenGroup ? new Group(data.screenGroup) : null,
  };
};

export const getViewers = () => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_VIEWER, payload: true });

    try {
      const { data } = await initiateAXIOS.get(viewerAPILimit, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      const dataViewer = data.items;
      let viewers = [];
      dataViewer.map((item) => viewers.push(new Viewer(item)));
      dispatch({ type: SET_VIEWER, payload: viewers });

      handleSuccessPromptTimeout(dispatch);
    } catch (error) {
      const message = handleError(error);
      dispatch({ type: SET_ERROR_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_VIEWER_PAGE, payload: message });

      handleErrorPromptTimeout(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_VIEWER, payload: false });
    }
  };
};

export const getViewer = (id) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_VIEWER_DETAIL, payload: true });

    try {
      const { data } = await initiateAXIOS.get(initiateViewerAPI + id, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      const OOP = new Viewer(handleInsertScheduleModel(data));
      dispatch({ type: SET_VIEWER_DETAIL, payload: OOP });

      handleSuccessPromptTimeout(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_VIEWER_PAGE, payload: message });

      handleErrorPromptTimeout(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_VIEWER_DETAIL, payload: false });
    }
  };
};

export const filterViewer = (query) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_VIEWER, payload: true });

    try {
      const { data } = await initiateAXIOS.get(viewerAPILimit + query, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      const dataViewer = data.items;
      let viewers = [];
      dataViewer.map((item) => viewers.push(new Viewer(item)));
      dispatch({ type: SET_VIEWER, payload: viewers });
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_VIEWER_PAGE, payload: message });

      handleErrorPromptTimeout(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_VIEWER, payload: false });
    }
  };
};

export const createViewer = (payload) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_VIEWER, payload: true });

    try {
      const { data } = await initiateAXIOS.post(initiateViewerAPI, payload, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      const viewerData = new Viewer(data);

      dispatch({ type: SET_CREATE_VIEWER, payload: viewerData });

      handleSuccessPromptTimeout(dispatch);
    } catch (error) {
      const message = handleError(error);
      dispatch({ type: SET_ERROR_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_VIEWER_PAGE, payload: message });

      handleErrorPromptTimeout(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_VIEWER, payload: false });
    }
  };
};

export const createViewerImmediateJob = (payload) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_VIEWER, payload: true });
    dispatch({ type: SET_LOADING_VIEWER_DETAIL, payload: true });

    try {
      const { data } = await initiateAXIOS.post(
        initiateViewerAPI + payload.id + "/immediate",
        payload.schedule,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      const OOP = new Viewer(data);

      dispatch({ type: SET_SUCCESS_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_VIEWER_PAGE,
        payload: "An immediate job has been created.",
      });
      dispatch({ type: SET_VIEWER_DETAIL, payload: OOP });

      handleSuccessPromptTimeout(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_VIEWER_PAGE, payload: message });

      handleErrorPromptTimeout(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_VIEWER_DETAIL, payload: false });
      dispatch({ type: SET_LOADING_VIEWER, payload: false });
    }
  };
};

export const editViewer = ({ id, name }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_VIEWER_DETAIL, payload: true });

    try {
      await initiateAXIOS.patch(
        initiateViewerAPI + id,
        { name },
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({
        type: SET_VIEWER_DETAIL,
        payload: new Viewer(await fetchViewerById(id, access_token)),
      });
      dispatch({ type: SET_SUCCESS_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_VIEWER_PAGE,
        payload: "Viewer successfully updated",
      });

      handleSuccessPromptTimeout(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_VIEWER_PAGE, payload: message });

      handleErrorPromptTimeout(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_VIEWER_DETAIL, payload: false });
    }
  };
};

export const deleteViewer = (id) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_VIEWER_DETAIL, payload: true });

    try {
      await initiateAXIOS.delete(initiateViewerAPI + id, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      dispatch({ type: SET_SUCCESS_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_VIEWER_PAGE,
        payload: "The viewer has been deleted.",
      });
      dispatch({ type: SET_DELETE_VIEWER, payload: id });

      handleSuccessPromptTimeout(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_VIEWER_PAGE, payload: message });

      handleErrorPromptTimeout(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_VIEWER_DETAIL, payload: false });
    }
  };
};

export const assignViewerToGroup = ({ id, screenGroupId }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_VIEWER_DETAIL, payload: true });

    try {
      await initiateAXIOS.patch(
        initiateViewerAPI + id,
        { screenGroupId },
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({
        type: SET_VIEWER_DETAIL,
        payload: new Viewer(await fetchViewerById(id, access_token)),
      });
      dispatch({ type: SET_SUCCESS_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_VIEWER_PAGE,
        payload: "The viewer has been joined the group.",
      });

      handleSuccessPromptTimeout(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_VIEWER_PAGE, payload: message });

      handleErrorPromptTimeout(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_VIEWER_DETAIL, payload: false });
    }
  };
};

export const removeGroupFromViewer = (id) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_VIEWER_DETAIL, payload: true });

    try {
      await initiateAXIOS.patch(
        initiateViewerAPI + id,
        { screenGroupId: 0 },
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({
        type: SET_VIEWER_DETAIL,
        payload: new Viewer(await fetchViewerById(id, access_token)),
      });
      dispatch({ type: SET_SUCCESS_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_VIEWER_PAGE,
        payload: "The viewer has been removed from the group.",
      });

      handleSuccessPromptTimeout(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_VIEWER_PAGE, payload: message });

      handleErrorPromptTimeout(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_VIEWER_DETAIL, payload: false });
    }
  };
};

export const assignViewerToSchedule = ({ id, assignData }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_VIEWER_DETAIL, payload: true });

    try {
      await initiateAXIOS.patch(
        initiateViewerAPI + id + "/schedule",
        { add: assignData },
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({
        type: SET_VIEWER_DETAIL,
        payload: new Viewer(await fetchViewerById(id, access_token)),
      });
      dispatch({ type: SET_SUCCESS_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_VIEWER_PAGE,
        payload: "The schedules has been assigned to selected viewer.",
      });

      handleSuccessPromptTimeout(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_VIEWER_PAGE, payload: message });

      handleErrorPromptTimeout(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_VIEWER_DETAIL, payload: false });
    }
  };
};

export const removeScheduleFromViewer = ({ id, removeSchedule }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_VIEWER_DETAIL, payload: true });

    try {
      const { data } = await initiateAXIOS.patch(
        initiateViewerAPI + id + "/schedule",
        { remove: removeSchedule },
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      const viewer = data;
      const OOP = new Viewer(handleInsertScheduleModel(viewer));

      dispatch({ type: SET_SUCCESS_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_VIEWER_PAGE,
        payload: "The schedule has been removed from the viewer.",
      });
      dispatch({ type: SET_VIEWER_DETAIL, payload: OOP });

      handleSuccessPromptTimeout(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_VIEWER_PAGE, payload: message });

      handleErrorPromptTimeout(dispatch);
    } finally {
      dispatch({ type: SET_LOADING_VIEWER_DETAIL, payload: false });
    }
  };
};

export const blockViewer = (payload) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_VIEWER, payload: true });

    try {
      await initiateAXIOS.post(initiateViewerAPI + "block", payload.data, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      dispatch({ type: SET_SUCCESS_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_VIEWER_PAGE,
        payload: "The viewer has been Blocked.",
      });

      handleSuccessPromptTimeout(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_VIEWER_PAGE, payload: message });

      handleErrorPromptTimeout(dispatch);
    } finally {
      dispatch(getViewers());
    }
  };
};

export const unblockViewer = (payload) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_VIEWER, payload: true });

    try {
      await initiateAXIOS.post(initiateViewerAPI + "unblock", payload, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      dispatch({ type: SET_SUCCESS_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_VIEWER_PAGE,
        payload: "The viewer has been Unblocked.",
      });

      handleSuccessPromptTimeout(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_VIEWER_PAGE, payload: message });

      handleErrorPromptTimeout(dispatch);
    } finally {
      dispatch(getViewers());
    }
  };
};
