import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CircularProgress, Pagination, Box, Alert } from "@mui/material";
import { TypographyNormal } from "../customComponent";
import { usePagination } from "../hooks";
import AnnouncementItem from "./AnnouncementItem";

import { getAnnouncements } from "../../store/actions/announcementAction";
import { colorStyling } from "../../helpers/color";

const AnnouncementList = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAnnouncements());
  }, [dispatch]);

  const { announcements, loadingAnnouncement: loading } = useSelector(
    (state) => state.announcement
  );

  const [page, setPage] = useState(1);

  const PER_PAGE = 5;

  const count = Math.ceil(announcements.length / PER_PAGE);

  const _DATA = usePagination(announcements, PER_PAGE);

  const handleChangePagination = (event, page) => {
    setPage(page);
    _DATA.jump(page);
  };
  useEffect(() => {
    setPage(1);
    _DATA.jump(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [announcements]);

  if (loading)
    return (
      <Box
        sx={{ height: "20vh" }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <CircularProgress
          size={20}
          thickness={3}
          sx={{ color: colorStyling.primary }}
        />
        <TypographyNormal
          sx={{ color: colorStyling.primary, marginTop: "15px" }}
        >
          Loading Data...
        </TypographyNormal>
      </Box>
    );

  return (
    <Box>
      {!loading && !announcements.length ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Alert severity="info" variant="outlined">
            <strong>No results found!</strong> There are no available
            announcements at the moment or We couldn't find what you're looking
            for
          </Alert>
        </Box>
      ) : (
        _DATA
          .currentData()
          .map((announcement) => (
            <AnnouncementItem
              key={announcement.id}
              announcement={announcement}
            />
          ))
      )}

      {!loading && announcements.length ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mt: 4,
          }}
        >
          <Pagination
            count={count}
            page={page}
            shape="rounded"
            color="primary"
            onChange={handleChangePagination}
          />
        </Box>
      ) : null}
    </Box>
  );
};

export default AnnouncementList;
