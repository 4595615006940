import React from "react";
import Sidebar from "../../Sidebar";
import { Box, Container, CssBaseline, Paper, Toolbar } from "@mui/material";
import { TypographyLarge, TypographyXLarge } from "../../customComponent";
import ContentUploadErrorPrompt from "../ContentUploadErrorPrompt";
import ContentUploadSuccessPrompt from "../ContentUploadSuccessPrompt";
import ContentHistoryFilter from "./ContentHistoryFilter";
import ContentHistoryList from "./ContentHistoryList";
import ExportContentHistory from "./ExportContentHistory";

const ContentHistory = () => {
  return (
    <div style={{ display: "flex" }}>
      <Sidebar />

      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Toolbar />
        <CssBaseline />

        <Container maxWidth="xl">
          <Box sx={{ mb: 1 }}>
            <TypographyXLarge sx={{ fontWeight: 700 }}>
              Content History
            </TypographyXLarge>
            <TypographyLarge sx={{ fontWeight: 400 }}>
              List of content history
            </TypographyLarge>
          </Box>

          <ContentUploadSuccessPrompt />
          <ContentUploadErrorPrompt />

          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <ExportContentHistory />
          </Box>
          <Box sx={{ my: 2 }}>
            <Paper
              component="section"
              elevation={2}
              sx={{ p: 2, my: 2, borderRadius: "6px" }}
            >
              <ContentHistoryFilter />
            </Paper>
            <ContentHistoryList />
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default ContentHistory;
