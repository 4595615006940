import React from "react";

const DisconnectedIcon = () => {
  return (
    <svg
      width="1440"
      height="119"
      viewBox="0 0 1440 119"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M529.901 34.4864H499.178C491.84 34.4864 485.911 40.4438 485.911 47.7333V71.2669C485.911 75.3002 487.727 78.9264 490.58 81.3686C492.915 83.3297 495.917 84.5508 499.178 84.5508H529.901V34.4864Z"
        fill="#2B3872"
      />
      <path
        d="M601.538 8.32581H540.167C530.679 8.32581 523.008 15.9853 523.008 25.458V93.5055C523.008 102.978 530.679 110.638 540.167 110.638H601.538V8.32581Z"
        fill="#4656A1"
      />
      <path
        d="M615.065 116.743V2.22017C615.065 0.96209 614.064 -0.0369797 612.804 -0.0369797H603.799C602.539 -0.0369797 601.538 0.96209 601.538 2.22017V116.743C601.538 118.001 602.539 119 603.799 119H612.804C614.027 119 615.065 118.001 615.065 116.743Z"
        fill="#38488C"
      />
      <path
        d="M615.064 74.856V92.2842H656.831C661.649 92.2842 665.577 88.3989 665.577 83.5516C665.577 78.7413 661.686 74.819 656.831 74.819H615.064V74.856Z"
        fill="#232851"
      />
      <path
        d="M615.064 26.7159V44.144H656.831C661.649 44.144 665.577 40.2588 665.577 35.4115C665.577 30.6011 661.686 26.6789 656.831 26.6789H615.064V26.7159Z"
        fill="#232851"
      />
      <path
        d="M601.538 8.32581H540.167C530.679 8.32581 523.008 15.9853 523.008 25.458V93.5055C523.008 102.978 530.679 110.638 540.167 110.638C545.281 37.1507 601.538 30.4163 601.538 30.4163V8.32581Z"
        fill="#38488C"
      />
      <path d="M601.554 8.33315V110.645H596.587V8.33315H601.554Z" fill="#2B3872" />
      <path
        d="M575.596 8.32567H540.167C530.679 8.32567 523.008 15.9852 523.008 25.4578V73.228C523.008 25.0138 575.596 8.32567 575.596 8.32567Z"
        fill="#2B3872"
      />
      <path d="M522.994 34.5039V84.5312H519.77V34.5039H522.994Z" fill="#232851" />
      <path d="M621.168 74.8472V92.2754H615.053V74.8472H621.168Z" fill="#15172D" />
      <path d="M621.177 26.6978V44.126H615.062V26.6978H621.177Z" fill="#15172D" />
      <path
        d="M523.008 34.4865H499.178C491.84 34.4865 485.911 40.4439 485.911 47.7334V71.267C485.911 75.3002 487.727 78.9265 490.58 81.3687C486.059 44.6992 523.008 39.1118 523.008 39.1118V34.4865Z"
        fill="#232851"
      />
      <path
        d="M894.016 34.4864H924.738C932.076 34.4864 938.006 40.4438 938.006 47.7333V71.2669C938.006 75.3002 936.19 78.9264 933.336 81.3686C931.001 83.3297 928 84.5508 924.738 84.5508H894.016V34.4864Z"
        fill="#2B3872"
      />
      <path
        d="M822.416 8.32581H883.787C893.274 8.32581 900.946 15.9853 900.946 25.458V93.5055C900.946 102.978 893.274 110.638 883.787 110.638H822.416V8.32581Z"
        fill="#4656A1"
      />
      <path
        d="M808.889 116.743V2.22017C808.889 0.96209 809.889 -0.0369797 811.149 -0.0369797H820.155C821.415 -0.0369797 822.416 0.96209 822.416 2.22017V116.743C822.416 118.001 821.415 119 820.155 119H811.149C809.889 119 808.889 118.001 808.889 116.743Z"
        fill="#38488C"
      />
      <path
        d="M822.416 8.32581H883.787C893.274 8.32581 900.946 15.9853 900.946 25.458V93.5055C900.946 102.978 893.274 110.638 883.787 110.638C878.673 37.1507 822.416 30.4163 822.416 30.4163V8.32581Z"
        fill="#38488C"
      />
      <path d="M827.362 8.33557V110.647H822.396V8.33557H827.362Z" fill="#2B3872" />
      <path
        d="M848.357 8.32567H883.787C893.274 8.32567 900.946 15.9852 900.946 25.4578V73.228C900.946 25.0138 848.357 8.32567 848.357 8.32567Z"
        fill="#2B3872"
      />
      <path d="M904.141 34.4883V84.5156H900.917V34.4883H904.141Z" fill="#232851" />
      <path
        d="M900.946 34.4865H924.775C932.113 34.4865 938.043 40.4439 938.043 47.7334V71.267C938.043 75.3002 936.227 78.9265 933.373 81.3687C937.895 44.6992 900.946 39.1118 900.946 39.1118V34.4865Z"
        fill="#232851"
      />
      <path d="M485.911 67.1963H-207V51.8032H485.911V67.1963Z" fill="#2C447D" />
      <path d="M1646 65.6055H938.043V50.2124H1646V65.6055Z" fill="#2C447D" />
    </svg>
  );
};

export default DisconnectedIcon;
