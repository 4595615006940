import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  Box,
  Select,
  FormControl,
  CircularProgress,
  MenuItem,
  OutlinedInput,
  IconButton,
  Button,
  Paper,
} from "@mui/material";
import { FiberManualRecord, NavigateNext } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

import { TypographyLarge, TypographyNormal, TypographySmall } from "../customComponent";

import { dateFormat } from "../../helpers/dateFormat";
import { getPendingActiveSchedules } from "../../store/actions/homeAction";
import { useDispatch } from "react-redux";
import { ScheduleYellowIcon } from "../../icons";
import { ViewDetailSchedule } from "../index";
import {
  switchBackgroundColorBasedOnStatus,
  switchColorOnScheduleStatus,
} from "../../helpers/switchColor";

const useStyles = makeStyles(() => ({
  hover: { "&:hover": { backgroundColor: "#DBE9FE", cursor: "pointer" } },
}));

const HomeScheduleList = () => {
  const dispatch = useDispatch();

  const {
    pendingActiveSchedulesData: schedules,
    loadingPendingActiveSchedulesData: loading,
  } = useSelector((state) => state.home);

  const [integer, setInteger] = useState(5);

  const handleChange = (e) => {
    setInteger(e.target.value);
    dispatch(getPendingActiveSchedules(e.target.value));
  };

  useEffect(() => {
    dispatch(getPendingActiveSchedules(5));
  }, [dispatch]);

  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        height={250}
      >
        <CircularProgress size={22} thickness={3} sx={{ color: "#1D4ED8" }} />
        <TypographyNormal sx={{ color: "#1D4ED8", marginTop: "15px", fontWeight: 300 }}>
          Loading Data...
        </TypographyNormal>
      </Box>
    );

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <TypographyLarge sx={{ fontWeight: 600 }}>Recent</TypographyLarge>

          <Box display="flex" alignItems="center">
            <FormControl>
              <Select
                input={<OutlinedInput size="small" />}
                onChange={handleChange}
                value={integer}
              >
                <MenuItem dense value={5}>
                  5
                </MenuItem>
                <MenuItem dense value={10}>
                  10
                </MenuItem>
              </Select>
            </FormControl>
            <TypographyLarge sx={{ ml: 2, fontWeight: 600 }}>Schedules</TypographyLarge>
          </Box>
        </Box>

        {Array.isArray(schedules) && !schedules.length ? (
          <TypographyNormal align="center">No schedule</TypographyNormal>
        ) : (
          <Paper
            elevation={2}
            sx={{
              maxHeight: 425,
              overflowY: "auto",
            }}
          >
            {schedules.map((schedule) => (
              <HomeScheduleItem key={schedule.id} schedule={schedule} />
            ))}
          </Paper>
        )}
      </Box>

      <Link to="/schedules" style={{ textDecoration: "none" }}>
        <Button variant="contained" fullWidth>
          View All Schedules
        </Button>
      </Link>
    </>
  );
};

const HomeScheduleItem = ({ schedule }) => {
  const classes = useStyles();

  const [openDetail, setOpenDetail] = useState(false);

  const handleOpenDetail = () => {
    setOpenDetail(true);
  };

  const handleCloseOpenDetail = () => {
    setOpenDetail(false);
  };
  return (
    <>
      <Box
        onClick={handleOpenDetail}
        sx={{ borderRadius: "6px", mb: 1 }}
        className={classes.hover}
        p={1}
        boxShadow="0px 4px 20px rgba(158, 181, 195, 0.18)"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex">
          <ScheduleYellowIcon />

          <Box display="flex" flexDirection="column" ml={2}>
            <TypographyNormal sx={{ fontWeight: 600, mb: 0.5 }}>
              {schedule.name}
            </TypographyNormal>

            <Box display="inline-flex" flexWrap="wrap" alignItems="center">
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 4.33333V7L9 9M13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7Z"
                  stroke="#1D4ED8"
                  strokeWidth="1.6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <TypographySmall
                sx={{
                  mx: 0.5,
                  textTransform: "lowercase",
                  color: "#1D4ED8",
                  fontWeight: 300,
                }}
              >
                {schedule.frequencyName}
              </TypographySmall>

              <svg
                width="6"
                height="6"
                viewBox="0 0 6 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="3" cy="3" r="3" fill="#9CA3AF" />
              </svg>

              <TypographySmall sx={{ ml: 0.5, fontWeight: 300 }}>
                next at {dateFormat(schedule.nextDate)}
              </TypographySmall>
            </Box>
          </Box>
        </Box>

        <Box display="inline-flex" alignItems="center">
          <TypographySmall
            sx={{
              color: switchColorOnScheduleStatus(schedule.status),
              backgroundColor: switchBackgroundColorBasedOnStatus(schedule.status),
              borderRadius: "15px",
              p: 1,
              mr: 2,
              fontWeight: 500,
            }}
          >
            <FiberManualRecord
              sx={{
                color: switchColorOnScheduleStatus(schedule.status),
                fontSize: 8,
                marginRight: "5px",
              }}
            />
            {schedule.status === "ACTIVE" ? "Active" : "Pending"}
          </TypographySmall>

          <IconButton color="inherit">
            <NavigateNext />
          </IconButton>
        </Box>
      </Box>

      <ViewDetailSchedule
        schedule={schedule}
        onClose={handleCloseOpenDetail}
        openDetail={openDetail}
      />
    </>
  );
};

export default HomeScheduleList;
