import { useSelector } from "react-redux";

import { Container, CssBaseline, Paper, Toolbar } from "@mui/material";
import { Box } from "@mui/system";
import { TypographyLarge, TypographyXLarge } from "../customComponent";
import Sidebar from "../Sidebar";
import UserSuccessPrompt from "./UserSuccessPrompt";
import UserErrorPrompt from "./UserErrorPrompt";
import UserList from "./UserList";
import UserFilter from "./UserFilter";
import CreateUser from "./CreateUser";

import { decrypt } from "../../helpers/crypto";
import Role from "../../helpers/roles";
import UserHistoryNavigateButton from "./history/UserHistoryNavigateButton";

const User = () => {
  const { loggedUser } = useSelector((state) => state.auth);
  const decryptedLoggedUser = decrypt(loggedUser);
  const userRole = decryptedLoggedUser.type;

  return (
    <div style={{ display: "flex" }}>
      <Sidebar />

      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Toolbar />
        <CssBaseline />

        <Container maxWidth="xl">
          <Box sx={{ mb: 1 }}>
            <TypographyXLarge sx={{ fontWeight: 700 }}>User</TypographyXLarge>
            <TypographyLarge sx={{ fontWeight: 400 }}>
              List of users who manage viewers, groups, announcements and
              schedules
            </TypographyLarge>
          </Box>

          <UserSuccessPrompt />
          <UserErrorPrompt />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {userRole !== Role.User && <UserHistoryNavigateButton />}

            {(userRole === Role.Eins || userRole === Role.Admin) && (
              <CreateUser />
            )}
          </Box>

          <Paper
            component="section"
            elevation={2}
            sx={{ p: 2, my: 2, borderRadius: "6px" }}
          >
            <UserFilter />
          </Paper>

          <UserList />
        </Container>
      </Box>
    </div>
  );
};

export default User;
