import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputLabel,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";

import { createAnnouncement } from "../../store/actions/announcementAction";
import { initiateAXIOS } from "../../config/axios";
import { contentCMSAPILimit } from "../../api";
import { CustomTextField } from "../customComponent";

const CreateAnnouncement = () => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("false");
  const [loadingContentList, setLoadingContentList] = useState(false);

  const [name, setName] = useState("");
  const [customContentList, setCustomContentList] = useState([]);
  const [selectCustomContent, setSelectCustomContent] = useState("");
  const [selectPriority, setSelectPriority] = useState(2);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    handleClearData();
    setOpen(false);
  };

  /**
   * Reset form data
   */
  const handleClearData = () => {
    setName("");
    setCustomContentList([]);
    setSelectCustomContent("");
    setSelectPriority(2);
  };

  /**
   * Handle closing error alert
   */
  const handleCloseErr = () => {
    setIsError(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let payload = {
      name: name,
      command: "PLAY_CONTENT",
      type: "ANNOUNCEMENT",
      contentId: +selectCustomContent,
      priority: +selectPriority,
    };

    dispatch(createAnnouncement(payload));

    handleClickClose();
  };

  const getCMSContent = async () => {
    const access_token = sessionStorage.getItem("access_token");
    setLoadingContentList(true);
    try {
      const { data } = await initiateAXIOS.get(contentCMSAPILimit, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      setCustomContentList(data.items);
    } catch (error) {
      setIsError(true);
      setErrorMessage("Cannot retrieve content list");
      setTimeout(handleCloseErr, 5000);
    } finally {
      setLoadingContentList(false);
    }
  };

  useEffect(() => {
    if (open) {
      getCMSContent();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <Button
        sx={{ boxShadow: 3 }}
        variant="contained"
        onClick={handleClickOpen}
        startIcon={<AddIcon fontSize="small" />}
      >
        Create New Announcement
      </Button>

      <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClickClose}>
        <Box component="form" onSubmit={handleSubmit}>
          <DialogTitle
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            New Announcement
            <IconButton onClick={handleClickClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <Divider />

          <DialogContent>
            {isError && (
              <Alert
                sx={{ my: 2 }}
                severity="error"
                variant="filled"
                onClose={handleCloseErr}
              >
                <AlertTitle>Something went wrong!</AlertTitle>
                {errorMessage}
              </Alert>
            )}

            <Box sx={{ mb: 1 }}>
              <InputLabel>Announcement Name</InputLabel>

              <TextField
                id="announcement-name"
                autoFocus
                fullWidth
                margin="dense"
                placeholder="Enter announcement name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </Box>

            <Box sx={{ mb: 1 }}>
              <InputLabel>Select Content</InputLabel>

              {loadingContentList ? (
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{ width: "100%", height: "56px" }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <CustomTextField
                  select
                  required
                  SelectProps={{ native: true }}
                  fullWidth
                  margin="dense"
                  name="contentSelect"
                  value={selectCustomContent}
                  onChange={(e) => {
                    setSelectCustomContent(e.target.value);
                  }}
                >
                  <option value="" disabled>
                    Choose Content
                  </option>
                  {customContentList.map((content) => (
                    <option value={content.id} key={content.id}>
                      {content.name}
                    </option>
                  ))}
                </CustomTextField>
              )}
            </Box>

            <Box>
              <InputLabel>Select Priority</InputLabel>
              <CustomTextField
                select
                required
                SelectProps={{ native: true }}
                fullWidth
                margin="dense"
                name="contentSelect"
                value={selectPriority}
                onChange={(e) => {
                  setSelectPriority(+e.target.value);
                }}
              >
                <option value="0">Highest</option>
                <option value="1">High</option>
                <option value="2">Normal</option>
                <option value="3">Low</option>
                <option value="4">Lowest</option>
              </CustomTextField>
            </Box>
          </DialogContent>

          <DialogActions>
            <Button fullWidth variant="outlined" onClick={handleClickClose}>
              Cancel
            </Button>
            <Button fullWidth type="submit" variant="contained">
              Create
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default CreateAnnouncement;
