import React from "react";

const TableIcon = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.9999 4.80005C4.01168 4.80005 2.3999 6.41182 2.3999 8.40005V15.6C2.3999 17.5883 4.01168 19.2 5.9999 19.2H17.9999C19.9881 19.2 21.5999 17.5883 21.5999 15.6V8.40005C21.5999 6.41182 19.9881 4.80005 17.9999 4.80005H5.9999ZM4.7999 15.6V14.4H10.7999V16.8H5.9999C5.33716 16.8 4.7999 16.2628 4.7999 15.6ZM13.1999 16.8H17.9999C18.6627 16.8 19.1999 16.2628 19.1999 15.6V14.4H13.1999V16.8ZM13.1999 12H19.1999V9.60005H13.1999V12ZM10.7999 9.60005H4.7999V12H10.7999V9.60005Z"
          fill="#9CA3AF"
        />
      </svg>
    </>
  );
};

export default TableIcon;
