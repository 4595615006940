import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";

import { useDrag } from 'react-dnd';

const PreviewItem = ({ id, layer }) => {
  const { layerMedias } = useSelector((state) => state.cms);

  const [layerPreview, setLayerPreview] = useState(null);

  useEffect(() => {
    if (layerMedias[id]?.length > 0) {
      const medias = [...layerMedias[id]];
      let previewEl = null;
      let fileSrc = null;

      const firstMedia = medias[0];
      switch(firstMedia.type) {
        case "VIDEO":
          fileSrc = firstMedia.vidSrc;
          previewEl = <video
            ref={(videoRef) => { if (videoRef) { videoRef.pause(); videoRef.removeAttribute('src'); videoRef.load(); } }}
            draggable={false}
            alt={firstMedia.name}
            controls={true}
            muted={true}
            style={{
              height: "100%",
              width: "100%",
              objectFit: "fill",
            }}
          >
            <source src={firstMedia.url ?? fileSrc} type="video/mp4" />
          </video>;
          break;
  
        case "IMAGE":
        default:
          fileSrc = firstMedia.thumbnailUrl ?? firstMedia.src;
          previewEl = <img
            draggable={false}
            src={fileSrc}
            alt={`preview layer #${layer.indexLayer}`}
            style={{
              height: "100%",
              width: "100%",
              objectFit: "fill",
            }}
          />;
          break;
      }
      setLayerPreview(previewEl);
    } else {
      const dummyImg = `https://placehold.co/${layer.width}x${layer.height}`;
      const previewEl = <img
        draggable={false}
        src={dummyImg}
        alt={`preview layer #${layer.indexLayer}`}
        style={{
          height: "100%",
          width: "100%",
          objectFit: "fill",
        }}
      />;
      setLayerPreview(previewEl);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layer, layerMedias]);

  const [{ isDragging }, dragBox] = useDrag(
    () => ({
      type: 'box',
      item: { id, ...layer }, // left, top, width, height
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [id, layer],
  )

  if (isDragging) {
    return <Box ref={dragBox} />
  }

  return (
    <Box
      ref={dragBox}
      sx={{
        top: layer.top,
        left: layer.left,
        width: layer.ratiodWidth,
        height: layer.ratiodHeight,
        lineHeight: 0,
        position: "absolute",
        backgroundColor: "gray",
        opacity: isDragging ? 0.5 : 1,
        cursor: !isDragging ? "grab" : "grabbing",
        border: layer.active ? "dodgerblue" : "burlywood",
        borderWidth: layer.active ? 2 : 1,
        borderStyle: layer.active ? "groove" : "groove",
      }}
    >
      { layerPreview }
    </Box>
  );
};

export default PreviewItem;
